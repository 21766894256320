export const environment = {
    production: false,
    apiKey: "5f3cd49bf3bc85f2558e6421",
    captchaKey: "6LfV5ccZAAAAACClqoeS8Vv8B_HyXJfd57AaFvKI",
    APIURL: "https://liquidasset-stage-backend-a7045d17c275.herokuapp.com",
    headerTitle: "Liquid Assets Stage",
    securityTokenRegistryAddress: '0xd8A77dc3EbC544B0f1B66b6528814897b7856b04',
    PlatformAddress: '0x2bf9085451FCfBDc592A9ed1FeE058E6EAB734a5',
    ipfsProjectId: '6d5a1864c4894f95ada6e88db97844a5',
    ipfsProjectSecret: '9BDaeqqTYdv7zZdWZMOIhKaETIzGwP2Ku+17M+t+AqMWGCVyn9MOlA',
    ipfsDomain: 'https://retap-master-stage.infura-ipfs.io',
    allowedNetworks: ['11155111'],
    loginCaptha: 'false',
    twoFactorEmailVerification: false,
    currencySign: '₹',
    acuantUrl: 'https://staging.identitymind.com/edna/applications',
  };
  