import { lazy } from 'react'

// here is new home page authored
//const HomePage = lazy(() => import('./view/HomePage'))
const HomePage = lazy(() => import('./layout/Homepage/HomePage'))

const RouteList = [
  { exact: true, path: "/",    component: HomePage }
]


export default RouteList