import React, { useEffect, useState } from "react";
import { InvestorService } from "../Investor/Investor.service";
import { AuthService } from "../Shared/Auth.service";
import moment from "moment";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { Card } from "antd";
import { environment } from "../../environments/environment";
import axios from "axios";
import { APIResponse } from "../Shared/interfaces";
import { SharedService } from "../Shared/Shared.service";

declare global {
  interface Window {
    ethereum: any;
  }
}
const authService = new AuthService();
const useUserContext = () => authService.useUserContext();
const investorService = new InvestorService();
const sharedService = new SharedService();

function TokenRecovery() {
  const APIURL = environment.APIURL;
  const [companyId, setCompanyId] = useState("");
  const [investorId, setinvesterId] = useState("");

  const [walletAdress, setWalletAdress] = useState("");
  const getWalletAdress = () => {
    window.ethereum.request({ method: "eth_requestAccounts" }).then((res) => {
      // Return the address of the wallet
      console.log("Wallet address: ", res);
      setWalletAdress(res[0]!.toString());
    });
    window.ethereum.on("accountsChanged", function (accounts) {
      // Time to reload your interface with accounts[0]!
      console.log("accounts: ", accounts[0]);
      setWalletAdress(accounts[0]);
    });
  };

  const { userInfo, setUserInfo } = useUserContext();
  const [name, setName] = useState("Jhon");
  const [presentAddress, setPresentAddress] = useState("H-23, Delhi, India");
  const [permanentAddress, setPermanentAddress] =
    useState("H-23, Delhi, India");
  const [contactNumber, setContactNumber] = useState("+91 1234567890");
  const [email, setEmail] = useState("abc@gmail.com");
  const [fraudRule, setFraudRule] = useState("Full Result Frn");
  const [userReputation, setUserReputation] = useState("Full Result user");
  const [userPreviousReputation, setUserPreviousReputation] =
    useState("Full Result upr");
  const [reasonForReputation, setReasonForReputation] =
    useState("Full Result erd");
  const [investorDetails, setInvestorDetails] = useState(null);
  useEffect(() => {
    (async () => {
      const response = await investorService.getKYCDetails({
        investorId: userInfo?._id,
      });
      setInvestorDetails(response.data["0"]);
      getWalletAdress();
      getUsers();
      // uploadDeedDocuments();
    })();
  }, []);

  const getUsers = async () => {
    axios
      .post<any, APIResponse>(
        `${APIURL}/users/getUser`,
        {},
        { headers: await sharedService.getAuthHeader() }
      )
      .then((response: any) => {
        setCompanyId(response.data.company.id);
        setinvesterId(response.data._id);
        console.log("companyId: ", response);
      });
  };

  const uploadDeedDocuments = async () => {
    axios
      .post<any, APIResponse>(
        `${APIURL}/issuerSuperAdmin/uploadDeedDocuments?companyId=${companyId}`,
        {
          investorId: investorId,
          name: name,
          presentAddress: presentAddress,
          permanentAddress: permanentAddress,
          contactNumber: contactNumber,
          email: email,
          nameOfFraudRule: fraudRule,
          userReputation: userReputation,
          reasonForReputation: reasonForReputation,
        },
        { headers: await sharedService.getAuthHeader() }
      )
      .then((response: any) => {
        if (response.success == true) {
          alert(response.data);
        }
        if (response.success == false) {
          alert(response.error.message);
        }
        setCompanyId(response.data._id);
      });
  };

  return (
    <>
      <div style={{ display: "grid" }}>
        <p
          style={{
            fontSize: "22px",
            fontWeight: "bold",
            textAlign: "left",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "20px",
          }}
        >
          Token Recovery
        </p>
        <Card
          style={{
            margin: "auto",
            width: 900,
            boxShadow:
              "0 4px 12px 0 rgba(0, 0, 0, .1), 0 6px 20px 0 rgba(0, 0, 0, 0.1)",
          }}
        >
          <table style={{ margin: "auto", width: "100%" }}>
            <tr>
              <td style={{ textAlign: "left", fontWeight: "bold" }}>Name</td>
              <td style={{ textAlign: "left", height: "2rem" }}>
                <input value={name} onChange={(e) => setName(e.target.value)} />
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "left", fontWeight: "bold" }}>
                Present Address
              </td>
              <td style={{ textAlign: "left", height: "2rem" }}>
                <input
                  value={presentAddress}
                  onChange={(e) => setPresentAddress(e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "left", fontWeight: "bold" }}>
                Permanent Address
              </td>
              <td style={{ textAlign: "left", height: "2rem" }}>
                <input
                  value={permanentAddress}
                  onChange={(e) => setPermanentAddress(e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "left", fontWeight: "bold" }}>
                Contact Number
              </td>
              <td style={{ textAlign: "left", height: "2rem" }}>
                <input
                  value={contactNumber}
                  onChange={(e) => setContactNumber(e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "left", fontWeight: "bold" }}>Email</td>
              <td style={{ textAlign: "left", height: "2rem" }}>
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "left", fontWeight: "bold" }}>
                Last Stage run
              </td>
              <td style={{ textAlign: "left", fontWeight: "bold" }}>3</td>
            </tr>
            <tr>
              <td style={{ textAlign: "left", fontWeight: "bold" }}>
                Date of KYC Request
              </td>
              <td style={{ textAlign: "left", height: "2rem" }}>
                {/* {moment(investorDetails?.dateOfSubmission).format("LLL")} */}
                19 July 2023
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "left", fontWeight: "bold" }}>
                Customer since
              </td>
              <td style={{ textAlign: "left", height: "2rem" }}>
                {moment(userInfo?.creationTS).format("LLL")}
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "left", fontWeight: "bold" }}>
                Priority
              </td>
              <td style={{ textAlign: "left", height: "2rem" }}>Normal</td>
            </tr>
            <tr>
              <td style={{ textAlign: "left", fontWeight: "bold" }}>
                Progress
              </td>
              <td style={{ textAlign: "left", height: "2rem" }}>New</td>
            </tr>
            <tr>
              <td style={{ textAlign: "left", fontWeight: "bold" }}>
                Name of fraud rule
              </td>
              <td style={{ textAlign: "left", height: "2rem" }}>
                <input
                  value={fraudRule}
                  onChange={(e) => setFraudRule(e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "left", fontWeight: "bold" }}>
                Result of fraud evaluation
              </td>
              {/* {investorDetails?.fullResult.frp === "ACCEPT" && (
              <td style={{ textAlign: "left", color: "green" }}>Accepted</td>
            )}
            {investorDetails?.fullResult.frp !== "ACCEPT" && (
              <td style={{ textAlign: "left", color: "red" }}>Rejected</td>
            )} */}
              <td style={{ textAlign: "left", color: "green" }}>Accepted</td>
            </tr>
            <tr>
              <td style={{ textAlign: "left", fontWeight: "bold" }}>
                Result of policy evaluation
              </td>
              {/* {investorDetails?.fullResult.res === "ACCEPT" && (
              <td style={{ textAlign: "left", color: "green" }}>Accepted</td>
            )}
            {investorDetails?.fullResult.res !== "ACCEPT" && (
              <td style={{ textAlign: "left", color: "red" }}>Rejected</td>
            )} */}
              <td style={{ textAlign: "left", color: "green" }}>Accepted</td>
            </tr>
            <tr>
              <td style={{ textAlign: "left", fontWeight: "bold" }}>
                User reputation
              </td>
              <td style={{ textAlign: "left", height: "2rem" }}>
                <input
                  value={userReputation}
                  onChange={(e) => setUserReputation(e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "left", fontWeight: "bold" }}>
                User previous reputation
              </td>
              <td style={{ textAlign: "left", height: "2rem" }}>
                <input
                  value={userPreviousReputation}
                  onChange={(e) => setUserPreviousReputation(e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "left", fontWeight: "bold" }}>
                Reason for reputation
              </td>
              <td style={{ textAlign: "left", height: "2rem" }}>
                <input
                  value={reasonForReputation}
                  onChange={(e) => setReasonForReputation(e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "left", fontWeight: "bold" }}>
                New Wallet Adress
              </td>
              <td style={{ textAlign: "left", height: "2rem" }}>
                <input
                  style={{ width: "100%", padding: "2px" }}
                  value={walletAdress}
                  disabled
                ></input>
              </td>
            </tr>
          </table>
        </Card>
        <button
          style={{
            background: "blue",
            color: "white",
            width: "fitontent",
            padding: "4px 16px",
            fontSize: "16px",
            fontWeight: "bold",
            borderRadius: "6px",
            margin: "10px auto 10px auto",
          }}
          onClick={uploadDeedDocuments}
        >
          Recover Token
        </button>
      </div>
    </>
  );
}

export default TokenRecovery;
