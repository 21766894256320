import { Button, Card, Col, Row, Spin, Table, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { APIResponse, RecoveryRequest } from "../../Shared/interfaces";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { RecoveryRequestsService } from "../RecoveryRequests.service";
import { SharedService } from "../../Shared/Shared.service";
import { MetamaskService } from "../../Shared/Metamask.service";
import { environment } from "../../../environments/environment";
import axios from "axios";

const { Title } = Typography;
const recoveryRequestsService = new RecoveryRequestsService();
const sharedService = new SharedService();
const useSelectedWalletContext = () =>
  new MetamaskService().useSelectedWalletContext();

export default function RecoveryRequestsList() {
  const APIURL = environment.APIURL;
  const [companyId, setCompanyId] = useState("");
  
  const { networkId } = useSelectedWalletContext();
  const [loading, setLoading] = useState<boolean>(true);

  const [recoveryProcessReqs, setRecoveryProcessReqs] =
    useState<RecoveryRequest[]>();

  const history = useHistory();

  useEffect(() => {
    (async () => {
      getUsers();
    })();
  }, []);

  const getUsers = async () => {
    axios
      .post<any, APIResponse>(
        `${APIURL}/users/getUser`,
        {},
        { headers: await sharedService.getAuthHeader() }
      )
      .then((response: any) => {
        setCompanyId(response.data.company.id);
        console.log("companyId: ", response.data.company.id);
        approveOrRejectKYCRequestForTokenTransfer(response.data.company.id);
      });
  };

  const approveOrRejectKYCRequestForTokenTransfer = async (compId: any) => {
    axios
      .get<any, APIResponse>(
        `${APIURL}/issuerSuperAdmin/getAllApprovedOrRejectedKYCRequestForTokenTransfer?companyId=${compId}`,
        { headers: await sharedService.getAuthHeader() }
      )
      .then((response: any) => {
        console.log("response: ", response);

        const _recoveryProcessReqs = response.data as RecoveryRequest[];
        setRecoveryProcessReqs(_recoveryProcessReqs);
        setLoading(false);
      });
  };

  const tokenTransfer = async (compId, investorId) => {
    axios
      .post(
        `${APIURL}/issuerSuperAdmin/tokenTransferBYLLPTokenAdminForInvestorApprovedRequest?companyId=${compId}`,
        {
          investorId: investorId,
        },
        { headers: await sharedService.getAuthHeader() }
      )
      .then(async (res) => {
        getUsers();
      });
  };

  const columns = [
    {
      title: "FIRST NAME",
      dataIndex: "investorDetails",
      key: "firstName",
      render: (item) => Object.values(item.firstName),
    },
    {
      title: "LAST NAME",
      dataIndex: "investorDetails",
      key: "lastName",
      render: (item) => Object.values(item.lastName),
    },
    {
      title: "EMAIL",
      dataIndex: "investorDetails",
      key: "email",
      render: (item) => Object.values(item.email),
    },
    {
      title: "PHONE",
      dataIndex: "investorDetails",
      key: "contactNumber",
      render: (item) => Object.values(item.contactNumber),
    },
    {
      title: "TOKEN NAME",
      dataIndex: "tokenName",
      key: "tokenName",
    },
    {
      title: "TOKEN Count",
      dataIndex: "tokenCount",
      key: "tokenCount",
    },
    {
      title: "CREATED ON",
      dataIndex: "dateOfSubmission",
      key: "creationTS",
      render: (text) => moment(text).format("LLL"),
    },
    {
      title: "ACTION",
      dataIndex: "action",
      key: "action",
      render: (text, investor) => (
        <Button type="primary" onClick={() => {
          tokenTransfer(investor.companyId, investor.investorId);
        }}>
          Execute
        </Button>
      ),
    },
  ];

  return (
    <>
      <br />
      <br />
      <Row justify="center">
        <Col span={20}>
          {loading && (
            <div style={{ textAlign: "center" }}>
              <br />
              <br />
              <Spin size="large" />
            </div>
          )}

          {!loading && (
            <Card>
              <Title level={3} style={{ textAlign: "center" }}>
                Securities Recovery Processes
              </Title>
              <Table
                // scroll={{x: true}}
                columns={columns}
                dataSource={recoveryProcessReqs}
                rowKey="_id"
                pagination={false}
              />
            </Card>
          )}
        </Col>
      </Row>
    </>
  );
}
