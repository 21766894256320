import React from 'react';
import {Tabs,Card} from 'antd';
import Dashboard from './Dashboard'
import TokenTransfer from './TokenTransfer'

const { TabPane } = Tabs;
export default class Demo extends React.Component {
render () {
    return (<>
    <Card>
     <Tabs defaultActiveKey="1" >
          <TabPane tab="Dashboard" key="1">
            <Dashboard></Dashboard>
          </TabPane>
          {/* <TabPane tab="Token Transfer" key="2">
            <TokenTransfer/>
          </TabPane> */}
        </Tabs>
    </Card>
    </>)
}
}
