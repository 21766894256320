import React, { Component, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo/logo.png";
import Config from "../../configure";
import { AuthService } from './../../components/Shared/Auth.service';
import { SharedService } from '../../components/Shared/Shared.service';
// import logo from "../../assets/images/logo/logo.png";
import searchlogo from "../../assets/images/icons/search.png";
import $ from "jquery";
import "./index.css";
import { userInfo } from "os";
import { environment } from "../../environments/environment";

const useUserContext = () => new AuthService().useUserContext();
const sharedService = new SharedService();
class OuterHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navMenuMobile: false,
      redirect: false,
      userInfo: null,
    };
  }
  toggleNavMenu = () => {

    this.setState({ navMenuMobile: !this.state.navMenuMobile });
  };
  handleScroll = () => {
    if (this.mount) {
      this.setState({ scroll: window.scrollY });
    }
  };

  logout = async() => {
    this.setState({ userInfo: null});;
    sharedService.deleteToken();
    window.location.href = '/';
  }

  componentDidMount() {
    this.mount = true;
    const el = document.querySelector(".gc_main_menu_wrapper");
    this.setState({ top: el.offsetTop + 700, height: el.offsetHeight, userInfo: sharedService.getToken() });
    window.addEventListener("scroll", this.handleScroll);
  }
  componentDidUpdate() {
    this.state.scroll > this.state.top
      ? (document.body.style.paddingTop = `${this.state.height}px`)
      : (document.body.style.paddingTop = "0");
  }
  componentWillUnmount() {
    this.mount = false;
  }

  render() {
    $(document).ready(() => {
      $('.wd_single_index_menu ul li a[href^="#"]').bind(
        "click",
        function (event) {
          event.preventDefault();
          event.stopPropagation();
          var $anchor = $(this);
          if ($(window).width() > 991) {
            var headerH = "60";
          } else {
            headerH = "56";
          }
          $("html, body")
            .stop()
            .animate(
              {
                scrollTop:
                  $($anchor.attr("href")).offset().top - headerH + "px",
              },
              800
            );
        }
      );
      $(window).scroll(function () {
        var windscroll = $(window).scrollTop();
        var target = $(".wd_single_index_menu ul li");
        if (windscroll >= 0) {
          $(".wd_scroll").each(function (i) {
            if ($(this).position().top <= windscroll + 90) {
              target.removeClass("active");
              target.eq(i).addClass("active");
            }
          });
        } else {
          target.removeClass("active");
          $(".wd_single_index_menu ul li:first").addClass("active");
        }
      });
    });
    let navigation = (
      <ul className="navbar-nav me-auto mb-2 mb-lg-0 menu-search">
        <a className="home" style={{ fontWeight: "bolder", paddingTop: '2px' }} href="/"><li>Home</li></a>
        <a className="market" style={{ fontWeight: "bolder", paddingTop: '2px' }} href="/marketplace"><li>Marketplace</li></a>
        {/* <a className="blog" style={{ fontWeight: "bolder" }} href="/marketplace"><li>Blogs</li></a>
        <a className="token" style={{ fontWeight: "bolder" }} href="/marketplace"><li>Tokenization</li></a> */}
        <a className="faq" style={{ fontWeight: "bolder", paddingTop: '2px' }} href="/marketplace"><li>FAQ</li></a>
         {/* <li>
         <img
                      src={searchlogo}
                      alt="logo" className="search" 
                  
                
                    />
         </li> */}
         <div className="menu-area" style={{marginTop: '-10px'}}>
            {this.state.userInfo?.length > 30 ? (
            <div className="login-btn animate__animated animate__bounceInDown animate__slower">
              <Link
                to="/"
                onClick={this.logout}
                style={{
                  paddingLeft: "30px",
                  paddingRight: "30px",
                  fontSize: "13px",
                }}
                className="primary-bttn"
              >
                <span>Logout</span>
              </Link>
            </div>) : (<>
            <div className="login-btn animate__animated animate__bounceInDown animate__slower">
              <Link
                to="/account/login"
                style={{
                  paddingLeft: "30px",
                  paddingRight: "30px",
                  fontSize: "13px",
                }}
                className="primary-bttn"
              >
                <span>Login</span>
              </Link>
            </div>
            <div className="login-btn animate__animated animate__bounceInDown animate__slower">
              <Link
                to="/account/register/investor"
                style={{
                  paddingLeft: "30px",
                  paddingRight: "30px",
                  fontSize: "13px",
                }}
                className="danger-btn"
              >
              
                <span>Register</span>
              </Link>
            </div>
            </>)}
          </div>              
      </ul>
    );
    return (
      <div id="default" className="wd_scroll_wrap wd_scroll">
        <header
          className={`gc_main_menu_wrapper ${
            this.state.scroll > this.state.top
              ? "menu_fixed animated fadeInDown"
              : "" 
          }`} 
        >
          <Container fluid>
            <Row>
              <nav class="navbar navbar-expand-lg navbar-light bg-light">
                <div class="container align-items-baseline">
                  <Link to={Config.defaultPath}>

                    <p className="navbar-brand retapstyle-brand">{environment.headerTitle}</p>
                  </Link>
                  <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                  </button>
                  <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
                    {navigation}
                  </div>
                </div>
              </nav>
          
            





              {/* <Col className="col-xs-12 new-col" sm={12} md={3} lg={3}>
                <div className="logo-area">
                  <Link to={Config.defaultPath}>
                    
                    <p id="retapstyle">RETAP</p>
                  </Link>
                  <div className="menu-area" style={{ display: "flex" }}>
                    <div id="register" className="login-btn">
                      <Link
                        to="/account/login"
                        style={{
                          paddingLeft: "30px",
                          paddingRight: "30px",
                          fontSize: "13px",
                        }}
                        className="primary-bttn"
                      >
                        <i>Login</i>
                      </Link>
                    </div>
                    <div id="login" className="login-btn">
                      <Link
                        to="/account/register/investor"
                        style={{
                          paddingLeft: "30px",
                          paddingRight: "30px",
                          fontSize: "13px",
                        }}
                        className="danger-btn"
                      >
                        <i >Register</i> 
                      </Link>
                    </div>
                  </div>
                </div>
              </Col> */}
              {/* <Col lg={6} md={6} sm={6} className="col-xs-6 new-col">
                <div className="menu-area hidden-xs">
                  <nav className="wd_single_index_menu btc_main_menu">
                    {navigation}
                  </nav>
                  <div className="login-btn animate__animated animate__bounceInDown animate__slower">
                    <Link
                      to="/account/login"
                      style={{
                        paddingLeft: "30px",
                        paddingRight: "30px",
                        fontSize: "13px",
                      }}
                      className="primary-bttn"
                    >
                    
                      <span>Login</span>
                    </Link>
                  </div>
                  <div className="login-btn animate__animated animate__bounceInDown animate__slower">
                    <Link
                      to="/account/register/investor"
                      style={{
                        paddingLeft: "30px",
                        paddingRight: "30px",
                        fontSize: "13px",
                      }}
                      className="danger-btn"
                    >
                    
                      <span>Register</span>
                    </Link>
                  </div>
                </div>
              </Col> */}
            </Row>
          </Container>
        </header>
      </div>
    );
  }
}

export default OuterHeader;
