import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Typography,
  Card,
  Form,
  Input,
  Button,
  Table,
  Spin,
  Result,
  notification,
  Descriptions,
  message,
  Modal,
  Checkbox,
} from 'antd';
import { AuthService } from '../Shared/Auth.service';
import { MetamaskService } from '../Shared/Metamask.service';
import { SecurityTokenRegistryService } from '../Shared/SecurityTokenRegistery/SecurityTokenRegistry.service';
import BigNumber from 'bignumber.js';
import { SharedService } from '../Shared/Shared.service';
import {
  TokenConfigurationProcess,
  SymbolDetailsAndSTData,
  UserLockedAmount,
  WhitelistedWallet,
  FiledNotice,
  RegLaunched,
} from '../Shared/interfaces';
import { IssuerSuperAdminService } from '../IssuerSuperAdmin/IssuerSuperAdmin.service';
import TransactionModal from '../Shared/TransactionModal';
import moment from 'moment';
import { TokenConfigurationService } from '../TokenConfigurations/TokenConfiguration.service';
import { useHistory } from 'react-router-dom';
import { TeamMemberService } from '../TeamMember/TeamMember.service';

import GeneralTransferManagerFacet from '../Shared/SecurityToken/Facets/GeneralTransferManagerFacet/index';
import MainFacet from '../Shared/SecurityToken/Facets/MainFacet/index';
import WhitelistFacet from '../Shared/SecurityToken/Facets/WhitelistFacet/index';
import Rule144Facet from '../Shared/SecurityToken/Facets/Rule144Facet/index';
import ERC20Facet from "../Shared/SecurityToken/Facets/ERC20Facet";
import ERC1410Facet from "../Shared/SecurityToken/Facets/ERC1410Facet";
import TxFeeDelegationModal from "../Shared/TxFeeDelegationModal";

const { Title } = Typography;
const securityTokenRegisteryService = new SecurityTokenRegistryService();
const sharedService = new SharedService();
const issuerSuperAdminService = new IssuerSuperAdminService();
const teamMemberService = new TeamMemberService();
const tokenConfigurationService = new TokenConfigurationService();

const generalTransferManagerFacet = new GeneralTransferManagerFacet();
const mainFacet = new MainFacet();
const whitelistFacet = new WhitelistFacet();
const erc20Facet = new ERC20Facet();
const erc1410Facet = new ERC1410Facet();
const rule144Facet = new Rule144Facet();

const useUserContext = () => new AuthService().useUserContext();
const useSelectedWalletContext = () =>
  new MetamaskService().useSelectedWalletContext();
const etherscanURL = sharedService.etherscanURL;
const affiliateFormCheckboxesOpts = [
  'Make sure that the sale is handled under routine trading transactions',
  'Make sure that neither you (Affiliate) nor Broker cannot solicit the orders to buy the securities',
  'Make sure that the Brokers may not receive more than normal commission',
];
export default function TokenDashboard() {
  const { userInfo } = useUserContext();
  const { selectedWallet, networkId } = useSelectedWalletContext();
  const history = useHistory();
  const [loadingCanTransfer, setLoadingCanTransfer] = useState<boolean>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [symbolDetailsAndSTData, setSymbolDetailsAndSTData] =
    useState<SymbolDetailsAndSTData>();
  const [transactions, setTransactions] = useState<
    { submitting?: boolean; receipt?: any; details: string }[]
  >([]);
  const [monthUnit, setMonthUnit] = useState('');
  const [balance, setBalance] = useState<string>();
  const [totalLockedAmount, setTotalLockedAmount] = useState<string>();
  const [totalLockedAmountAccredeted, setTotalLockedAmountAccredeted] =
    useState<string>();
  const [userLockedAmountList, setUserLockedAmountList] =
    useState<UserLockedAmount[]>();
  const [whitelistedWallet, setWhitelistedWallet] =
    useState<WhitelistedWallet>();
  const [maxAffiliateSellPercentage, setMaxAffiliateSellPercentage] =
    useState<string>();
  const [maxAffiliateAmountMonths, setMaxAffiliateAmountMonths] =
    useState<string>();
  const [maxAffiliateAmount, setMaxAffiliateAmount] = useState<string>();
  const [maxAffiliateTokensMonths, setMaxAffiliateTokensMonths] =
    useState<string>();
  const [maxAffiliateTokens, setMaxAffiliateTokens] = useState<string>();
  const [totalSupply, setTotalSupply] = useState<string>();
  const [affiliateAmountSentIn3Months, setAffiliateAmountSentIn3Months] =
    useState<string>();
  const [affiliateSellLimit, setAffiliateSellLimit] = useState<string>();
  const [affiliateTokenAmountSent, setAffiliateTokenAmountSent] =
    useState<string>();
  const [affiliateUSDAmountSent, setAffiliateUSDAmountSent] =
    useState<string>();
  const [USDPrice, setUSDPrice] = useState<string>();
  const [lastUpdateUSDPrice, setLastUpdateUSDPrice] = useState<string>();

  const [partitions, setPartitions] = useState<string[]>();
  const [partitionBalances, setPartitionBalances] = useState<string[]>();
  const [partitionInfos, setPartitionInfos] = useState<RegLaunched[]>();
  const [selectedPartition, setSelectedPartition] = useState<string>();

  // const [regulationsLaunched, setRegulationsLaunched] = useState<RegLaunched[]>(
  //   []
  // );
  // const [selectedRegToTransfer, setSelectedRegToTransfer] =
  //   useState<RegLaunched>();
  // const [defaultSenderReg, setDefaultSenderReg] = useState<RegLaunched>();
  // const [defaultSenderRegIndex, setDefaultSenderRegIndex] =
  //   useState<number>(-1);
  // const [isDefaultRegModalVisible, setIsDefaultRegModalVisible] =
  //   useState(false);
  // const [regLaunched, setRegLaunched] = useState<RegLaunched>()
  const [isTokenPriceOutdated, setIsTokenPriceOutdated] = useState<boolean>();
  const [needsFiledNoticeWithSEC, setNeedsFiledNoticeWithSEC] =
    useState<boolean>();
  const [requestingPriceUpdate, setRequestingPriceUpdate] = useState<boolean>();
  const [filedNotice, setFiledNotice] = useState<FiledNotice>();
  const [isAffiliateModalVisible, setIsAffiliateModalVisible] =
    useState<boolean>();
  const [isTokenTransferVisible, setIsTokenTransferVisible] =
    useState<boolean>();
  const [form] = Form.useForm();
  const [formUSDPrice] = Form.useForm();
  const [affiliateForm] = Form.useForm();
  const decimals = +(symbolDetailsAndSTData?.securityTokenData
    .decimals as string);
  const precision = mainFacet.precision;
  const tokenSymbol = symbolDetailsAndSTData?.securityTokenData.symbol;

  const [isDelegationModalVisible, setIsDelegationModalVisible] = useState(false);
  const [txParams, setTxParams] = useState<any>();

  useEffect(() => {
    (async () => {
      if (!userInfo || !selectedWallet) return;

      let _tokenConfigurationProcess: TokenConfigurationProcess;
      // if(userInfo.role === 'issuer super admin') {
      _tokenConfigurationProcess = (
        await tokenConfigurationService.getLastTokenConfigurationProcess()
      ).data;
      // } else {
      //   _tokenConfigurationProcess = (await teamMemberService.getTokenConfigurationProcess()).data;
      // }
      // setTokenConfigurationProcess(_tokenConfigurationProcess);

      console.log(_tokenConfigurationProcess);
      // if (_tokenConfigurationProcess?.tokenSymbol) return setLoading(false);

      // const _symbolDetailsAndSTData =
      //   await securityTokenRegisteryService.getSymbolDetailsAndSTData(
      //     _tokenConfigurationProcess.tokenSymbol
      //   );
      // setSymbolDetailsAndSTData(_symbolDetailsAndSTData);

      // if (!_symbolDetailsAndSTData?.symbolDetails.isDeployed)
      //   return setLoading(false);

      // let [
      //   _totalLockedAmount,
      //   _totalLockedAmountAccredeted,
      //   _userLockedAmountListTemp,
      //   _balance,
      //   _whitelistedWallet,
      //   _totalSupply,
      //   _monthUnit,
      //   _partitions
      //   // __regulationsLaunched,
      // ] = await Promise.all([
      //   generalTransferManagerFacet.calculateTotalLockedAmount(
      //     _symbolDetailsAndSTData.securityTokenData.contractAddress,
      //     selectedWallet
      //   ),
      //   generalTransferManagerFacet.calculateTotalLockedAmountAccredited(
      //     _symbolDetailsAndSTData.securityTokenData.contractAddress,
      //     selectedWallet
      //   ),
      //   generalTransferManagerFacet.getUserLockedAmountList(
      //     _symbolDetailsAndSTData.securityTokenData.contractAddress,
      //     selectedWallet
      //   ),
      //   erc20Facet.balanceOf(
      //     _symbolDetailsAndSTData.securityTokenData.contractAddress,
      //     selectedWallet
      //   ),
      //   whitelistFacet.getWhitelistedWallet(
      //     _symbolDetailsAndSTData.securityTokenData.contractAddress,
      //     selectedWallet
      //   ),
      //   erc20Facet.totalSupply(
      //     _symbolDetailsAndSTData.securityTokenData.contractAddress
      //   ),
      //   mainFacet.monthUnit(
      //     _symbolDetailsAndSTData.securityTokenData.contractAddress
      //   ),
      //   erc1410Facet.partitionsOf(
      //     _symbolDetailsAndSTData.securityTokenData.contractAddress,
      //     selectedWallet
      //   )
      //   // mainFacet.getRegulationsLaunched(
      //   //   _symbolDetailsAndSTData.securityTokenData.contractAddress
      //   // ),
      // ]);

      // const [_partitionBalances, _partitionInfos] = await Promise.all([
      //   Promise.all(
      //     _partitions.map(_partition => erc1410Facet.balanceOfByPartition(
      //       _symbolDetailsAndSTData.securityTokenData.contractAddress,
      //       _partition,
      //       selectedWallet
      //       ))
      //   ),
      //   Promise.all(
      //     _partitions.map(_partition => mainFacet.getPartitionInfo(
      //       _symbolDetailsAndSTData.securityTokenData.contractAddress,
      //       _partition,
      //     ))
      //   )
      // ]);


      // let _regulationsLaunched = __regulationsLaunched.map((launched) => ({
      //   regulation: launched.regulation,
      //   dateLaunched: launched.dateLaunched,
      //   regDTransferableOutsideUSA: launched.regDTransferableOutsideUSA,
      //   creationTS: launched.creationTS,
      //   index: launched.index,
      //   legendRemoved: launched.legendRemoved,
      //   typeOfSecurity: launched.typeOfSecurity,
      // }));

      // for(let _regulationLaunched of _regulationsLaunched) {
      //   let _regBalance = await securityTokenService.regBalanceOf(_symbolDetailsAndSTData.securityTokenData.contractAddress, selectedWallet, +_regulationLaunched.index);
      //   _regulationLaunched['balance'] = _regBalance;
      // }

      // for (let _regulationLaunched of _regulationsLaunched) {
      //   let _regBalance = await mainFacet.regBalanceOf(
      //     _symbolDetailsAndSTData.securityTokenData.contractAddress,
      //     selectedWallet,
      //     +_regulationLaunched.index
      //   );
      //   _regulationLaunched['balance'] = _regBalance;
      // }

      // console.log(_regulationsLaunched);

      // console.log(_whitelistedWallet);
      // console.log(_userLockedAmountListTemp);

      // if(_whitelistedWallet.isWhiteListed) {
      //   const _regLaunched = await mainFacet.getRegLaunchedByTS(
      //     _symbolDetailsAndSTData.securityTokenData.contractAddress,
      //     _whitelistedWallet.assetCreationTS
      //   );
      //   setRegLaunched(_regLaunched);
      // }


      // const _userLockedAmountList = [..._userLockedAmountListTemp]
      //   .map((data) => ({
      //     amount: data.amount,
      //     lockPeriod: data.lockPeriod,
      //     dateLocked: data.dateLocked,
      //   }))
      //   .filter((lockAmount) => Date.now() < +lockAmount.lockPeriod * 1000);

      // _userLockedAmountList.forEach(
      //   (userLockedAmount, index) => (userLockedAmount['key'] = index)
      // );

      // setTotalLockedAmount(_totalLockedAmount);
      // setTotalLockedAmountAccredeted(_totalLockedAmountAccredeted);
      // setUserLockedAmountList(_userLockedAmountList);
      // setBalance(_balance);
      // setWhitelistedWallet(_whitelistedWallet);
      // setTotalSupply(_totalSupply);
      // setMonthUnit(_monthUnit);

      // setPartitions(_partitions);
      // setPartitionBalances(_partitionBalances);
      // setPartitionInfos(_partitionInfos);
      // setRegulationsLaunched(_regulationsLaunched);
      // setRegBalance(_regBalance);


      // if (_whitelistedWallet.isAffiliate) {
      //   let [
      //     _getAffiliateRule144Params,
      //     _affiliateAmountSentIn3Months,
      //     _affiliateSellLimit,
      //     _affiliateTokenAmountSent,
      //     _affiliateUSDAmountSent,
      //     _USDPrice,
      //     _filedNoticeResponse,
      //   ] = await Promise.all([
      //     rule144Facet.getAffiliateRule144Params(
      //       _symbolDetailsAndSTData.securityTokenData.contractAddress
      //     ),
      //     generalTransferManagerFacet.calculateAffiliateAmountSentIn3Months(
      //       _symbolDetailsAndSTData.securityTokenData.contractAddress,
      //       selectedWallet
      //     ),
      //     generalTransferManagerFacet.calculateAffiliateSellLimit(
      //       _symbolDetailsAndSTData.securityTokenData.contractAddress
      //     ),
      //     generalTransferManagerFacet.calculateAffiliateTokenAmountSent(
      //       _symbolDetailsAndSTData.securityTokenData.contractAddress,
      //       selectedWallet
      //     ),
      //     generalTransferManagerFacet.calculateAffiliateUSDAmountSent(
      //       _symbolDetailsAndSTData.securityTokenData.contractAddress,
      //       selectedWallet
      //     ),
      //     generalTransferManagerFacet.USDPrice(
      //       _symbolDetailsAndSTData.securityTokenData.contractAddress
      //     ),
      //     teamMemberService.getFiledNotice(),
      //   ]);

      //   setMaxAffiliateSellPercentage(_getAffiliateRule144Params[0]);
      //   setMaxAffiliateAmountMonths(_getAffiliateRule144Params[1]);
      //   setMaxAffiliateAmount(_getAffiliateRule144Params[2]);
      //   setMaxAffiliateTokensMonths(_getAffiliateRule144Params[3]);
      //   setMaxAffiliateTokens(_getAffiliateRule144Params[4]);
      //   setAffiliateAmountSentIn3Months(_affiliateAmountSentIn3Months);
      //   setAffiliateSellLimit(_affiliateSellLimit);
      //   setAffiliateTokenAmountSent(_affiliateTokenAmountSent);
      //   setAffiliateUSDAmountSent(_affiliateUSDAmountSent);
      //   setUSDPrice(_USDPrice);
      //   setFiledNotice(_filedNoticeResponse.data);
      // }

      // if (
      //   selectedWallet.toLowerCase() ===
      //   _symbolDetailsAndSTData.symbolDetails.owner.toLowerCase()
      // ) {
      //   // let [_USDPrice, _lastUpdateUSDPrice] = await Promise.all([
      //   //   generalTransferManagerService.USDPrice(_symbolDetailsAndSTData.securityTokenData.contractAddress),
      //   //   generalTransferManagerService.lastUpdateUSDPrice(_symbolDetailsAndSTData.securityTokenData.contractAddress)
      //   // ]);

      //   let [_USDPrice, _lastUpdateUSDPrice] = await Promise.all([
      //     generalTransferManagerFacet.USDPrice(
      //       _symbolDetailsAndSTData.securityTokenData.contractAddress
      //     ),
      //     generalTransferManagerFacet.lastUpdateUSDPrice(
      //       _symbolDetailsAndSTData.securityTokenData.contractAddress
      //     ),
      //   ]);

      //   setUSDPrice(_USDPrice);
      //   setLastUpdateUSDPrice(_lastUpdateUSDPrice);
      //   // formUSDPrice.setFieldsValue({USDPrice:_USDPrice});
      // }

      setLoading(false);
    })();
  }, [userInfo, selectedWallet]);


  const lockFundsColumns = [
    {
      title: 'Release Date',
      dataIndex: 'lockPeriod',
      render: (value: string, record: UserLockedAmount) => (
        <>
          {!whitelistedWallet?.isAccredetedInvestor && (
            <>
              {moment(new BigNumber(value).times(1000).toNumber()).format(
                'LLL'
              )}
            </>
          )}

          {whitelistedWallet?.isAccredetedInvestor && (
            <>
              {new BigNumber(record.dateLocked)
                .plus(3 * +monthUnit)
                .times(1000)
                .isGreaterThanOrEqualTo(Date.now()) &&
                `
              ${moment(
                new BigNumber(record.dateLocked)
                  .plus(3 * +monthUnit)
                  .times(1000)
                  .toNumber()
              ).format('LLL')} if sender is an Accredeted Investor
              ${moment(new BigNumber(value).times(1000).toNumber()).format(
                'LLL'
              )} if sender is NOT an Accredeted Investor
              `}
              {new BigNumber(record.dateLocked)
                .plus(3 * +monthUnit)
                .times(1000)
                .isLessThan(Date.now()) &&
                `
              Already released if sender is an Accredeted Investor
              ${moment(new BigNumber(value).times(1000).toNumber()).format(
                'LLL'
              )} if sender is NOT an Accredeted Investor
              `}
            </>
          )}
        </>
      ),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      render: (value: string) =>
        `${displayTokenAmount(value)} ${
          symbolDetailsAndSTData?.securityTokenData.symbol
        }`,
    },
  ];

  const partitionColumns = [
    {
      title: 'Partition',
      dataIndex: 'partition',
      render: (value: string) => sharedService.bytes32ToString(value),
    },

    {
      title: 'Regulation',
      dataIndex: 'regulation',
      render: (value: string) => sharedService.regOptions.find(
        (opt) => opt.shortValue === value
      )?.name,
    },
    {
      title: 'Launched on',
      dataIndex: 'dateLaunched',
      render: (value: string) => moment(+value * 1000).format('LLL'),
    },
    {
      title: 'Type of Security',
      dataIndex: 'typeOfSecurity',
      render: (value: string) => `${sharedService.typeOfSecurityName(value)}`,
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      render: (value: string) => `${displayTokenAmount(value)} ${tokenSymbol}`,
    },
    {
      title: 'Action',
      render: (value, row) => (
        <Button onClick={() => openTokenTransferModal(row)} type="primary">
          REGULATION TRANSFER
        </Button>
      ),
    },
  ];

  const displayTokenAmount = (amount) => {
    return new BigNumber(amount)
      .times(
        new BigNumber(10).pow(
          -(symbolDetailsAndSTData?.securityTokenData.decimals as string)
        )
      )
      .toFixed();
  };

  const transferByPartition = async (formValue) => {
    const _to = formValue.to;
    const _value = new BigNumber(formValue.amount)
      .times(new BigNumber(10).pow(decimals))
      .decimalPlaces(0)
      .toFixed();
    // let response = await sharedService.getDetailByWalletAddress({
    //   walletAddress: _to.toLowerCase(),
    // });
    // if (response.data === null) {
    //   notification.error({
    //     message: 'Token Transfer error',
    //     description: 'Wallet Address is Not Whitelisted in Our Platform',
    //   });
    //   return;
    // }
    // const { country } = response.data;
    // const { approvedByICA } = response.data;
    // response = await issuerTokenAdminService.getAllowedCountries({
    //   companyId: userInfo?.company?.id,
    // });
    // const allowedCountries = response.data;
    // if (!approvedByICA) {
    //   notification.error({
    //     message: 'Token Transfer error',
    //     description: 'Wallet Address is Not Whitelisted in Our Platform',
    //   });
    //   return;
    // }
    // if (!allowedCountries.includes(country.toUpperCase())) {
    //   notification.error({
    //     message: 'Token Transfer error',
    //     description:
    //       'User Not Belongs to Allowed Countries for Transfer Exchange',
    //   });
    //   return;
    // }
    setLoadingCanTransfer(true);



    const canTransfer = await erc1410Facet.canTransferByPartition(
      symbolDetailsAndSTData?.securityTokenData.contractAddress as string,
      selectedWallet as string,
      _to,
      selectedPartition as string,
      _value,
    );

    console.log('canTransfer:', canTransfer);

    setLoadingCanTransfer(false);

    if (canTransfer[0] !== '0x51') {
      if (sharedService.bytes32ToString(canTransfer[1]) === 'Price outdated. Contact Issuer') {
        // canTransfer[1] = 'Receiver Wallet belongs to affiliate but Token Price is outdated';
        setIsTokenPriceOutdated(true);
      } else if (
        sharedService.bytes32ToString(canTransfer[1]) === "Token limit reached. File Notice" ||
        sharedService.bytes32ToString(canTransfer[1]) === "USD limit reached. File a Notice"
      ) {
        setNeedsFiledNoticeWithSEC(true);
      }

      notification.error({
        message: 'Token Transfer error',
        description: sharedService.bytes32ToString(canTransfer[1]),
      });
      return;
    }

    setIsModalVisible(true);
    setIsTokenTransferVisible(false);

    setTransactions([{ details: 'Sending funds', submitting: true }]);

    try {
      const receipt = await erc1410Facet.transferByPartition(
        symbolDetailsAndSTData?.securityTokenData.contractAddress as string,
        selectedWallet as string,
        selectedPartition as string,
        _to,
        _value,
      );

      if (receipt.status) {
        let [
          _balance,
          _partitions
        ] = await Promise.all([
          erc20Facet.balanceOf(
            symbolDetailsAndSTData?.securityTokenData.contractAddress as string,
            selectedWallet as string
          ),
          erc1410Facet.partitionsOf(
            symbolDetailsAndSTData?.securityTokenData.contractAddress as string,
            selectedWallet as string
          )
        ]);

        const [_partitionBalances, _partitionInfos] = await Promise.all([
          Promise.all(
            _partitions.map(_partition => erc1410Facet.balanceOfByPartition(
              symbolDetailsAndSTData?.securityTokenData.contractAddress as string,
              _partition,
              selectedWallet as string
            ))
          ),
          Promise.all(
            _partitions.map(_partition => mainFacet.getPartitionInfo(
              symbolDetailsAndSTData?.securityTokenData.contractAddress as string,
              _partition as string,
            ))
          )
        ]);

        setBalance(_balance);
        setPartitions(_partitions);
        setPartitionBalances(_partitionBalances);
        setPartitionInfos(_partitionInfos);


      }

      if (receipt.status && whitelistedWallet?.isAffiliate) {
        let [
          _getAffiliateRule144Params,
          _totalSupply,
          _affiliateAmountSentIn3Months,
          _affiliateSellLimit,
          _affiliateTokenAmountSent,
          _affiliateUSDAmountSent,
          _whitelistedWallet,
          // _USDPrice
        ] = await Promise.all([
          rule144Facet.getAffiliateRule144Params(
            symbolDetailsAndSTData?.securityTokenData.contractAddress as string
          ),
          erc20Facet.totalSupply(
            symbolDetailsAndSTData?.securityTokenData.contractAddress as string
          ),
          generalTransferManagerFacet.calculateAffiliateAmountSentIn3Months(
            symbolDetailsAndSTData?.securityTokenData.contractAddress as string,
            selectedWallet as string
          ),
          generalTransferManagerFacet.calculateAffiliateSellLimit(
            symbolDetailsAndSTData?.securityTokenData.contractAddress as string
          ),
          generalTransferManagerFacet.calculateAffiliateTokenAmountSent(
            symbolDetailsAndSTData?.securityTokenData.contractAddress as string,
            selectedWallet as string
          ),
          generalTransferManagerFacet.calculateAffiliateUSDAmountSent(
            symbolDetailsAndSTData?.securityTokenData.contractAddress as string,
            selectedWallet as string
          ),
          whitelistFacet.getWhitelistedWallet(
            symbolDetailsAndSTData?.securityTokenData.contractAddress as string,
            selectedWallet as string
          ),
          // generalTransferManagerFacet.USDPrice(symbolDetailsAndSTData?.securityTokenData.contractAddress as string)
        ]);

        setMaxAffiliateSellPercentage(_getAffiliateRule144Params[0]);
        setMaxAffiliateAmountMonths(_getAffiliateRule144Params[1]);
        setMaxAffiliateAmount(_getAffiliateRule144Params[2]);
        setMaxAffiliateTokensMonths(_getAffiliateRule144Params[3]);
        setMaxAffiliateTokens(_getAffiliateRule144Params[4]);
        setTotalSupply(_totalSupply);
        setAffiliateAmountSentIn3Months(_affiliateAmountSentIn3Months);
        setAffiliateSellLimit(_affiliateSellLimit);
        setAffiliateTokenAmountSent(_affiliateTokenAmountSent);
        setAffiliateUSDAmountSent(_affiliateUSDAmountSent);
        setWhitelistedWallet(_whitelistedWallet);

        // setUSDPrice(_USDPrice);
      }

      setTransactions((prev) => {
        const _prev = [...prev].map((obj) => ({ ...obj }));
        _prev[0].receipt = receipt;
        return _prev;
      });
    } catch (err) {
      console.error(err);
    }

    setTransactions((prev) => {
      const _prev = [...prev].map((obj) => ({ ...obj }));
      _prev[0].submitting = false;
      return _prev;
    });
  };

  const openTokenTransferModal = async (row: {partition: string}) => {
    // setSelectedRegToTransfer(reg);
    setSelectedPartition(row.partition);

    setIsTokenTransferVisible(true);
  };


  const openTxFeeDelegationModal = async(formValue) => {
    setTxParams(formValue);
    setIsDelegationModalVisible(true);
  }

  const updateUSDPrice = async (prop: {delegate: boolean}) => {
    // formUSDPrice.setFieldsValue({USDPrice: formValue.USDPrice});
    setIsModalVisible(true);

    setTransactions([{ details: 'Updating Price', submitting: true }]);

    try {

      const receipt = await generalTransferManagerFacet.updateUSDPrice(
        symbolDetailsAndSTData?.securityTokenData.contractAddress as string,
        selectedWallet as string,
        new BigNumber(txParams.USDPrice)
          .times(new BigNumber(10).pow(decimals))
          .decimalPlaces(0)
          .toFixed(),
        {delegate: prop.delegate}
      );

      if (receipt?.status)
        setLastUpdateUSDPrice(Math.floor(Date.now() / 1000).toString());

      setTransactions((prev) => {
        const current = JSON.parse(JSON.stringify(prev));
        current[0].receipt = receipt;
        return current;
      });
    } catch (err) {
      console.error(err);
    }

    setTransactions((prev) => {
      const current = JSON.parse(JSON.stringify(prev));
      current[0].submitting = false;
      return current;
    });
  };

  const requestPriceUpdate = async () => {
    setRequestingPriceUpdate(true);

    try {
      const response = await issuerSuperAdminService.requestPriceUpdate();

      if (response.success) {
        message.success('Token Price update request sent successfully');
        setIsTokenPriceOutdated(false);
      } else {
        message.error(response.error.message);
      }
    } catch (err) {
      console.error(err);
    }

    setRequestingPriceUpdate(false);
  };

  // const openDefaultRegModal = () => {
  //   setDefaultSenderRegIndex(
  //     regulationsLaunched.findIndex(
  //       (regLaunched) => regLaunched.creationTS === defaultSenderReg?.creationTS
  //     )
  //   );
  //
  //   setIsDefaultRegModalVisible(true);
  // };

  // const submitDefaultSenderReg = async () => {
  //   const _index = defaultSenderRegIndex;
  //
  //   console.log('_index:', _index);
  //   setIsModalVisible(true);
  //   setIsDefaultRegModalVisible(false);
  //
  //   setTransactions([
  //     { details: 'Setting default regulation', submitting: true },
  //   ]);
  //
  //   try {
  //
  //     const receipt = await mainFacet.setDefaultSenderReg(
  //       symbolDetailsAndSTData?.securityTokenData.contractAddress as string,
  //       selectedWallet as string,
  //       _index
  //     );
  //
  //     if (receipt?.status) {
  //       setDefaultSenderReg(regulationsLaunched[_index]);
  //     }
  //
  //     setTransactions((prev) => {
  //       const current = JSON.parse(JSON.stringify(prev));
  //       current[0].receipt = receipt;
  //       return current;
  //     });
  //   } catch (err) {
  //     console.error(err);
  //   }
  //
  //   setTransactions((prev) => {
  //     const current = JSON.parse(JSON.stringify(prev));
  //     current[0].submitting = false;
  //     return current;
  //   });
  // };

  const filedNoticeExists =
    filedNotice &&
    whitelistedWallet &&
    filedNotice.creationTS > +whitelistedWallet.dateFiledNoticeRemoved * 1000;

  // const selectedERC20 =
  //   (selectedRegToTransfer?.dateLaunched as string) ===
  //   (defaultSenderReg?.dateLaunched as string);

  return (
    <>
      <br />
      <br />
      <Row justify="center">
        <Col span={20}>
          {loading && (
            <div style={{ textAlign: 'center' }}>
              <br />
              <br />
              <Spin size="large" />
            </div>
          )}

          {!loading && (
            <>
              <Card>
                <Title level={1} style={{ textAlign: 'center' }}>
                  Token Dashboard
                </Title>

                {!symbolDetailsAndSTData?.symbolDetails.isDeployed && (
                  <Result title={`Security Token not deployed`} />
                )}

                {symbolDetailsAndSTData?.symbolDetails.isDeployed && (
                  <>
                    <div>
                      <Descriptions bordered column={1}>
                        <Descriptions.Item label={'Token address'}>
                          <a
                            style={{ textDecoration: 'underline' }}
                            href={`${
                              etherscanURL[networkId as string]
                            }/address/${
                              symbolDetailsAndSTData?.securityTokenData
                                .contractAddress
                            }`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {
                              symbolDetailsAndSTData?.securityTokenData
                                .contractAddress
                            }
                          </a>
                        </Descriptions.Item>

                        <Descriptions.Item label={'Total Tokens created'}>
                          {displayTokenAmount(totalSupply)} {tokenSymbol}
                        </Descriptions.Item>
                      </Descriptions>

                      <br />

                      <div style={{ textAlign: 'center' }}>
                        <Title level={3}>
                          Balance: {displayTokenAmount(balance)} {tokenSymbol}
                        </Title>
                        {/*{regLaunched &&*/}
                        {/*  <Title level={4}>*/}
                        {/*    Wallet Whitelisted for: {' '}*/}
                        {/*    {sharedService.assetName(regLaunched)}*/}
                        {/*  </Title>*/}
                        {/*}*/}

                        {/*<Button*/}
                        {/*  onClick={openTokenTransferModal}*/}
                        {/*  type="primary"*/}
                        {/*  size={'large'}*/}
                        {/*>*/}
                        {/*  TRANSFER*/}
                        {/*</Button>*/}
                      </div>

                      <Table
                        columns={partitionColumns}
                        rowKey="partition"
                        dataSource={partitions?.map((partition, index) => ({
                          partition,
                          regulation: partitionInfos?.[index].regulation,
                          dateLaunched: partitionInfos?.[index].dateLaunched,
                          typeOfSecurity: partitionInfos?.[index].typeOfSecurity,
                          amount: partitionBalances?.[index],
                          // regDTransferableOutsideUSA:
                          //   regLaunched.regDTransferableOutsideUSA,
                          // creationTS: regLaunched.creationTS,
                          // index: regLaunched.index,
                          // legendRemoved: regLaunched.legendRemoved,
                          // regulationName: sharedService.regOptions.find(
                          //   (opt) => opt.shortValue === regLaunched.regulation
                          // )?.name,
                        }))}
                        pagination={false}
                        summary={() => {
                          return (
                            <>
                              <tr>
                                <th> </th>
                                <th> </th>
                                <th>
                                  Total =
                                  <br />
                                </th>
                                <th>
                                  {displayTokenAmount(balance)} {tokenSymbol}
                                </th>
                                {/*<th>*/}
                                {/*  <Button*/}
                                {/*    onClick={() =>*/}
                                {/*      openTokenTransferModal(*/}
                                {/*        defaultSenderReg as RegLaunched*/}
                                {/*      )*/}
                                {/*    }*/}
                                {/*    size="small"*/}
                                {/*    type="primary"*/}
                                {/*  >*/}
                                {/*    STANDAR (ERC20) TRANSFER*/}
                                {/*  </Button>*/}

                                {/*  <Button*/}
                                {/*    style={{*/}
                                {/*      marginLeft: '10px',*/}
                                {/*      marginTop: '4px',*/}
                                {/*    }}*/}
                                {/*    onClick={openDefaultRegModal}*/}
                                {/*    size="small"*/}
                                {/*  >*/}
                                {/*    SET DEFAULT REGULATION*/}
                                {/*  </Button>*/}
                                {/*</th>*/}
                              </tr>
                            </>
                          );
                        }}
                      />

                      <br />
                    </div>

                    {isTokenPriceOutdated && (
                      <div style={{ textAlign: 'center' }}>
                        <Button
                          size="large"
                          type="primary"
                          loading={requestingPriceUpdate}
                          onClick={requestPriceUpdate}
                        >
                          TOKEN PRICE IS OUTDATED. REQUEST PRICE UPDATE
                        </Button>
                      </div>
                    )}

                    {needsFiledNoticeWithSEC && (
                      <div style={{ textAlign: 'center' }}>
                        {/* {Date.now() < +(whitelistedWallet?.dateFiledCanBeNoticedAgain as string)*1000 && 
                          <Button size='large' type='primary' disabled>
                            You have to wait until {' '}
                            {moment(new BigNumber(whitelistedWallet?.dateFiledCanBeNoticedAgain as string).times(1000).toNumber()).format('LLL')} {' '}
                            to File a Notice with SEC on Form 144 to allow the sale greater than the allowed sale
                          </Button>
                        } */}
                        {
                          // Date.now() >= +(whitelistedWallet?.dateFiledCanBeNoticedAgain as string)*1000 &&
                          <Button
                            size="large"
                            type="primary"
                            onClick={() =>
                              history.push('/team-member/file-notice-with-sec')
                            }
                          >
                            File a Notice with SEC on Form 144 to allow the sale
                            greater than the allowed sale
                          </Button>
                        }
                      </div>
                    )}
                  </>
                )}
              </Card>

              <br />
              {symbolDetailsAndSTData?.symbolDetails.isDeployed && (
                <>
                  {selectedWallet?.toLowerCase() ===
                    symbolDetailsAndSTData.symbolDetails.owner.toLowerCase() && (
                    <Card style={{ textAlign: 'center' }}>
                      <Title level={1} style={{ textAlign: 'center' }}>
                        Token Price
                      </Title>

                      {Date.now() - +(lastUpdateUSDPrice as string) * 1000 >
                        24 * 3600 * 1000 && (
                        <div>
                          <Title
                            level={3}
                            style={{ color: 'red', fontWeight: 'bold' }}
                          >
                            Price is outdated
                          </Title>
                          {+(lastUpdateUSDPrice as string) > 0 && (
                            <Title
                              level={4}
                              style={{
                                color: 'red',
                                fontWeight: 'bold',
                                marginTop: 0,
                              }}
                            >
                              Last Update:{' '}
                              {moment(
                                new BigNumber(lastUpdateUSDPrice as string)
                                  .times(1000)
                                  .toNumber()
                              ).format('LLL')}
                            </Title>
                          )}
                        </div>
                      )}

                      <Form
                        form={formUSDPrice}
                        initialValues={{
                          USDPrice: displayTokenAmount(USDPrice),
                        }}
                        autoComplete={'off'}
                        onFinish={openTxFeeDelegationModal}
                      >
                        <p style={{ display: 'inline' }}>1 USD = </p>

                        <Form.Item
                          wrapperCol={{ span: 18, offset: 3 }}
                          name="USDPrice"
                          noStyle
                          normalize={(
                            value: any,
                            prevValue: any,
                            allValues
                          ) => {
                            if (new BigNumber(value).isGreaterThan(0))
                              return value;
                            if (!value) return '';
                            return prevValue || '';
                          }}
                          rules={[
                            {
                              required: true,
                              message: 'This field is required',
                              whitespace: true,
                            },
                          ]}
                        >
                          <Input
                            placeholder="Price"
                            style={{
                              display: 'inline-block',
                              width: '110px',
                              margin: '0 10px',
                            }}
                          />
                        </Form.Item>

                        <p style={{ display: 'inline' }}>{tokenSymbol}</p>

                        <br />
                        <br />

                        <div style={{ textAlign: 'center' }}>
                          <Button
                            size="large"
                            type="primary"
                            htmlType="submit"
                            loading={false}
                          >
                            UPDATE
                          </Button>
                        </div>
                      </Form>
                    </Card>
                  )}

                  <br />

                  {whitelistedWallet?.isAffiliate && (
                    <Card>
                      <Title level={1} style={{ textAlign: 'center' }}>
                        Affiliate Rule 144
                      </Title>
                      <Descriptions
                        bordered
                        column={1}
                        title="Cond. 3: Trading Volume Formula (Equity Tokens only)"
                      >
                        <Descriptions.Item label="Total existing coins (Total supply)">
                          {displayTokenAmount(totalSupply)} {tokenSymbol}
                        </Descriptions.Item>
                        <Descriptions.Item label="You cannot sell in any three months more than">
                          {displayTokenAmount(affiliateSellLimit)} {tokenSymbol}{' '}
                          (
                          {new BigNumber(maxAffiliateSellPercentage as string)
                            .div(new BigNumber(10).pow(precision))
                            .decimalPlaces(4)
                            .toFixed()}
                          % of total supply)
                        </Descriptions.Item>
                        <Descriptions.Item label="Total sent in last three months">
                          {displayTokenAmount(affiliateAmountSentIn3Months)}{' '}
                          {tokenSymbol}
                        </Descriptions.Item>
                        <Descriptions.Item label="You can still send up to">
                          {displayTokenAmount(
                            new BigNumber(affiliateSellLimit as string)
                              .minus(affiliateAmountSentIn3Months as string)
                              .isGreaterThan(0)
                              ? new BigNumber(
                                  affiliateSellLimit as string
                                ).minus(affiliateAmountSentIn3Months as string)
                              : 0
                          )}{' '}
                          {tokenSymbol}
                        </Descriptions.Item>
                      </Descriptions>
                      <br />

                      {!whitelistedWallet.isFiledNoticeApproved && (
                        <>
                          <Descriptions
                            bordered
                            column={1}
                            title="Cond. 5: Filing a Notice of Proposed Sale with the SEC"
                          >
                            <Descriptions.Item label="Token Price">
                              1 USD = {displayTokenAmount(USDPrice)}{' '}
                              {tokenSymbol}
                            </Descriptions.Item>
                            <Descriptions.Item
                              label={`You cannot send in ${maxAffiliateAmountMonths} months more than`}
                            >
                              {maxAffiliateAmount} USD (
                              {displayTokenAmount(
                                new BigNumber(
                                  maxAffiliateAmount as string
                                ).times(USDPrice as string)
                              )}{' '}
                              {tokenSymbol} according to token price)
                            </Descriptions.Item>
                            <Descriptions.Item
                              label={`Total sent in ${maxAffiliateAmountMonths} months`}
                            >
                              {affiliateUSDAmountSent} USD (
                              {displayTokenAmount(
                                new BigNumber(
                                  affiliateUSDAmountSent as string
                                ).times(USDPrice as string)
                              )}{' '}
                              {tokenSymbol} according to token price)
                            </Descriptions.Item>
                            <Descriptions.Item
                              label={`You can still send in ${maxAffiliateAmountMonths} months up to`}
                            >
                              {new BigNumber(maxAffiliateAmount as string)
                                .minus(affiliateUSDAmountSent as string)
                                .isGreaterThan(0)
                                ? new BigNumber(maxAffiliateAmount as string)
                                    .minus(affiliateUSDAmountSent as string)
                                    .toFixed()
                                : 0}{' '}
                              USD (
                              {displayTokenAmount(
                                new BigNumber(maxAffiliateAmount as string)
                                  .minus(affiliateUSDAmountSent as string)
                                  .times(USDPrice as string)
                                  .isGreaterThan(0)
                                  ? new BigNumber(maxAffiliateAmount as string)
                                      .minus(affiliateUSDAmountSent as string)
                                      .times(USDPrice as string)
                                  : 0
                              )}{' '}
                              {tokenSymbol} according to token price)
                            </Descriptions.Item>
                            <Descriptions.Item
                              label={`You cannot send in ${maxAffiliateTokensMonths} months more than`}
                            >
                              {displayTokenAmount(maxAffiliateTokens)}{' '}
                              {tokenSymbol}
                            </Descriptions.Item>
                            <Descriptions.Item
                              label={`Total sent in ${maxAffiliateTokensMonths} months`}
                            >
                              {displayTokenAmount(affiliateTokenAmountSent)}{' '}
                              {tokenSymbol}
                            </Descriptions.Item>
                            <Descriptions.Item
                              label={`You can still send in ${maxAffiliateTokensMonths} months up to`}
                            >
                              {displayTokenAmount(
                                new BigNumber(maxAffiliateTokens as string)
                                  .minus(affiliateTokenAmountSent as string)
                                  .isGreaterThan(0)
                                  ? new BigNumber(
                                      maxAffiliateTokens as string
                                    ).minus(affiliateTokenAmountSent as string)
                                  : 0
                              )}{' '}
                              {tokenSymbol}
                            </Descriptions.Item>
                          </Descriptions>

                          {/* {!filedNotice && 
                            <Button size='large' style={{fontWeight:'bold'}} type='link' onClick={() => history.push('/team-member/file-notice-with-sec')}>
                              File a Notice with SEC on Form 144 to allow the sale greater than the allowed sale
                            </Button>
                          } */}
                          {filedNoticeExists && (
                            <>
                              {!filedNotice?.isRejected && (
                                <Button
                                  size="large"
                                  style={{ fontWeight: 'bold' }}
                                  type="link"
                                  onClick={() =>
                                    history.push(
                                      '/team-member/file-notice-with-sec'
                                    )
                                  }
                                >
                                  Filed Notice Request is pending
                                </Button>
                              )}
                              {filedNotice?.isRejected && (
                                <Button
                                  size="large"
                                  style={{ fontWeight: 'bold' }}
                                  type="link"
                                  onClick={() =>
                                    history.push(
                                      '/team-member/file-notice-with-sec'
                                    )
                                  }
                                >
                                  Your Filed Notice has been Rejected. Click
                                  here to edit it
                                </Button>
                              )}
                            </>
                          )}
                        </>
                      )}

                      {whitelistedWallet.isFiledNoticeApproved && (
                        <Button
                          size="large"
                          style={{ fontWeight: 'bold' }}
                          type="link"
                          onClick={() =>
                            history.push('/team-member/file-notice-with-sec')
                          }
                        >
                          Your Filed notice is approved
                        </Button>
                      )}
                    </Card>
                  )}
                  <Card>
                    <Title level={1} style={{ textAlign: 'center' }}>
                      Locked funds
                    </Title>
                    <Table
                      columns={lockFundsColumns}
                      dataSource={userLockedAmountList}
                      pagination={false}
                      summary={() => {
                        return (
                          <>
                            <tr>
                              <td></td>
                              <th>
                                Total Locked =
                                <br />
                                {(!whitelistedWallet?.isAccredetedInvestor ||
                                  new BigNumber(
                                    totalLockedAmount as string
                                  ).isEqualTo(
                                    totalLockedAmountAccredeted as string
                                  )) && (
                                  <>
                                    {displayTokenAmount(totalLockedAmount)}{' '}
                                    {tokenSymbol}
                                  </>
                                )}
                                {whitelistedWallet?.isAccredetedInvestor &&
                                  !new BigNumber(
                                    totalLockedAmount as string
                                  ).isEqualTo(
                                    totalLockedAmountAccredeted as string
                                  ) && (
                                    <>
                                      {displayTokenAmount(
                                        totalLockedAmountAccredeted
                                      )}{' '}
                                      {tokenSymbol} if sender is an Accredeted
                                      Investor
                                      <br />
                                      {displayTokenAmount(
                                        totalLockedAmount
                                      )}{' '}
                                      {tokenSymbol} if sender is NOT an
                                      Accredeted Investor
                                    </>
                                  )}
                              </th>
                            </tr>
                          </>
                        );
                      }}
                    />
                  </Card>
                </>
              )}

              <br />
              <br />
            </>
          )}
        </Col>
      </Row>

      <Modal
        title={`
        ${
          selectedPartition && 
          `Transfer of Regulation with Partition ${sharedService.bytes32ToString(selectedPartition)}`
          // 'ERC20 Transfer'
          // selectedERC20
          //   ? 'ERC20 Transfer'
          //   : sharedService.regOptions.find(
          //       (opt) => opt.shortValue === selectedRegToTransfer?.regulation
          //     )?.name
        }
        
        `}
        okText="TRANSFER"
        cancelText="Cancel"
        visible={isTokenTransferVisible}
        onOk={() => form.submit()}
        okButtonProps={{
          loading: loadingCanTransfer,
          // disabled: !(+(selectedRegToTransfer?.dateLaunched as string) > 0),
        }}
        onCancel={() => {
          setIsTokenTransferVisible(false);
          form.resetFields();
        }}
      >
        {/*{selectedERC20 && (*/}
        {/*  <>*/}
        {/*    <p style={{ fontWeight: 'bold' }}>ERC20 Transfer behaviour</p>*/}

        {/*    {!(+(selectedRegToTransfer?.dateLaunched as string) > 0) && (*/}
        {/*      <p>You have to set a Default Regulation</p>*/}
        {/*    )}*/}

        {/*    {+(selectedRegToTransfer?.dateLaunched as string) > 0 && (*/}
        {/*      <>*/}
        {/*        <p>*/}
        {/*          Default Regulation will be used for the transfer:{' '}*/}
        {/*          {*/}
        {/*            sharedService.regOptions.find(*/}
        {/*              (opt) =>*/}
        {/*                opt.shortValue === selectedRegToTransfer?.regulation*/}
        {/*            )?.name*/}
        {/*          }*/}
        {/*        </p>*/}
        {/*      </>*/}
        {/*    )}*/}
        {/*  </>*/}
        {/*)}*/}

        {
          // (!selectedERC20 ||
          // +(selectedRegToTransfer?.dateLaunched as string) > 0) &&
        (
          // whitelist -> is kyc completed .

          <Form
            form={form}
            autoComplete={'off'}
            onFinish={(formValue) =>
              whitelistedWallet?.isAffiliate
                ? setIsAffiliateModalVisible(true)
                : setIsAffiliateModalVisible(true)
            }
          >
            <Form.Item
              wrapperCol={{ span: 18, offset: 3 }}
              name="to"
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                  whitespace: true,
                },
                {
                  validator: (rule, value) => {
                    if (value && !sharedService.isEthereumAddress(value))
                      return Promise.reject('Enter a valid Address');
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input placeholder="Receiver" />
            </Form.Item>

            <Form.Item
              wrapperCol={{ span: 18, offset: 3 }}
              name="amount"
              normalize={(value: any, prevValue: any, allValues) => {
                // console.log(value, prevValue, allValues);
                if (new BigNumber(value).isGreaterThan(0)) return value;
                if (!value) return '';
                return prevValue || '';
              }}
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                  whitespace: true,
                },
              ]}
            >
              <Input placeholder="Amount" />
            </Form.Item>

            <br />
          </Form>
        )}
      </Modal>


      <TxFeeDelegationModal
        isVisible={isDelegationModalVisible}
        onSubmit={({delegate}) => {
          setIsDelegationModalVisible(false);
          updateUSDPrice({delegate}).then();
        }}
        onCancel={() => setIsDelegationModalVisible(false)}
      />

      <TransactionModal
        title={
          transactions[0]?.details === 'Updating Price'
            ? 'Price update'
            : 'Token Transfer'
        }
        transactions={transactions}
        isModalVisible={isModalVisible}
        closeModal={() => setIsModalVisible(false)}
      />

      <Modal
        title={'Rule 144: Condition 4: Ordinary Brokerage Transaction'}
        okText="Confirm"
        cancelText="Cancel"
        visible={isAffiliateModalVisible}
        onOk={() => affiliateForm.submit()}
        onCancel={() => {
          setIsAffiliateModalVisible(false);
          affiliateForm.resetFields();
        }}
      >
        <p>
          The <span style={{ fontWeight: 'bold' }}>Affiliate</span> has to agree
          to all the 3 conditions of Ordinary Brokerage Transaction as given
          below, before transferring or selling the securities.
        </p>

        <Form
          form={affiliateForm}
          labelAlign="right"
          onFinish={() => {
            setIsAffiliateModalVisible(false);
            transferByPartition(
              form.getFieldsValue(),
              // selectedRegToTransfer as RegLaunched
            );
          }}
        >
          {affiliateFormCheckboxesOpts.map((opt, index) => (
            <Form.Item
              key={index}
              name={`opt${index}`}
              valuePropName="checked"
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
                {
                  validator: (rule, value) => {
                    if (value === false)
                      return Promise.reject('This field is required');
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Checkbox>{opt}</Checkbox>
            </Form.Item>
          ))}
        </Form>
      </Modal>

      {/*<Modal*/}
      {/*  visible={isDefaultRegModalVisible}*/}
      {/*  onOk={submitDefaultSenderReg}*/}
      {/*  okButtonProps={{ disabled: !(defaultSenderRegIndex >= 0) }}*/}
      {/*  onCancel={() => setIsDefaultRegModalVisible(false)}*/}
      {/*  title="Set default regulation for ERC20 Transfers"*/}
      {/*>*/}
      {/*  <Radio.Group*/}
      {/*    value={defaultSenderRegIndex}*/}
      {/*    onChange={(e) => setDefaultSenderRegIndex(e.target.value)}*/}
      {/*  >*/}
      {/*    {regulationsLaunched.map((launched, index) => (*/}
      {/*      <Radio value={index} key={index}>*/}
      {/*        <span style={{ fontWeight: 'bold' }}>*/}
      {/*          {*/}
      {/*            sharedService.regOptions.find(*/}
      {/*              (opt) => opt.shortValue === launched?.regulation*/}
      {/*            )?.name*/}
      {/*          }*/}
      {/*        </span>*/}
      {/*        {' - '}*/}
      {/*        Launched on: {moment(+launched.dateLaunched * 1000).format('LLL')}*/}
      {/*      </Radio>*/}
      {/*    ))}*/}
      {/*  </Radio.Group>*/}
      {/*</Modal>*/}
    </>
  );
}
