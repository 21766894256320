import React, { useState, useEffect } from "react";
import {
  Button,
  Input,
  Row,
  Card,
  Col,
  Form,
  Typography,
  Select,
  message,
  Space,
  List,
  Empty,
  Spin,
} from "antd";
import { PlatformComplianceAdminService } from "../PlatformComplianceAdmin.service";
const { Title } = Typography;
const { TextArea } = Input;
const { Option } = Select;
const FormItem = Form.Item;
const platformComplianceAdminService = new PlatformComplianceAdminService();
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

export default () => {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const [issuersList, setIssuersList] = useState(null);
  const [dueDiligenceList, setDueDiligenceList] = useState(null);
  const [reviewDueDiligenceView, setReviewDueDiligenceView] = useState(false);
  const [currentIssuerToDisplay, setCurrentIssuerToDisplay] = useState(null);

  useEffect(() => {
    (async () => {
      const issuersListResponse =
        await platformComplianceAdminService.getIssuersList();
      if (issuersListResponse.success) {
        if (issuersListResponse.data.length > 0) {
          // here setting the list to only msa, so that only msa can answer.
          let msa = issuersListResponse.data[0];
          console.log('msa: ', msa);
          await setIssuersList([msa]);
        } else {
          message.error("No issuer(s) present on the platform");
        }
      } else {
        message.error("Error in loading issuers list");
      }
    })();
  }, []);

  const showAnswer = (data) => {
    setCurrentIssuerToDisplay(data);
    setReviewDueDiligenceView(true);
  };

  const submitReview = async (review) => {
    setSubmitting(true);
    if (review) currentIssuerToDisplay.isReviewed = review;
    else currentIssuerToDisplay.isRejected = true;
    delete currentIssuerToDisplay["_id"];
    const dataSubmissionResult =
      await platformComplianceAdminService.reviewDueDiligence(
        currentIssuerToDisplay
      );
    if (dataSubmissionResult.success && dataSubmissionResult.data) {
      await form.resetFields();
      await setDueDiligenceList(
        dueDiligenceList.filter(
          (data) => data.question !== currentIssuerToDisplay.question
        )
      );
      message.success("Due diligence reviewed succesfully.");
    } else {
      message.error("Some problem occured while reviewing question.");
    }
    setReviewDueDiligenceView(false);
    setCurrentIssuerToDisplay(null);
    setSubmitting(false);
  };

  const fetchIssuerQuestions = async (companyId) => {
    const issuerQuestionListResponse =
      await platformComplianceAdminService.getDueDiligenceQuestionListForIssuer(
        { companyId: companyId }
      );
    if (issuerQuestionListResponse.success) {
      if (issuerQuestionListResponse.data.length > 0) {
        await setDueDiligenceList(issuerQuestionListResponse.data);
      } else {
        await setDueDiligenceList(null);
        message.error("No Data to display!");
      }
    } else {
      message.error("Error in loading due diligence!");
    }
  };

  return (
    <>
      <br />
      <Row justify="center">
        <Col span={22} style={{ textAlign: "center" }}>
          <Card>
            <Title
              level={2}
              style={{
                textAlign: "left",
                color: "#1890ff",
                // fontSize: '30px',
                fontWeight: "bold",
              }}
            >
              Review Due Diligence
            </Title>
            <div hidden={reviewDueDiligenceView}>
              <Space direction="vertical">
                <Space>
                  <h3>Select Issuer:</h3>
                  <Select
                    style={{ width: 200 }}
                    key="issuerDropDown"
                    disabled={submitting}
                    onChange={fetchIssuerQuestions}
                  >
                    {issuersList &&
                      issuersList.map((data, index) => (
                        <Option key={index} value={data.companyId}>
                          {data.companyName}
                        </Option>
                      ))}
                  </Select>
                </Space>
              </Space>

              {dueDiligenceList ? (
                <List
                  style={{ textAlign: "left" }}
                  loading={!dueDiligenceList}
                  key="dueDiligenceList"
                >
                  {dueDiligenceList?.map((data, index) => (
                    <List.Item
                      key={index}
                      style={{ cursor: "pointer" }}
                      actions={[
                        <Button type="primary" onClick={() => showAnswer(data)}>
                          Review
                        </Button>,
                      ]}
                    >
                      <List.Item.Meta title={data.question}></List.Item.Meta>
                    </List.Item>
                  ))}
                </List>
              ) : (
                <Empty />
              )}
            </div>

            <div
              hidden={!reviewDueDiligenceView}
              style={{ textAlign: "start" }}
            >
              <Space direction="vertical">
                <h4>Question:{`  ${currentIssuerToDisplay?.question}`}</h4>
                <br />
                <h5>
                  Answer:
                  {currentIssuerToDisplay?.answerType === "attachment" && (
                    <ol>
                      {currentIssuerToDisplay?.answer.map((a) => (
                        <>
                          <li>
                            <a
                              href={a.URL}
                              rel="noopener norefferer"
                              target="_blank"
                            >
                              {a.name}
                            </a>
                          </li>
                        </>
                      ))}
                    </ol>
                  )}
                  {currentIssuerToDisplay?.answerType !== "attachment" &&
                    currentIssuerToDisplay?.answer}
                </h5>
                <Space>
                  <Button
                    type="primary"
                    onClick={() => submitReview(true)}
                    loading={submitting}
                  >
                    Accept
                  </Button>
                  <Button
                    onClick={() => submitReview(false)}
                    danger
                    loading={submitting}
                  >
                    Reject
                  </Button>
                  <Button
                    onClick={() => setReviewDueDiligenceView(false)}
                    loading={submitting}
                  >
                    Cancel
                  </Button>
                </Space>
              </Space>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};
