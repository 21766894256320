import React, { useState, useEffect } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import ManagementList from "./ManagementList";

const ManagementCompany = () => {
    const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}`} exact>
        <ManagementList/>
      </Route>
    </Switch>
  )
}

export default ManagementCompany