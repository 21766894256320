import React, { useState, useEffect } from "react";
import { Menu, Layout } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { AuthService } from "../../Shared/Auth.service";
import { useHistory, useLocation } from "react-router-dom";
import { SharedService } from "../../Shared/Shared.service";

import "./SideNav.scss";

const { Sider } = Layout;
const { SubMenu } = Menu;

const sharedService = new SharedService();

const menuHeader = sharedService.menuHeader;

const appPages = [
  {
    title: "Team Member KYC Requests",
    url: "/issuer-compliance-admin/team-member-kyc-requests",
    icon: <UserOutlined />,
    roles: ["issuer compliance admin"]
  },
  {
    title: "Team Member KYC Requests",
    url: "/master-company-super-admin/team-member-kyc-requests",
    icon: <UserOutlined />,
    roles: ["master company compliance admin"],
  },
  {
    title: "Investor KYC Requests",
    url: "/issuer-compliance-admin/kyc-verification-request",
    icon: <UserOutlined />,
    roles: ["issuer compliance admin"],
  },
  {
    title: "Investor KYC Requests",
    url: "/platform-compliance-admin/kyc-verification-request",
    icon: <UserOutlined />,
    roles: ["platform compliance admin"],
  },
  {
    title: "Team Member KYC Requests",
    url: "/platform-compliance-admin/team-member-kyc-requests",
    icon: <UserOutlined />,
    roles: ["platform compliance admin"],
  },
  {
    title: "Due diligence for marketplace",
    key: "Due Diligence",
    icon: <UserOutlined />,
    roles: ["platform compliance admin", "platform issuer admin"],
    children: [
      {
        title: "Add",
        url: "/platform-compliance-admin/add-due-diligence-for-issuer",
        roles: ["platform compliance admin", "platform issuer admin"],
      },
      {
        title: "Review answers",
        url: "/platform-compliance-admin/review-due-diligence",
        roles: ["platform compliance admin", "platform issuer admin"],
      },
    ],
  },
  {
    title: "KYC Status",
    url: "/team-member/KYCUpload",
    icon: <UserOutlined />,
    roles: ["employee", "contractor_consultant_advisor"],
  },
  {
    title: "Token Dashboard",
    url: "/token-dashboard",
    icon: <UserOutlined />,
    roles: [
      // "issuer super admin",
      // "issuer token admin",
      //"employee",
      "contractor_consultant_advisor",
      "companyMember",
    ],
  },
  {
    title: "View All Issuer Super Admins",
    url: "/platform-issuer-admin/issuer-super-admins",
    icon: <UserOutlined />,
    roles: ["platform issuer admin"],
  },
  {
    title: "Issuer Approval Requests",
    key: "issuer-approval-requests",
    icon: <UserOutlined />,
    roles: ["platform issuer admin", "master company super admin"],
    children: [
      {
        title: "Issuer Registration Requests",
        url: "/platform-issuer-admin/issuer-reg-reqs",
        roles: ["platform issuer admin", "master company super admin"],
      },
      {
        title: "Issuer Basic Info Verification Requests",
        url: "/platform-issuer-admin/issuer-basic-info-verif-reqs",
        roles: ["platform issuer admin", "master company super admin"],
      },
      {
        title: "Issuer Property Verification Requests",
        url: "/platform-issuer-admin/issuer-property-verif-reqs",
        roles: ["platform issuer admin", "master company super admin"],
      },
    ],
  },
  // {
  //   title: 'Edit Basic Info of Issuer Super Admin',
  //   url: '/1',
  //   icon: <UserOutlined/>,
  //   roles: ['platform issuer admin']
  // },
  // {
  //   title: "Investor Registation Requests",
  //   url: "/platform-issuer-admin/investor-registration-requests",
  //   icon: <UserOutlined />,
  //   roles: ["issuer super admin"],
  // },
  // // ,
  // {
  //   title: "Information Question for issuer",
  //   key: "information-ques-for-issuer",
  //   icon: <UserOutlined />,
  //   roles: ["platform issuer admin"],
  //   children: [
  //     {
  //       title: "Create Information Questions",
  //       url: "/platform-issuer-admin/create-information-questions",
  //       roles: ["platform issuer admin"],
  //     },
  //     {
  //       title: "View & Edit Questions",
  //       url: "/platform-issuer-admin/view-and-edit-questions",
  //       roles: ["platform issuer admin"],
  //     },
  //   ],
  // },
  {
    title: "Basic Information",
    url: "/issuer-super-admin/basic-info-verif",
    icon: <UserOutlined />,
    roles: ["issuer super admin"],
  },
  {
    title: "Basic Information",
    url: "/master-company-super-admin/basic-info-verif",
    icon: <UserOutlined />,
    roles: ["master company super admin", "master company officer"],
  },
  {
    title: "Add Property",
    url: "/issuer-super-admin/add-property",
    icon: <UserOutlined />,
    roles: ["issuer super admin"],
  },
  {
    title: "Website management",
    url: "/issuer-super-admin/manage-website",
    icon: <UserOutlined />,
    roles: [],
  },
  {
    title: "Service Provider management",
    url: "/issuer-super-admin/manage-service-providers",
    icon: <UserOutlined />,
    roles: [],
  },
  // {
  //   title: 'Dashboard',
  //   url: '/issuer-super-admin/dashboard',
  //   icon: <UserOutlined/>,
  //   roles: ['issuer super admin']
  // },
  {
    title: "Create Offering",
    url: "/issuer-super-admin/create-offering",
    icon: <UserOutlined />,
    roles: ["issuer super admin"],
  },
  {
    title: "Manage Payment Methods",
    url: "/issuer-super-admin/manage-payment-methods",
    icon: <UserOutlined />,
    roles: ["issuer super admin"],
  },
  // {
  //   title: "Amendments",
  //   url: "/master-company-super-admin/amendments",
  //   icon: <UserOutlined />,
  //   roles: ["master company super admin", "master company officer"],
  // },
  {
    title: "Member Information",
    key: "memberInfo",
    // url: "/issuer-super-admin/member-info",
    icon: <UserOutlined />,
    roles: ["master company super admin"],
    children: [
      {
        title: "Member Details",
        url: "/issuer-super-admin/member-details",
        roles: [
          "issuer super admin",
          "companyMember",
          "master company super admin",
        ],
      },
      {
        title: "Manage Individuals",
        url: "/issuer-super-admin/manage-individuals",
        roles: ["master company super admin"],
      },
      {
        title: "Manage Comapanies",
        url: "/issuer-super-admin/manage-companies",
        roles: ["companyMember"],
      },
      // {
      //   title: "Add Company",
      //   url: "/issuer-super-admin/add-company",
      //   roles: ["issuer super admin"],
      // },
      // {
      //   title: "Assign Manager",
      //   url: "/master-company-super-admin/assign manager",
      //   roles: ["issuer super admin", "master company super admin"],
      // }
    ],
  },
  {
    title: "Admin",
    key: "admin",
    icon: <UserOutlined />,
    roles: ["issuer super admin"],
    children: [
      {
        title: "Admin Roles",
        url: "/issuer-super-admin/admin/admin-roles",
        roles: ["issuer super admin"],
      },
      {
        title: "Role Managment",
        url: "/issuer-super-admin/admin/role-managment",
        roles: ["issuer super admin"],
      },
      {
        title: "Team Managment",
        url: "/issuer-super-admin/admin/team-managment",
        roles: ["issuer super admin"],
      },
    ],
  },
  {
    title: "Admin",
    key: "admin1",
    icon: <UserOutlined />,
    roles: ["master company super admin"],
    children: [
      {
        title: "Admin Roles",
        url: "/master-company-super-admin/admin/admin-roles",
        roles: ["master company super admin"],
      },
      {
        title: "Role Managment",
        url: "/master-company-super-admin/admin/role-managment",
        roles: ["master company super admin"],
      },
      {
        title: "Team Managment",
        url: "/master-company-super-admin/admin/team-managment",
        roles: ["master company super admin"],
      },
    ],
  },
  {
    title: "Bank Information",
    url: "/issuer-super-admin/bank-info",
    icon: <UserOutlined />,
    roles: [],
  },
  {
    title: "Token Configuration",
    url: "/token-configurations",
    icon: <UserOutlined />,
    roles: ["issuer token admin", "issuer super admin"],
  },
  // {
  //   title: "Due Diligence",
  //   url: "/issuer-super-admin/answer-due-diligence",
  //   icon: <UserOutlined />,
  //   roles: ["issuer super admin"],
  // },
  {
    title: "Due Diligence",
    url: "/master-company-super-admin/answer-due-diligence",
    icon: <UserOutlined />,
    roles: ["master company super admin"],
  },
  // {
  //   title: 'Token Configuration',
  //   key: 'token-configuration',
  //   icon: <UserOutlined/>,
  //   roles: ['issuer token admin'],
  //   children: [
  //     {
  //       title: 'Token Type & Details',
  //       url: '/token-configuration/type-and-details',
  //       // url: '/issuer-super-admin/token-configuration/type-and-details',
  //       roles: ['issuer token admin'],
  //     },
  //     {
  //       title: 'Rule 144',
  //       url: '/token-configuration/rule-144',
  //       roles: ['issuer token admin'],
  //     },
  //     {
  //       title: 'Phase',
  //       url: '/token-configuration/phase',
  //       roles: ['issuer token admin'],
  //     },
  //     {
  //       title: 'Create Token',
  //       url: '/token-configuration/create',
  //       roles: ['issuer token admin'],
  //     },
  //   ]
  // },
  // {
  //   title: 'Employees and Affiliates',
  //   url: '/issuer-super-admin/team',
  //   icon: <UserOutlined/>,
  //   roles: ['issuer super admin'],
  // },
  {
    title: "Whitelist",
    key: "whitelist",
    icon: <UserOutlined />,
    roles: ["issuer compliance admin"],
    children: [
      {
        title: "Pending Whitelisted Wallets",
        url: "/issuer-compliance-admin/whitelist/pending-whitelisted-wallets",
        roles: ["issuer compliance admin"],
      },
      {
        title: "Whitelisted Wallets",
        url: "/issuer-compliance-admin/whitelist/whitelisted-wallets",
        roles: ["issuer compliance admin"],
      },
    ],
  },
  // {
  //   title: "Team Token Managment",
  //   key: "token-managment",
  //   icon: <UserOutlined />,
  //   roles: ["issuer token admin"],
  //   children: [
  //     {
  //       title: "Create Vesting Schedule",
  //       url: "/issuer-token-admin/token-managment/create-vesting-schedule",
  //       roles: ["issuer token admin"],
  //     },
  //     {
  //       title: "Apply Vesting to Team",
  //       url: "/issuer-token-admin/token-managment/apply-vesting-schedule",
  //       roles: ["issuer token admin"],
  //     },
  //     {
  //       title: "Token Security",
  //       url: "/issuer-token-admin/token-managment/token-security",
  //       roles: ["issuer token admin"],
  //     },
  //   ],
  // },
  // {
  //   title: "Token Features",
  //   key: "token-features",
  //   icon: <UserOutlined />,
  //   roles: ["issuer token admin"],
  //   children: [
  //     {
  //       title: "Dividend",
  //       url: "/issuer-token-admin/token-features/dividend",
  //       roles: ["issuer token admin"],
  //     },
  //     {
  //       title: "Voting",
  //       url: "/issuer-token-admin/token-features/voting",
  //       roles: ["issuer token admin"],
  //     },
  //     {
  //       title: "Cap Table",
  //       url: "/issuer-token-admin/token-features/cap-table",
  //       roles: ["issuer token admin"],
  //     },
  //   ],
  // },
  {
    title: "Transfer Restrictions",
    key: "trasnferRestrictions",
    icon: <UserOutlined />,
    roles: ["issuer token admin"],
    children: [
      {
        title: "Select Countries",
        url: "/issuer-token-admin/transfer-restriction/select-countries",
        roles: ["issuer token admin"],
      },
    ],
  },

  /*{
    title: 'Choose your Providers',
    url: '/issuer-super-admin/choose-providers',
    icon: <UserOutlined/>,
    roles: ['issuer super admin'],
  },*/
  {
    title: "Issue tokens",
    url: "/issuer-token-admin/issue-tokens",
    icon: <UserOutlined />,
    roles: ["issuer token admin"],
  },
  // {
  //   title: 'Create Issuer Role',
  //   url: '/issuer-super-admin/create-issuer-role',
  //   icon: <UserOutlined/>,
  //   roles: ['issuer super admin'],
  // },
  // {
  //   title: 'Remove Legend',
  //   url: '/issuer-token-admin/remove-legend',
  //   icon: <UserOutlined/>,
  //   roles: ['issuer token admin'],
  // },
  // {
  //   title: 'Vesting Schedule',
  //   url: '/employee/vesting-schedule',
  //   icon: <UserOutlined/>,
  //   roles: ['employee'],
  // },
  // {
  //   title: "Vesting Schedule",
  //   url: "/team-member/vesting-schedule",
  //   icon: <UserOutlined />,
  //   roles: ["employee", "contractor_consultant_advisor"],
  // },
  {
    title: "Information questions",
    url: "/issuer-super-admin/information-questions",
    icon: <UserOutlined />,
    roles: ["issuer super admin"],
  },
  {
    title: "Information questions",
    url: "/master-company-super-admin/information-questions",
    icon: <UserOutlined />,
    roles: ["master company super admin"],
  },
  // {
  //   title: "Investor Payment Details",
  //   url: "/issuer-token-admin/investor-payment-details",
  //   icon: <UserOutlined />,
  //   roles: ["issuer token admin"],
  // },
  {
    title: "AI Verification Request",
    url: "/issuer-compliance-admin/ai-verification-request",
    icon: <UserOutlined />,
    roles: ["issuer compliance admin"],
  },
  // {
  //   title: 'Create Admin Role',
  //   url: '/platform-super-admin/create-admin-role',
  //   icon: <UserOutlined/>,
  //   roles: ['platform super admin'],
  // },
  // {
  //   title: 'Manage all Admins Roles',
  //   url: '/platform-super-admin/manage-all-admin-roles',
  //   icon: <UserOutlined/>,
  //   roles: ['platform super admin'],
  // },
  {
    title: "View All Issuers",
    url: "/platform-super-admin/view-all-issuers",
    icon: <UserOutlined />,
    roles: ["platform super admin"],
  },
  // {
  //   title: 'View All Investors',
  //   url: '/platform-super-admin/view-all-investors',
  //   icon: <UserOutlined/>,
  //   roles: ['platform super admin'],
  // },
  // {
  //   title: 'View/Edit All Admins according to issuer',
  //   url: '/platform-super-admin/edit-all-admins',
  //   icon: <UserOutlined/>,
  //   roles: ['platform super admin'],
  // },
  {
    title: "Add Service Providers",
    url: "/platform-super-admin/add-service-providers",
    icon: <UserOutlined />,
    roles: ["platform super admin"],
  },
  {
    title: "Manage API key",
    url: "/platform-super-admin/manage-api-key",
    icon: <UserOutlined />,
    roles: ["platform super admin"],
  },
  {
    title: "Dashboard",
    url: "/investor/dashboard",
    icon: <UserOutlined />,
    roles: ["investor"],
  },
  {
    title: "NDA",
    url: "/investor/nda",
    icon: <UserOutlined />,
    roles: ["investor"],
  },
  {
    title: "Invest",
    url: "/investor/home",
    icon: <UserOutlined />,
    roles: ["investor"],
  },
  {
    title: "KYC Status",
    url: "/investor/kyc-status",
    icon: <UserOutlined />,
    roles: ["investor"],
  },
  // {
  //   title: "AI Verification status",
  //   url: "/investor/ai-status",
  //   icon: <UserOutlined />,
  //   roles: ["investor"],
  // },
  {
    title: "Payments",
    url: "/investor/payments",
    icon: <UserOutlined />,
    roles: ["investor"],
  },
  // {
  //   title: "Whitelisted Wallet Requests",
  //   url: "/whitelisted-wallet-requests",
  //   icon: <UserOutlined />,
  //   roles: ["investor", "employee", "contractor_consultant_advisor",],
  // },
  {
    title: "Whitelisted Wallet Requests",
    url: "/whitelisted-wallet-requests",
    icon: <UserOutlined />,
    roles: ["investor"],
  },
  // {
  //   title: "Token Issuer Documents",
  //   url: "/investor/token-issuer-documents",
  //   icon: <UserOutlined />,
  //   roles: ["investor"],
  // },
  // {
  //   title: "Issuer Documents",
  //   url: "/issuer-super-admin/issuer-documents",
  //   icon: <UserOutlined />,
  //   roles: ["issuer token admin","issuer company officer","issuer compliance admin","issuer super admin","issuer counsel"],
  // },
  // {
  //   title: "Add Wallet address for another regulation",
  //   url: "/investor/add-wallet-for-other-regulations",
  //   icon: <UserOutlined />,
  //   roles: ["investor"],
  // },
  // {
  //   title: 'Create a Legend',
  //   url: '/issuer-counsel/create-legend',
  //   icon: <UserOutlined/>,
  //   roles: ['issuer counsel'],
  // },
  // {
  //   title: 'Remove Legend from Securities',
  //   url: '/issuer-counsel/remove-legend',
  //   icon: <UserOutlined/>,
  //   roles: ['issuer counsel'],
  // },
  {
    title: "Review Token Configuration",
    url: "/token-configurations",
    icon: <UserOutlined />,
    roles: ["issuer company officer", "issuer counsel"],
  },
  {
    title: "Review Affiliate Form 144",
    url: "/issuer-counsel/review-affiliate-form-144",
    icon: <UserOutlined />,
    roles: ["issuer counsel"],
  },
  // {
  //   title: "Review Due Diligence",
  //   url: "/issuer-counsel/review-due-diligence",
  //   icon: <UserOutlined />,
  //   roles: ["issuer counsel"],
  // },
  {
    title: "Safe Harbor",
    url: "/issuer-counsel/safe-harbor",
    icon: <UserOutlined />,
    roles: ["issuer counsel"],
  },
  {
    title: "Disclosures",
    url: "/issuer-counsel/disclosures",
    icon: <UserOutlined />,
    roles: ["issuer counsel"],
  },
  {
    title: "Transaction Documents",
    url: "/issuer-counsel/transaction-documents",
    icon: <UserOutlined />,
    roles: ["issuer counsel"],
  },
  /*{
    title: 'Manage Approvers & Crypto Accounts',
    url: '/issuer-super-admin/approvers-and-accounts',
    icon: <UserOutlined/>,
    roles: ['issuer super admin'],
  },*/
  // {
  //   title: "Amount Withdrawal Requests",
  //   url: "/issuer-super-admin/withdrawal-requests",
  //   icon: <UserOutlined />,
  //   roles: [
  //     "issuer company officer",
  //     "issuer token admin",
  //     "issuer compliance admin",
  //   ],
  // },
  {
    title: "Security Recovery Processes",
    url: "/recovery-requests",
    icon: <UserOutlined />,
    roles: ["issuer token admin"],
  },
  // {
  //   title: "Security Recovery Process",
  //   url: "/recovery-requests/new",
  //   icon: <UserOutlined />,
  //   roles: ["investor"],
  //   // roles: ['employee', 'contractor_consultant_advisor', 'investor'],
  // },
  // {
  //   title: "Trusted Forwarder",
  //   url: "/trusted-forwarder",
  //   icon: <UserOutlined />,
  //   roles: ["issuer super admin"],
  //   // roles: ['employee', 'contractor_consultant_advisor', 'investor'],
  // },
  {
    title: "Master Company Details",
    url: "/master-company-details",
    icon: <UserOutlined />,
    roles: ["master company super admin"],
    // roles: ['employee', 'contractor_consultant_advisor', 'investor'],
  },
  {
    title: "Master Company Fields",
    url: "/platform-issuer-admin/master-company-fields",
    icon: <UserOutlined />,
    roles: ["platform issuer admin"],
    // roles: ['employee', 'contractor_consultant_advisor', 'investor'],
  },
  {
    title: "Series Details",
    url: "/master-company-super-admin/series-details",
    icon: <UserOutlined />,
    roles: ["master company super admin", "master company officer"],
  },
  {
    title: "Series",
    url: "/series",
    icon: <UserOutlined />,
    roles: ["master company series manager"],
  },
  {
    title: "Invite Investors",
    url: "/issuer-super-admin/invite-investors",
    icon: <UserOutlined />,
    roles: ["issuer super admin"],
  },
  // {
  //   title: "Amendments",
  //   url: "/amendments",
  //   icon: <UserOutlined />,
  //   roles: [
  //     "master company super admin",
  //     "master company officer",
  //     "master company counsel",
  //   ],
  // },
  {
    title: "Upload Documents",
    url: "/master-company-counsel/upload-documents",
    icon: <UserOutlined />,
    roles: ["master company counsel"],
  },
  {
    title: "Connect Your Wallet",
    url: "/connect-wallet",
    icon: <UserOutlined />,
    roles: [
      "master company officer",
      "master company compliance admin",
      "master company series manager",
      "master company super admin",
      "master company counsel",
      "employee",
      "contractor_consultant_advisor",
      "issuer token admin",
      "issuer super admin",
      "platform super admin",
      "platform issuer admin",
      "issuer counsel",
      "investor",
      "issuer company officer",
      "platform compliance admin",
      "issuer compliance admin",
    ],
  },
  {
    title: "Tenants",
    url: "/tenants",
    icon: <UserOutlined />,
    roles: [
      "issuer super admin",
      "issuer token admin",
      "companyMember",
    ],
  },
  {
    title: "LLP Details",
    url: "/llp-details",
    icon: <UserOutlined />,
    roles: [
      "issuer super admin",
      // "issuer token admin",
      // "employee",
      // "contractor_consultant_advisor",
      // "companyMember",
    ],
  },
  {
    title: "LLP Deed Documents",
    url: "/deed-docs",
    icon: <UserOutlined />,
    roles: [
      "issuer super admin",
    ],
  },
  // {
  //   title: "Designated Partner Details",
  //   url: "/designated-partner-details",
  //   icon: <UserOutlined />,
  //   roles: [
  //     "issuer super admin",
  //     "issuer token admin",
  //     "employee",
  //     "contractor_consultant_advisor",
  //     "companyMember",
  //   ],
  // },
  {
    title: "Add Management Company",
    url: "/management-company",
    icon: <UserOutlined />,
    roles: [
      "issuer super admin",
      "issuer token admin",
      "companyMember",
    ],
  },
  {
    title: "Token recovery",
    url: "/token-recovery",
    icon: <UserOutlined />,
    roles: ["investor"],
  },
  {
    title: "Due Diligence",
    url: "/due-diligence",
    icon: <UserOutlined />,
    roles: ["investor"],
  },
  {
    title: "Whitelist Recovery",
    url: "/whitelist-recovery",
    icon: <UserOutlined />,
    roles: ["issuer compliance admin"],
  },
  {
    title: "Investor Payments Approval",
    url: "/investor-payment-approval",
    icon: <UserOutlined />,
    roles: ["issuer compliance admin"],
  },
  {
    title: "Transfer Recovery token",
    url: "/transfer-recovery-token",
    icon: <UserOutlined />,
    roles: ["issuer token admin"],
  },
  // {
  //   title: "Token Transfer Request",
  //   url: "/token-transfer-request",
  //   icon: <UserOutlined />,
  //   roles: ["investor"],
  // },
  // {
  //   title: "Token KYC Requests",
  //   url: "/token-kyc-request",
  //   icon: <UserOutlined />,
  //   roles: ["issuer compliance admin"],
  // },
  {
    title: "Deed Approval",
    url: "/deed-approval",
    icon: <UserOutlined />,
    roles: ["issuer compliance admin", "master company compliance admin"],
  },
  // {
  //   title: "All deed approval Requests",
  //   url: "/all-deed-approval-requests",
  //   icon: <UserOutlined />,
  //   roles: ["master company compliance admin"],
  // },
  {
    title: "Pending Payment Requests",
    url: "/pending-payment-requests",
    icon: <UserOutlined />,
    roles: ["issuer compliance admin"],
  },
  {
    title: "Bank Info Request",
    url: "/bank-info-request",
    icon: <UserOutlined />,
    roles: [
      "issuer compliance admin",
      "master company compliance admin",
      "platform issuer admin",
    ],
  },
];

const authService = new AuthService();

const useUserContext = () => authService.useUserContext();

export default () => {
  const history = useHistory();
  const { pathname } = useLocation();

  const { userInfo } = useUserContext();

  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  // const [openKeys, setOpenkeys`] = useState<string[]>([]);
  const [visiblePages, setVisiblePages] = useState<any>();

  useEffect(() => {
    if (!selectedKeys.includes(pathname)) setSelectedKeys([pathname]);
    // if(!openKeys.includes('issuer-approval-requests')) {
    //   if(pathname === '/platform-issuer-admin/issuer-reg-reqs') setOpenkeys(['issuer-approval-requests']);
    // }
  }, [pathname, selectedKeys]);

  useEffect(() => {
    if (userInfo) {
      const v = [...appPages]
        .filter((page) => page.roles.includes(userInfo?.role as string))
        .map((page) => {
          page.children = page.children?.filter((child) =>
            child.roles.includes(userInfo?.role as string)
          );
          return page;
        });

      // if (
      //   userInfo?.corporateRoles?.includes("director") ||
      //   userInfo?.roles?.includes("issuer company officer") ||
      //   userInfo?.roles?.includes("issuer counsel")
      // )
      // v.push({
      //   title: "Authorization Request",
      //   url: "/authorization-request-review",
      //   icon: <UserOutlined />,
      //   roles: [],
      // });
      
      //if user has no KYC status or REJECTED restrict to specified routes
      if( userInfo.role === "investor" && (!userInfo.status.hasOwnProperty('kyc') || userInfo.status?.kyc =='rejected')) {
        setTimeout(() => {
          history.push('kyc-status')
        }, 4);
        filterInvestorRoutes(v,['KYC Status','Invest','NDA'])
        return;
      }

      setVisiblePages(v)

      if (
        userInfo.role === "investor" &&
        userInfo?.country?.toLowerCase() === "united states of america"
      ) {
        // v.push({
        //   title: "AI Verification status",
        //   url: "/investor/ai-status",
        //   icon: <UserOutlined />,
        //   roles: ["investor"],
        // });
      }
    }
  }, [userInfo]);

  const filterInvestorRoutes = (v,routes) => {
    let filteredRoutes = v.filter(r => routes.includes(r.title) )
    setVisiblePages(filteredRoutes)
    filteredRoutes.forEach(route => {
      if (route.url !== pathname) {
        console.log('no access to route')
        history.push('kyc-status')
      }
    })
    
  }

  const goTo = ({ key }) => history.push(key);

  return (
    <Sider
      style={{
        overflowY: "auto",
        height: "calc(100vh - 60px)",
        // overflow: "auto",
        // height: "100vh",
        position: "fixed",
        left: 0,
      }}
    >
      {userInfo && (
        <Menu
          mode="inline"
          theme="dark"
          selectedKeys={selectedKeys}
          // openKeys={openKeys}
          // defaultOpenKeys={selectedKeys}
          // style={{ height: '100%', borderRight: 0 }}
        >
          <li
            style={{
              lineHeight: "normal",
              textAlign: "center",
              fontWeight: "bold",
              margin: "10px 0",
            }}
          >
            {userInfo.role === "contractor_consultant_advisor" ||
            userInfo.role === "employee"
              ? `PROFILE (${
                  userInfo.role === "contractor_consultant_advisor"
                    ? "CONTRACTOR"
                    : "EMPLOYEE"
                })`
              : menuHeader[userInfo.role]}
          </li>
          {/* {console.log(visiblePages)} */}
          {(visiblePages as any[])?.map((page) =>
            !page.children ? (
              <Menu.Item key={page.url} onClick={goTo}>
                {/* {page.icon}  */} {page.title}
              </Menu.Item>
            ) : (
              <SubMenu
                key={page.key}
                title={
                  <span>
                    {/* {page.icon}  */} {page.title}
                  </span>
                }
              >
                {(page.children as any[]).map((child) => (
                  <Menu.Item key={child.url} onClick={goTo}>
                    {child.title}
                  </Menu.Item>
                ))}
              </SubMenu>
            )
          )}
        </Menu>
      )}
    </Sider>
  );
};
