import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import {
  Button,
  Input,
  Row,
  Card,
  Col,
  Form,
  message,
  notification,
  Progress,
  Spin,
  Space,
  Modal
} from "antd";
import { IssuerSuperAdminService } from '../IssuerSuperAdmin.service';
import { SharedService } from '../../Shared/Shared.service';
import './AddDocument.scss';
import { environment } from "../../../environments/environment";
import  {Erc1643} from"../../Shared/ERC1643/ERC1643.service"
import { MetamaskService } from '../../Shared/Metamask.service';
import TransactionModal from '../../Shared/TransactionModal';
import { AuthService } from "../../Shared/Auth.service";
const issuerSuperAdminService = new IssuerSuperAdminService();
const sharedService = new SharedService();
const erc1643 = new Erc1643();
const useSelectedWalletContext = () => new MetamaskService().useSelectedWalletContext();
const useUserContext = () => new AuthService().useUserContext();

export default function AddDocuments(props) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [editView, setEditView] = useState(false);
  const [enableEdit, setEnableEdit] = useState(false);
  const [mergedFileLink, setMergedFileLink] = useState('');
  const {selectedWallet, networkId} = useSelectedWalletContext();
  const [transactions, setTransactions] = useState<{submitting?: boolean, receipt?: any, details: string}[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { userInfo } = useUserContext();
  const [rejectReason, setRejectReason] = useState("");
  const [showRejectPopup, setShowRejectPopup] = useState(false);

  useEffect(() => {
    console.log('props.propertyInfo: ', props.propertyInfo);
    if(props.documentsDone){
      setSendForApprovalVisibility(true)
      console.log('status ', props.propertyInfo.status)
      setPropertyInfoStatus(props.propertyInfo.status ?? '')
    }
  }, [])
  const [propertyFiles, setPropertyFiles] = useState(
    {
      privatePlacementFile: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null
      },
      // whitePaperFile: {
      //   uploading: false,
      //   uploadFilePercentage: 0,
      //   URL: null,
      //   name: null,
      //   type: null
      // },
      subscriptionAgreementFile: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null
      },
      financialProjectionsFile: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null
      },
      operatingAgreementFile: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null
      },
      propertyDetailsFile: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null
      },
      draftFundFile: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null
      },
      ConfirmationWithRegulation292kFile: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null,
      },
      ConfirmationFromIssuerForNpoFile: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null,
      },
      ConfirmationOfMoneyLaunderingFile: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null,
      },
      ConfirmationWithRegulation292nFile: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null,
      },
      ListingApplicationFile: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null,
      },
      ResolutionPassedByGoverningBodyFile: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null,
      },
      CertificationFromNpoFile: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null,
      },
      GoverningDocumentFile: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null,
      },
      UniformListingAgreementFile: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null,
      },
      otherDocumentsFile: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null
      }
      // minInvestmentFile: {
      //   uploading: false,
      //   uploadFilePercentage: 0,
      //   URL: null,
      //   name: null,
      //   type: null
      // }
      // titleClearanceFile: {
      //   uploading: false,
      //   uploadFilePercentage: 0,
      //   URL: null,
      //   name: null,
      //   type: null
      // },
      // titleDocumentsFile: {
      //   uploading: false,
      //   uploadFilePercentage: 0,
      //   URL: null,
      //   name: null,
      //   type: null
      // },
      // floorPlanFile: {
      //   uploading: false,
      //   uploadFilePercentage: 0,
      //   URL: null,
      //   name: null,
      //   type: null
      // },
      // mortgageDeedsFile: {
      //   uploading: false,
      //   uploadFilePercentage: 0,
      //   URL: null,
      //   name: null,
      //   type: null
      // },
      // leanDocumentFile: {
      //   uploading: false,
      //   uploadFilePercentage: 0,
      //   URL: null,
      //   name: null,
      //   type: null
      // },
      // constructionTimelineFile: {
      //   uploading: false,
      //   uploadFilePercentage: 0,
      //   URL: null,
      //   name: null,
      //   type: null
      // },
      // constructionBudgetFile: {
      //   uploading: false,
      //   uploadFilePercentage: 0,
      //   URL: null,
      //   name: null,
      //   type: null
      // },
      // otherDocumentFile: {
      //   uploading: false,
      //   uploadFilePercentage: 0,
      //   URL: null,
      //   name: null,
      //   type: null
      // },
    }
  );
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 12 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 12 }
    }
  };

  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0
      },
      sm: {
        span: 14,
        offset: 10
      }
    }
  };

  const [uploadFiles, setUploadFiles] = useState(props.propertyInfo);
  const [privatePlacementFile, setPrivatePlacementFile] = useState(props.propertyInfo.privatePlacementFile);
  const [subscriptionAgreementFile, setSubscriptionAgreementFile] = useState(props.propertyInfo.subscriptionAgreementFile);
  const [financialProjectionsFile, setFinancialProjectionsFile] = useState(props.propertyInfo.financialProjectionsFile);
  const [operatingAgreementFile, setOperatingAgreementFile] = useState(props.propertyInfo.operatingAgreementFile);
  const [propertyDetailsFile, setPropertyDetailsFile] = useState(props.propertyInfo.propertyDetailsFile);
  const [otherDocumentsFile, setOtherDocumentsFile] = useState(props.propertyInfo.otherDocumentsFile)
  const [draftFundFile, setDraftFundFile] = useState(props.propertyInfo.draftFundFile)
  const [
    ConfirmationWithRegulation292kFile,
    setConfirmationWithRegulation292kFile,
  ] = useState(props.propertyInfo.ConfirmationWithRegulation292kFile ? props.propertyInfo.ConfirmationWithRegulation292kFile : "");
  const [
    ConfirmationFromIssuerForNpoFile,
    setConfirmationFromIssuerForNpoFile,
  ] = useState(props.propertyInfo.ConfirmationFromIssuerForNpoFile ? props.propertyInfo.ConfirmationFromIssuerForNpoFile : "");
  const [
    ConfirmationOfMoneyLaunderingFile,
    setConfirmationOfMoneyLaunderingFile,
  ] = useState(props.propertyInfo.ConfirmationOfMoneyLaunderingFile ? props.propertyInfo.ConfirmationOfMoneyLaunderingFile : "");
  const [
    ConfirmationWithRegulation292nFile,
    setConfirmationWithRegulation292nFile,
  ] = useState(props.propertyInfo.ConfirmationWithRegulation292nFile ? props.propertyInfo.ConfirmationWithRegulation292nFile : "");
  const [ListingApplicationFile, setListingApplicationFile] = useState(
    props.propertyInfo.ListingApplicationFile ? props.propertyInfo.ListingApplicationFile : ""
  );
  const [
    ResolutionPassedByGoverningBodyFile,
    setResolutionPassedByGoverningBodyFile,
  ] = useState(props.propertyInfo.ResolutionPassedByGoverningBodyFile ? props.propertyInfo.ResolutionPassedByGoverningBodyFile : "");
  const [CertificationFromNpoFile, setCertificationFromNpoFile] = useState(
    props.propertyInfo.CertificationFromNpoFile ? props.propertyInfo.CertificationFromNpoFile : ""
  );
  const [GoverningDocumentFile, setGoverningDocumentFile] = useState(
    props.propertyInfo.GoverningDocumentFile ?  props.propertyInfo.GoverningDocumentFile : ""
  );
  const [UniformListingAgreementFile, setUniformListingAgreementFile] =
    useState(props.propertyInfo.UniformListingAgreementFile ? props.propertyInfo.UniformListingAgreementFile : "");

    const [sendForApprovalVisibility, setSendForApprovalVisibility] = useState(false);
    const [propertyInfoStatus, setPropertyInfoStatus] = useState('');

  // multi upload screen
  const multiUploadFile = async (e, name) => {
    if (!e.target.files) return;
    const file = e.target.files[0];
    if (!file) return;
    const fileType = sharedService.getFileType(file.name);
    if (!enableEdit) setEnableEdit(true);

    switch (name) {
      case 'private':
        {
          let prev = privatePlacementFile;
          setPrivatePlacementFile([...prev, { URL: null }]);
          try {
            setPrivatePlacementFile([...prev, { uploading: true, uploadFilePercentage: 10 }]);
            const { hash } = await sharedService.uploadDocumentToIPFS({ file });
            const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
            setPrivatePlacementFile([...prev, { URL, uploading: false, uploadFilePercentage: 100, name: file.name, type: fileType.toLowerCase() }]);
            form2.setFieldsValue({ [name]: file.name });
          } catch (err) {
            console.error(err);
            setPrivatePlacementFile([...prev, { uploading: false }]);
          }
          break;
        }
      case 'subscription':
        {
          let prev = subscriptionAgreementFile;
          setSubscriptionAgreementFile([...prev, { URL: null }]);
          try {
            setSubscriptionAgreementFile([...prev, { uploading: true, uploadFilePercentage: 10 }]);
            const { hash } = await sharedService.uploadDocumentToIPFS({ file });
            const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
            setSubscriptionAgreementFile([...prev, { URL, uploading: false, uploadFilePercentage: 100, name: file.name, type: fileType.toLowerCase() }]);
            form2.setFieldsValue({ [name]: file.name });
          } catch (err) {
            console.error(err);
            setSubscriptionAgreementFile([...prev, { uploading: false }]);
          }
          break;
        }
      case 'financial':
        {
          let prev = financialProjectionsFile ? financialProjectionsFile : [];
          setFinancialProjectionsFile([...prev, { URL: null }]);
          try {
            setFinancialProjectionsFile([...prev, { uploading: true, uploadFilePercentage: 10 }]);
            const { hash } = await sharedService.uploadDocumentToIPFS({ file });
            const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
            setFinancialProjectionsFile([...prev, { URL, uploading: false, uploadFilePercentage: 100, name: file.name, type: fileType.toLowerCase() }]);
            form2.setFieldsValue({ [name]: file.name });
          } catch (err) {
            console.error(err);
            setFinancialProjectionsFile([...prev, { uploading: false }]);
          }
          break;
        }
      case 'operating':
        {
          let prev = operatingAgreementFile;
          setOperatingAgreementFile([...prev, { URL: null }]);
          try {
            setOperatingAgreementFile([...prev, { uploading: true, uploadFilePercentage: 10 }]);
            const { hash } = await sharedService.uploadDocumentToIPFS({ file });
            const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
            setOperatingAgreementFile([...prev, { URL, uploading: false, uploadFilePercentage: 100, name: file.name, type: fileType.toLowerCase() }]);
            form2.setFieldsValue({ [name]: file.name });
          } catch (err) {
            console.error(err);
            setOperatingAgreementFile([...prev, { uploading: false }]);
          }
          break;
        }
      case 'property':
        {
          let prev = propertyDetailsFile;
          setPropertyDetailsFile([...prev, { URL: null }]);
          try {
            setPropertyDetailsFile([...prev, { uploading: true, uploadFilePercentage: 10 }]);
            const { hash } = await sharedService.uploadDocumentToIPFS({ file });
            const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
            setPropertyDetailsFile([...prev, { URL, uploading: false, uploadFilePercentage: 100, name: file.name, type: fileType.toLowerCase() }]);
            form2.setFieldsValue({ [name]: file.name });
          } catch (err) {
            console.error(err);
            setPropertyDetailsFile([...prev, { uploading: false }]);
          }
          break;
        }
      case 'draftfund':
        {
          let prev = draftFundFile;
          // setDraftFundFile([...prev, { URL: null }]);
          try {
            setDraftFundFile([...prev, { uploading: true, uploadFilePercentage: 10 }]);
            const { hash } = await sharedService.uploadDocumentToIPFS({ file });
            const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
            setDraftFundFile([...prev, { URL, uploading: false, uploadFilePercentage: 100, name: file.name, type: fileType.toLowerCase() }]);
            form2.setFieldsValue({ [name]: file.name });
          } catch (err) {
            console.error(err);
            // setDraftFundFile([...prev, { uploading: false }]);
          }
          break;
        }
        case "ConfirmationWithRegulation292kFile": {
          let prev = ConfirmationWithRegulation292kFile;
          setConfirmationWithRegulation292kFile([...prev, { URL: null }]);
          try {
            setConfirmationWithRegulation292kFile([
              ...prev,
              { uploading: true, uploadFilePercentage: 10 },
            ]);
            const { hash } = await sharedService.uploadDocumentToIPFS({ file });
            const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
            setConfirmationWithRegulation292kFile([
              ...prev,
              {
                URL,
                uploading: false,
                uploadFilePercentage: 100,
                name: file.name,
                type: fileType.toLowerCase(),
              },
            ]);
            form2.setFieldsValue({ [name]: file.name });
          } catch (err) {
            console.error(err);
            setConfirmationWithRegulation292kFile([
              ...prev,
              { uploading: false },
            ]);
          }
          break;
        }
        case "ConfirmationFromIssuerForNpoFile": {
          let prev = ConfirmationFromIssuerForNpoFile;
          setConfirmationFromIssuerForNpoFile([...prev, { URL: null }]);
          try {
            setConfirmationFromIssuerForNpoFile([
              ...prev,
              { uploading: true, uploadFilePercentage: 10 },
            ]);
            const { hash } = await sharedService.uploadDocumentToIPFS({ file });
            const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
            setConfirmationFromIssuerForNpoFile([
              ...prev,
              {
                URL,
                uploading: false,
                uploadFilePercentage: 100,
                name: file.name,
                type: fileType.toLowerCase(),
              },
            ]);
            form2.setFieldsValue({ [name]: file.name });
          } catch (err) {
            console.error(err);
            setConfirmationFromIssuerForNpoFile([...prev, { uploading: false }]);
          }
          break;
        }
        case "ConfirmationOfMoneyLaunderingFile": {
          let prev = ConfirmationOfMoneyLaunderingFile;
          setConfirmationOfMoneyLaunderingFile([...prev, { URL: null }]);
          try {
            setConfirmationOfMoneyLaunderingFile([
              ...prev,
              { uploading: true, uploadFilePercentage: 10 },
            ]);
            const { hash } = await sharedService.uploadDocumentToIPFS({ file });
            const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
            setConfirmationOfMoneyLaunderingFile([
              ...prev,
              {
                URL,
                uploading: false,
                uploadFilePercentage: 100,
                name: file.name,
                type: fileType.toLowerCase(),
              },
            ]);
            form2.setFieldsValue({ [name]: file.name });
          } catch (err) {
            console.error(err);
            setConfirmationOfMoneyLaunderingFile([...prev, { uploading: false }]);
          }
          break;
        }
        case "ConfirmationWithRegulation292nFile": {
          let prev = ConfirmationWithRegulation292nFile;
          setConfirmationWithRegulation292nFile([...prev, { URL: null }]);
          try {
            setConfirmationWithRegulation292nFile([
              ...prev,
              { uploading: true, uploadFilePercentage: 10 },
            ]);
            const { hash } = await sharedService.uploadDocumentToIPFS({ file });
            const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
            setConfirmationWithRegulation292nFile([
              ...prev,
              {
                URL,
                uploading: false,
                uploadFilePercentage: 100,
                name: file.name,
                type: fileType.toLowerCase(),
              },
            ]);
            form2.setFieldsValue({ [name]: file.name });
          } catch (err) {
            console.error(err);
            setConfirmationWithRegulation292nFile([
              ...prev,
              { uploading: false },
            ]);
          }
          break;
        }
        case "ListingApplicationFile": {
          let prev = ListingApplicationFile;
          setListingApplicationFile([...prev, { URL: null }]);
          try {
            setListingApplicationFile([
              ...prev,
              { uploading: true, uploadFilePercentage: 10 },
            ]);
            const { hash } = await sharedService.uploadDocumentToIPFS({ file });
            const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
            setListingApplicationFile([
              ...prev,
              {
                URL,
                uploading: false,
                uploadFilePercentage: 100,
                name: file.name,
                type: fileType.toLowerCase(),
              },
            ]);
            form2.setFieldsValue({ [name]: file.name });
          } catch (err) {
            console.error(err);
            setListingApplicationFile([...prev, { uploading: false }]);
          }
          break;
        }
        case "ResolutionPassedByGoverningBodyFile": {
          let prev = ResolutionPassedByGoverningBodyFile;
          setResolutionPassedByGoverningBodyFile([...prev, { URL: null }]);
          try {
            setResolutionPassedByGoverningBodyFile([
              ...prev,
              { uploading: true, uploadFilePercentage: 10 },
            ]);
            const { hash } = await sharedService.uploadDocumentToIPFS({ file });
            const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
            setResolutionPassedByGoverningBodyFile([
              ...prev,
              {
                URL,
                uploading: false,
                uploadFilePercentage: 100,
                name: file.name,
                type: fileType.toLowerCase(),
              },
            ]);
            form2.setFieldsValue({ [name]: file.name });
          } catch (err) {
            console.error(err);
            setResolutionPassedByGoverningBodyFile([
              ...prev,
              { uploading: false },
            ]);
          }
          break;
        }
        case "CertificationFromNpoFile": {
          let prev = CertificationFromNpoFile;
          setCertificationFromNpoFile([...prev, { URL: null }]);
          try {
            setCertificationFromNpoFile([
              ...prev,
              { uploading: true, uploadFilePercentage: 10 },
            ]);
            const { hash } = await sharedService.uploadDocumentToIPFS({ file });
            const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
            setCertificationFromNpoFile([
              ...prev,
              {
                URL,
                uploading: false,
                uploadFilePercentage: 100,
                name: file.name,
                type: fileType.toLowerCase(),
              },
            ]);
            form2.setFieldsValue({ [name]: file.name });
          } catch (err) {
            console.error(err);
            setCertificationFromNpoFile([...prev, { uploading: false }]);
          }
          break;
        }
        case "GoverningDocumentFile": {
          let prev = GoverningDocumentFile;
          setGoverningDocumentFile([...prev, { URL: null }]);
          try {
            setGoverningDocumentFile([
              ...prev,
              { uploading: true, uploadFilePercentage: 10 },
            ]);
            const { hash } = await sharedService.uploadDocumentToIPFS({ file });
            const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
            setGoverningDocumentFile([
              ...prev,
              {
                URL,
                uploading: false,
                uploadFilePercentage: 100,
                name: file.name,
                type: fileType.toLowerCase(),
              },
            ]);
            form2.setFieldsValue({ [name]: file.name });
          } catch (err) {
            console.error(err);
            setGoverningDocumentFile([...prev, { uploading: false }]);
          }
          break;
        }
        case "UniformListingAgreementFile": {
          let prev = UniformListingAgreementFile;
          setUniformListingAgreementFile([...prev, { URL: null }]);
          try {
            setUniformListingAgreementFile([
              ...prev,
              { uploading: true, uploadFilePercentage: 10 },
            ]);
            const { hash } = await sharedService.uploadDocumentToIPFS({ file });
            const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
            setUniformListingAgreementFile([
              ...prev,
              {
                URL,
                uploading: false,
                uploadFilePercentage: 100,
                name: file.name,
                type: fileType.toLowerCase(),
              },
            ]);
            form2.setFieldsValue({ [name]: file.name });
          } catch (err) {
            console.error(err);
            setUniformListingAgreementFile([...prev, { uploading: false }]);
          }
          break;
        }
      case 'other':
        {
          let prev = otherDocumentsFile;
          setOtherDocumentsFile([...prev, { URL: null }]);
          try {
            setOtherDocumentsFile([...prev, { uploading: true, uploadFilePercentage: 10 }]);
            const { hash } = await sharedService.uploadDocumentToIPFS({ file });
            const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
            setOtherDocumentsFile([...prev, { URL, uploading: false, uploadFilePercentage: 100, name: file.name, type: fileType.toLowerCase() }]);
            form2.setFieldsValue({ [name]: file.name });
          } catch (err) {
            console.error(err);
            setOtherDocumentsFile([...prev, { uploading: false }]);
          }
          break;
        }
    }

  }

  const uploadFile = async (e, key) => {
    if (!e.target.files) return;
    const file = e.target.files[0];
    if (!file) return;
    const fileType = sharedService.getFileType(file.name);

    setPropertyFiles((prev) => ({ [key]: { URL: null, ...prev[key] }, ...prev }));

    try {
      setPropertyFiles((prev) => {
        return ({ ...prev, [key]: { ...prev[key], uploading: true, uploadFilePercentage: 10 } });
      });
      const { hash } = await sharedService.uploadDocumentToIPFS({ file });
      const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
      setPropertyFiles((prev) => ({ ...prev, [key]: { ...prev[key], URL, uploading: false, uploadFilePercentage: 100, name: file.name, type: fileType.toLowerCase() } }));
      form.setFieldsValue({ [key]: file.name });
    } catch (err) {
      console.error(err);
      setPropertyFiles((prev) => ({ ...prev, [key]: { ...prev[key], uploading: false } }));
    }

  }

  const updateUploadedDocuments = async (_form2) => {
    const form2 = { ..._form2 };
    setLoading(true);

    form2.privatePlacementFile = privatePlacementFile.map(file => ({ URL: file.URL, name: file.name, type: file.type }));
    form2.subscriptionAgreementFile = subscriptionAgreementFile.map(file => ({ URL: file.URL, name: file.name, type: file.type }));
    form2.financialProjectionsFile = financialProjectionsFile.map(file => ({ URL: file.URL, name: file.name, type: file.type }));
    form2.operatingAgreementFile = operatingAgreementFile.map(file => ({ URL: file.URL, name: file.name, type: file.type }));
    form2.propertyDetailsFile = propertyDetailsFile.map(file => ({ URL: file.URL, name: file.name, type: file.type }));
    form2.draftFundFile = draftFundFile.map(file => ({ URL: file.URL, name: file.name, type: file.type }));
    form2.ConfirmationWithRegulation292kFile =
    ConfirmationWithRegulation292kFile.map((file) => ({
      URL: file.URL,
      name: file.name,
      type: file.type,
    }));
  form2.ConfirmationFromIssuerForNpoFile =
    ConfirmationFromIssuerForNpoFile.map((file) => ({
      URL: file.URL,
      name: file.name,
      type: file.type,
    }));
  form2.ConfirmationOfMoneyLaunderingFile =
    ConfirmationOfMoneyLaunderingFile.map((file) => ({
      URL: file.URL,
      name: file.name,
      type: file.type,
    }));
  form2.ConfirmationWithRegulation292nFile =
    ConfirmationWithRegulation292nFile.map((file) => ({
      URL: file.URL,
      name: file.name,
      type: file.type,
    }));
  form2.ListingApplicationFile = ListingApplicationFile.map((file) => ({
    URL: file.URL,
    name: file.name,
    type: file.type,
  }));
  form2.ResolutionPassedByGoverningBodyFile =
    ResolutionPassedByGoverningBodyFile.map((file) => ({
      URL: file.URL,
      name: file.name,
      type: file.type,
    }));
  form2.CertificationFromNpoFile = CertificationFromNpoFile.map((file) => ({
    URL: file.URL,
    name: file.name,
    type: file.type,
  }));
  form2.GoverningDocumentFile = GoverningDocumentFile.map((file) => ({
    URL: file.URL,
    name: file.name,
    type: file.type,
  }));
  form2.UniformListingAgreementFile = UniformListingAgreementFile.map(
    (file) => ({
      URL: file.URL,
      name: file.name,
      type: file.type,
    })
  );
    form2.otherDocumentsFile = otherDocumentsFile.map(file => ({ URL: file.URL, name: file.name, type: file.type }));
    form2.userId = props.userInfo._id;
    form2.propertyDocuments = 'done';

    const response = await issuerSuperAdminService.updateUploadedDocuments(form2);

    if (response.success) {
      history.push('/issuer-super-admin/add-property/basic-information');
      notification.success({ message: 'Success' });
    } else {
      message.error(response.error.message);
      notification.error({ message: 'Error', description: 'response.error.message' });
    }

    props.setUpdated(true);
    setLoading(false);
    //window.location.reload();
  }

  const submitUploadDocuments = async (_form) => {
    const form = { ..._form };
    setLoading(true);

    form.privatePlacementFile = propertyFiles.privatePlacementFile?.URL ? [{
      name: propertyFiles.privatePlacementFile?.name,
      URL: propertyFiles.privatePlacementFile?.URL,
      type: propertyFiles.privatePlacementFile.type,
    }] : [];
    form.subscriptionAgreementFile = propertyFiles.subscriptionAgreementFile?.URL ? [{
      name: propertyFiles.subscriptionAgreementFile?.name,
      URL: propertyFiles.subscriptionAgreementFile?.URL,
      type: propertyFiles.subscriptionAgreementFile.type,
    }] : [];
    form.financialProjectionsFile = propertyFiles.financialProjectionsFile?.URL ? [{
      name: propertyFiles.financialProjectionsFile?.name,
      URL: propertyFiles.financialProjectionsFile?.URL,
      type: propertyFiles.financialProjectionsFile.type,
    }] : [];
    form.operatingAgreementFile = propertyFiles.operatingAgreementFile?.URL ? [{
      name: propertyFiles.operatingAgreementFile?.name,
      URL: propertyFiles.operatingAgreementFile?.URL,
      type: propertyFiles.operatingAgreementFile.type,
    }] : [];
    form.propertyDetailsFile = propertyFiles.propertyDetailsFile?.URL ? [{
      name: propertyFiles.propertyDetailsFile?.name,
      URL: propertyFiles.propertyDetailsFile?.URL,
      type: propertyFiles.propertyDetailsFile.type,
    }] : [];
    form.draftFundFile = propertyFiles.draftFundFile?.URL ? [{
      name: propertyFiles.draftFundFile?.name,
      URL: propertyFiles.draftFundFile?.URL,
      type: propertyFiles.draftFundFile.type,
    }] : [];
    form.ConfirmationWithRegulation292kFile = propertyFiles
    .ConfirmationWithRegulation292kFile?.URL
    ? [
        {
          name: propertyFiles.ConfirmationWithRegulation292kFile?.name,
          URL: propertyFiles.ConfirmationWithRegulation292kFile?.URL,
          type: propertyFiles.ConfirmationWithRegulation292kFile.type,
        },
      ]
    : [];
  form.ConfirmationFromIssuerForNpoFile = propertyFiles
    .ConfirmationFromIssuerForNpoFile?.URL
    ? [
        {
          name: propertyFiles.ConfirmationFromIssuerForNpoFile?.name,
          URL: propertyFiles.ConfirmationFromIssuerForNpoFile?.URL,
          type: propertyFiles.ConfirmationFromIssuerForNpoFile.type,
        },
      ]
    : [];
  form.ConfirmationOfMoneyLaunderingFile = propertyFiles
    .ConfirmationOfMoneyLaunderingFile?.URL
    ? [
        {
          name: propertyFiles.ConfirmationOfMoneyLaunderingFile?.name,
          URL: propertyFiles.ConfirmationOfMoneyLaunderingFile?.URL,
          type: propertyFiles.ConfirmationOfMoneyLaunderingFile.type,
        },
      ]
    : [];
  form.ConfirmationWithRegulation292nFile = propertyFiles
    .ConfirmationWithRegulation292nFile?.URL
    ? [
        {
          name: propertyFiles.ConfirmationWithRegulation292nFile?.name,
          URL: propertyFiles.ConfirmationWithRegulation292nFile?.URL,
          type: propertyFiles.ConfirmationWithRegulation292nFile.type,
        },
      ]
    : [];
  form.ListingApplicationFile = propertyFiles.ListingApplicationFile?.URL
    ? [
        {
          name: propertyFiles.ListingApplicationFile?.name,
          URL: propertyFiles.ListingApplicationFile?.URL,
          type: propertyFiles.ListingApplicationFile.type,
        },
      ]
    : [];
  form.ResolutionPassedByGoverningBodyFile = propertyFiles
    .ResolutionPassedByGoverningBodyFile?.URL
    ? [
        {
          name: propertyFiles.ResolutionPassedByGoverningBodyFile?.name,
          URL: propertyFiles.ResolutionPassedByGoverningBodyFile?.URL,
          type: propertyFiles.ResolutionPassedByGoverningBodyFile.type,
        },
      ]
    : [];
  form.CertificationFromNpoFile = propertyFiles.CertificationFromNpoFile?.URL
    ? [
        {
          name: propertyFiles.CertificationFromNpoFile?.name,
          URL: propertyFiles.CertificationFromNpoFile?.URL,
          type: propertyFiles.CertificationFromNpoFile.type,
        },
      ]
    : [];
  form.GoverningDocumentFile = propertyFiles.GoverningDocumentFile?.URL
    ? [
        {
          name: propertyFiles.GoverningDocumentFile?.name,
          URL: propertyFiles.GoverningDocumentFile?.URL,
          type: propertyFiles.GoverningDocumentFile.type,
        },
      ]
    : [];
  form.UniformListingAgreementFile = propertyFiles.UniformListingAgreementFile
    ?.URL
    ? [
        {
          name: propertyFiles.UniformListingAgreementFile?.name,
          URL: propertyFiles.UniformListingAgreementFile?.URL,
          type: propertyFiles.UniformListingAgreementFile.type,
        },
      ]
    : [];
    form.otherDocumentsFile = propertyFiles.otherDocumentsFile?.URL ? [{
      name: propertyFiles.otherDocumentsFile?.name,
      URL: propertyFiles.otherDocumentsFile?.URL,
      type: propertyFiles.otherDocumentsFile.type,
    }] : [];
    // form.minInvestmentFile ={
    //   name: propertyFiles.minInvestmentFile?.name,
    //   URL: propertyFiles.minInvestmentFile?.URL,
    //   type: propertyFiles.minInvestmentFile.type,
    // };
    // form.titleClearanceFile = propertyFiles.titleClearanceFile?.URL ? {
    //   name: propertyFiles.titleClearanceFile?.name,
    //   URL: propertyFiles.titleClearanceFile?.URL,
    //   type: propertyFiles.titleClearanceFile.type,
    // } : null ;
    // form.titleDocumentsFile = propertyFiles.titleDocumentsFile?.URL ? {
    //   name: propertyFiles.titleDocumentsFile?.name,
    //   URL: propertyFiles.titleDocumentsFile?.URL,
    //   type: propertyFiles.titleDocumentsFile.type,
    // } : null ;
    // form.floorPlanFile = propertyFiles.floorPlanFile?.URL ? {
    //   name: propertyFiles.floorPlanFile?.name,
    //   URL: propertyFiles.floorPlanFile?.URL,
    //   type: propertyFiles.floorPlanFile.type,
    // } : null ;
    // form.mortgageDeedsFile = propertyFiles.mortgageDeedsFile?.URL ? {
    //   name: propertyFiles.mortgageDeedsFile?.name,
    //   URL: propertyFiles.mortgageDeedsFile?.URL,
    //   type: propertyFiles.mortgageDeedsFile.type,
    // } : null ;
    // form.leanDocumentFile = propertyFiles.leanDocumentFile?.URL ? {
    //   name: propertyFiles.leanDocumentFile?.name,
    //   URL: propertyFiles.leanDocumentFile?.URL,
    //   type: propertyFiles.leanDocumentFile.type,
    // } : null ;
    // form.constructionTimelineFile = propertyFiles.constructionTimelineFile?.URL ? {
    //   name: propertyFiles.constructionTimelineFile?.name,
    //   URL: propertyFiles.constructionTimelineFile?.URL,
    //   type: propertyFiles.constructionTimelineFile.type,
    // } : null ;
    // form.constructionBudgetFile = propertyFiles.constructionBudgetFile?.URL ? {
    //   name: propertyFiles.constructionBudgetFile?.name,
    //   URL: propertyFiles.constructionBudgetFile?.URL,
    //   type: propertyFiles.constructionBudgetFile.type,
    // } : null ;
    // form.otherDocumentFile = propertyFiles.otherDocumentFile?.URL ? {
    //   name: propertyFiles.otherDocumentFile?.name,
    //   URL: propertyFiles.otherDocumentFile?.URL,
    //   type: propertyFiles.otherDocumentFile.type,
    // } : null ;

    form.userId = props.userInfo._id;
    form.propertyDocuments = 'done';
    form.stepDone = 4;

    const response = await issuerSuperAdminService.savePropertyDocuments(form);

    if (response.success) {
      notification.success({ message: 'Success' });
      setSendForApprovalVisibility(true)
      history.push('/issuer-super-admin/add-property/basic-information')
    } else {
      message.error(response.error.message);
      notification.error({ message: 'Error', description: 'response.error.message' });
    }

    props.setUpdated(true);
    setLoading(false);
    window.location.reload();
  }

  const mergeFiles= async() =>{
    setLoading(true);
    console.log('api for merging files...')
    const response = await issuerSuperAdminService.mergePropertyDocuments({userid:props.userInfo._id});
    console.log(response)
    if(response.success && response.data.merged){
      setLoading(false)
      
      setMergedFileLink(response.data.file)
    }else{
      setLoading(false)
    }
  }

  const uploadMergedFile = async () => {
    try {
      setLoading(true);
      //download file and upload to ipfs
      
      if(!mergedFileLink){
        setLoading(false);
        notification.error({ message: 'Error', description: 'No file to upload' });
        return;
      }

      const resp = await fetch(mergedFileLink);
      const blob = await resp.blob();
      const fileobj = new Blob([blob], { type: "application/pdf" });
      const file = new File([fileobj], "mergedfile.pdf", {});

      console.log(blob);
      const { hash } = await sharedService.uploadDocumentToIPFS({ file });
      const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
      console.log(URL);

      if(!hash){
        setLoading(false);
        notification.error({ message: 'Error', description: 'Error while uploading file' });
        return;
      }

      const data = {
        mergedFile: {
          name: mergedFileLink.split("/")[4],
          URL: URL,
          type: "application/pdf",
        },
        userId: props.userInfo._id,
      };

      try {
        setIsModalVisible(true);
        setTransactions([{details: 'Adding the combined document to blockchain', submitting: true}]);
        
        // blockchain call to add documents
        const receipt = await erc1643.setDocument(
          "0xD1237441f457A3d83279711bBBEE6eB8cB62d3C7", // contract address to add property
          selectedWallet as string, // owner to add document
          "0x00",  // name of the document as bytes32
          URL,  // url of the ipfs data as string
          "0x00" // hash of the document as string
          );

        setTransactions(prev => {
          const current = sharedService.clone(prev);
          current[0].receipt = receipt;
         return current;
       });    
        
      } catch (err) {
        console.error(err);
      }
  
      setTransactions(prev => {
        const current = sharedService.clone(prev);
        current[0].submitting = false;
        return current;
      });
      const res = await issuerSuperAdminService.updateUploadedDocuments(data);
      if(res.success) notification.success({ message: 'File uploaded successfully!' });
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    
  };

  const sendPropertyDetailsForApproval  = async()=>{
    try {
      if(!userInfo) return;

      setLoading(false);
      const res = await issuerSuperAdminService.sendPropertyDetailsForApprovalToMSA(userInfo?.company?.id);
      if(!res.success){
        notification.error({ message: 'Error', description: res?.error?.message })
        setLoading(false);
        return;
      }
      notification.success({ message: 'Success', description: 'Property details sent for approval successfully.'})

    } catch (error) {
      console.log(error)
      setLoading(false);
    }
  }

  const approveOrReject = async(status) =>{
    try {
      
      let payload = {status,rejectionMessage:''}
      if(status == 'rejected'){

        if(rejectReason == ''){
            notification.info({message: 'Info', description: 'Provide valid rejection message'});
            return;
          }

        payload.rejectionMessage = rejectReason;
        setShowRejectPopup(false)
      }
      console.log(payload)    
      const id = sessionStorage.getItem('companyId')
      const res = await issuerSuperAdminService.approveOrRejectPropertyDetails(id,payload)
      console.log(res)
      if(res.success){
        notification.success({message: 'Success', description: `Property info ${status} successfully`});
        setTimeout(() => {
          window.location.reload();
        }, 3000);   
      }else{
        notification.error({message: 'Error', description: res.error.message});
      }

    } catch (error) {
      console.log(error);
      notification.error({message:'error', description:error})
    }
  }

  return (
    <>
      {loading &&
        <div style={{ textAlign: 'center' }}>
          <br /><br />
          <Spin size='large' />
        </div>
      }
      {!loading && !props.documentsDone && (<Row justify="center">
        <Col span={20}>
          <h2 style={{ color: 'darkblue', fontSize: '20px', marginTop: '20px', fontWeight: 'bold' }}>Offering Document</h2>
          <Form {...formItemLayout} labelAlign="left" form={form} initialValues={{ boardResolution: { option: 'upload' }, additionInfo: null }} onFinish={submitUploadDocuments}>
            <div>
              <Card>
                <Form.Item
                  label="Private Placement Memorandum"
                  name='privatePlacementFile'
                  rules={[
                    {
                      required: false,
                      message: 'This field is required'
                    }
                  ]}>
                  <>
                    <Input type='file' onChange={e => { uploadFile(e, 'privatePlacementFile') }} />
                    {propertyFiles.privatePlacementFile.uploadFilePercentage > 0 &&
                      <Progress percent={propertyFiles.privatePlacementFile.uploadFilePercentage} />
                    }
                  </>
                </Form.Item>
                {/* <Form.Item
                label="WhitePaper- Business profile document"
                name='whitePaperFile'
                rules={[
                  {
                    required: true,
                    message: 'This field is required'
                  }
                ]}>
                <>
                  <Input type='file' onChange={e => { uploadFile(e, 'whitePaperFile') }} />
                  {propertyFiles.whitePaperFile.uploadFilePercentage > 0 &&
                    <Progress percent={propertyFiles.whitePaperFile.uploadFilePercentage} />
                  }
                </>
              </Form.Item> */}
                <Form.Item
                  label="Subscription Agreement"
                  name='subscriptionAgreementFile'
                  rules={[
                    {
                      required: false,
                      message: 'This field is required'
                    }
                  ]}>
                  <>
                    <Input type='file' onChange={e => { uploadFile(e, 'subscriptionAgreementFile') }} />
                    {propertyFiles.subscriptionAgreementFile.uploadFilePercentage > 0 &&
                      <Progress percent={propertyFiles.subscriptionAgreementFile.uploadFilePercentage} />
                    }
                  </>
                </Form.Item>
                <Form.Item
                  label="Financial Projection"
                  name='financialProjectionsFile'
                  rules={[
                    {
                      required: false,
                      message: 'This field is required'
                    }
                  ]}>
                  <>
                    <Input type='file' onChange={e => { uploadFile(e, 'financialProjectionsFile') }} />
                    {propertyFiles.financialProjectionsFile.uploadFilePercentage > 0 &&
                      <Progress percent={propertyFiles.financialProjectionsFile.uploadFilePercentage} />
                    }
                  </>
                </Form.Item>
                <Form.Item
                  label="Operating Agreement"
                  name='operatingAgreementFile'
                  rules={[
                    {
                      required: false,
                      message: 'This field is required'
                    }
                  ]}>
                  <>
                    <Input type='file' onChange={e => { uploadFile(e, 'operatingAgreementFile') }} />
                    {propertyFiles.operatingAgreementFile.uploadFilePercentage > 0 &&
                      <Progress percent={propertyFiles.operatingAgreementFile.uploadFilePercentage} />
                    }
                  </>
                </Form.Item>
                <Form.Item
                  label="Property Details (Key Facts)"
                  name='propertyDetailsFile'
                  rules={[
                    {
                      required: false,
                      message: 'This field is required'
                    }
                  ]}>
                  <>
                    <Input type='file' onChange={e => { uploadFile(e, 'propertyDetailsFile') }} />
                    {propertyFiles.propertyDetailsFile.uploadFilePercentage > 0 &&
                      <Progress percent={propertyFiles.propertyDetailsFile.uploadFilePercentage} />
                    }
                  </>
                </Form.Item>
                <Form.Item
                  label="Draft fund raising document"
                  name='draftFundFile'
                  rules={[
                    {
                      required: false,
                      message: 'This field is required'
                    }
                  ]}>
                  <>
                    <Input type='file' onChange={e => { uploadFile(e, 'draftFundFile') }} />
                    {propertyFiles.draftFundFile.uploadFilePercentage > 0 &&
                      <Progress percent={propertyFiles.draftFundFile.uploadFilePercentage} />
                    }
                  </>
                </Form.Item>
                <Form.Item
                    label="Confirmation with regulation 292K"
                    name="ConfirmationWithRegulation292kFile"
                    rules={[
                      {
                        required: false,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <>
                      <Input
                        type="file"
                        onChange={(e) => {
                          uploadFile(e, "ConfirmationWithRegulation292kFile");
                        }}
                      />
                      {propertyFiles.ConfirmationWithRegulation292kFile
                        .uploadFilePercentage > 0 && (
                        <Progress
                          percent={
                            propertyFiles.ConfirmationWithRegulation292kFile
                              .uploadFilePercentage
                          }
                        />
                      )}
                    </>
                  </Form.Item>
                  <Form.Item
                    label="Confirmation from issuer that is eligible to be an NPO"
                    name="ConfirmationFromIssuerForNpoFile"
                    rules={[
                      {
                        required: false,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <>
                      <Input
                        type="file"
                        onChange={(e) => {
                          uploadFile(e, "ConfirmationFromIssuerForNpoFile");
                        }}
                      />
                      {propertyFiles.ConfirmationFromIssuerForNpoFile
                        .uploadFilePercentage > 0 && (
                        <Progress
                          percent={
                            propertyFiles.ConfirmationFromIssuerForNpoFile
                              .uploadFilePercentage
                          }
                        />
                      )}
                    </>
                  </Form.Item>
                  <Form.Item
                    label="Confirmation of Money Laundering Act, 2002"
                    name="ConfirmationOfMoneyLaunderingFile"
                    rules={[
                      {
                        required: false,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <>
                      <Input
                        type="file"
                        onChange={(e) => {
                          uploadFile(e, "ConfirmationOfMoneyLaunderingFile");
                        }}
                      />
                      {propertyFiles.ConfirmationOfMoneyLaunderingFile
                        .uploadFilePercentage > 0 && (
                        <Progress
                          percent={
                            propertyFiles.ConfirmationOfMoneyLaunderingFile
                              .uploadFilePercentage
                          }
                        />
                      )}
                    </>
                  </Form.Item>
                  <Form.Item
                    label="Confirmation with regulation 292N"
                    name="ConfirmationWithRegulation292nFile"
                    rules={[
                      {
                        required: false,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <>
                      <Input
                        type="file"
                        onChange={(e) => {
                          uploadFile(e, "ConfirmationWithRegulation292nFile");
                        }}
                      />
                      {propertyFiles.ConfirmationWithRegulation292nFile
                        .uploadFilePercentage > 0 && (
                        <Progress
                          percent={
                            propertyFiles.ConfirmationWithRegulation292nFile
                              .uploadFilePercentage
                          }
                        />
                      )}
                    </>
                  </Form.Item>
                  <Form.Item
                    label="Listing Application"
                    name="ListingApplicationFile"
                    rules={[
                      {
                        required: false,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <>
                      <Input
                        type="file"
                        onChange={(e) => {
                          uploadFile(e, "ListingApplicationFile");
                        }}
                      />
                      {propertyFiles.ListingApplicationFile
                        .uploadFilePercentage > 0 && (
                        <Progress
                          percent={
                            propertyFiles.ListingApplicationFile
                              .uploadFilePercentage
                          }
                        />
                      )}
                    </>
                  </Form.Item>
                  <Form.Item
                    label="Resolution passed by the Governing Body, List of allottees"
                    name="ResolutionPassedByGoverningBodyFile"
                    rules={[
                      {
                        required: false,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <>
                      <Input
                        type="file"
                        onChange={(e) => {
                          uploadFile(e, "ResolutionPassedByGoverningBodyFile");
                        }}
                      />
                      {propertyFiles.ResolutionPassedByGoverningBodyFile
                        .uploadFilePercentage > 0 && (
                        <Progress
                          percent={
                            propertyFiles.ResolutionPassedByGoverningBodyFile
                              .uploadFilePercentage
                          }
                        />
                      )}
                    </>
                  </Form.Item>
                  <Form.Item
                    label="Undertaking/Certification from the NPO (As per Annexure I)"
                    name="CertificationFromNpoFile"
                    rules={[
                      {
                        required: false,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <>
                      <Input
                        type="file"
                        onChange={(e) => {
                          uploadFile(e, "CertificationFromNpoFile");
                        }}
                      />
                      {propertyFiles.CertificationFromNpoFile
                        .uploadFilePercentage > 0 && (
                        <Progress
                          percent={
                            propertyFiles.CertificationFromNpoFile
                              .uploadFilePercentage
                          }
                        />
                      )}
                    </>
                  </Form.Item>
                  <Form.Item
                    label="Governing document (MoA & AoA/ Trust Deed/ Byelaws/ Constitution)"
                    name="GoverningDocumentFile"
                    rules={[
                      {
                        required: false,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <>
                      <Input
                        type="file"
                        onChange={(e) => {
                          uploadFile(e, "GoverningDocumentFile");
                        }}
                      />
                      {propertyFiles.GoverningDocumentFile
                        .uploadFilePercentage > 0 && (
                        <Progress
                          percent={
                            propertyFiles.GoverningDocumentFile
                              .uploadFilePercentage
                          }
                        />
                      )}
                    </>
                  </Form.Item>
                  <Form.Item
                    label="Uniform Listing Agreement"
                    name="UniformListingAgreementFile"
                    rules={[
                      {
                        required: false,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <>
                      <Input
                        type="file"
                        onChange={(e) => {
                          uploadFile(e, "UniformListingAgreementFile");
                        }}
                      />
                      {propertyFiles.UniformListingAgreementFile
                        .uploadFilePercentage > 0 && (
                        <Progress
                          percent={
                            propertyFiles.UniformListingAgreementFile
                              .uploadFilePercentage
                          }
                        />
                      )}
                    </>
                  </Form.Item>
                <Form.Item
                  label="Other Documents File"
                  name='otherDocumentsFile'
                  rules={[
                    {
                      required: false,
                      message: 'This field is required'
                    }
                  ]}>
                  <>
                    <Input type='file' onChange={e => { uploadFile(e, 'otherDocumentsFile') }} />
                    {propertyFiles.otherDocumentsFile.uploadFilePercentage > 0 &&
                      <Progress percent={propertyFiles.otherDocumentsFile.uploadFilePercentage} />
                    }
                  </>
                </Form.Item>
                {/* <Form.Item
                label="Min Investment"
                name='minInvestmentFile'
                rules={[
                  {
                    required: true,
                    message: 'This field is required'
                  }
                ]}>
                <>
                  <Input type='file' onChange={e => { uploadFile(e, 'minInvestmentFile') }} />
                  {propertyFiles.minInvestmentFile.uploadFilePercentage > 0 &&
                    <Progress percent={propertyFiles.minInvestmentFile.uploadFilePercentage} />
                  }
                </>
              </Form.Item> */}
                {/* <h3 className="property-details">Property Details Documents</h3>
              <Form.Item
                label="Title clearance certificate"
                name='titleClearanceFile'
                rules={[
                  {
                    required: false,
                    message: 'This field is required'
                  }
                ]}>
                <>
                  <Input type='file' onChange={e => { uploadFile(e, 'titleClearanceFile') }} />
                  {propertyFiles.titleClearanceFile.uploadFilePercentage > 0 &&
                    <Progress percent={propertyFiles.titleClearanceFile.uploadFilePercentage} />
                  }
                </>
              </Form.Item>
              <Form.Item
                label="Title Document"
                name='titleDocumentsFile'
                rules={[
                  {
                    required: false,
                    message: 'This field is required'
                  }
                ]}>
                <>
                  <Input type='file' onChange={e => { uploadFile(e, 'titleDocumentsFile') }} />
                  {propertyFiles.titleDocumentsFile.uploadFilePercentage > 0 &&
                    <Progress percent={propertyFiles.titleDocumentsFile.uploadFilePercentage} />
                  }
                </>
              </Form.Item>
              <Form.Item
                label="Floor Plan"
                name='floorPlanFile'
                rules={[
                  {
                    required: false,
                    message: 'This field is required'
                  }
                ]}>
                <>
                  <Input type='file' onChange={e => { uploadFile(e, 'floorPlanFile') }} />
                  {propertyFiles.floorPlanFile.uploadFilePercentage > 0 &&
                    <Progress percent={propertyFiles.floorPlanFile.uploadFilePercentage} />
                  }
                </>
              </Form.Item>
              <Form.Item
                label="Mortgage deeds if any"
                name='mortgageDeedsFile'
                rules={[
                  {
                    required: false,
                    message: 'This field is required'
                  }
                ]}>
                <>
                  <Input type='file' onChange={e => { uploadFile(e, 'mortgageDeedsFile') }} />
                  {propertyFiles.mortgageDeedsFile.uploadFilePercentage > 0 &&
                    <Progress percent={propertyFiles.mortgageDeedsFile.uploadFilePercentage} />
                  }
                </>
              </Form.Item>
              <Form.Item
                label="Any lean document"
                name='leanDocumentFile'
                rules={[
                  {
                    required: false,
                    message: 'This field is required'
                  }
                ]}>
                <>
                  <Input type='file' onChange={e => { uploadFile(e, 'leanDocumentFile') }} />
                  {propertyFiles.leanDocumentFile.uploadFilePercentage > 0 &&
                    <Progress percent={propertyFiles.leanDocumentFile.uploadFilePercentage} />
                  }
                </>
              </Form.Item>
              <Form.Item
                label="Construction Timeline"
                name='constructionTimelineFile'
                rules={[
                  {
                    required: false,
                    message: 'This field is required'
                  }
                ]}>
                <>
                  <Input type='file' onChange={e => { uploadFile(e, 'constructionTimelineFile') }} />
                  {propertyFiles.constructionTimelineFile.uploadFilePercentage > 0 &&
                    <Progress percent={propertyFiles.constructionTimelineFile.uploadFilePercentage} />
                  }
                </>
              </Form.Item>
              <Form.Item
                label="Construction budget"
                name='constructionBudgetFile'
                rules={[
                  {
                    required: false,
                    message: 'This field is required'
                  }
                ]}>
                <>
                  <Input type='file' onChange={e => { uploadFile(e, 'constructionBudgetFile') }} />
                  {propertyFiles.constructionBudgetFile.uploadFilePercentage > 0 &&
                    <Progress percent={propertyFiles.constructionBudgetFile.uploadFilePercentage} />
                  }
                </>
              </Form.Item>
              <Form.Item
                label="Other document"
                name='otherDocumentFile'
                rules={[
                  {
                    required: false,
                    message: 'This field is required'
                  }
                ]}>
                <>
                  <Input type='file' onChange={e => { uploadFile(e, 'otherDocumentFile') }} />
                  {propertyFiles.otherDocumentFile.uploadFilePercentage > 0 &&
                    <Progress percent={propertyFiles.otherDocumentFile.uploadFilePercentage} />
                  }
                </>
              </Form.Item> */}
              </Card>
            </div>

            <br /><br />
            <Form.Item {...tailFormItemLayout}>
              <Button size='large' style={{ marginLeft: '10px' }} type='primary' htmlType="submit" loading={loading}>Submit</Button>
            </Form.Item>
            <br /><br />

          </Form>
        </Col>
      </Row>)}

      {props.documentsDone && !loading && !editView &&
        <div>
          <Row justify="center">
            <Col span={16}>
              <Card>
                <Row>
                  <Col span={20}>
                    <div>
                      <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>Private Placement Memorandum :</sub>
                      <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}>{uploadFiles.privatePlacementFile.length > 0 ? (<a href={uploadFiles.privatePlacementFile[uploadFiles.privatePlacementFile.length - 1]?.URL} download={uploadFiles.privatePlacementFile[uploadFiles.privatePlacementFile.length - 1]?.name} target="_blank" rel="noopener noreferrer">{uploadFiles.privatePlacementFile[uploadFiles.privatePlacementFile.length - 1]?.name}</a>) : (<span>No Documents</span>)}</sub>
                    </div>
                  </Col>
                </Row>
                {/* <br/>
                <Row>
                  <Col span={20}>
                    <div>
                      <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>WhitePaper- Business profile document :</sub>
                      <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}><a href={uploadFiles.whitePaperFile?.URL} download={uploadFiles.whitePaperFile?.name} target="_blank" rel="noopener noreferrer">{uploadFiles.whitePaperFile?.name}</a></sub>
                    </div>
                  </Col>
                </Row> */}
                <br />
                <Row>
                  <Col span={20}>
                    <div>
                      <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>Subscription Agreement: </sub>
                      <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}>{uploadFiles.subscriptionAgreementFile.length > 0 ? (<a href={uploadFiles.subscriptionAgreementFile[uploadFiles.subscriptionAgreementFile.length - 1]?.URL} download={uploadFiles.subscriptionAgreementFile[uploadFiles.subscriptionAgreementFile.length - 1]?.name} target="_blank" rel="noopener noreferrer">{uploadFiles.subscriptionAgreementFile[uploadFiles.subscriptionAgreementFile.length - 1]?.name}</a>) : (<span>No Documents</span>)}</sub>
                    </div>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col span={20}>
                    <div>
                      <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>Financial Projection: </sub>
                      <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}>{uploadFiles.financialProjectionsFile.length > 0 ? (<a href={uploadFiles.financialProjectionsFile[uploadFiles.financialProjectionsFile.length - 1]?.URL} download={uploadFiles.financialProjectionsFile[uploadFiles.financialProjectionsFile.length - 1]?.name} target="_blank" rel="noopener noreferrer">{uploadFiles.financialProjectionsFile[uploadFiles.financialProjectionsFile.length - 1]?.name}</a>) : (<span>No Documents</span>)}</sub>
                    </div>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col span={20}>
                    <div>
                      <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>Operating Agreement: </sub>
                      <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}>{uploadFiles.operatingAgreementFile.length > 0 ? (<a href={uploadFiles.operatingAgreementFile[uploadFiles.operatingAgreementFile.length - 1]?.URL} download={uploadFiles.operatingAgreementFile[uploadFiles.operatingAgreementFile.length - 1]?.name} target="_blank" rel="noopener noreferrer">{uploadFiles.operatingAgreementFile[uploadFiles.operatingAgreementFile.length - 1]?.name}</a>) : (<span>No Documents</span>)}</sub>
                    </div>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col span={20}>
                    <div>
                      <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>Property Details (Key Facts)</sub>
                      <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}>{uploadFiles.propertyDetailsFile.length > 0 ? (<a href={uploadFiles.propertyDetailsFile[uploadFiles.propertyDetailsFile.length - 1]?.URL} download={uploadFiles.propertyDetailsFile[uploadFiles.propertyDetailsFile.length - 1]?.name} target="_blank" rel="noopener noreferrer">{uploadFiles.propertyDetailsFile[uploadFiles.propertyDetailsFile.length - 1]?.name}</a>) : (<span>No Documents</span>)}</sub>
                    </div>
                  </Col>
                </Row>
                <br />
                <br/>
                <Row>
                  <Col span={20}>
                    <div>
                      <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>DraftFundRaising as per SEBI</sub>
                      <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}>{uploadFiles.draftFundFile.length > 0 ? (<a href={uploadFiles.draftFundFile[uploadFiles.draftFundFile.length - 1]?.URL} download={uploadFiles.draftFundFile[uploadFiles.draftFundFile.length - 1]?.name} target="_blank" rel="noopener noreferrer">{uploadFiles.draftFundFile[uploadFiles.draftFundFile.length - 1]?.name}</a>) : (<span>No Documents</span>)}</sub>
                    </div>
                  </Col>
                </Row>
                <br/>
                <Row>
                  <Col span={20}>
                    <div>
                      <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>Confirmation with regulation 292K</sub>
                      <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}>{uploadFiles.ConfirmationWithRegulation292kFile?.length > 0 ? (<a href={uploadFiles.ConfirmationWithRegulation292kFile[uploadFiles.ConfirmationWithRegulation292kFile?.length - 1]?.URL} download={uploadFiles.ConfirmationWithRegulation292kFile[uploadFiles.ConfirmationWithRegulation292kFile?.length - 1]?.name} target="_blank" rel="noopener noreferrer">{uploadFiles.ConfirmationWithRegulation292kFile[uploadFiles.ConfirmationWithRegulation292kFile?.length - 1]?.name}</a>) : (<span> No Documents</span>)}</sub>
                    </div>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col span={20}>
                    <div>
                      <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>Confirmation from issuer that is eligible to be an NPO</sub>
                      <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}>{uploadFiles.ConfirmationFromIssuerForNpoFile?.length > 0 ? (<a href={uploadFiles.ConfirmationFromIssuerForNpoFile[uploadFiles.ConfirmationFromIssuerForNpoFile?.length - 1]?.URL} download={uploadFiles.ConfirmationFromIssuerForNpoFile[uploadFiles.ConfirmationFromIssuerForNpoFile?.length - 1]?.name} target="_blank" rel="noopener noreferrer">{uploadFiles.ConfirmationFromIssuerForNpoFile[uploadFiles.ConfirmationFromIssuerForNpoFile?.length - 1]?.name}</a>) : (<span> No Documents</span>)}</sub>
                    </div>
                  </Col>
                </Row>
                <br/>
                <Row>
                  <Col span={20}>
                    <div>
                      <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>Confirmation of Money Laundering Act, 2002</sub>
                      <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}>{uploadFiles.ConfirmationOfMoneyLaunderingFile?.length > 0 ? (<a href={uploadFiles.ConfirmationOfMoneyLaunderingFile[uploadFiles.ConfirmationOfMoneyLaunderingFile?.length - 1]?.URL} download={uploadFiles.ConfirmationOfMoneyLaunderingFile[uploadFiles.ConfirmationOfMoneyLaunderingFile?.length - 1]?.name} target="_blank" rel="noopener noreferrer">{uploadFiles.ConfirmationOfMoneyLaunderingFile[uploadFiles.ConfirmationOfMoneyLaunderingFile?.length - 1]?.name}</a>) : (<span> No Documents</span>)}</sub>
                    </div>
                  </Col>
                </Row>
                <br/>
                <Row>
                  <Col span={20}>
                    <div>
                      <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>Confirmation with regulation 292N</sub>
                      <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}>{uploadFiles.ConfirmationWithRegulation292nFile?.length > 0 ? (<a href={uploadFiles.ConfirmationWithRegulation292nFile[uploadFiles.ConfirmationWithRegulation292nFile?.length - 1]?.URL} download={uploadFiles.ConfirmationWithRegulation292nFile[uploadFiles.ConfirmationWithRegulation292nFile?.length - 1]?.name} target="_blank" rel="noopener noreferrer">{uploadFiles.ConfirmationWithRegulation292nFile[uploadFiles.ConfirmationWithRegulation292nFile?.length - 1]?.name}</a>) : (<span> No Documents</span>)}</sub>
                    </div>
                  </Col>
                </Row>
                <br/>
                <Row>
                  <Col span={20}>
                    <div>
                      <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>Listing Application</sub>
                      <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}>{uploadFiles.ListingApplicationFile?.length > 0 ? (<a href={uploadFiles.ListingApplicationFile[uploadFiles.ListingApplicationFile?.length - 1]?.URL} download={uploadFiles.ListingApplicationFile[uploadFiles.ListingApplicationFile?.length - 1]?.name} target="_blank" rel="noopener noreferrer">{uploadFiles.ListingApplicationFile[uploadFiles.ListingApplicationFile?.length - 1]?.name}</a>) : (<span> No Documents</span>)}</sub>
                    </div>
                  </Col>
                </Row>
                <br/>
                <Row>
                  <Col span={20}>
                    <div>
                      <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>Resolution passed by the Governing Body, List of allottees</sub>
                      <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}>{uploadFiles.ResolutionPassedByGoverningBodyFile?.length > 0 ? (<a href={uploadFiles.ResolutionPassedByGoverningBodyFile[uploadFiles.ResolutionPassedByGoverningBodyFile?.length - 1]?.URL} download={uploadFiles.ResolutionPassedByGoverningBodyFile[uploadFiles.ResolutionPassedByGoverningBodyFile?.length - 1]?.name} target="_blank" rel="noopener noreferrer">{uploadFiles.ResolutionPassedByGoverningBodyFile[uploadFiles.ResolutionPassedByGoverningBodyFile?.length - 1]?.name}</a>) : (<span> No Documents</span>)}</sub>
                    </div>
                  </Col>
                </Row>
                <br/>
                <Row>
                  <Col span={20}>
                    <div>
                      <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>Undertaking/Certification from the NPO (As per Annexure I)</sub>
                      <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}>{uploadFiles.CertificationFromNpoFile?.length > 0 ? (<a href={uploadFiles.CertificationFromNpoFile[uploadFiles.CertificationFromNpoFile?.length - 1]?.URL} download={uploadFiles.CertificationFromNpoFile[uploadFiles.CertificationFromNpoFile?.length - 1]?.name} target="_blank" rel="noopener noreferrer">{uploadFiles.CertificationFromNpoFile[uploadFiles.CertificationFromNpoFile?.length - 1]?.name}</a>) : (<span> No Documents</span>)}</sub>
                    </div>
                  </Col>
                </Row>
                <br/>
                <Row>
                  <Col span={20}>
                    <div>
                      <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>Governing document (MoA & AoA/ Trust Deed/ Byelaws/ Constitution)</sub>
                      <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}>{uploadFiles.GoverningDocumentFile?.length > 0 ? (<a href={uploadFiles.GoverningDocumentFile[uploadFiles.GoverningDocumentFile?.length - 1]?.URL} download={uploadFiles.GoverningDocumentFile[uploadFiles.GoverningDocumentFile?.length - 1]?.name} target="_blank" rel="noopener noreferrer">{uploadFiles.GoverningDocumentFile[uploadFiles.GoverningDocumentFile?.length - 1]?.name}</a>) : (<span> No Documents</span>)}</sub>
                    </div>
                  </Col>
                </Row>
                <br/>
                <Row>
                  <Col span={20}>
                    <div>
                      <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>Uniform Listing Agreement</sub>
                      <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}>{uploadFiles.UniformListingAgreementFile?.length > 0 ? (<a href={uploadFiles.UniformListingAgreementFile[uploadFiles.UniformListingAgreementFile?.length - 1]?.URL} download={uploadFiles.UniformListingAgreementFile[uploadFiles.UniformListingAgreementFile?.length - 1]?.name} target="_blank" rel="noopener noreferrer">{uploadFiles.UniformListingAgreementFile[uploadFiles.UniformListingAgreementFile?.length - 1]?.name}</a>) : (<span> No Documents</span>)}</sub>
                    </div>
                  </Col>
                </Row>
                <br/>
                <Row>
                  <Col span={20}>
                    <div>
                      <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>Other Documents File</sub>
                      <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}>{uploadFiles.otherDocumentsFile?.length > 0 ? (<a href={uploadFiles.otherDocumentsFile[uploadFiles.otherDocumentsFile?.length - 1]?.URL} download={uploadFiles.otherDocumentsFile[uploadFiles.otherDocumentsFile?.length - 1]?.name} target="_blank" rel="noopener noreferrer">{uploadFiles.otherDocumentsFile[uploadFiles.otherDocumentsFile?.length - 1]?.name}</a>) : (<span> No Documents</span>)}</sub>
                    </div>
                  </Col>
                </Row>
                <br />
                {!props.userInfo.roles.includes('master company super admin') && 
                <>
                <Space style={{ display: "block", float: 'left' }}>
                {!mergedFileLink && <Button
                    type="primary"
                    onClick={() => mergeFiles()}
                  >
                      Merge Files
                  </Button>
                  }
                  
                 {  mergedFileLink && <a href={mergedFileLink}>Meged file</a> }
                 {mergedFileLink &&  <Button
                    type="primary"
                    onClick={() => uploadMergedFile()}
                  >
                      Upload Merged File
                  </Button>
                  }
                </Space>

                <Space style={{ float: 'right' }}>
                  <Button
                    type="primary"
                    onClick={() => setEditView(true)}
                  >
                    Edit Documents
                  </Button>
                  {sendForApprovalVisibility && <Button
                    type="primary"
                    disabled={propertyInfoStatus?.toLowerCase() == 'pending' || propertyInfoStatus?.toLowerCase() == 'approved' }
                    onClick={() => sendPropertyDetailsForApproval()}
                  >
                    {propertyInfoStatus?.toLowerCase() == 'approved' ?'Approved' :'Send For Approval'}
                  </Button>}
                </Space>
                </>
                }

                {props.userInfo.roles.includes('master company super admin') &&
                <>
                  <Space style={{ float: 'right' }}>
                  <Button
                    type="primary"
                    onClick={() => approveOrReject('approved')}
                    disabled={propertyInfoStatus?.toLowerCase() == 'approved' }
                  >
                    Approve
                  </Button>
                  <Button
                    type="primary"
                    disabled={propertyInfoStatus?.toLowerCase() == 'rejected' }
                    onClick={() => setShowRejectPopup(true)}
                  >
                    Reject
                  </Button>
                </Space>
                </>
                }
              </Card>
            </Col>
          </Row>
        </div>
      }
      {props.documentsDone && !loading && editView && (
        <Row justify="center">
          <Col span={20}>
            <h2 style={{ color: 'darkblue', fontSize: '20px', marginTop: '20px', fontWeight: 'bold' }}>Edit Offering Document</h2>
            <Form {...formItemLayout} labelAlign="left" form={form2} onFinish={updateUploadedDocuments} className="edit-form">
              <Card>
                <Form.Item
                  label="Private Placement Memorandum"
                  name='privatePlacementFile'
                  rules={[
                    {
                      required: false,
                      message: 'This field is required'
                    }
                  ]}>
                  <>
                    <Input type='file' onChange={e => { multiUploadFile(e, 'private') }} />
                    {privatePlacementFile[privatePlacementFile.length - 1]?.uploadFilePercentage > 0 &&
                      <Progress percent={privatePlacementFile[privatePlacementFile.length - 1]?.uploadFilePercentage} />
                    }
                    <div style={{}}>
                      <p>Files Uploaded:</p>
                      {privatePlacementFile?.length > 0 && privatePlacementFile.map((file) => <h4 key={Math.random()}>{file.name}</h4>)}
                    </div>
                  </>
                </Form.Item>
                <Form.Item
                  label="Subscription Agreement"
                  name='subscriptionAgreementFile'
                  rules={[
                    {
                      required: false,
                      message: 'This field is required'
                    }
                  ]}>
                  <>
                    <Input type='file' onChange={e => { multiUploadFile(e, 'subscription') }} />
                    {subscriptionAgreementFile[subscriptionAgreementFile.length - 1]?.uploadFilePercentage > 0 &&
                      <Progress percent={subscriptionAgreementFile[subscriptionAgreementFile.length - 1]?.uploadFilePercentage} />
                    }
                    <div style={{}}>
                      <p>Files Uploaded:</p>
                      {subscriptionAgreementFile?.length > 0 && subscriptionAgreementFile.map((file) => <h4 key={Math.random()}>{file.name}</h4>)}
                    </div>
                  </>
                </Form.Item>
                <Form.Item
                  label="Financial Projection"
                  name='financialProjectionsFile'
                  rules={[
                    {
                      required: false,
                      message: 'This field is required'
                    }
                  ]}>
                  <>
                    <Input type='file' onChange={e => { multiUploadFile(e, 'financial') }} />
                    {financialProjectionsFile[financialProjectionsFile.length - 1]?.uploadFilePercentage > 0 &&
                      <Progress percent={financialProjectionsFile[financialProjectionsFile.length - 1]?.uploadFilePercentage} />
                    }
                    <div style={{}}>
                      <p>Files Uploaded:</p>
                      {financialProjectionsFile?.length > 0 && financialProjectionsFile.map((file) => <h4 key={Math.random()}>{file.name}</h4>)}
                    </div>
                  </>
                </Form.Item>
                <Form.Item
                  label="Operating Agreement"
                  name='operatingAgreementFile'
                  rules={[
                    {
                      required: false,
                      message: 'This field is required'
                    }
                  ]}>
                  <>
                    <Input type='file' onChange={e => { multiUploadFile(e, 'operating') }} />
                    {operatingAgreementFile[operatingAgreementFile.length - 1]?.uploadFilePercentage > 0 &&
                      <Progress percent={operatingAgreementFile[operatingAgreementFile.length - 1]?.uploadFilePercentage} />
                    }
                    <div style={{}}>
                      <p>Files Uploaded:</p>
                      {operatingAgreementFile?.length > 0 && operatingAgreementFile.map((file) => <h4 key={Math.random()}>{file.name}</h4>)}
                    </div>
                  </>
                </Form.Item>
                <Form.Item
                  label="Property Details (Key Facts)"
                  name='propertyDetailsFile'
                  rules={[
                    {
                      required: false,
                      message: 'This field is required'
                    }
                  ]}>
                  <>
                    <Input type='file' onChange={e => { multiUploadFile(e, 'property') }} />
                    {propertyDetailsFile[propertyDetailsFile.length - 1]?.uploadFilePercentage > 0 &&
                      <Progress percent={propertyDetailsFile[propertyDetailsFile.length - 1]?.uploadFilePercentage} />
                    }
                    <div style={{}}>
                      <p>Files Uploaded:</p>
                      {propertyDetailsFile?.length > 0 && propertyDetailsFile.map((file) => <h4 key={Math.random()}>{file.name}</h4>)}
                    </div>
                  </>
                </Form.Item>
                <Form.Item
                  label="Draft fund raising document"
                  name='draftFundFile'
                  rules={[
                    {
                      required: false,
                      message: 'This field is required'
                    }
                  ]}>
                  <>
                    <Input type='file' onChange={e => { multiUploadFile(e, 'draftfund') }} />
                    {draftFundFile[draftFundFile.length - 1]?.uploadFilePercentage > 0 &&
                      <Progress percent={draftFundFile[draftFundFile.length - 1]?.uploadFilePercentage} />
                    }
                    <div style={{}}>
                      <p>Files Uploaded:</p>
                      {draftFundFile?.length > 0 && draftFundFile.map((file) => <h4 key={Math.random()}>{file.name}</h4>)}
                    </div>
                  </>
                </Form.Item>
                <Form.Item
                  label="Confirmation with regulation 292K"
                  name="ConfirmationWithRegulation292kFile"
                  rules={[
                    {
                      required: false,
                      message: "This field is required",
                    },
                  ]}
                >
                  <>
                    <Input
                      type="file"
                      onChange={(e) => {
                        multiUploadFile(
                          e,
                          "ConfirmationWithRegulation292kFile"
                        );
                      }}
                    />
                    {ConfirmationWithRegulation292kFile[
                      ConfirmationWithRegulation292kFile.length - 1
                    ]?.uploadFilePercentage > 0 && (
                      <Progress
                        percent={
                          ConfirmationWithRegulation292kFile[
                            ConfirmationWithRegulation292kFile.length - 1
                          ]?.uploadFilePercentage
                        }
                      />
                    )}
                    <div style={{}}>
                      <p>Files Uploaded:</p>
                      {ConfirmationWithRegulation292kFile?.length > 0 &&
                        ConfirmationWithRegulation292kFile.map((file) => (
                          <h4 key={Math.random()}>{file.name}</h4>
                        ))}
                    </div>
                  </>
                </Form.Item>
                <Form.Item
                  label="Confirmation from issuer that is eligible to be an NPO"
                  name="ConfirmationFromIssuerForNpoFile"
                  rules={[
                    {
                      required: false,
                      message: "This field is required",
                    },
                  ]}
                >
                  <>
                    <Input
                      type="file"
                      onChange={(e) => {
                        multiUploadFile(e, "ConfirmationFromIssuerForNpoFile");
                      }}
                    />
                    {ConfirmationFromIssuerForNpoFile[
                      ConfirmationFromIssuerForNpoFile.length - 1
                    ]?.uploadFilePercentage > 0 && (
                      <Progress
                        percent={
                          ConfirmationFromIssuerForNpoFile[
                            ConfirmationFromIssuerForNpoFile.length - 1
                          ]?.uploadFilePercentage
                        }
                      />
                    )}
                    <div style={{}}>
                      <p>Files Uploaded:</p>
                      {ConfirmationFromIssuerForNpoFile?.length > 0 &&
                        ConfirmationFromIssuerForNpoFile.map((file) => (
                          <h4 key={Math.random()}>{file.name}</h4>
                        ))}
                    </div>
                  </>
                </Form.Item>
                <Form.Item
                  label="Confirmation of Money Laundering Act, 2002"
                  name="ConfirmationOfMoneyLaunderingFile"
                  rules={[
                    {
                      required: false,
                      message: "This field is required",
                    },
                  ]}
                >
                  <>
                    <Input
                      type="file"
                      onChange={(e) => {
                        multiUploadFile(e, "ConfirmationOfMoneyLaunderingFile");
                      }}
                    />
                    {ConfirmationOfMoneyLaunderingFile[
                      ConfirmationOfMoneyLaunderingFile.length - 1
                    ]?.uploadFilePercentage > 0 && (
                      <Progress
                        percent={
                          ConfirmationOfMoneyLaunderingFile[
                            ConfirmationOfMoneyLaunderingFile.length - 1
                          ]?.uploadFilePercentage
                        }
                      />
                    )}
                    <div style={{}}>
                      <p>Files Uploaded:</p>
                      {ConfirmationOfMoneyLaunderingFile?.length > 0 &&
                        ConfirmationOfMoneyLaunderingFile.map((file) => (
                          <h4 key={Math.random()}>{file.name}</h4>
                        ))}
                    </div>
                  </>
                </Form.Item>
                <Form.Item
                  label="Confirmation with regulation 292N"
                  name="ConfirmationWithRegulation292nFile"
                  rules={[
                    {
                      required: false,
                      message: "This field is required",
                    },
                  ]}
                >
                  <>
                    <Input
                      type="file"
                      onChange={(e) => {
                        multiUploadFile(
                          e,
                          "ConfirmationWithRegulation292nFile"
                        );
                      }}
                    />
                    {ConfirmationWithRegulation292nFile[
                      ConfirmationWithRegulation292nFile.length - 1
                    ]?.uploadFilePercentage > 0 && (
                      <Progress
                        percent={
                          ConfirmationWithRegulation292nFile[
                            ConfirmationWithRegulation292nFile.length - 1
                          ]?.uploadFilePercentage
                        }
                      />
                    )}
                    <div style={{}}>
                      <p>Files Uploaded:</p>
                      {ConfirmationWithRegulation292nFile?.length > 0 &&
                        ConfirmationWithRegulation292nFile.map((file) => (
                          <h4 key={Math.random()}>{file.name}</h4>
                        ))}
                    </div>
                  </>
                </Form.Item>
                <Form.Item
                  label="Listing Application"
                  name="ListingApplicationFile"
                  rules={[
                    {
                      required: false,
                      message: "This field is required",
                    },
                  ]}
                >
                  <>
                    <Input
                      type="file"
                      onChange={(e) => {
                        multiUploadFile(e, "ListingApplicationFile");
                      }}
                    />
                    {ListingApplicationFile[ListingApplicationFile.length - 1]
                      ?.uploadFilePercentage > 0 && (
                      <Progress
                        percent={
                          ListingApplicationFile[
                            ListingApplicationFile.length - 1
                          ]?.uploadFilePercentage
                        }
                      />
                    )}
                    <div style={{}}>
                      <p>Files Uploaded:</p>
                      {ListingApplicationFile?.length > 0 &&
                        ListingApplicationFile.map((file) => (
                          <h4 key={Math.random()}>{file.name}</h4>
                        ))}
                    </div>
                  </>
                </Form.Item>
                <Form.Item
                  label="Resolution passed by the Governing Body, List of allottees"
                  name="ResolutionPassedByGoverningBodyFile"
                  rules={[
                    {
                      required: false,
                      message: "This field is required",
                    },
                  ]}
                >
                  <>
                    <Input
                      type="file"
                      onChange={(e) => {
                        multiUploadFile(
                          e,
                          "ResolutionPassedByGoverningBodyFile"
                        );
                      }}
                    />
                    {ResolutionPassedByGoverningBodyFile[
                      ResolutionPassedByGoverningBodyFile.length - 1
                    ]?.uploadFilePercentage > 0 && (
                      <Progress
                        percent={
                          ResolutionPassedByGoverningBodyFile[
                            ResolutionPassedByGoverningBodyFile.length - 1
                          ]?.uploadFilePercentage
                        }
                      />
                    )}
                    <div style={{}}>
                      <p>Files Uploaded:</p>
                      {ResolutionPassedByGoverningBodyFile?.length > 0 &&
                        ResolutionPassedByGoverningBodyFile.map((file) => (
                          <h4 key={Math.random()}>{file.name}</h4>
                        ))}
                    </div>
                  </>
                </Form.Item>
                <Form.Item
                  label="Undertaking/Certification from the NPO (As per Annexure I)"
                  name="CertificationFromNpoFile"
                  rules={[
                    {
                      required: false,
                      message: "This field is required",
                    },
                  ]}
                >
                  <>
                    <Input
                      type="file"
                      onChange={(e) => {
                        multiUploadFile(e, "CertificationFromNpoFile");
                      }}
                    />
                    {CertificationFromNpoFile[
                      CertificationFromNpoFile.length - 1
                    ]?.uploadFilePercentage > 0 && (
                      <Progress
                        percent={
                          CertificationFromNpoFile[
                            CertificationFromNpoFile.length - 1
                          ]?.uploadFilePercentage
                        }
                      />
                    )}
                    <div style={{}}>
                      <p>Files Uploaded:</p>
                      {CertificationFromNpoFile?.length > 0 &&
                        CertificationFromNpoFile.map((file) => (
                          <h4 key={Math.random()}>{file.name}</h4>
                        ))}
                    </div>
                  </>
                </Form.Item>
                <Form.Item
                  label="Governing document (MoA & AoA/ Trust Deed/ Byelaws/ Constitution)"
                  name="GoverningDocumentFile"
                  rules={[
                    {
                      required: false,
                      message: "This field is required",
                    },
                  ]}
                >
                  <>
                    <Input
                      type="file"
                      onChange={(e) => {
                        multiUploadFile(e, "GoverningDocumentFile");
                      }}
                    />
                    {GoverningDocumentFile[GoverningDocumentFile.length - 1]
                      ?.uploadFilePercentage > 0 && (
                      <Progress
                        percent={
                          GoverningDocumentFile[
                            GoverningDocumentFile.length - 1
                          ]?.uploadFilePercentage
                        }
                      />
                    )}
                    <div style={{}}>
                      <p>Files Uploaded:</p>
                      {GoverningDocumentFile?.length > 0 &&
                        GoverningDocumentFile.map((file) => (
                          <h4 key={Math.random()}>{file.name}</h4>
                        ))}
                    </div>
                  </>
                </Form.Item>
                <Form.Item
                  label="Uniform Listing Agreement"
                  name="UniformListingAgreementFile"
                  rules={[
                    {
                      required: false,
                      message: "This field is required",
                    },
                  ]}
                >
                  <>
                    <Input
                      type="file"
                      onChange={(e) => {
                        multiUploadFile(e, "UniformListingAgreementFile");
                      }}
                    />
                    {UniformListingAgreementFile[
                      UniformListingAgreementFile.length - 1
                    ]?.uploadFilePercentage > 0 && (
                      <Progress
                        percent={
                          UniformListingAgreementFile[
                            UniformListingAgreementFile.length - 1
                          ]?.uploadFilePercentage
                        }
                      />
                    )}
                    <div style={{}}>
                      <p>Files Uploaded:</p>
                      {UniformListingAgreementFile?.length > 0 &&
                        UniformListingAgreementFile.map((file) => (
                          <h4 key={Math.random()}>{file.name}</h4>
                        ))}
                    </div>
                  </>
                </Form.Item>
                <Form.Item
                  label="Other Documents File"
                  name='otherDocumentsFile'
                  rules={[
                    {
                      required: false,
                      message: 'This field is required'
                    }
                  ]}>
                  <>
                    <Input type='file' onChange={e => { multiUploadFile(e, 'other') }} />
                    {otherDocumentsFile[otherDocumentsFile.length - 1]?.uploadFilePercentage > 0 &&
                      <Progress percent={otherDocumentsFile[otherDocumentsFile.length - 1]?.uploadFilePercentage} />
                    }
                    <div style={{}}>
                      <p>Files Uploaded:</p>
                      {otherDocumentsFile?.length > 0 && otherDocumentsFile.map((file) => <h4 key={Math.random()}>{file.name}</h4>)}
                    </div>
                  </>
                </Form.Item>
              </Card>

              <br /><br />
              <Form.Item {...tailFormItemLayout}>
                <Button size='large' disabled={!enableEdit} style={{ marginLeft: '10px' }} type='primary' htmlType="submit" loading={loading}>Submit</Button>
              </Form.Item>
              <br /><br />
            </Form>
          </Col>
        </Row>
      )}
      
      <Modal
        title="Reject Property Info"
        visible={showRejectPopup}
        onCancel={() => setShowRejectPopup(false)}
        footer={[
          <Button key="back" onClick={() => { setShowRejectPopup(false); }}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={() => approveOrReject('rejected')}>
            Submit
          </Button>,
        ]}
      >
        <Input
          placeholder="Reason for rejection"
          value={rejectReason}
          onChange={(e) => setRejectReason(e.target.value)}
        />
      </Modal>            
      
      <TransactionModal
        title = 'adding the document'
        transactions = {transactions}
        isModalVisible = {isModalVisible}
        closeModal = {() => setIsModalVisible(false)}
      />
    </>
  )
};