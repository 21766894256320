import React, { useState, useEffect } from "react";
import {
  Typography,Form,Card,Row,Input,Button,Col,Progress,Spin,notification, Result, Table,
} from "antd";
import { IssuerSuperAdminService } from "../IssuerSuperAdmin.service";
import { AuthService } from "../../Shared/Auth.service";
import { SharedService } from "../../Shared/Shared.service";
import { environment } from "../../../environments/environment";


const issuerSuperAdminService = new IssuerSuperAdminService();
const { Text, Title } = Typography;

const useUserContext = () => new AuthService().useUserContext();
const sharedService = new SharedService();

const LLPDeedDocs = () => {
  const { userInfo } = useUserContext();
  const [loading, setLoading] = useState(false);
  const [llpcontract, setllpcontract] = useState("");
  const [adminWallet, setadminWallet] = useState("");
  const [deedurl, setdeedurl] = useState("");
  const [deedonchain, setdeedonchain] = useState(false);

  const [deedFormVisible, setDeedFormVisibility] = useState({visible:false,reason:''});
  const [form] = Form.useForm();
  const [deedFiles, setdeedFiles] = useState({
    deedDocument: {
      uploading: false,
      uploadFilePercentage: 0,
      URL: null,
      name: null,
      type: null,
    }
  });
  const [deedData, setDeedDetails] = useState<any>();
  


  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 12,  offset: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 12, offset: 6 },
    },
  };

  const deedColumns = [
    {
    title: 'Company Name',
    dataIndex: 'companyname',
    key: 'companyname',
    },
    {
    title: 'Create Date',
    dataIndex: 'createdate',
    key: 'createdate',
    },
    {
    title: 'Contact Number',
    dataIndex: 'contactnumber',
    key: 'contactnumber',
    },
    {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    },
    {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    },
    
];

  
  useEffect(() => {
    (async () => {
    setLoading(true);
     try {
      let res = await issuerSuperAdminService.getPropertyDetails(userInfo?._id);
      if (!res.success){
        notification.error({message:res.error?.code});
        setLoading(false);
        return;
      }
      console.log("res:", res.data[0], userInfo);
      console.log("usernfo : ", userInfo);
      const newWalletValue = userInfo?.wallet || "";
      const splitString = newWalletValue.split(',');
      setadminWallet(splitString[0]);

      if(
        res['data'][0].propertyDocuments =='done' &&
        res['data'][0].hasOwnProperty('teamMembers') && 
        res['data'][0].teamMembers.length >= 2
        ){
            console.log('exists...',res);
            setDeedFormVisibility({visible:true, reason:'exists'});
            // setLoading(false);
            // return;
        }
     } catch (error) {
      console.log(error);
     }
     try {
      const llpaddress = await issuerSuperAdminService.getLLPAddress(userInfo?.company?.id);
      console.log("LLP Address = ",llpaddress?.data?.deployedSmartContractAddress);
      setllpcontract(llpaddress?.data?.deployedSmartContractAddress);
      const approve = await issuerSuperAdminService.getListOfUploadedDeedByCompanyId(userInfo?.company?.id);
      if(approve.data){
        // console.log(approve.data);
      setdeedurl(approve?.data?.deedDocument[0].URL);
      setdeedonchain(approve?.data?.deedOnChain);
      }
      if (!approve.success){
        // notification.error({message:approve.error?.code});
        console.log({message:approve.error?.code});
        setLoading(false);
        return;
      }

      if (approve.data && approve.data.status == 'rejected') {

        setDeedFormVisibility({ visible: false, reason: 'rejected' });
        setLoading(false);
        return;

      }else if(approve.data && approve.data.status == 'pending'){

        setDeedFormVisibility({ visible: false, reason: 'pending' });
        setLoading(false);
        return;

      }
      else if(approve.data && approve.data.status == 'approved'){
        
        setDeedFormVisibility({ visible: false, reason: 'approved' });
        console.log('approved...', approve);
        const deedData = [{
          key: approve.data.companyId,
          companyname: approve.data.seriesDetails.name,
          createdate: new Date(approve.data.creationTS).toLocaleString(),
          contactnumber: approve.data.contactNumber,
          email: approve.data.email,
          status: approve.data.status.toUpperCase(),
        }];

        setDeedDetails(deedData);
        setLoading(false)
      }
      } catch (error) {
        console.log(error);
        throw error;
        
      }

    })();
  }, []);

  const saveDeedDetails = async (_form) => {
    const form = { ..._form };

    form.deedDocument = deedFiles.deedDocument?.URL ? [{
        name: deedFiles.deedDocument?.name,
        URL: deedFiles.deedDocument?.URL,
        type: deedFiles.deedDocument.type,
      }] : [];

      try {
          setLoading(true);
          const res = await issuerSuperAdminService.updateDeedDocs(form, userInfo?.company?.id);
          console.log(res)
          notification.success({ message: 'Success' });
          setDeedFormVisibility({ visible: false, reason: "pending" })
          setLoading(false);
      } catch (err) {
          console.log(err);
          notification.success({ message: err });
          setLoading(false);
      }
  };

  const uploadDeedOnChain = async () => {
    try{
      setLoading(true);
    const receipt0 =
        await issuerSuperAdminService.uploadDeedOnchain(
          deedurl,
         "0x6f776e6572736869705f64656564000000000000000000000000000000000000",
         llpcontract,
          adminWallet
        );

      console.log("call result : ", receipt0);        
      if (receipt0.status){
        await saveDeedOnChainStatus();
      }else{
        alert("Error in Document Uploaded");
      }
      

    }catch(e){
      console.log(e);
      setLoading(false);
    }
  }

  const saveDeedOnChainStatus = async () => {
    try {
      setLoading(true);
      const form = {
        deedOnChain: true,
        status: "approved"
      };
      const res = await issuerSuperAdminService.updateDeedDocs(form, userInfo?.company?.id);
      console.log(res);
      notification.success({ message: 'Success' });
    } catch (err) {
      console.error(err);
      notification.error({ message: err });
    } finally {
      setLoading(false);
    }
  };
  

  const uploadFile = async (e, key) => {
    if (!e.target.files) return;
    const file = e.target.files[0];
    if (!file) return;
    const fileType = sharedService.getFileType(file.name);

    setdeedFiles((prev) => ({
      [key]: { URL: null, ...prev[key] },
      ...prev,
    }));

      try {
          setdeedFiles((prev) => {
              return {
                  ...prev,
                  [key]: { ...prev[key], uploading: true, uploadFilePercentage: 10 },
              };
          });
          const { hash } = await sharedService.uploadDocumentToIPFS({ file });
          const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
          setdeedFiles((prev) => ({
              ...prev,
              [key]: {
                  ...prev[key],
                  URL,
                  uploading: false,
                  uploadFilePercentage: 100,
                  name: file.name,
                  type: fileType.toLowerCase(),
              },
          }));
          form.setFieldsValue({ [key]: file.name });
      } catch (err) {
          console.error(err);
          setdeedFiles((prev) => ({
              ...prev,
              [key]: { ...prev[key], uploading: false },
          }));
      }
  };

  return (
    <>
       <br/><br/>
      {loading && (
        <div style={{ textAlign: "center" }}>
          <br />
          <Spin size="large" />
        </div>
      )}
      {!loading && deedFormVisible.visible && (
        <>
          <Title
            level={1}
            style={{ color: "#186AB4", textAlign: "center", marginTop: "20px" }}
          >
            LLP Deed Documents
          </Title>
          <br />
          <br />
          <Row justify="center">
            <Col span={16}>
              <Form
                {...formItemLayout}
                labelAlign="left"
                form={form}
                onFinish={saveDeedDetails}
              >
                <Card>
                  <Form.Item
                    label="Upload Deed Document"
                    name="deedDocument"
                    rules={[
                      {
                        required: true,
                        message: "Document required",
                      },
                    ]}
                    valuePropName="fileList"
                  >
                    <>
                      <Input
                        type="file"
                        onChange={(e) => {
                          uploadFile(e, "deedDocument");
                        }}
                        style={{ marginTop: "20px" }}
                      />
                      {deedFiles.deedDocument.uploadFilePercentage > 0 && (
                        <Progress
                          percent={deedFiles.deedDocument.uploadFilePercentage}
                        />
                      )}
                    </>
                  </Form.Item>

                  <Form.Item>
                    <Button
                      size="large"
                      type="primary"
                      htmlType="submit"
                      loading={loading}
                    >
                      Submit
                    </Button>
                  </Form.Item>
                  <br />
                  <br />
                </Card>
              </Form>
            </Col>
          </Row>
        </>
      )}

      {!loading && deedFormVisible.reason != "approved" && !deedFormVisible.visible && (
        <Row justify="center" style={{ marginBottom: "50px" }}>
          <Col span={20}>
            <Card>
             
                <Result
                  title={
                    deedFormVisible.reason === "pending"
                      ? "Your deed documents are currently in pending state."
                      : deedFormVisible.reason === "rejected"
                      ? "Your deed documents have been rejected! Click below to submit again. "
                      : "Something went wrong"
                  }
                  extra={
                    deedFormVisible.reason === "rejected" && [
                      <Button
                        key="submitAgain"
                        onClick={() =>
                          setDeedFormVisibility({ visible: true, reason: "new" })
                        }
                      >
                        Submit Again
                      </Button>,
                    ]
                  }
                />
    
            </Card>
          </Col>
        </Row>
      )}

      {/* show data in table if the deed is approved here */}
      {!loading && deedFormVisible.reason == "approved" && (

        <Row justify="center">
          <Col span={23}>
        <Card>
          <Title style={{ textAlign: "center" }}>Deed Details</Title>
            <Table
              dataSource={deedData}
              loading={loading}
              columns={deedColumns}
            />
            {!deedonchain && (
            <button onClick={() => {uploadDeedOnChain();}}>Upload Approved Document to Blockchain</button>
            )}
            {deedonchain && (
            <button onClick={() => {}}> Approved Deed uploaded to Blockchain</button>
            )}

          </Card>
          </Col>
        </Row>
        
      )}
    </>
  );
};

export default LLPDeedDocs;
