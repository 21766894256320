import React, { useState, useEffect } from "react";
import { Row, Col, Card, Typography, Tabs, Button, Spin, Table, notification, Modal, Input } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { AdminService } from "../../IssuerSuperAdmin/Admin/Admin.service";
import { SharedService } from "../Shared.service";
import { User } from "../interfaces";
import { IssuerSuperAdminService } from "../../IssuerSuperAdmin/IssuerSuperAdmin.service";
import { AuthService } from "../Auth.service";
import { BankService } from "./BankInfoRequests.service";


const { Title } = Typography;
const { TabPane } = Tabs;

const authService = new AuthService();
const issuerSuperAdminService = new IssuerSuperAdminService();
const useUserContext = () => authService.useUserContext();
const bankService = new BankService()

const BankInfoRequests = () => {
    const { userInfo } = useUserContext();
    const history = useHistory();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [BankInfoDataSource, setBankInfoDataSource] = useState<any>([]);
    const [BankInfoDocs, setBankInfoDocs] = useState<any>([]);
    const [rejectReason, setRejectReason] = useState("");
    const [showRejectPopup, setShowRejectPopup] = useState(false);


    useEffect(() => {
        (async () => {

            setLoading(true);
            const bankData: any = []
            let bankInfo 
            if(userInfo?.role == "master company compliance admin" || userInfo?.role == "platform issuer admin"){
                bankInfo = await bankService.getBankInfoForMCAOrPSA()
                if (bankInfo.success ) {
                    if(userInfo?.role == "master company compliance admin"){
                        const pendingBankData = bankInfo.data?.filter(data => data.mca =="pending")
                        setBankInfoDataSource(pendingBankData)
                    }else{
                        const pendingBankData = bankInfo.data?.filter(data => data.psa =="pending")
                        setBankInfoDataSource(pendingBankData)
                    }
                }
            }else{
                bankInfo= await bankService.getBankInfoByCompanyId(userInfo?.company?.id)
                bankData.push(bankInfo.data)
                if (bankInfo.success && bankInfo.data?._id) {
                    setBankInfoDataSource(bankData)
                }
            }
            console.log('bank info ', bankInfo)

        
            setLoading(false);

        })();
    }, []);

    const columns = [
        {
            title: "Account Holder",
            dataIndex: "accountHolder",
            key: "accountHolder",
        },
        {
            title: "Bank Name",
            dataIndex: "bankName",
            key: "bankName",
        },
        {
            title: "Branch Name",
            dataIndex: "branchName",
            key: "branchName",
        },
        {
            title: "Account Number",
            dataIndex: "accountNo",
            key: "accountNo",
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
        },
        {
            title: "Create Date",
            dataIndex: "creationTS",
            key: "creationTS",
        },
        {
            title: "Action",
            key: "action",
            render: () => {
                return (
                    <>
                        <Button type="link" onClick={bankInfoDetails}>Details</Button>
                    </>
                );
            },
        },
    ];

    const bankInfoDetails = () => {
        console.log(BankInfoDataSource)
        setBankInfoDocs(BankInfoDataSource[0].escrowDocs)
        setShowDetails(true)
    }

    const approveOrReject = async(status) =>{
        try {
          // console.log(data)
          
          
          let payload = {status,rejectionMessage:''}
          if(status == 'rejected'){

            if(rejectReason == ''){
                notification.info({message: 'Info', description: 'Provide valid rejection message'});
                return;
              }

            payload.rejectionMessage = rejectReason;
            setShowRejectPopup(false)
          }
          console.log(payload)
          const role = userInfo?.role.replaceAll(' ','');
          
          let companyId = userInfo?.company?.id;
          //incase of these below roles use the companyId of the previous api 
          if(userInfo?.role == 'master company compliance admin' || userInfo?.role == 'platform issuer admin'){
            companyId = BankInfoDataSource[0].companyId
          }

          const res = await bankService.approveOrRejectBankInfo(companyId,payload,role)
          console.log(res)
          if(res.success){
            notification.success({message: 'Success', description: `Bank Info ${status} successfully`});
            // setDetailsDivision(true)
          }else{
            notification.error({message: 'Error', description: res.error.message});
          }

        } catch (error) {
          console.log(error);
          notification.error({message:'error', description:error})
        }
      }

    return (
        <>
            <br />
            <br />
            <Row justify="center">
                <Col span={23}>
                    {loading && (
                        <div style={{ textAlign: "center" }}>
                            <Spin size="large" />
                        </div>
                    )}
                    {!loading && !showDetails && (
                        <Card>
                            <Title level={1} style={{ textAlign: "center" }}>
                                Bank Information Request
                            </Title>
                            <Row>
                                <Col
                                    span={3}
                                    offset={21}
                                    style={{ display: "flex", justifyContent: "end" }}
                                ></Col>
                            </Row>
                            <Table dataSource={BankInfoDataSource} columns={columns} />
                        </Card>
                    )}

                    {showDetails && <Card title="Bank Information">

                        <div className="document-details">
                            <h4>Escrow Docs</h4>
                            {BankInfoDocs?.map((doc, i) => {
                                return <>
                                    <div key={i} style={{ textAlign: 'left', cursor: 'pointer',marginBottom:'10px' }}><a href={doc.url} target='_blank'>{doc.name}</a><br /></div>
                                </>

                            })}
                        </div>

                        <div className="controls" style={{display:'flex', justifyContent:'space-between',marginTop:'50px'}}>
                            <Button htmlType='submit' size='large' type='primary' onClick={()=> setShowDetails(false)} disabled={false} loading={loading}>
                                Back
                            </Button>
                            <div className="controls-action">
                                <Button htmlType='submit' style={{marginRight:'10px'}} onClick={()=>approveOrReject('approved')} size='large' type='primary' disabled={false} loading={loading}>
                                    Approve
                                </Button>
                                <Button htmlType='submit' size='large' type='primary' onClick={()=>{setShowRejectPopup(true);}} disabled={false} loading={loading}>
                                    Reject
                                </Button>
                            </div>
                        </div>
                    </Card>}
                </Col>
            </Row>


            <Modal
                title="Reject Bank Info"
                visible={showRejectPopup}
                onCancel={() => setShowRejectPopup(false)}
                footer={[
                    <Button key="back" onClick={() => { setShowRejectPopup(false); }}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" onClick={() => approveOrReject('rejected')}>
                        Submit
                    </Button>,
                ]}
            >
                <Input
                    placeholder="Reason for rejection"
                    value={rejectReason}
                    onChange={(e) => setRejectReason(e.target.value)}
                />
            </Modal>
        </>
    );
};

export default BankInfoRequests;
