import React, { useState, useEffect } from "react";
import { Row, Col, Card, Typography, Tabs, Button, Spin, Table } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { SharedService } from "../Shared/Shared.service";
import { User } from "../Shared/interfaces";
import { AdminService } from "../IssuerSuperAdmin/Admin/Admin.service";
import {LLP} from "../Shared/LLP/LLP.service";
import TransactionModal from "../Shared/TransactionModal";
import { MetamaskService } from "../Shared/Metamask.service";

const { Title } = Typography;
const { TabPane } = Tabs;

const adminService = new AdminService();
const sharedService = new SharedService();
const llp = new LLP();
const useSelectedWalletContext = () => new MetamaskService().useSelectedWalletContext();

const TransferRecoveryToken = () => {
  const history = useHistory();
  const [admins, setAdmins] = useState<User[]>();
  const [adminRoles, setAdminRoles] = useState<any[]>();
  const [transactions, setTransactions] = useState<{submitting?: boolean, receipt?: any, details: string}[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const {selectedWallet, networkId} = useSelectedWalletContext();

  const dataSource = [
    {
      key: "1",
      name: "John",
      email: "abc@gmail.com",
      contactNumber: "+91 1234567890",
      dateOfKycRequest: new Date().toLocaleString(),
      description: "Recover token #1",
    },
  ];

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Contact Number",
      dataIndex: "contactNumber",
      key: "contactNumber",
    },
    {
      title: "Date of KYC Request",
      dataIndex: "dateOfKycRequest",
      key: "dateOfKycRequest",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Action",
      key: "action",
      render: () => {
        return (
          <>
            <Button type="link" onClick={TransferRecovery}>Transfer</Button>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    (async () => {
      const response = await adminService.getAdmins();

      const _admins: User[] = response.data;

      const _adminRoles = sharedService.adminRolesOptions.map((roleOpt) => {
        const result = sharedService.clone(roleOpt);
        result["total"] = _admins.filter((admin) =>
          admin.roles.includes(roleOpt.value as any)
        ).length;

        return result;
      });

      console.log(_adminRoles);

      setAdmins(_admins);
      setAdminRoles(_adminRoles);
    })();
  }, []);

  const TransferRecovery = async () => {
    setIsModalVisible(true);
    setTransactions([{details: 'Transfer Recvered Tokens', submitting: true}]);

    try {

      const receipt = await llp.controllerTransfer(
        "0x5891cd3cDEf219f5533Af3db08d1311aF1294E4D", // llp contract address
        selectedWallet as string, // token owner address
        selectedWallet as string, // from address(previously owned address)
        selectedWallet as string, // to address (address need to be owned currenly)
        1000 // need to pass correct value
      )
  

      setTransactions(prev => {
        const current = sharedService.clone(prev);
        current[0].receipt = receipt;
       return current;
     });
      
    } catch (err) {
      console.error(err);
    }

    setTransactions(prev => {
      const current = sharedService.clone(prev);
      current[0].submitting = false;
      return current;
    });
  }
  return (
    <>
      <br />
      <br />
      <Row justify="center">
        <Col span={23}>
          {!admins && (
            <div style={{ textAlign: "center" }}>
              <Spin size="large" />
            </div>
          )}
          {admins && (
            <Card>
              <Title level={1} style={{ textAlign: "center" }}>
                Transfer Recovery Token
              </Title>
              <Row>
                <Col
                  span={3}
                  offset={21}
                  style={{ display: "flex", justifyContent: "end" }}
                ></Col>
              </Row>
              <Table dataSource={dataSource} columns={columns} />
            </Card>
          )}
        </Col>
      </Row>
      <TransactionModal
        title = 'Transferreng Recodered Tokens'
        transactions = {transactions}
        isModalVisible = {isModalVisible}
        closeModal = {() => setIsModalVisible(false)}
      />
    </>
  );
};

export default TransferRecoveryToken;
