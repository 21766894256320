import React, { useState, useEffect } from 'react';
import CardHOC from "../../CardHOC";
import {AuthService} from "../../Shared/Auth.service";
import {SeriesService} from "../Series.service";
import {Button, Form, Input, message, Modal, notification, Spin, Table} from "antd";
import { useHistory } from 'react-router-dom';
import moment from "moment";
import {SeriesDetails} from "../../Shared/interfaces";



const seriesService = new SeriesService();

const useUserContext = () => new AuthService().useUserContext();


export default function SeriesList({newSeries = true}) {
  const {userInfo} = useUserContext();
  const history = useHistory();

  const [loading, setLoading] = useState<boolean>(true);
  const [series, setSeries] = useState<SeriesDetails[]>();
  const [isInviteModalVisible, setIsInviteModalVisible] = useState(false);
  const [modalForm] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const [selectedSeries, setSelectedSeries] = useState<SeriesDetails>();
  const [smartDeployed, setSmartDeployed] = useState(false);

  useEffect(() => {
    (async () => {
      if(!userInfo) return;

      const response = await seriesService.getSeries();

      setSeries(response.data);

      const res = await seriesService.getSmart();
      if(res.data === 'true'){
        setSmartDeployed(true);
      }

      setLoading(false);

    })();
  }, [userInfo]);

  const openInviteSeriesModal = (prop: {selectedSeries: SeriesDetails}) => {
    modalForm.resetFields();
    setSelectedSeries(prop.selectedSeries);
    setIsInviteModalVisible(true);
  }

  const inviteSeriesManager = async(formValue) => {
    setSubmitting(true);

    const response = await seriesService.inviteSeriesManager({
      email: formValue.email,
      seriesId: selectedSeries?._id as string
    });

    if(response.success) {
      notification.success({
        message: 'Success',
        description: 'Invite sent to Property Company Manager Successfully'
      });

      setIsInviteModalVisible(false);

    } else {
      console.error(response.error);
      message.error(response.error.message);
    }

    setSubmitting(false);
  }

  const seriesName = (value) => `Crypto Asset Rating ${value}`;

  const columns = [
    {
      title: 'SERIES NUMBER',
      dataIndex: 'number'
    },
    {
      title: 'SERIES NAME',
      dataIndex: 'name',
      render: (value: string) => seriesName(value)
    },
    {
      title: 'Property Company Manager NAME',
      dataIndex: 'seriesManagerName',
      render: (value, record: SeriesDetails) =>
        record.seriesManagerId
          ? `${record['seriesManagerFirstName']} ${record['seriesManagerLastName']}`
          : '-'
    },
    {
      title: 'Property Company Manager EMAIL ADDRESS',
      dataIndex: 'seriesManagerEmail',
      render: (value: string, record: SeriesDetails) =>
        record.seriesManagerId
          ? record['seriesManagerEmail']
          : '-'
    },
    {
      title: 'CREATED ON',
      dataIndex: 'creationTS',
      render: (value: number) => moment(value).format('lll')
    },
     {
       title: 'ACTION',
       dataIndex: 'action',
       render: (value, record: SeriesDetails) => <>
         {!record.seriesManagerId &&
           <Button
             size='small'
             style={{marginLeft: '5px'}}
             type='primary'
             onClick={() => openInviteSeriesModal({selectedSeries: record})}
           >
             INVITE Property Company Manager
           </Button>
         }

       </>
     }
  ];



  return (
    <CardHOC
      heading={'List of All Customers (Properties)'}
      component={<>

        {loading &&
          <div style={{textAlign:'center'}}>
            <br/><br/>
            <Spin size='large'/>
          </div>
        }

        {!loading &&
          <>
            {newSeries && (<div style={{textAlign:'right', marginBottom: '10px'}}>
            {smartDeployed && (
              <Button type="primary" size='large' onClick={() => history.push(`/series/new`)}>
                NEW SERIES
              </Button>)}
            </div>)}

            <Table
              columns={columns}
              dataSource={series}
              rowKey='_id'
              pagination={false}
            />
          </>
        }


        <Modal
          title={`Invite Series Manager to ${seriesName(selectedSeries?.name)}`}
          okText='SUBMIT'
          cancelText='CANCEL'
          visible={isInviteModalVisible}
          onOk={()=> modalForm.submit()}
          onCancel={()=>{
            setIsInviteModalVisible(false);
          }}
          okButtonProps={{
            loading: submitting,
          }}
        >

          <Form
            form={modalForm}
            autoComplete={'off'}
            onFinish={inviteSeriesManager}
          >

            <Form.Item
              name={'email'}
              label={'Email'}
              wrapperCol={{ xs: { span: 24 }, sm: { span: 14 } }}
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Input placeholder={'Email'}/>
            </Form.Item>

          </Form>

        </Modal>




      </>}
    />
  );
}
