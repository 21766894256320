import { Button, Card, Col, Row, Select, Spin } from "antd";
import React, { useEffect , useState} from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { Container } from "reactstrap";
import sign from "../../assets/images/icons/tick.png";
import buildingimage from "../../assets/images/icons/building.jpg"
import united from "../../assets/images/icons/uinted.jpeg"
import england from "../../assets/images/icons/England.jpg"
import india from "../../assets/images/icons/india.jpg"
import aus from "../../assets/images/icons/aus.jpeg"
import { PropertyAdminService } from "../PropertyDetails/PropertyAdmin.service";
import "./Disclaimer.css";
import { environment } from "../../environments/environment";

const propertyAdminService = new PropertyAdminService();

const Disclaimer = () => {
  const [loading, setLoading] = useState(false);
  const [propertyId, setPropertyId] = useState('');
  const [properties, setProperties] = useState([]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
    (async function loadProperties(){
      setLoading(true);
      const properties = await propertyAdminService.getAllProperties();
      if(properties && properties.data?.length > 0){
        setProperties(properties.data);
        setPropertyId(properties.data[0]._id);
      }
      setLoading(false);
    })();
  }, []);

  const actions = [
    { label: "Add", value: 1 },
    { label: "Edit", value: 2 },
    { label: "Delete", value: 3 }
  ];



  return (
    <div className="wd_scroll_wrap margin-bt-0" >
      {loading && (
        <div style={{textAlign:'center', marginTop: '100px', marginBottom: '100px'}}>
          <Spin size='large'/>
        </div>
      )}

    {!loading && (<>
    {/* <img id="building" src={buildingimage} alt="" /> */}
       {/* <Card id="carddetails">
          <Row>
            <div id="fieldsradius">
              <div className="col-md-3">
                <p id="incon1">Select Country :</p>
                <Select className="disbox1" options={actions} />
                <span id="mline"></span>
              </div>

              <div className="col-md-3">
                <p id="incon2">Select City :</p>
                <Select className="disbox2" options={actions} />
                <span id="mline1"></span>
              </div>
              <div className="col-md-3">
                <p id="incon3">Search for property name :</p>
                <Select className="disbox3" options={actions} />

              </div>
              <Button id="buttoncssss">
                <span id="textcss">Search</span>
              </Button>
            </div>

          </Row>
        </Card> */}
        
        {/* <Row>
        <div className="col-md-12" id="backblur">
          <p id="mcontent1">EXPLORE PROPERTIES HERE <span id="downcolor"></span></p>
          <p id="mcontent2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut quis phasellus vulputate nullam egestas. Fermentum ultricies aliquam enim feugiat morbi. Sagittis sapien nullam placerat pharetra, a nam pulvinar nisi, quam. adipiscing elit. Ut quis phasellus vulputate nullam egestas. Fermentum </p>
        </div>
        </Row>
        <Row>
          <div id="mgroup">
          <div className="col-md-3">
            <img id="mimage1" src={united} alt="" />
            <p id="mcontent3">UNITED STATES</p>
            <p id="mcontent4">Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Elementum non
              in mollis ac, turpis tincidunt. </p>
          </div>
          <div className="col-md-3">
            <img id="mimage1" src={england} alt="" />
            <p id="mcontent3">ENGLAND</p>
            <p id="mcontent4">Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Elementum non
              in mollis ac, turpis tincidunt. </p>
          </div>
          <div className="col-md-3">
            <img id="mimage1" src={aus} alt="" />
            <p id="mcontent3">AUSTRALIA</p>
            <p id="mcontent4">Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Elementum non
              in mollis ac, turpis tincidunt. </p>
          </div>
          <div className="col-md-3">
            <img id="mimage1" src={india} alt="" />
            <p id="mcontent3">INDIA</p>
            <p id="mcontent4">Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Elementum non
              in mollis ac, turpis tincidunt. </p>
          </div>
        </div>

        </Row> */}

      <div className="disclaimer">
        <Row style={{marginBottom: '100px'}}>     
          <div className="col-12 heading-wrapper">
            <h1 className="header">What's Popular</h1>
            <span className="heading-line"></span>
          </div>    
          <div id="box col-12 row" style={{width: '100%'}}>
            {properties && properties.length > 0 && properties.map((property, index)=>(
              <div className="col-md-4 property-wrapper" key={index}>
                <p className="property-category">{property.propertyCategoryType}</p>
                <h2 className="property-title">{property.propertyTitle}</h2>
                <p className="property-company">{property.companyName}</p>

                {property['propertyImages'] && property['propertyImages'][0] && property['propertyImages'][0]['name'] && (
                  <img src={property['propertyImages'][0]['URL']} alt={property['propertyImages'][0]['name']} style={{width: '100%', minWidth: '100%', minHeight: '200px', height: '200px'}} onClick={()=>{window.location.href = `/property-details?propertyId=${property._id}`}}/>
                )}

                <div className="details-box-wrapper talebox">
                  <div className="target-wrapper">
                    <h6 className="property-city">{property.propertyCity}</h6>
                    <div className="top-target-wrapper">
                      <span class="nav-link active" data-toggle="tab" data-target="#21afb79eeae34b36a8d64e66f84ea464-targets">
                        <span className="target-text">Targets</span> 
                        <svg class="rc-svgicon rc-svgicon-targets" width="16" height="16" viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
                          <g fill="currentColor" fill-rule="evenodd">
                              <path d="M0 0h6v2H0zM0 4h6v2H0zM0 8h6v2H0zM8 0h2v2H8zM8 4h2v2H8zM8 8h2v2H8z">
                              </path>
                          </g>
                        </svg>
                      </span>
                    </div>
                  </div>

                  <div className="details-box">
                    <span className="label">IRR</span>
                    <span className="value">{property.targetedInvestorIRR}%</span>
                  </div>
                  <div className="details-box">
                    <span className="label">Equity Multiple</span>
                    <span className="value">{property.targetedEquityMultiple}x</span>
                  </div>
                  <div className="details-box">
                    <span className="label">Year 1 Cash on Cash</span>
                    <span className="value">{property.yearOneCashOnCash}%</span>
                  </div>
                  <div className="details-box">
                    <span className="label">Stabilized Cash on Cash</span>
                    <span className="value">{property.stabilizedCashOnCash}%</span>
                  </div>
                  <div className="details-box last">
                    <span className="label">Minimum Investment</span>
                    <span className="value">{property.minimumInvestment} {environment.currencySign}</span>
                  </div>
                </div>
                <div className="details-box outer">
                  <span className="label">Asset Profile</span>
                  <span className="value">{property.assetProfile}</span>
                </div>

                <div className="btn invest-button" onClick={()=>{window.location.href = `/property-details?propertyId=${property['_id']}`}}>
                  <a class="btn btn-lg btn-success mb-1 rc-open-cta trackOfferingIntent" data-source="bottom" data-offering="e6b570b88139455c8c7e15c743f3830b">
                      <i class="fa fa-check" aria-hidden="true"></i>
                      {property.projectedFundingDeadline ? new Date(property.projectedFundingDeadline) > new Date() ? (<span>Invest Now!</span>) : (<span>Offer Closed</span>) : (<span>Offer Closed</span>)}
                  </a>
                </div>
                {property.projectedFundingDeadline && new Date(property.projectedFundingDeadline) > new Date() && (<>
                  <div class="rc-offering-close-date">
                    Projected Funding Deadline <span className="close-date">{moment(property.projectedFundingDeadline)?.format('MMMM DD, YYYY')}</span>
                  </div>
                </>)}
                {/*<span id="iiicon1" style={{border: 'none'}}>
                   <Link to={`/property-details?propertyId=${propertyId}`} style={{ color: 'white' }}>
                    <i id="iiicon2" className="fa fa-picture-o" aria-hidden="true"></i>
                    <p id="mcontent6">{property['propertyImages']['length']} Photos</p>
                  </Link>*/}
                  {/* <span id="iiiborder" style={{top: '5px', left: '0'}}>
                    <p id="iiisale">Outgoing Sale</p>
                  </span> */}
                {/* <div id="contentbox" style={{width: '100%'}}>
                  <h3 style={{margin: '10px', textAlign: 'center', color: 'black'}}>{property['propertyTitle']}</h3>
                  <p style={{margin: '10px', textAlign: 'center', fontSize: '14px'}}>{property['propertyCountry'] + ' ' + property['propertyState'] + ' ' 
                    + property['propertyCity'] + ' ' + property['propertyZipCode']}</p>
                </div>
                <div className="tablebox" style={{width: '100%'}}>
                  <div className="box-wrapper">
                    <p className="label">Target Investor IRR Yearly</p>
                    <p className="value">{property['targetedInvestorIrr']}</p>
                  </div>
                  <div className="box-wrapper">
                    <p className="label">Investment Period</p>
                    <p className="value">{property['targetedInvestmentPeriod'] + ' Months'}</p>
                  </div>
                  <div className="box-wrapper">
                    <p className="label">Goal</p>
                    <p className="value">{property['targetedAvgCash']}</p>
                  </div>
                  <div className="box-wrapper">
                    <p className="label">Token Name</p>
                    <p className="value">{property['nameOfToken']}</p>
                  </div>
                </div>
                <div className="button-group" style={{padding: '20px', height: '90px', margin: '0'}}>
                  <Button id="ctabutton1" style={{width: '100%', height: '100%', position:'static'}}> 
                    <Link to={`/property-details?propertyId=${property['_id']}`}>
                      <span id="ctatext1">INVEST NOW</span>
                    </Link>
                  </Button>
                </div> */}
              </div>
            ))}
          </div>
        </Row>
      </div>
      </>)}
    </div>
  );
};

export default Disclaimer;
