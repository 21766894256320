import React, { useEffect, useState } from "react";
import { environment } from "../../../environments/environment";
import { SharedService } from "../../Shared/Shared.service";
import { Card, Col, Row, Table } from "antd";
import Title from "antd/lib/typography/Title";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

declare global {
  interface Window {
    ethereum: any;
  }
}

const sharedService = new SharedService();

function DueDiligence() {
  const APIURL = environment.APIURL;

  useEffect(() => {
    getUsers();
  }, []);

  const [dueDiligence, setDueDiligence] = useState([{}]);

  const getUsers = async () => {
    const response = await sharedService.getUsers();
    if (response.data) {
      console.log("getUsers Response: ", response);
      getIssuerDueDiligenceByCompanyId(response.data?.company?.id);
    }
  };

  const getIssuerDueDiligenceByCompanyId = async (companyId) => {
    const response = await sharedService.getIssuerDueDiligenceByCompanyId(
      companyId
    );
    if (response.data) {
      console.log(
        "getIssuerDueDiligenceByCompanyId Response: ",
        response.data?.data
      );
      setDueDiligence(response.data);
    }
  };

  const renderURL = (object) => {
    const newObject = object[0]?.URL;
    return newObject;
  };
  const renderName = (object) => {
    const newObject = object[0]?.name;
    return newObject;
  };
  const columns = [
    {
      title: "Question",
      dataIndex: "question",
      render: (value) => value,
    },
    {
      title: "Answer Type",
      dataIndex: "answerType",
      render: (value) => value,
    },
    {
      title: "Answer",
      dataIndex: "answer",
      render: (value: any) =>
        typeof value != "object" ? (
          value
        ) : (
          <span>{<a target="_blank" href={renderURL(value)}>{renderName(value)}</a>}</span>
        ),
    },
  ];

  return (
    <>
      <br></br>
      <Row justify="center">
        <Col span={22} style={{ textAlign: "center" }}>
          <Card>
            <Title
              level={2}
              style={{
                textAlign: "left",
                color: "#1890ff",
                // fontSize: '30px',
                fontWeight: "bold",
              }}
            >
              Due Diligence
            </Title>
            <div style={{ display: "grid" }}>
              <Table
                columns={columns}
                dataSource={dueDiligence}
                rowKey="_id"
                pagination={false}
              />
            </div>
          </Card>
        </Col>
      </Row>
      <ToastContainer />
    </>
  );
}

export default DueDiligence;
