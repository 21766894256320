import React, { useEffect, useRef, useState } from "react";
import { Button, Checkbox, message, Typography } from "antd";
import BankInfo from "../BankInfo";
import CardHOC from "../../CardHOC";
import { AuthService } from "../../Shared/Auth.service";
import { IssuerSuperAdminService } from "../IssuerSuperAdmin.service";
const issuerSuperAdminService = new IssuerSuperAdminService();
const authService = new AuthService();
const { Text } = Typography;
const useUserContext = () => authService.useUserContext();
const ManagePaymentMethod = () => {
  const { userInfo } = useUserContext();
  const [coinbaseSelected, setCoinbaseSelected] = useState(false);
  const [primeTrustSelected, setPrimeTrustSelected] = useState(false);
  const [othersSelected, setOthersSelected] = useState(false);
  const [northCapitalSelected, setNorthCapitalSelected] = useState(false);
  const [skrillSelected, setSkrillSelected] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const coinbaseRef = useRef(null);
  useEffect(() => {
    (async () => {
      const response = await issuerSuperAdminService.getSavedPaymentMethod({
        companyId: userInfo?.company?.id,
      });
      if (response.success && response.data) {
        console.log(response.data?.coinbaseSelected);
        console.log(response.data?.primeTrustSelected);
        console.log(response.data?.skrillSelected);
        setCoinbaseSelected(response.data?.coinbaseSelected);
        setPrimeTrustSelected(response.data?.primeTrustSelected);
        setNorthCapitalSelected(response.data?.northCapitalSelected);
        setOthersSelected(response.data?.othersSelected);
        setSkrillSelected(response.data?.skrillSelected);
      }
    })();
  }, []);

  const onSave = async () => {
    setSubmitting(true);
    if (!coinbaseSelected && !primeTrustSelected && !othersSelected && !skrillSelected && !northCapitalSelected) {
      setSubmitting(false);
      message.error("Please select atleast one payment method");
      return;
    }
    const dataToSend = {
      coinbaseSelected,
      primeTrustSelected,
      northCapitalSelected,
      othersSelected,
      skrillSelected,
      companyName: userInfo?.company?.name,
      companyId: userInfo?.company?.id,
    };
    const response = await issuerSuperAdminService.savePaymentMethod(
      dataToSend
    );
    if (response.success && response.data) {
      console.log(response.data?.coinbaseSelected);
      console.log(response.data?.primeTrustSelected);
      setCoinbaseSelected(response.data?.coinbaseSelected);
      setPrimeTrustSelected(response.data?.primeTrustSelected);
      setNorthCapitalSelected(response.data?.northCapitalSelected);
      setOthersSelected(response.data?.othersSelected);
      message.success("Succesfully saved");
    }
    setSubmitting(false);
  };
  return (
    <>
      <div style={{ textAlign: "left" }}>
        <Text>Please select the payment methods you want to use:</Text>
        <br />
        <br />
        {/* <Checkbox
          checked={coinbaseSelected}
          onChange={(e) => setCoinbaseSelected(e.target.checked)}
          disabled={submitting}
        >
          CryptoCurrency (Provider: Coinbase)
        </Checkbox>
        <br />
        <Checkbox
          checked={primeTrustSelected}
          onChange={(e) => setPrimeTrustSelected(e.target.checked)}
          disabled={submitting}
        >
          ACH/Wire-Transfer/Mail a check/Credit/Debit (Provider: Prime Trust)
        </Checkbox>
        <br /> */}
        <Checkbox
          checked={northCapitalSelected}
          onChange={(e) => {
            setNorthCapitalSelected(e.target.checked)
            setPrimeTrustSelected(!e.target.checked)
          }}
          disabled={submitting}
        >
          ACH/Wire-Transfer/Mail a check/Credit/Debit
        </Checkbox>
        {/* <br />
        <Checkbox
          checked={othersSelected}
          onChange={(e) => setOthersSelected(e.target.checked)}
          disabled={submitting}
        >
          Others (Direct wire transfer)
        </Checkbox>
        <br />
        <Checkbox
          checked={skrillSelected}
          onChange={(e) => setSkrillSelected(e.target.checked)}
          disabled={submitting}
        >
          Skrill Checkout
        </Checkbox>
        <br /> */}
      </div>
      {(othersSelected || northCapitalSelected) && (<BankInfo />)}
      {(othersSelected || northCapitalSelected) && (<Button
        type="primary"
        htmlType="button"
        onClick={onSave}
        loading={submitting}
      >
        Save
      </Button>)}
    </>
  );
};
export default () => {
  return (
    <CardHOC
      heading="Select Payment Method"
      component={<ManagePaymentMethod></ManagePaymentMethod>}
    ></CardHOC>
  );
};
