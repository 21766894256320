import React,{ReactDOM,useEffect,useState} from 'react';
import   {Row,Col,Card,Typography,Button,Tabs,Spin,Space,Table,Descriptions} from 'antd';
import {AuthService} from '../../Shared/Auth.service';
import {IssuerSuperAdminService} from '../IssuerSuperAdmin.service';
import TokenDashboard from '../../TokenDashboard';
import moment from 'moment';
import ReactToExcel from 'react-html-table-to-excel';
import ExportToExcel from './ExportToExcel';
import ExportPendingTableToExcel from './ExportPendingToExcel';
import DescriptionsItem from 'antd/lib/descriptions/Item';
import { useHistory } from 'react-router-dom';
const {Title}=Typography;
const issuerSuperAdminService=new IssuerSuperAdminService();
const TabPane=Tabs.TabPane;
const authService=new AuthService();
const useUserContext = () => authService.useUserContext();
export default ()=>{
  const {userInfo, setUserInfo} = useUserContext();
  const [tokenDetail,setTokenDetail]=useState(null);
  const [dashboardData,setDashboardData]=useState([]);
  const [totalTokenDetail,setTotalTokenDetail]=useState(null);
  const [investorsData,setInvestorsData]=useState([]);
  const [investorsDataOfPendingToken,setInvestorsDataOfPendingToken]=useState([]);
  const [investorData,setInvestorData]=useState([]);
  const [showInvestorsTable, setShowInvestorsTable] = useState(false)
  const history=useHistory();
useEffect(()=>{ 
    (async ()=>{
      let tempDashboardData=[]
      const response=await issuerSuperAdminService.getTokenTypeDetail({companyId:userInfo.company.id});
      (await setTokenDetail(response.data));
      if(response.data.length>1)
      {
        for (let i = 0; i < response.data.length; i++) {
          let token=response.data[i]; 
          tempDashboardData[i]={
            'tokenSymbol':token.tokenConfiguration[0].tokenSymbol, 'regulation':token.tokenConfiguration[0].regulation,
            'saleStartDate':token.tokenPhase[0].startDate, 'saleEndDate':token.tokenPhase[0].endDate,
            'typeOfSecurity':token.typeOfSecurity,'tokenPrice':token.tokenConfiguration[0].pricePerToken,
            'tokenSold':100000,'tokenMinted':2500000,'tokenHolder':400
          }
        }
      }
      else
      {
        let token=response.data[0];
        tempDashboardData[0]={
          'tokenSymbol':token.tokenConfiguration[0].tokenSymbol, 'regulation':token.tokenConfiguration[0].regulation,
          'saleStartDate':token.tokenPhase[0].startDate, 'saleEndDate':token.tokenPhase[0].endDate,
          'typeOfSecurity':token.typeOfSecurity,'tokenPrice':token.tokenConfiguration[0].pricePerToken,
          'tokenSold':100000,'tokenMinted':2500000,'tokenHolder':400
        }
      }
      setDashboardData(tempDashboardData);
      let totalTokenSold=0;
      let totalTokenMinted=0;
      let totalTokenHolder=0;
      
      for (let i = 0; i < tempDashboardData.length; i++) {
        totalTokenSold+=tempDashboardData[i].tokenSold;
        totalTokenMinted+=tempDashboardData[i].tokenMinted;
        totalTokenHolder+=tempDashboardData[i].tokenHolder;
      }
      let tempTotalTokenDetail={
        'totalTokenSold':totalTokenSold,'totalTokenMinted':totalTokenMinted,'totalTokenHolder':totalTokenHolder
      }
      setTotalTokenDetail(tempTotalTokenDetail);
      let tempInvestorsData=[
        {
          'firstName':'Ashutosh',
          'lastName' :'Gautam',
          'country' :'India',
          'tokenType':'Equity',
          'regulation':'Regulation D',
          'tokenIssued':'2000',
          'dateOfInvestment':'26/11/2020',
          'walletAddress':'345745djkjgfkf',
          'dateOfIssue':'17/11/2020',
          'lockOpenDate':'30/05/2021'
        },
        {
          'firstName':'Anuj',
          'lastName' :'Neema',
          'country' :'India',
          'tokenType':'Equity',
          'regulation':'Regulation D',
          'tokenIssued':'1000',
          'dateOfInvestment':'26/11/2020',
          'walletAddress':'34gdgdfgdfgkf',
          'dateOfIssue':'17/11/2020',
          'lockOpenDate':'30/05/2021'
        },
        {
          'firstName':'Amit',
          'lastName' :'Singh',
          'country' :'US',
          'tokenType':'Typical',
          'regulation':'Regulation S',
          'tokenIssued':'8000',
          'dateOfInvestment':'26/11/2020',
          'walletAddress':'tjvbbg45djkjgfkf',
          'dateOfIssue':'17/11/2020',
          'lockOpenDate':'30/05/2021'
        },
        {
          'firstName':'Amit',
          'lastName' :'Singh',
          'country' :'US',
          'tokenType':'Typical',
          'regulation':'Regulation S',
          'tokenIssued':'8000',
          'dateOfInvestment':'26/11/2020',
          'walletAddress':'tjvbbg45djkjgfkf',
          'dateOfIssue':'17/11/2020',
          'lockOpenDate':'30/05/2021'
        }
      ];
      const tempInvestorsDataOfPendingToken=[
        {
          'firstName':'Amit',
          'lastName' :'Singh',
          'country' :'US',
          'tokenType':'Typical',
          'regulation':'Regulation S',
          'tokenToIssue':'8000',
          'dateOfPurchase':'26/11/2020',
          'walletAddress':'tjvbbg45djkjgfkf',
          'phase':'17/11/2020',
         }
      ];
    setInvestorsData(tempInvestorsData);
    setInvestorsDataOfPendingToken(tempInvestorsDataOfPendingToken);
    const tempInvestorData=[
      {
        "personalInformation": {
          "nameOfInvestor": "Ashutosh",
          "walletAddress": "dfdgfgdfgf",
          "country": "India",
          "email": "ashutoshgautam2611@gmail.com",
          "state": "Bihar",
          "zipCode": 841506,
          "phoneNumber": "9340593340",
          "permanentAddress": "Siwan,Bihar",
          "presentAddress": "wilson garden, Banglore"
        },
        "KYCStatus": {
          "approvedOn": "26/11/1998",
          "approvedBy": "26/11/1998",
          "rejectedOn": "17/11/1998",
          "rejectedBy": "17/11/1998",
          "KYCResult":"Approved"
        },
        "accredation": {
          "status": "valid",
          "approvedOn": "26/11/1998",
          "approvedBy": "26/11/1998",
          "rejectedOn": "17/11/1998",
          "rejectedBy": "17/11/1998"
        },
        "whitelisting": {
          "validTill": "26/11/1998",
          "whitelistedOn": "26/11/1998"
        },
        "tokenDetails": {
          "tokenSymbol": "Car_d",
          "tokenType": "qualcom",
          "regulation": "regulation d",
          "tokenPrice": 34,
          "totalTokenIssued": 34000,
          "lockPeriod": {
            "duration": 4,
            "startDate": "30/11/2021",
            "endDateOutsideUS": "24/12/2025",
            "endDate": "22/11/2022"
          }
        },
        "investmentDetails": {
          "dateOfInvestment": "12/10/2020",
          "totalInvestmentAmount_USD": 15,
          "totalInvestmentAmount_CC": 15,
          "phase": "locked"
        },
        "tokenHistory": {
          "tokenTransferredTo": "xyz ltd",
          "receiverWalletAddress": "3453fdgfgdd",
          "receiverName": "Anuj Neema",
          "receiverInfo": {
            "email": "xyz@gmail.com",
            "country": "India",
            "accredited": "yes",
            "tokenTransferred": "345"
          }
        },
        "exchangeTransfer": "Outside"
      }
      ];
      setInvestorData(tempInvestorData);

    })();},[userInfo]);

    const headingStyle={fontSize:'15px',color:'#91939c'};
    const dataStyle={fontSize:'25px', color:'#0275d8'};
    const headStyle={fontSize:'30px',color:'#040854',textAlign:'center',float:'center'};
    const subHeadStyle={fontSize:'25px',color:'#0275d8'};
    const secondDataStyle={fontSize:'20px',color:'#91939c'};
    const tokenSymbol=dashboardData.tokenSymbol;
    const tokenIssuedColumn=[
      {
        title: 'First Name',
        dataIndex: 'firstName',
        key: 'firstName',
        fixed: 'left'
      },
      {
        title: 'Last Name',
        dataIndex: 'lastName',
        key: 'lastName'
      },
      {
        title: 'Country',
        dataIndex: 'country',
        key: 'country'
      },
      {
        title: 'TokenType',
        dataIndex: 'tokenType',
        key: 'tokenType'
      },
      {
        title: 'Regulation',
        dataIndex: 'regulation',
        key: 'regulation'
      },
      {
        title: 'Token Issued',
        dataIndex: 'tokenIssued',
        key: 'tokenIssued'
      },
      {
        title: 'Date of Investment',
        dataIndex: 'dateOfInvestment',
        key: 'dataOfInvestment'
      },
      {
        title: 'Wallet Address',
        dataIndex: 'walletAddress',
        key: 'walletAddress'
      },
      {
        title: 'Date if Issue',
        dataIndex: 'dateOfIssue',
        key: 'dateOfIssue'
      },
      {
        title: 'Lock open Date',
        dataIndex: 'lockOpenDate',
        key: 'lockOpenDate'
      },
      {
        title: 'ToPDF',
        key: 'operation',
        fixed:'right',
        render: ()=> <Button onClick={(e)=>{
          console.log("ButtonData",fixed);
        }}>PDF</Button>
      }
    ];
    const pendingTokenColumn=[
      {
        title: 'First Name',
        dataIndex: 'firstName',
        key: 'firstName',
        fixed: 'left'
      },
      {
        title: 'Last Name',
        dataIndex: 'lastName',
        key: 'lastName'
      },
      {
        title: 'Country',
        dataIndex: 'country',
        key: 'country'
      },
      {
        title: 'TokenType',
        dataIndex: 'tokenType',
        key: 'tokenType'
      },
      {
        title: 'Regulation',
        dataIndex: 'regulation',
        key: 'regulation'
      },
      {
        title: 'Token to be Issued',
        dataIndex: 'tokenToIssue',
        key: 'tokenToIssue'
      },
      {
        title: 'Date of Purchase',
        dataIndex: 'dateOfPurchase',
        key: 'dataOfPurchase'
      },
      {
        title: 'Wallet Address',
        dataIndex: 'walletAddress',
        key: 'walletAddress'
      },
      {
        title: 'Phase',
        dataIndex: 'phase',
        key: 'phase'
      },
      {
        title: 'ToPDF',
        key: 'operation',
        fixed:'right',
        render: ()=> <Button >PDF</Button>
      }
    ];
    var fixed=null;
    let element=document.getElementById("investorDetailHeading");
    const onInvestorsTableRowSelect=(data)=>{
        //alert("yaha control aya");
       console.log("data hai yeh to",data);
       element=document.getElementById("investorDetailHeading");
       console.log("Element ",element);
       fixed=data;
       if(element)element.scrollIntoView(); 
       if(showInvestorsTable==false)
        {
          element=document.getElementById("investorDetailHeading");
          setShowInvestorsTable(true);
        }
        else
        {
          element=document.getElementById("investorDetailHeading");
          setShowInvestorsTable(false);
        } 
         
    };


    return(
      <>
     <div style={{ textAlign:'center', backgroundColor:'#0275d8' , fontSize:'25px',color:'white' }}>Tokenization Asset Platform</div>
      <div className="card-container">
      <Tabs type="card" defaultActiveKey="1" >
      {(dashboardData.length==0 && !totalTokenDetail)&& 
              <div style={{textAlign:'center',margin: 'auto'}}>
                <Spin size='large'/>
              </div>
            }

      { (dashboardData.length>0 && totalTokenDetail!=null) &&<TabPane tab="Dashboard" key="1">
          <div style={{color: '#1890ff',fontSize: '25px',fontWeight: 'bold',textDecoration:'underline',textAlign:'center'}}>Dashboard</div>
          <Row justify="center">
          <Col span={22}>
            <Card>
              <Title level={2} style={{textAlign: 'left',color: '#444187',fontSize: '25px',fontWeight: 'bold'}}>OVERVIEW</Title>
              <div style={{ background: '#ECECEC', padding: '30px' }}>
              <div style={{color: '#1890ff',fontSize: '25px',fontWeight: 'bold',textAlign:'center'}}>{(dashboardData.length>0)  && dashboardData[0]?.tokenSymbol}</div>
              <br/>
          <Row gutter={16}>
            {(dashboardData.length>0)  && dashboardData?.map((data,index) => {
              return(
              <>
              <Col span={6}></Col>
              <Col span={12} key={index}>
              <Card bordered={false} style={{textAlign:'center'}} key={index}> 
                <p style={headingStyle}>Regulation</p>
                <p style={dataStyle}>{data.regulation}</p>
                <p style={headingStyle}>Sale Start Date</p>
                <p style={dataStyle}>{moment(data.saleStartDate).format('L')}</p>
                <p style={headingStyle}>Sale Close Date</p>
                <p style={dataStyle}>{moment(data.saleEndDate).format('L')}</p>
                <p style={headingStyle}>Token Sold</p>
                <p style={dataStyle}>{data.tokenSold}</p>
                <p style={headingStyle}>Tokens Minted</p>
                <p style={dataStyle}>{data.tokenMinted}</p>
                <p style={headingStyle}>TypeOfSecurity</p>
                <p style={dataStyle}>{data.typeOfSecurity}</p>
                <p style={headingStyle}>Token Price</p>
                <p style={dataStyle}>{data.tokenPrice}</p>
                <p style={headingStyle}>Token Holders</p>
                <p style={dataStyle}>{data.tokenHolder}</p>
              </Card>
           </Col>
           <Col span={6}></Col>
           </>
             )})}
            </Row>
          </div>
        </Card>
      </Col>
    </Row>
    <div className="site-card-wrapper">
    <Card title="" bordered={false} style={{ background:'#f7f7f7',padding:'30px'}}>
      <Row gutter={16}>
        <Col span={8}>
          <Card title="Token Sold" bordered={false}>
          <p style={dataStyle}>{(totalTokenDetail!=null)  && totalTokenDetail?.totalTokenSold}</p>
          </Card>
        </Col>
        <Col span={8}>
         <Card title="Token Minted" bordered={false}>
          <p style={dataStyle}>{(totalTokenDetail!=null)  && totalTokenDetail?.totalTokenMinted}</p>
         </Card>
        </Col>
        <Col span={8}>
          <Card title="Token Holder" bordered={false}>
              <p style={dataStyle}>{(totalTokenDetail!=null)  && totalTokenDetail?.totalTokenHolder}</p>
          </Card>
        </Col>
        </Row>
        </Card>
        </div>
        </TabPane>
    }
    {(investorsData.length>0 && investorData.length>0 ) && <TabPane tab="Investor" key="2">
      <>
        <div>
          <Space size='large'>
            <p> </p>
            <Button type="primary" href="https://www.w3schools.com/" ghost>All Investors</Button>
            <Button type="primary" href="https://www.w3schools.com/" ghost>Securities recovery request</Button>
          </Space>
        </div>
        <br/>
        <Row justify='center'>
          <Col span={'22'}>
            <Card style={{margin:'auto',padding:''}}>
            <Title level={2}     style={{textAlign: 'left',color: '#444187',fontSize: '25px',fontWeight: 'bold'}}>Investors</Title>
              <Tabs type="card" defaultActiveKey="21" >
                <TabPane tab='Tokens Issued' key='21'>
                <ExportToExcel data={investorsData} fileName="Investors_List" ></ExportToExcel>
                  <Table  id="investorsTable" onRow={(data,index)=>{
                    return {
                      onClick:()=>{onInvestorsTableRowSelect(data)}
                    };
                  }} columns={tokenIssuedColumn}  dataSource={investorsData} />
                  <div id="investorDetail">
                  {showInvestorsTable!=false && <Card>
                    <p style={headStyle} id="investorDetailHeading">Investor Detail</p>
                    <Descriptions title="Personal Information" bordered>
                      <Descriptions.Item label="Name of Investor">{investorData[0]?.personalInformation.nameOfInvestor}</Descriptions.Item>
                      <Descriptions.Item label="Wallet Address">{investorData[0]?.personalInformation.walletAddress}</Descriptions.Item>
                      <Descriptions.Item label="Country">{investorData[0]?.personalInformation.country}</Descriptions.Item>
                      <Descriptions.Item label="Email">{investorData[0]?.personalInformation.email}</Descriptions.Item>
                      <Descriptions.Item label="State">{investorData[0]?.personalInformation.state}</Descriptions.Item>
                      <Descriptions.Item label="PINCode">{investorData[0]?.personalInformation.zipCode}</Descriptions.Item>
                      <Descriptions.Item label="Phone Number">{investorData[0]?.personalInformation.phoneNumber}</Descriptions.Item>
                      <Descriptions.Item label="Permanent Address">{investorData[0]?.personalInformation.permanentAddress}</Descriptions.Item>
                      <Descriptions.Item label="Present Address">{investorData[0]?.personalInformation.presentAddress}</Descriptions.Item>
                    </Descriptions>                  
                    <Descriptions title="KYC Status" bordered>
                      { (investorData[0]?.KYCStatus.approvedOn!=null) &&
                        <Descriptions.Item label="Approved On">{investorData[0]?.KYCStatus.approvedOn}</Descriptions.Item>
                      }
                      { (investorData[0]?.KYCStatus.approvedBy!=null) &&
                        <Descriptions.Item label="Approved By">{investorData[0]?.KYCStatus.approvedBy}</Descriptions.Item>
                      }
                      { (investorData[0]?.KYCStatus.rejectedOn!=null) &&
                        <Descriptions.Item label="Rejected On">{investorData[0]?.KYCStatus.rejectedOn}</Descriptions.Item>
                      }
                      { (investorData[0]?.KYCStatus.rejectedBy!=null) &&
                        <Descriptions.Item label="Rejected By">{investorData[0]?.KYCStatus.rejectedBy}</Descriptions.Item>
                      }
                      { (investorData[0]?.KYCStatus.KYCResult!=null) &&
                        <Descriptions.Item label="KYC Result">{investorData[0]?.KYCStatus.KYCResult}</Descriptions.Item>
                      }
                    </Descriptions>                  
                    <Descriptions title="Accredation" bordered>
                      <Descriptions.Item label="Status">{investorData[0]?.accredation.status}</Descriptions.Item>
                      { (investorData[0]?.accredation.approvedOn!=null) &&
                        <Descriptions.Item label="Approved On">{investorData[0]?.accredation.approvedOn}</Descriptions.Item>
                      }
                      { (investorData[0]?.accredation.approvedBy!=null) &&
                        <Descriptions.Item label="Approved By">{investorData[0]?.accredation.approvedBy}</Descriptions.Item>
                      }
                      { (investorData[0]?.accredation.rejectedOn!=null) &&
                        <Descriptions.Item label="Rejected On">{investorData[0]?.accredation.rejectedOn}</Descriptions.Item>
                      }
                      { (investorData[0]?.accredation.rejectedBy!=null) &&
                        <Descriptions.Item label="Rejected By">{investorData[0]?.accredation.rejectedBy}</Descriptions.Item>
                      }
                    </Descriptions>
                    <Descriptions title="White Listing" bordered>
                      <Descriptions.Item label="Valid Till">{investorData[0]?.whitelisting.validTill}</Descriptions.Item>
                      <Descriptions.Item label="Whitelisted On">{investorData[0]?.whitelisting.whitelistedOn}</Descriptions.Item>
                    </Descriptions>
                    <Descriptions title="Token Details" bordered>
                      <Descriptions.Item label="Token Symbol">{investorData[0]?.tokenDetails.tokenSymbol}</Descriptions.Item>
                      <Descriptions.Item label="Token Type">{investorData[0]?.tokenDetails.tokenType}</Descriptions.Item>
                      <Descriptions.Item label="Regulation">{investorData[0]?.tokenDetails.regulation}</Descriptions.Item>
                      <Descriptions.Item label="Token Price">{investorData[0]?.tokenDetails.tokenPrice}</Descriptions.Item>
                      <Descriptions.Item label="Total Token Issued">{investorData[0]?.tokenDetails.totalTokenIssued}</Descriptions.Item>
                      <Descriptions.Item label="Lock Period"> 
                      <Descriptions  bordered>
                        <Descriptions.Item label="Duration">{investorData[0]?.tokenDetails.lockPeriod.duration}</Descriptions.Item>
                        <Descriptions.Item label="Start Date">{investorData[0]?.tokenDetails.lockPeriod.startDate}</Descriptions.Item>
                        <Descriptions.Item label="End Date">{investorData[0]?.tokenDetails.lockPeriod.endDate}</Descriptions.Item>
                      </Descriptions>
                      </Descriptions.Item>
                    </Descriptions>
                    <Descriptions title="Investment Detail" bordered>
                      <Descriptions.Item label="Date of Investment">{investorData[0]?.investmentDetails.dateOfInvestment}</Descriptions.Item>
                      <Descriptions.Item label="Total Investment Amount(USD)">{investorData[0]?.investmentDetails.totalInvestmentAmount_USD}</Descriptions.Item>
                      <Descriptions.Item label="Total Investment Amount(CryptoCurrency)">{investorData[0]?.investmentDetails.totalInvestmentAmount_CC}</Descriptions.Item>
                      <Descriptions.Item label="Phase">{investorData[0]?.investmentDetails.phase}</Descriptions.Item>
                    </Descriptions>
                    <Descriptions title="Token History" bordered>
                      <Descriptions.Item label="Token transfered to">{investorData[0]?.tokenHistory.tokenTransferredTo}</Descriptions.Item>
                      <Descriptions.Item label="Receiver wallet Address">{investorData[0]?.tokenHistory.receiverWalletAddress}</Descriptions.Item>
                      <Descriptions.Item label="Recever Name">{investorData[0]?.tokenHistory.receiverName}</Descriptions.Item>
                      <Descriptions.Item label="Receiver Information">
                        <Descriptions bordered>
                        <Descriptions.Item label="Email">{investorData[0]?.tokenHistory.receiverInfo.email}</Descriptions.Item>
                        <Descriptions.Item label="Country">{investorData[0]?.tokenHistory.receiverInfo.country}</Descriptions.Item>
                        <Descriptions.Item label="Accredited">{investorData[0]?.tokenHistory.receiverInfo.accredited}</Descriptions.Item>
                        <Descriptions.Item label="Token Tranfered">{investorData[0]?.tokenHistory.receiverInfo.tokenTransferred}</Descriptions.Item>
                        </Descriptions>
                      </Descriptions.Item>
                    </Descriptions>
                    <Descriptions title="Exchange Transfer" bordered>
                    <Descriptions.Item >{investorData[0]?.exchangeTransfer}</Descriptions.Item>
                    </Descriptions>
                  </Card>
                  }                  
                  </div>
                </TabPane>
                <TabPane tab='Pending' key='22'>
                <ExportPendingTableToExcel data={investorsDataOfPendingToken} fileName="Pending_Investors_List" ></ExportPendingTableToExcel>
                  <Table columns={pendingTokenColumn} dataSource={investorsDataOfPendingToken} />  
                </TabPane>
              </Tabs>
            </Card> 
          </Col>
        </Row>
      </>      
    </TabPane>
    }
    {(dashboardData.length>0 && totalTokenDetail!=null) &&    <TabPane tab="Transfer" key="3">
    <div>
          <Space size='large'>
            <p> </p>
            <Button type="primary" href="https://www.w3schools.com/" ghost>Peer to Peer Transfer</Button>
            <Button type="primary" href="https://www.w3schools.com/" ghost>Exchange Transfer</Button>
          </Space>
        </div>
      </TabPane>
    }
    </Tabs>
  </div>
     </>
  );
}