import React, { useState, useEffect } from 'react';
import { Typography, Form, Card, Row, Input, Select, DatePicker, Radio, Button, Modal, Col, Progress, Result, message, Descriptions, Spin ,notification} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useHistory, useLocation, useParams } from "react-router-dom";
import TenantStyle from './tenants.module.css'
import { IssuerTokenAdminService } from '../IssuerTokenAdmin/IssuerTokenAdmin.service';
import moment from 'moment';
import { AuthService } from '../Shared/Auth.service';

const issuerTokenAdminService = new IssuerTokenAdminService();
const useUserContext = () => new AuthService().useUserContext();

const { Text, Title } = Typography;

const AddTenant = (props) => {
    const { tenantdata } = props;
    const { tenantId } = useParams();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const { userInfo } = useUserContext();
    

    const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 12 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12 }
        }
      };

      const submitTenantForm = async (_form) => {
        const form = { marketPlaceAdminId: userInfo?._id, ..._form };
        console.log(_form)
        const response = await issuerTokenAdminService.saveTenant(form);
        history.push('/tenants');
      }

      useEffect(() => {
        console.log('Route Parameter on Page Load: ', tenantId, tenantdata);
        if(Object.keys(tenantdata).length){
          form.setFieldsValue(
            {
              tenantName:tenantdata.tenantName,
              leaseStartDate:moment(tenantdata.leaseStartDate),
              leaseEndDate:moment(tenantdata.leaseEndDate),
              tenantDescription:tenantdata.tenantDescription,
              tenantLockPeriod:tenantdata.tenantLockPeriod,
              totalArea:tenantdata.totalArea,
              rentPerSqFt:tenantdata.rentPerSqFt,
              leasePeriod:moment(tenantdata.leasePeriod),
              escalation:tenantdata.escalation,
              rentEscalation:tenantdata.rentEscalation,
              securityDeposit:tenantdata.securityDeposit,
              propertyTax:tenantdata.propertyTax,
              maintenanceCost:tenantdata.maintenanceCost,
          }
            )
        }
      }, []);

  return (
    <>
      {loading &&  
         <div style={{textAlign:'center'}}>
           <br/>
           <Spin size='large'/>
         </div>
       }
       {!loading && (
          <>
          <Title level={1} style={{ color: '#186AB4', textAlign: 'center', marginTop:'20px' }}>{ tenantId? 'View Tenant':'Add New Tenant'}</Title>
          <br/><br/>
          <Row justify="center">
            <Col span={16}>
              <Form {...formItemLayout} labelAlign="left" form={form} onFinish={submitTenantForm}>
                  <Card>
                     <Form.Item
                        label={`Tenant Name`}
                        name="tenantName"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input placeholder='Enter Tenant Name' />
                      </Form.Item>

                     <Form.Item
                        label='Lease Start Date'
                        name="leaseStartDate"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                          }
                        ]}>
                       <DatePicker className={TenantStyle.customDatePicker}  />
                     </Form.Item>

                     <Form.Item
                        label='Lease End Date'
                        name="leaseEndDate"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                          }
                        ]}>
                        <DatePicker  className={TenantStyle.customDatePicker} format="MMMM DD, YYYY" />
                      </Form.Item>

                      <Form.Item
                        label='Tenant Description'
                        name="tenantDescription"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                         <TextArea rows={4} />
                      </Form.Item>

                      <Form.Item
                        label='Lock Period for Tenant'
                        name='tenantLockPeriod'
                        rules={[{ required: true, message: 'Lock Period for Tenant is required' }]}>
                        <Input placeholder='' />
                      </Form.Item>

                      <Form.Item
                        label='Total Area'
                        name='totalArea'
                        rules={[{ message: 'Total Area is required' }]}>
                        <Input placeholder='' />
                      </Form.Item>
                      
                      <Form.Item 
                        label='Rent Per Sq Ft'
                        name="rentPerSqFt"
                        rules={[
                          {
                            required: true,
                            message: 'Enter Rent Per Sq Ft here',
                            whitespace: true
                          }
                        ]}>
                           <Input placeholder='' />
                      </Form.Item>

                      <Form.Item
                        label='Lease Period'
                        name="leasePeriod"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                          }
                        ]}>
                        <DatePicker className={TenantStyle.customDatePicker} format="MMMM DD, YYYY" />
                      </Form.Item>

                      <Form.Item
                        label='Escalation'
                        name="escalation"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: false,
                          }
                        ]}
                      >
                        <Input type="text" placeholder='' />
                      </Form.Item>

                      <Form.Item
                        label='Rent Escalation'
                        name='rentEscalation'
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}
                      >
                        <Input type="text" placeholder='' />
                      </Form.Item>

                      <Form.Item
                        label='Security Deposit'
                        name='securityDeposit'
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}
                      >
                        <Input type="text" placeholder='' />
                      </Form.Item>

                      <Form.Item
                        label='Property Tax Paid by - Owner/Tenant'
                        name='propertyTax'
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}
                      >
                        <Input type="text" placeholder='' />
                      </Form.Item>

                      <Form.Item
                        label='Maintenance Cost Paid by - Owner/Tent'
                        name='maintenanceCost'
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}
                      >
                        <Input type="text" placeholder='' />
                      </Form.Item>

                      <br /><br />
                    { !tenantId && <Form.Item>
                        <Button size='large' style={{ marginLeft: '300px' }} type='primary' htmlType="submit" loading={loading}>Submit</Button>
                     </Form.Item>}
                     <br /><br />
                  </Card>
               </Form>
            </Col>
         </Row>
         </>
       )}
      </>
  )
}

export default AddTenant