import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Typography, Form, Radio, Select, Input, Button, message } from 'antd';
import { useHistory } from 'react-router-dom';
import { MasterCompanySuperAdminSer } from '../../../masterCompanySuperAdmin.service';
import { AuthService } from '../../../../Shared/Auth.service';
import { TeamDepartment } from '../../../../Shared/interfaces';

const {Title} = Typography;
const { Option } = Select;


const MasterCompanySuperAdminService = new MasterCompanySuperAdminSer();

const useUserContext = () => new AuthService().useUserContext();

export default () => {
  const {userInfo} = useUserContext();
  const history = useHistory();
  
  const [form] = Form.useForm();

  const [submitting, setSubmitting] = useState(false);
  const [teamDepartments, setTeamDepartments] = useState<TeamDepartment[]>();

  useEffect(() => {
    (async () => {
      if(!userInfo) return;

      const response = await MasterCompanySuperAdminService.getTeamDepartments();
      const _teamDepartments: TeamDepartment[] = response.data;
      setTeamDepartments(_teamDepartments);
      console.log(_teamDepartments);

    })();
  }, [userInfo]);


  const addTeamRole = async (formValue) => {
    const req = {...formValue};
    
    setSubmitting(true);
    const response = await MasterCompanySuperAdminService.addTeamRole(req);

    if (response.success) {
      message.success('Role added successfully');
      history.push(`/master-company-super-admin/admin/role-managment`);
    } else {
      message.error(response.error.message);
    }

    setSubmitting(false);
  }



  return (
    <>
      <br/><br/>
      <Row justify="center">
         <Col span={20}>
            <Card>
              <Title level={1} style={{textAlign:'center'}}>Add Employees/Affiliates Roles</Title>
              <Row justify="center">
                <Col span={20}>
                  <Card
                    bordered={false} 
                    className='card-shadow'>
                    <Form labelAlign="left" initialValues={{team: 'employee'}} onFinish={addTeamRole} form={form}>
                      <Form.Item
                        labelCol={{xs: { span: 24 }, sm: { span: 6, offset:4 }}}
                        wrapperCol={{xs: { span: 24 }, sm: { span: 10 }}}
                        label="Select The team"
                        name='team'
                        rules={[
                          {
                            required: true,
                            message: 'This field is required'
                          }
                        ]}>
                        <Radio.Group onChange={() => form.resetFields(['department', 'role', 'departmentName'])}>
                          <Radio key='employee' value='employee'>
                            Employee
                          </Radio>
                          <Radio key='contractor_consultant_advisor' value='contractor_consultant_advisor'>
                            Contractor/Consultant/Advisor
                          </Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item 
                        noStyle
                        shouldUpdate={(prevValues, currentValues) => prevValues.team !== currentValues.team}
                      >
                        {({ getFieldValue }) => (
                          <Form.Item 
                            style={{marginBottom:0}}
                            labelCol={{xs: { span: 24 }, sm: { span: 6, offset:4 }}}
                            wrapperCol={{xs: { span: 24 }, sm: { span: 10 }}}
                            label='Department name'
                            name="department"
                            validateStatus={teamDepartments? '' : 'validating'}
                            hasFeedback
                            help= {teamDepartments? '' : "Loading Departments..."}
                            rules={[
                              {
                                required: true,
                                message: 'This field is required',
                              }
                            ]}>
                            <Select showSearch placeholder="Select Department">
                              {teamDepartments?.filter(teamDepartment => teamDepartment.team === getFieldValue('team')).map(teamDepartment => 
                                <Option key={teamDepartment._id} value={teamDepartment._id}>{teamDepartment.name}</Option>
                              )}
                              <Option key='other' value='other'>Other</Option>
                            </Select>
                          </Form.Item>
                        )}
                      </Form.Item>


                      <Form.Item 
                        noStyle
                        shouldUpdate={(prevValues, currentValues) => prevValues.department !== currentValues.department}
                        >
                        {({ getFieldValue }) => {
                          return getFieldValue('department') === 'other' ? (
                            <Form.Item 
                              labelCol={{xs: { span: 24 }, sm: { span: 8, offset:4 }}}
                              wrapperCol={{xs: { span: 24 }, sm: { span: 8 }}}
                              label={`Enter the new Department name`}
                              name="departmentName"
                              rules={[
                                {
                                  required: true,
                                  message: 'This field is required',
                                  whitespace: true
                                }
                              ]}>
                              <Input/>
                            </Form.Item>
                          ) : null

                        }}
                      </Form.Item>

                      <Form.Item 
                        labelCol={{xs: { span: 24 }, sm: { span: 6, offset:4 }}}
                        wrapperCol={{xs: { span: 24 }, sm: { span: 10 }}}
                        label={`Enter the Role`}
                        name="role"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input/>
                      </Form.Item>

                      <div style={{textAlign:'right'}}>
                        <Button style={{marginRight:'5px'}} htmlType='button' size='large' onClick={()=> history.push(`/master-company-super-admin/admin/role-managment`)}>
                          BACK
                        </Button>
                        <Button htmlType='submit' size='large' type='primary' loading={submitting}>
                          SAVE
                        </Button>
                      </div>
                    </Form>
                  </Card>
                </Col>
              </Row>
            </Card>
         </Col>
      </Row>
    </>
  );
};