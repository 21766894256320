import { Button, Card, Col, Input, Modal, notification, Row, Spin, Table, Typography } from 'antd';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { User } from '../../../Shared/interfaces';
import { SharedService } from '../../../Shared/Shared.service';
import { ApproversAndAccountsService } from '../ApproversAndAccounts.service';

const {Title} = Typography;

const sharedService = new SharedService();
const approversAndAccountsService = new ApproversAndAccountsService();





export default function ListApprovers() {

  const [loading, setLoading] = useState(true);
  const [approvers, setApprovers] = useState<User[]>();
  const [providerToRemove, setProviderToRemove] = useState<User | null>();
  const history = useHistory();
  const [submitting, setSubmitting] = useState(false);
  const [cryptoAccountModalVisible, setCryptoAccountModalVisible] = useState(false);
  const [accountName, setAccountName] = useState('');
  const [accountAddress, setAccountAddress] = useState('');


  useEffect(() => {
    (async () => {
      const _approvers = (await approversAndAccountsService.getApprovers()).data;

      setApprovers(_approvers);
      setLoading(false);

    })();
  }, []);



  const columns = [
    {
      title: 'NAME',
      dataIndex: 'firstName'
    },
    {
      title: 'EMAIL',
      dataIndex: 'email'
    },
    {
      title: 'WALLET ADDRESS',
      dataIndex: 'email'
    },
    {
      title: 'ADMIN ROLES',
      dataIndex: 'roles',
      render: (roles: string[], record: User) => (
        sharedService.adminRolesOptions2.find(opt => roles.includes(opt.value))?
          sharedService.adminRolesOptions2.filter(opt => roles.includes(opt.value)).map(opt => opt.name).join('| ') 
          : 
          'None'
      )
    },
    {
      title: 'ACTION',
      render: (value, record: User) => {
        return (
          <Button danger onClick={() => {
           setSubmitting(false);
           setProviderToRemove(record);
          }}>
            Remove
          </Button>
        );
      }
    }
  ];

  const removeApprover = async() => {
    setSubmitting(true);

    const response = await approversAndAccountsService.removeApprover({approverId: providerToRemove?._id as string});

    if (response.success) {
      notification.success({message: 'Success', description: 'Approver removed succesfully'});
      setApprovers(approvers?.filter(approver => approver._id !== providerToRemove?._id));
      

    } else {
      notification.error({message: 'Error', description: response.error.message});
    }
    setSubmitting(false);
    setProviderToRemove(null);
  }



  const addCryptoAccount = async() => {
    if(!accountName || !accountAddress) return notification.error({message: 'Error', description: 'Fill all the fields'});
    setSubmitting(true);


    const response = await approversAndAccountsService.addCryptoAccount({
      accountName,
      accountAddress
    });

    if (response.success) {
      notification.success({message: 'Success', description: 'Account added succesfully'});
      setCryptoAccountModalVisible(false);
      
    } else {
      notification.error({message: 'Error', description: response.error.message});
      
    }

    setSubmitting(false);

  }


  return (
    <>
      <br/><br/>
      <Row justify="center">
        <Col span={23}>
          {loading &&  
            <div style={{textAlign:'center'}}>
              <br/>
              <Spin size='large'/>
            </div>
          }
          {!loading && 
            <Card>
              <Title level={1} style={{textAlign:'center'}}>Withdrawal and Transfer Request Approvers</Title>

              <div style={{textAlign:'right', marginBottom: '10px'}}>
                <Button style={{marginRight:'5px'}} size='large' onClick={()=> setCryptoAccountModalVisible(true)}>
                  ADD CRYPTO ACCOUNTS
                </Button>
                <Button size='large' onClick={()=> history.push(`/issuer-super-admin/approvers-and-accounts/add-approvers`)} >
                  ADD APPROVER
                </Button>
              </div>

              <Table
                columns={columns}
                dataSource={approvers}
                rowKey='_id'
                pagination={false}
              />
            </Card>
          }
        </Col>
      </Row>


      <Modal 
        title='Confirm Removal' 
        visible={!!providerToRemove} 
        okText='Yes' 
        cancelText='No' 
        onCancel={() => setProviderToRemove(null)} 
        onOk={removeApprover}
        okButtonProps={{loading: submitting}}
      >

        {providerToRemove && 
          <p>
            Are you sure you want to remove {providerToRemove.firstName} {providerToRemove.lastName} as Approver?
          </p>
        }
      </Modal>

      <Modal
        title='Add Crypto Account'
        okText='Confirm'
        visible={cryptoAccountModalVisible}
        onCancel={()=> {setCryptoAccountModalVisible(false); setAccountName(''); setAccountAddress('')}}
        onOk={addCryptoAccount}
        okButtonProps={{loading: submitting}}
        >
        
        <div>
          <p>Account Name</p>
          <Input value={accountName} onChange={e=> {setAccountName(e.target.value)}} placeholder="Account name"/>
        </div>
        <div>
          <br/>
          <p>Account Address</p>
          <Input value={accountAddress} onChange={e=> {setAccountAddress(e.target.value)}} placeholder="Account address"/>
        </div>

      </Modal>
    </>
  );


  
}