import { MetamaskService } from "../Metamask.service";
import { environment } from "../../../environments/environment";

export class PlatformFactoryService {
  private APIURL = environment.APIURL;

  public contract = {
    address: environment.PlatformAddress,
    ABI: require("./ABI.json"),
  };

  private get web3() {
    return MetamaskService.web3;
  }

  async deploy(owner: string, name: string) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      this.contract.address
    );
    return contractInstance.methods
      .launchPlatform(name, owner)
      .send({ from: owner });
  }
}
