import { Button, Col, Row, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useHistory, Link } from "react-router-dom";
import { Spin } from "antd";
import { Container, TabPane } from "reactstrap";
import queryString from "query-string";
import sign from "../../assets/images/icons/tick.png";
//import "./site-common.min.css";
import "./index.css";
import "./PropertyDetails.css";
import pimage from "../../assets/images/icons/home2.jpg";
import { Carousel } from "antd";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { ArrowLeftOutlined } from "@ant-design/icons";
import Item from "antd/lib/list/Item";
// import { Link } from "react-feather";
import { VideoPlayer } from "../mainSlider/VideoPlayer";
import { PropertyAdminService } from "./PropertyAdmin.service";
import { AuthService } from "../Shared/Auth.service";
import InvestModel from "../Shared/InvestModel/InvestModel";
import { environment } from "../../environments/environment";

const propertyAdminService = new PropertyAdminService();
const useUserContext = () => new AuthService().useUserContext();

const stopHandler = (e) => {};

const PropertyDetails = () => {
  const { userInfo } = useUserContext();
  const [properties, setProperties] = useState([]);
  const [propertyDetails, setPropertyDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { propertyId } = queryString.parse(location.search);
  const [data1, setData1] = useState([]);
  const [tabNo, setTabNo] = useState(1);
  const [offeringText, setOfferingText] = useState([]);
  const [offeringImages, setOfferingImages] = useState([]);
  const [displayInvestModel, setDisplayInvestModel] = useState(false);
  const history = useHistory();
  const tabNames = [
    "Summary",
    "Sponsor",
    "Diligence Report",
    "Financials",
    "Location",
    "Documents",
    "FAQs",
    "Links"
  ];

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
    (async function loadProperties() {
      setLoading(true);
      if (propertyId && propertyId.length > 0) {
        const propertyDetails = await propertyAdminService.getPropertyById(
          propertyId
        );
        if (
          propertyDetails &&
          propertyDetails.data &&
          propertyDetails.data.length > 0
        ) {
          setPropertyDetails(propertyDetails.data[0]);
          console.log(propertyDetails.data[0]);
          let ab = propertyDetails.data[0];
          setData1([
            propertyDetails.data[0].targetedInvestorIrr,
            propertyDetails.data[0].targetedSumContribution,
            propertyDetails.data[0].periodOfSale,
            "Initial",
            "31 Dec, 2021",
            propertyDetails.data[0]?.nameOfToken,
            propertyDetails.data[0].tokenSymbol,
            propertyDetails.data[0].typeOfToken,
            propertyDetails.data[0].tokenStandard,
          ]);
          setOfferingText([
            ab.distributionStrategy,
            ab.financials,
            ab.sources,
            ab.organizationalChart,
            ab.fees,
          ]);
          setOfferingImages([
            ab.distributionStrategyFile?.URL,
            ab.financialsFile?.URL,
            ab.sourcesFile?.URL,
            ab.organizationalChartFile?.URL,
            ab.feesFile?.URL,
          ]);
        }
      }
      setLoading(false);
    })();
  }, []);

  const images = [
    require("../../assets/images/icons/home2.jpg"),
    require("../../assets/images/icons/home2.jpg"),
    require("../../assets/images/icons/home2.jpg"),
  ];

  const convertToMillion = (labelValue) => {
    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e9
      ? Math.abs(Number(labelValue)) / 1.0e9 + "B"
      : // Six Zeroes for Millions
      Math.abs(Number(labelValue)) >= 1.0e6
      ? Math.abs(Number(labelValue)) / 1.0e6 + "M"
      : // Three Zeroes for Thousands
      Math.abs(Number(labelValue)) >= 1.0e3
      ? Math.abs(Number(labelValue)) / 1.0e3 + "K"
      : Math.abs(Number(labelValue));
  };

  const onChange = (key) => {
    setTabNo(key);
  };

  const investNow = ()=>{
    if (userInfo) {
      setDisplayInvestModel(true);
    }else{
      history.push("/account/login")
    }
  }

  const cancelInvestModel = ()=>{
    setDisplayInvestModel(false);
  }

  return (
    <div className="wd_scroll_wrap overflow-y-hidden">
    <span style={{cursor: "pointer", display: "flex", margin: "20px 0 0 20px", width: "min-content", border: "1px solid dodgerblue", padding: "4px 12px", borderRadius: "14px"}} onClick={() => history.push("/investor/dashboard")}><span style={{fontSize: "38px", color: "dodgerblue"}}>⬅</span><span style={{fontSize: "19px", color: "dodgerblue", fontWeight: "600"}}>Dashboard</span></span>
    
    <InvestModel displayInvestModel={displayInvestModel} handleCancel={cancelInvestModel}>
    </InvestModel>

      {loading && (
        <div
          style={{
            textAlign: "center",
            marginTop: "20px",
            marginBottom: "200px",
          }}
        >
          <Spin size="large" />
        </div>
      )}
      {!loading && (
        <>
          {!propertyDetails && (
            <h3 className="no-property">No Propery Found! Please try again.</h3>
          )}
          {propertyDetails && (
            <>
              <div
                className="container-fluid col-12"
                style={{ padding: "50px 20px" }}
              >
                {/* // Apply to invest section */}
                <div
                  className="col-12 invest"
                  style={{ width: "100%", display: "inline-block" }}
                >
                  <button
                    className="btn btn-block btn-lg btn-success"
                    onClick={() => investNow()}
                  >
                    Invest Now
                  </button>
                  <h3>{propertyDetails.propertyTitle}</h3>
                  <h6>
                    {propertyDetails.propertyCity},{" "}
                    {propertyDetails.propertyState}
                  </h6>
                  <h4>{propertyDetails.companyName}</h4>
                </div>

                {/* Image and Details section */}
                <div
                  className="col-12 row image-detail"
                  style={{ margin: "30px 0" }}
                >
                  <div className="col-md-6 image-container">
                    <Carousel
                      effect="fade"
                      autoplay
                      className="carosel"
                      nextArrow={sign}
                    >
                      {propertyDetails.propertyImages &&
                        propertyDetails.propertyImages.map((img, index) => (
                          <div key={index}>
                            <img
                              id={`propertyimage`}
                              src={img.URL}
                              alt={img.name}
                              style={{
                                width: "100%",
                                minHeight: "370px",
                                maxHeight: "370px",
                              }}
                            />
                          </div>
                        ))}
                    </Carousel>
                  </div>
                  <div className="col-md-6 detail-container">
                    <h5 className="col-12">TARGETS</h5>
                    <div className="row col-12 target-wrapper">
                      <div
                        className="col-md-6 left-side"
                        style={{ paddingRight: "10px" }}
                      >
                        <div className="detail-line">
                          <span className="line-left">IRR</span>
                          <span className="line-right">
                            {propertyDetails.targetedInvestorIRR}%
                          </span>
                        </div>
                        <div className="detail-line">
                          <span className="line-left">Equity Multiple</span>
                          <span className="line-right">
                            {propertyDetails.targetedEquityMultiple}x
                          </span>
                        </div>
                        <div className="detail-line">
                          <span className="line-left">Year 1 Cash on Cash</span>
                          <span className="line-right">
                            {propertyDetails.yearOneCashOnCash}%
                          </span>
                        </div>
                        <div className="detail-line">
                          <span className="line-left">
                            Stabilized Cash on Cash
                          </span>
                          <span className="line-right">
                            {propertyDetails.stabilizedCashOnCash}%
                          </span>
                        </div>
                        <div className="detail-line back-color">
                          <span className="line-left">Co-Investment</span>
                          <span className="line-right">
                            {parseInt(
                              (propertyDetails.coInvestment /
                                propertyDetails.totalInvestmentTarget) *
                                100
                            )}
                            % ({convertToMillion(propertyDetails.coInvestment)}
                            )
                          </span>
                        </div>
                        <div className="detail-line back-color">
                          <span className="line-left">Preferred Return</span>
                          <span className="line-right">
                            {propertyDetails.preferredReturn}%
                          </span>
                        </div>
                        <div className="detail-line  back-color">
                          <span className="line-left">
                            Investor Profit Share
                          </span>
                          <span className="line-right">
                            {propertyDetails.investorProfitShare}%
                          </span>
                        </div>
                        <div className="detail-line">
                          <span className="line-left">Hold Period</span>
                          <span className="line-right">
                            {propertyDetails.holdPeriod}Y
                          </span>
                        </div>
                        <div className="detail-line">
                          <span className="line-left">Minimum Investment</span>
                          <span className="line-right">
                            {propertyDetails.minimumInvestment} {environment.currencySign}
                          </span>
                        </div>
                        <div className="detail-line">
                          <span className="line-left">First Distribution</span>
                          <span className="line-right">
                            {propertyDetails.firstDistribution}
                          </span>
                        </div>
                        <div className="detail-line">
                          <span className="line-left">
                            Distribution Frequency
                          </span>
                          <span className="line-right">
                            {propertyDetails.distributionFrequency}
                          </span>
                        </div>
                        <div className="detail-line back-color">
                          <span className="line-left">Asset Profile</span>
                          <span className="line-right">
                            {propertyDetails.assetProfile}
                          </span>
                        </div>
                        <div className="detail-line back-color">
                          <span className="line-left">Loan-to-Value</span>
                          <span className="line-right">
                            {propertyDetails.loanToValue}%
                          </span>
                        </div>
                        <div className="detail-line back-color">
                          <span className="line-left">Current Occupancy</span>
                          <span className="line-right">
                            {propertyDetails.currentOccupancy}
                          </span>
                        </div>
                        <div className="detail-line back-color">
                          <span className="line-left">Total Investment Target</span>
                          <span className="line-right">
                            {propertyDetails.totalInvestmentTarget}
                          </span>
                        </div>
                        <div className="detail-line back-color">
                          <span className="line-left">Funding Deadline</span>
                          <span className="line-right">
                            {new Date(propertyDetails.projectedFundingDeadline).toLocaleString()}
                          </span>
                        </div>
                      </div>
                      <div
                        className="col-md-6 right-side"
                        style={{ paddingLeft: "10px" }}
                      >

                        <div className="detail-line back-color">
                          <span className="line-left">Co-Investment</span>
                          <span className="line-right">
                            {propertyDetails.coInvestment}
                          </span>
                        </div>
                        <div className="detail-line back-color">
                          <span className="line-left">Avg Cash Yeild</span>
                          <span className="line-right">
                            {propertyDetails.targetedEquityMultiple}
                          </span>
                        </div>
                        <div className="detail-line back-color">
                          <span className="line-left">Investment Period</span>
                          <span className="line-right">
                            {propertyDetails.targetedInvestmentPeriod}
                          </span>
                        </div>
                        <div className="detail-line back-color">
                          <span className="line-left">Token Issuance</span>
                          <span className="line-right">
                            {propertyDetails.tokenIssuance}
                          </span>
                        </div>
                        <div className="detail-line back-color">
                          <span className="line-left">Location | MSA</span>
                          <span className="line-right">
                            {propertyDetails.locationMsa}
                          </span>
                        </div>
                        <div className="detail-line back-color">
                          <span className="line-left">Property Type</span>
                          <span className="line-right">
                            {propertyDetails.propertyType}
                          </span>
                        </div>
                        <div className="detail-line back-color">
                          <span className="line-left">Period of Sale</span>
                          <span className="line-right">
                            {propertyDetails.periodOfSale}
                          </span>
                        </div>
                        <div className="detail-line back-color">
                          <span className="line-left">Type Of Token</span>
                          <span className="line-right">
                            {propertyDetails.typeOfToken}
                          </span>
                        </div>
                        <div className="detail-line back-color">
                          <span className="line-left">Token Properties</span>
                          <span className="line-right">
                            {propertyDetails.tokenProperties}
                          </span>
                        </div>
                        <div className="detail-line back-color">
                          <span className="line-left">Minimum Sum of Contributions</span>
                          <span className="line-right">
                            {propertyDetails.minimumSumContribution}
                          </span>
                        </div>
                        <div className="detail-line back-color">
                          <span className="line-left">Targeted Sum of Contributions</span>
                          <span className="line-right">
                            {propertyDetails.targetedSumContribution}
                          </span>
                        </div>
                        <div className="detail-line back-color">
                          <span className="line-left">Accepted Currencies</span>
                          <span className="line-right">
                            {propertyDetails.acceptedCurrencies}
                          </span>
                        </div>
                        <div className="detail-line back-color">
                          <span className="line-left">Name Of Token</span>
                          <span className="line-right">
                            {propertyDetails.nameOfToken}
                          </span>
                        </div>
                        <div className="detail-line back-color">
                          <span className="line-left">Token Symbol</span>
                          <span className="line-right">
                            {propertyDetails.tokenSymbol}
                          </span>
                        </div>
                        <div className="detail-line back-color">
                          <span className="line-left">Token Symbol</span>
                          <span className="line-right">
                            {propertyDetails.tokenStandard}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* multiple tabs section */}
                <Tabs
                  onChange={onChange}
                  type="card"
                  className="col-12"
                  style={{ marginBottom: "30px" }}
                >
                  {tabNames.map((name, index) => (
                    <TabPane
                      tab={`${name}`}
                      key={`${index + 1}`}
                      style={{ fontSize: "18px", fontWeight: "500" }}
                    ></TabPane>
                  ))}
                </Tabs>

                <div
                  className="tabs-details-wrapper"
                  style={{ textAlign: "center" }}
                >
                  {/* Summary Section */}
                  {tabNo == 1 && (
                    <>
                      <div className="summary" style={{ padding: "0 20px" }}>
                        <h3>About This Property</h3>
                        <p>{propertyDetails.descriptionOfProperty}</p>
                        <br />
                        {propertyDetails?.offeringVideoFile &&
                          propertyDetails?.offeringVideoFile[0] && (
                            <div
                              className="video col-12"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                textAlign: "center",
                              }}
                            >
                              <div
                                className="animate__animated animate__fadeInUp animate__slower  animate__delay-1s"
                                style={{ width: "700px" }}
                              >
                                <VideoPlayer
                                  style={{ height: "700px", width: "500px" }}
                                  linkSrc={`${propertyDetails?.offeringVideoFile[0]?.URL}`}
                                  playerId="player-1"
                                  coverId="cover-2"
                                  playButtonId="play-1"
                                  subsButtonId="subs-1"
                                />
                              </div>
                            </div>
                          )}
                        <br />
                        <div
                          className="row-striped-wrapper col-12"
                          style={{ marginTop: "30px" }}
                        >
                          <div className="row-striped col-12">
                            <span className="left-sec">Address</span>
                            <span className="right-sec">
                              {propertyDetails.propertyAddress}
                            </span>
                          </div>
                          <div className="row-striped col-12">
                            <span className="left-sec">Square Footage</span>
                            <span className="right-sec">
                              {propertyDetails.suarefootage} sq. ft.
                            </span>
                          </div>
                          <div className="row-striped col-12">
                            <span className="left-sec"># of Units</span>
                            <span className="right-sec">
                              {propertyDetails.numberOfUnits}
                            </span>
                          </div>
                          <div className="row-striped col-12">
                            <span className="left-sec">Year Built</span>
                            <span className="right-sec">
                              {propertyDetails.yearBuilt}
                            </span>
                          </div>
                          <div className="row-striped col-12">
                            <span className="left-sec">Year Renovated</span>
                            <span className="right-sec">
                              {propertyDetails.yearRenovated || "N/A"}
                            </span>
                          </div>
                          <div className="row-striped col-12">
                            <span className="left-sec">Current Occupancy</span>
                            <span className="right-sec">
                              {propertyDetails.currentOccupancy}%
                            </span>
                          </div>
                          <div className="row-striped col-12">
                            <span className="left-sec">Market Occupancy</span>
                            <span className="right-sec">
                              {">"}
                              {propertyDetails.marketOccupancy}%
                            </span>
                          </div>
                          <div className="row-striped col-12">
                            <span className="left-sec">
                              Current Average Rents
                            </span>
                            <span className="right-sec">
                              ${propertyDetails.currentAverageRent}
                            </span>
                          </div>
                          <div className="row-striped col-12">
                            <span className="left-sec">
                              Average Market Rents
                            </span>
                            <span className="right-sec">
                              {propertyDetails.averageMarketRent || "N/A"}
                            </span>
                          </div>
                          <div className="row-striped col-12">
                            <span className="left-sec">Purchase Price</span>
                            <span className="right-sec">
                              ${propertyDetails.purchasedPrice}
                            </span>
                          </div>
                          <div className="row-striped col-12">
                            <span className="left-sec">Price/Sq. Ft.</span>
                            <span className="right-sec">
                              $
                              {(
                                propertyDetails.purchasedPrice /
                                propertyDetails.suarefootage
                              )?.toFixed(2)}
                              /Sq. Ft.
                            </span>
                          </div>
                          <div className="row-striped col-12">
                            <span className="left-sec">
                              Stabilized Loan to Value
                            </span>
                            <span className="right-sec">
                              {propertyDetails.stabilizedLoanToValue}%
                            </span>
                          </div>
                          <div className="row-striped col-12">
                        <span className="left-sec">Asset Type</span>
                        <span className="right-sec">{propertyDetails.assetType}</span>
                      </div>
                      <div className="row-striped col-12">
                        <span className="left-sec">Primary Property Type</span>
                        <span className="right-sec">{propertyDetails.primaryPropertyType}</span>
                      </div>
                      <div className="row-striped col-12">
                        <span className="left-sec">Primary Sub-Type</span>
                        <span className="right-sec">{propertyDetails.primarySubType}</span>
                      </div>
                      <div className="row-striped col-12">
                        <span className="left-sec">Building Size</span>
                        <span className="right-sec">{propertyDetails.buildingSize}</span>
                      </div>
                      <div className="row-striped col-12">
                        <span className="left-sec">Lot Size (Acres)</span>
                        <span className="right-sec">{propertyDetails.lotSize}</span>
                      </div>
                      <div className="row-striped col-12">
                        <span className="left-sec">Parking Count</span>
                        <span className="right-sec">{propertyDetails.parkingCount}</span>
                      </div>
                        </div>
                      </div>
                    </>
                  )}

                  {/* sponsor or team members section */}
                  {tabNo == 2 && (
                    <>
                      {console.log(tabNo)}
                      <div className="col-12">
                        <p
                          style={{ textAlign: "center" }}
                          id="teamDetails"
                          className="col-md-12 mt-5 propertyDetailsHeader"
                        >
                          Management Team Details
                        </p>
                        <p style={{ textAlign: "center" }}>
                          {propertyDetails?.descriptionOfManagementTeam}
                        </p>
                        {propertyDetails.teamMembers &&
                          propertyDetails.teamMembers.map((img, index) => (
                            <div
                              key={index}
                              style={{ width: "50%", margin: "0 auto" }}
                            >
                              <h5 className="mt-5 membertext">
                                Member {index + 1}
                              </h5>
                              <table className="table member-table">
                                <tbody>
                                  {propertyDetails?.teamMembers[index]
                                    ?.memberName !== null && (
                                    <tr>
                                      <th>Name</th>
                                      <td
                                        className="memberDetailValue"
                                        style={{ textAlign: "left" }}
                                      >
                                        {
                                          propertyDetails?.teamMembers[index]
                                            ?.memberName
                                        }
                                      </td>
                                    </tr>
                                  )}

                                  {propertyDetails?.teamMembers[index]
                                    ?.memberPhoto?.URL !== null && (
                                    <tr>
                                      <th>Photo</th>
                                      <td
                                        className="memberDetailValue"
                                        style={{ textAlign: "left" }}
                                      >
                                        <img
                                          style={{ height: "100px" }}
                                          src={
                                            propertyDetails?.teamMembers[index]
                                              ?.memberPhoto?.URL
                                          }
                                          alt="Member Photo"
                                        />
                                      </td>
                                    </tr>
                                  )}

                                  {propertyDetails?.teamMembers[index]
                                    ?.memberContact !== null && (
                                    <>
                                      <tr>
                                        <th>Contact</th>
                                        <td
                                          className="memberDetailValue"
                                          style={{ textAlign: "justify" }}
                                        >
                                          {
                                            propertyDetails?.teamMembers[index]
                                              ?.memberContact
                                          }
                                        </td>
                                      </tr>
                                    </>
                                  )}

                                  {propertyDetails?.teamMembers[index]
                                    ?.memberRole !== null && (
                                    <>
                                      <tr>
                                        <th>Role</th>
                                        <td
                                          className="memberDetailValue"
                                          style={{ textAlign: "justify" }}
                                        >
                                          {
                                            propertyDetails?.teamMembers[index]
                                              ?.memberRole
                                          }
                                        </td>
                                      </tr>
                                    </>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          ))}
                      </div>
                    </>
                  )}

                  {/* diligence report Section */}
                  {tabNo == 3 && (
                    <>
                      <div className="summary">
                        <h4>{propertyDetails.diligenceReport}</h4>
                        <br />
                        <img
                          src={propertyDetails.diligenceReportFile?.URL}
                          alt="diligence report"
                          style={{ width: "500px", height: "300px" }}
                        />
                      </div>
                    </>
                  )}

                  {/* financial Section */}
                  {tabNo == 4 && (
                    <>
                      <div className="summary">
                        <h4>{propertyDetails.financials}</h4>
                        <br />
                        <img
                          src={propertyDetails.financialsFile?.URL}
                          alt="financials report"
                          style={{ width: "500px", height: "300px" }}
                        />
                      </div>
                    </>
                  )}

                  {/* Location Section */}
                  {tabNo == 5 && (
                    <>
                      <div className="summary">
                        <h4>{propertyDetails.location}</h4>
                        <br />
                        <img
                          src={propertyDetails.locationFile?.URL}
                          alt="Location report"
                          style={{ width: "500px", height: "300px" }}
                        />
                      </div>
                    </>
                  )}

                  {/* Document Section */}
                  {tabNo == 6 && (
                    <>
                      <div
                        className="col-sm-6 offset-sm-3"
                        style={{ paddingLeft: "20px" }}
                      >
                        <div className="card mb-5">
                          <div
                            className="card-header fw-bold text-dark text-center h5 p-3"
                            style={{ background: "#E0E0E0" }}
                          >
                            DOCUMENTS
                          </div>
                          <div
                            className="card-body"
                            style={{ background: "#F8F8F8" }}
                          >
                            <table className="table">
                              <tbody>
                                <tr>
                                  {propertyDetails.propertyDetailsFile?.length >
                                    0 && (
                                    <>
                                      <th
                                        className="text-start"
                                        style={{ minWidth: "150px" }}
                                      >
                                        Property Details
                                        <br />
                                        {/* <small className="text-muted">
                                    File Name:{" "}
                                    { propertyDetails.propertyDetailsFile[
                                        propertyDetails.propertyDetailsFile
                                          .length - 1
                                      ]?.name
                                    }
                                  </small> */}
                                        {/* <br /> */}
                                        {/* <small className="text-muted">
                                    File Type:{" "}
                                    {
                                      propertyDetails.propertyDetailsFile[
                                        propertyDetails.propertyDetailsFile
                                          .length - 1
                                      ]?.type
                                    }
                                  </small> */}
                                      </th>
                                      {userInfo?._id && (
                                        <td style={{ textAlign: "right" }}>
                                          {" "}
                                          {
                                            <a
                                              className="btn btn-outline-info text-white btn-sm px-4 rounded-pill fw-bold"
                                              style={{ background: "#149EC3" }}
                                              target="_blank"
                                              href={
                                                propertyDetails
                                                  .propertyDetailsFile[
                                                  propertyDetails
                                                    .propertyDetailsFile
                                                    .length - 1
                                                ]?.URL
                                              }
                                            >
                                              DOWNLOAD
                                            </a>
                                          }
                                        </td>
                                      )}
                                    </>
                                  )}
                                </tr>
                                <tr>
                                  {propertyDetails.financialProjectionsFile
                                    ?.length > 0 && (
                                    <>
                                      <th className="text-start">
                                        Financial Projections
                                        <br />
                                        {/* <small className="text-muted">
                                    File Name:{" "}
                                    { propertyDetails.financialProjectionsFile[
                                        propertyDetails.financialProjectionsFile
                                          .length - 1
                                      ]?.name
                                    }
                                  </small> */}
                                        {/* <br /> */}
                                        {/* <small className="text-muted">
                                    File Type:{" "}
                                    {
                                      propertyDetails
                                        .financialProjectionsFile[
                                        propertyDetails
                                          .financialProjectionsFile.length -
                                          1
                                      ]?.type
                                    }
                                  </small> */}
                                      </th>
                                      {userInfo?._id && (
                                        <td style={{ textAlign: "right" }}>
                                          {" "}
                                          {propertyDetails
                                            .financialProjectionsFile?.length >
                                            0 && (
                                            <a
                                              className="btn btn-outline-info text-white btn-sm px-4 rounded-pill fw-bold"
                                              style={{ background: "#149EC3" }}
                                              target="_blank"
                                              href={
                                                propertyDetails
                                                  .financialProjectionsFile[
                                                  propertyDetails
                                                    .financialProjectionsFile
                                                    .length - 1
                                                ]?.URL
                                              }
                                            >
                                              DOWNLOAD
                                            </a>
                                          )}
                                        </td>
                                      )}
                                    </>
                                  )}
                                </tr>
                                <tr>
                                  {propertyDetails.privatePlacementFile
                                    ?.length > 0 && (
                                    <>
                                      <th className="text-start">
                                        Private Placement
                                        <br />
                                        {/* <small className="text-muted">
                                    File Name:{" "}
                                    { propertyDetails.privatePlacementFile[
                                        propertyDetails.privatePlacementFile
                                          .length - 1
                                      ]?.name
                                    }
                                  </small> */}
                                        {/* <br /> */}
                                        {/* <small className="text-muted">
                                    File Type:{" "}
                                    {
                                      propertyDetails.privatePlacementFile[
                                        propertyDetails.privatePlacementFile
                                          .length - 1
                                      ]?.type
                                    }
                                  </small> */}
                                      </th>
                                      {userInfo?._id && (
                                        <td style={{ textAlign: "right" }}>
                                          {" "}
                                          {propertyDetails.privatePlacementFile
                                            ?.length > 0 && (
                                            <a
                                              className="btn btn-outline-info text-white btn-sm px-4 rounded-pill fw-bold"
                                              style={{ background: "#149EC3" }}
                                              target="_blank"
                                              href={
                                                propertyDetails
                                                  .privatePlacementFile[
                                                  propertyDetails
                                                    .privatePlacementFile
                                                    .length - 1
                                                ]?.URL
                                              }
                                            >
                                              DOWNLOAD
                                            </a>
                                          )}
                                        </td>
                                      )}
                                    </>
                                  )}
                                </tr>
                                {propertyDetails.subscriptionAgreementFile
                                  ?.length > 0 && (
                                  <>
                                    <tr>
                                      <th className="text-start">
                                        Subscription Aggreement
                                        <br />
                                        {/* <small className="text-muted">
                                    File Name:{" "}
                                    { propertyDetails.subscriptionAgreementFile[
                                        propertyDetails.subscriptionAgreementFile
                                          .length - 1
                                      ]?.name
                                    }
                                  </small> */}
                                        {/* <br /> */}
                                        {/* <small className="text-muted">
                                    File Type:{" "}
                                    {
                                      propertyDetails
                                        .subscriptionAgreementFile[
                                        propertyDetails
                                          .subscriptionAgreementFile
                                          .length - 1
                                      ]?.type
                                    }
                                  </small> */}
                                      </th>
                                      {userInfo?._id && (
                                        <td style={{ textAlign: "right" }}>
                                          {" "}
                                          {propertyDetails
                                            .subscriptionAgreementFile?.length >
                                            0 && (
                                            <a
                                              className="btn btn-outline-info text-white btn-sm px-4 rounded-pill fw-bold"
                                              style={{ background: "#149EC3" }}
                                              target="_blank"
                                              href={
                                                propertyDetails
                                                  .subscriptionAgreementFile[
                                                  propertyDetails
                                                    .subscriptionAgreementFile
                                                    .length - 1
                                                ]?.URL
                                              }
                                            >
                                              DOWNLOAD
                                            </a>
                                          )}
                                        </td>
                                      )}
                                    </tr>
                                  </>
                                )}

                                {propertyDetails.operatingAgreementFile
                                  ?.length > 0 && (
                                  <>
                                    <tr>
                                      <th className="text-start">
                                        Operating Agreement
                                        <br />
                                        {/* <small className="text-muted">
                                    File Name:{" "}
                                    { propertyDetails.operatingAgreementFile[
                                        propertyDetails.operatingAgreementFile
                                          .length - 1
                                      ]?.name
                                    }
                                  </small> */}
                                        {/* <br /> */}
                                        {/* <small className="text-muted">
                                    File Type:
                                    {
                                      propertyDetails
                                        .operatingAgreementFile[
                                        propertyDetails
                                          .operatingAgreementFile.length - 1
                                      ]?.type
                                    }
                                  </small> */}
                                      </th>
                                      {userInfo?._id && (
                                        <td style={{ textAlign: "right" }}>
                                          {" "}
                                          {propertyDetails
                                            .operatingAgreementFile?.length >
                                            0 && (
                                            <a
                                              className="btn btn-outline-info text-white btn-sm px-4 rounded-pill fw-bold"
                                              style={{ background: "#149EC3" }}
                                              target="_blank"
                                              href={
                                                propertyDetails
                                                  .operatingAgreementFile[
                                                  propertyDetails
                                                    .operatingAgreementFile
                                                    .length - 1
                                                ]?.URL
                                              }
                                            >
                                              DOWNLOAD
                                            </a>
                                          )}
                                        </td>
                                      )}
                                    </tr>
                                  </>
                                )}

                                {propertyDetails.otherDocumentsFile?.length >
                                  0 && (
                                  <>
                                    <tr>
                                      <th className="text-start">
                                        Other Documents
                                        <br />
                                        {/* <small className="text-muted">
                                    File Name:{" "}
                                    { propertyDetails.otherDocumentsFile[
                                        propertyDetails.otherDocumentsFile
                                          .length - 1
                                      ]?.name
                                    }
                                  </small> */}
                                        {/* <br /> */}
                                        {/* <small className="text-muted">
                                    File Type:
                                    {
                                      propertyDetails
                                        .otherDocumentsFile[
                                        propertyDetails
                                          .otherDocumentsFile.length - 1
                                      ]?.type
                                    }
                                  </small> */}
                                      </th>
                                      {userInfo?._id && (
                                        <td style={{ textAlign: "right" }}>
                                          {" "}
                                          {propertyDetails.otherDocumentsFile
                                            ?.length > 0 &&
                                            propertyDetails.otherDocumentsFile.map(
                                              (doc, index) => (
                                                <a
                                                  key={index}
                                                  className="btn btn-outline-info text-white btn-sm px-4 rounded-pill fw-bold"
                                                  target="_blank"
                                                  style={{
                                                    borderRadius: "20px",
                                                    marginBottom: "10px",
                                                    background: "#149EC3",
                                                  }}
                                                  href={doc?.URL}
                                                >
                                                  {doc.name}
                                                </a>
                                              )
                                            )}
                                        </td>
                                      )}
                                    </tr>
                                  </>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {/* FAQ Section */}
                  {tabNo == 7 && (
                    <>
                      <div className="summary">
                        <h4>{propertyDetails.FAQs}</h4>
                        <br />
                        {propertyDetails.FAQsFile?.URL && (
                          <img
                            src={propertyDetails.FAQsFile?.URL}
                            alt="Location report"
                            style={{ width: "500px", height: "300px" }}
                          />
                        )}
                      </div>
                    </>
                  )}

                  {/* Link Section */}
                  {tabNo == 8 && (
                    <>
                      <div className="summary">
                        <br />
                        {propertyDetails?.facebook != null ? <span>
                          <span>Facebook: </span> <u><a target="_blank"
                            href={propertyDetails?.facebook}
                          >{propertyDetails?.facebook}</a></u>
                        </span> : ""}
                        <br />
                        {propertyDetails?.linkedin != null ? <span>
                          <span>Linkedin: </span> <u><a target="_blank"
                            href={propertyDetails?.linkedin}
                          >{propertyDetails?.linkedin}</a></u>
                        </span> : ""}
                        <br />
                        {propertyDetails?.twitter != null ? <span>
                          <span>Twitter: </span> <u><a target="_blank"
                            href={propertyDetails?.twitter}
                          >{propertyDetails?.twitter}</a></u>
                        </span> : ""}
                        <br />
                        {propertyDetails?.otherLinks != null ? <span>
                          <span>Other links: </span> <u><a target="_blank"
                            href={propertyDetails?.otherLinks}
                          >{propertyDetails?.otherLinks}</a></u>
                        </span> : ""}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default PropertyDetails;
