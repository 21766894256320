import React, { useState, useEffect } from "react";
import { Row, Col, Card, Typography, Tabs, Button, Spin, Table } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { AuthService } from "../Shared/Auth.service";
import { useHistory, useLocation } from "react-router-dom";
import { SharedService } from "../Shared/Shared.service";
import { User } from "../Shared/interfaces";
import { AdminService } from "../IssuerSuperAdmin/Admin/Admin.service";
import { IssuerTokenAdminService } from "../IssuerTokenAdmin/IssuerTokenAdmin.service";

const { Title } = Typography;
const { TabPane } = Tabs;

const adminService = new AdminService();
const sharedService = new SharedService();
const issuerTokenAdminService = new IssuerTokenAdminService();

const TenantList = (props:any) => {
    const {selectedTenant} = props
    const history = useHistory();
    const [admins, setAdmins] = useState<User[]>();
    const [tenantDelete, setTenantDelete] = useState(false);
    const [tenantList, setTenantList] = useState<any>([]);
    const [adminRoles, setAdminRoles] = useState<any[]>();

    const dataSource = [
      {
        key: '1',
        tenantname: 'Tenant one',
        startdate: new Date().toLocaleString(),
        enddate: new Date().toLocaleString(),
        description: '10 Downing Street',
      },
    ];
    
    const columns = [
      {
        title: 'Tenant Name',
        dataIndex: 'tenantname',
        key: 'tenantname',
      },
      {
        title: 'Start Date',
        dataIndex: 'startdate',
        key: 'startdate',
      },
      {
        title: 'End Date',
        dataIndex: 'enddate',
        key: 'enddate',
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
      },
      {
        title: 'Action',
        key: 'action',
        render: (record) => {
            return (
              <>
                  <Button
                    type="link"
                    onClick={() => deleteTenant(record)}
                  >
                    Delete
                  </Button>
              </>
            );
          },
      },
    ];

    useEffect(() => {
      (async () => {
        const response = await adminService.getAdmins();
  
        const _admins: User[] = response.data;
  
        const _adminRoles = sharedService.adminRolesOptions.map((roleOpt) => {
          const result = sharedService.clone(roleOpt);
          result["total"] = _admins.filter((admin) =>
            admin.roles.includes(roleOpt.value as any)
          ).length;
  
          return result;
        });
  
        console.log(_adminRoles);
  
        setAdmins(_admins);
        setAdminRoles(_adminRoles);
      })();


      (async()=> {
        const tenantList = await issuerTokenAdminService.getTenants();
        console.log('listing tenants..',tenantList)
        setTenantList(tenantList.data)
      })();

    }, [tenantDelete]);

    const deleteTenant = async(record) => {
      console.log(record)
      const tenant = await issuerTokenAdminService.deleteTenant(record.key);
      console.log(tenant)
      setTenantDelete((prev) => !prev);
    }

    const editTenant = async(record) => {
      console.log('edit records' ,record)
      selectedTenant(tenantList.find(t => t._id == record.key))
      // history.push(`/add-tenant/${record.key}`)
    }

    return (
        <>
        <br />
          <br />
          <Row justify="center">
            <Col span={23}>
              {!admins && (
                <div style={{ textAlign: "center" }}>
                  <Spin size="large" />
                </div>
              )}
              {admins && (
                <Card>
                  <Title level={1} style={{ textAlign: "center" }}>
                    Tenants
                  </Title>
                  <Row>
                    <Col span={3} offset={21} style={{display:'flex', justifyContent:'end'}}>
                      <Button type="primary" size='large' onClick={() => history.push('/tenants/add-tenant')}>
                        Add Tenant
                      </Button>
                    </Col>
                  </Row>
                  <Table dataSource={tenantList.map(t => {
                    return { 
                      key: t._id,
                      tenantname: t.tenantName,
                      startdate: new Date(t.leaseStartDate).toLocaleDateString(),
                      enddate:  new Date(t.leaseEndDate).toLocaleDateString(),
                      description: t.tenantDescription,}
                  } )} columns={columns} 
                  
                  onRow={(record) => {
                    return {
                      onDoubleClick: () => {
                        editTenant(record);
                        
                      },
                    };
                  }}
                  />
    
                  </Card>
                  )}
                </Col>
                </Row>
    
        </>
      )

}

export default TenantList