import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Typography, Button, Form, Radio, Input, Select, Checkbox, message, Spin, notification } from 'antd';
import { TeamRole, TeamDepartment, User } from '../../../../Shared/interfaces';
import { useHistory, useParams } from 'react-router-dom';
import { SharedService } from '../../../../Shared/Shared.service';
import { IssuerSuperAdminService } from '../../../IssuerSuperAdmin.service';

const {Title} = Typography;
const { Option } = Select;


const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
};



// const adminService = new AdminService();
const issuerSuperAdminService = new IssuerSuperAdminService();
const sharedService = new SharedService();


export default function EditMember() {

  const [form] = Form.useForm();

  const [teamRoles, setTeamRoles] = useState<TeamRole[] | null>();
  // const [companyMember, setCompanyMember] = useState<User>();
  const [teamDepartments, setTeamDepartments] = useState<TeamDepartment[]>();

  const history = useHistory();

  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);

  const {id} = useParams<{id: string}>();


  useEffect(() => {
    (async () => {

      const [_companyMember, _teamDepartments] = (await Promise.all([
        issuerSuperAdminService.getCompanyMember(id),
        issuerSuperAdminService.getTeamDepartments()
      ])).map(res => res.data);

 
      let team: string | undefined = undefined;

      if((_companyMember as User).roles.includes('contractor_consultant_advisor')) team = 'contractor_consultant_advisor';
      else if((_companyMember as User).roles.includes('employee')) team = 'employee';


      form.setFieldsValue({
        adminRole: (_companyMember as User).roles,
        name: `${(_companyMember as User).firstName} ${(_companyMember as User).lastName}`,
        email: (_companyMember as User).email,
        team,
        corporateRoles: (_companyMember as User).corporateRoles,
        department: (_companyMember as User).departmentId,
      });
      
      if((_companyMember as User).departmentId) {
        await selectTeamDepartments((_companyMember as User).departmentId as string);
        form.setFieldsValue({
          teamRoleId: (_companyMember as User).teamRoleId,
        });
      }
      
      // setCompanyMember(_companyMember);
      setTeamDepartments(_teamDepartments);
      setLoading(false);

    })();
  }, [id]);


  const selectTeamDepartments = async (departmentId: string) => {
    if(!departmentId) return;
    setTeamRoles(undefined);
    const response = await issuerSuperAdminService.getTeamRoles(departmentId);
    const _teamRoles: TeamRole[] = response.data;
    setTeamRoles(_teamRoles);
  }



  const editCompanyMember = async (formValue) => {
    const req = sharedService.clone(formValue);
    req.memberId = id;
    console.log(req);
    setSubmitting(true);
    
    const response = await issuerSuperAdminService.editCompanyMember(req);

    if (response.success) {
      notification.success({message: 'Success', description: 'User Edited successfully'})
      history.push(`/issuer-super-admin/admin/admin-roles`);

    } else {
      notification.error({message: 'Error', description: response.error.message})
    }
    setSubmitting(false);
  }

  
  return (
    <>
      <br/><br/>
      <Row justify="center">
        <Col span={20}>
          {loading && 
            <div style={{textAlign:'center'}}>
              <Spin size='large'/>
            </div>
          }
          {!loading && 
            <Card>
              <Title level={1} style={{textAlign:'center'}}>Edit Company Member</Title>

              <Form labelAlign="left" form={form} onFinish={editCompanyMember}>

                <Form.Item 
                  labelCol={{xs: { span: 24 }, sm: { span: 10, offset: 0 }}}
                  wrapperCol={{xs: { span: 24 }, sm: { span: 10 }}}
                  label={`Name`}
                  name="name">
                  <Input disabled/>
                </Form.Item>

                <Form.Item 
                  labelCol={{xs: { span: 24 }, sm: { span: 10, offset: 0 }}}
                  wrapperCol={{xs: { span: 24 }, sm: { span: 10 }}}
                  label={`Email`}
                  name="email">
                  <Input disabled/>
                </Form.Item>

                <Form.Item
                  labelCol={{xs: { span: 24 }, sm: { span: 10, offset: 0 }}}
                  wrapperCol={{xs: { span: 24 }, sm: { span: 14 }}}
                  label="Admin Roles"
                  name='adminRole'
                  rules={[
                    {
                      required: true,
                      message: 'This field is required'
                    }
                  ]}>
                  <Checkbox.Group>
                    <Row>
                      {sharedService.adminRolesOptions2.map((role) => (
                        <Col key={role.value} span={24}>
                          <Checkbox disabled style={{ lineHeight: '32px' }} value={role.value}>
                            {role.name}
                          </Checkbox>
                        </Col>
                      ))}
                    </Row>
                  </Checkbox.Group>
                </Form.Item>


                <Form.Item noStyle
                  shouldUpdate={(prevValues, currentValues) => prevValues.adminRole !== currentValues.adminRole}
                >

                  {({ getFieldValue }) => (
                    (getFieldValue('adminRole') !== 'issuer counsel')?
                      <>
                        <Form.Item
                          labelCol={{xs: { span: 24 }, sm: { span: 10, offset: 0 }}}
                          wrapperCol={{xs: { span: 24 }, sm: { span: 10 }}}
                          label="Select one"
                          name='team'
                          rules={[
                            {
                              required: true,
                              message: 'This field is required'
                            }
                          ]}>
                          <Radio.Group onChange={() => {form.resetFields(['department', 'role', 'teamRoleId']); setTeamRoles(undefined)}}>
                            <Radio key='employee' value='employee'>
                              Employee
                            </Radio>
                            <Radio key='contractor_consultant_advisor' value='contractor_consultant_advisor'>
                            Contractor/Consultant/Advisor
                            </Radio>
                          </Radio.Group>
                        </Form.Item>
          
                        <Form.Item noStyle
                          shouldUpdate={(prevValues, currentValues) => prevValues.team !== currentValues.team}
                        >
                          {({ getFieldValue }) => (
                            <Form.Item 
                              style={{marginBottom:0}}
                              labelCol={{xs: { span: 24 }, sm: { span: 10, offset: 0 }}}
                              wrapperCol={{xs: { span: 24 }, sm: { span: 10 }}}
                              label='Department name'
                              name="department"
                              validateStatus={teamDepartments? '' : 'validating'}
                              hasFeedback
                              help= {teamDepartments? '' : "Loading Departments..."}
                              rules={[
                                {
                                  required: true,
                                  message: 'This field is required',
                                }
                              ]}>
                              <Select showSearch placeholder="Select Department" onChange={e => selectTeamDepartments(e as string)}>
                                {teamDepartments?.filter(teamDepartment => teamDepartment.team === getFieldValue('team')).map(teamDepartment => 
                                  <Option key={teamDepartment._id} value={teamDepartment._id}>{teamDepartment.name}</Option>
                                )}
                              </Select>
                            </Form.Item>
                          )}
                        </Form.Item>
                          
                          <Form.Item noStyle
                            shouldUpdate={(prevValues, currentValues) => prevValues.department !== currentValues.department}
                          >
                            {({ getFieldValue }) => (
                              <Form.Item 
                                style={{marginBottom:0}}
                                labelCol={{xs: { span: 24 }, sm: { span: 10, offset: 0 }}}
                                wrapperCol={{xs: { span: 24 }, sm: { span: 10 }}}
                                label='Role'
                                name='teamRoleId'
                                validateStatus={(getFieldValue('department') && !teamRoles)? 'validating' : ''}
                                hasFeedback
                                help= {(getFieldValue('department') && !teamRoles)? 'Loading Roles...' : ''}
                                rules={[
                                  {
                                    required: true,
                                    message: 'This field is required',
                                  }
                                ]}>
                                <Select showSearch placeholder="Select Role">
                                  {teamRoles?.filter(teamRole => teamRole.departmentId === getFieldValue('department')).map(teamRole => 
                                    <Option key={teamRole._id} value={teamRole._id}>{teamRole.name}</Option>
                                  )}
                                </Select>
                              </Form.Item>
                            )}
                          </Form.Item>
          
                          <Form.Item noStyle
                            shouldUpdate={(prevValues, currentValues) => prevValues.adminRole !== currentValues.adminRole}
                          >
                            {({ getFieldValue }) => (
                                <Form.Item
                                  labelCol={{xs: { span: 24 }, sm: { span: 10, offset: 0 }}}
                                  wrapperCol={{xs: { span: 24 }, sm: { span: 14 }}}
                                  label={'Corporate Role if Affiliate'}
                                  name="corporateRoles"
                                >
                                    <Checkbox.Group>
                                      <Row>
                                        <Col span={24}>
                                          <Checkbox value="director" style={{ lineHeight: '32px' }}>
                                            Director
                                          </Checkbox>
                                        </Col>
                                        <Col span={24}>
                                          <Checkbox value="large shareholder" style={{ lineHeight: '32px' }}>
                                            Large Shareholder
                                          </Checkbox>
                                        </Col>
                                        <Col span={24}>
                                          <Checkbox value="executive officer" style={{ lineHeight: '32px' }}>
                                            Executive Officer
                                          </Checkbox>
                                        </Col>
                                        <Col span={24}>
                                          <Checkbox value="inital member" style={{ lineHeight: '32px' }}>
                                            Initial Member
                                          </Checkbox>
                                        </Col>
                                      </Row>
                                    </Checkbox.Group>
                                </Form.Item>
                            )}
          
                          </Form.Item>


                      </>                      
                      : null
                  )}

                </Form.Item>


                  <div style={{textAlign:'right'}}>
                    <Button style={{marginRight:'5px'}} htmlType='button' size='large' onClick={()=> history.push(`/issuer-super-admin/admin/admin-roles`)}>
                      BACK
                    </Button>
                    <Button htmlType='submit' size='large' type='primary' loading={submitting}>
                      EDIT COMPANY MEMBER
                    </Button>
                  </div>
              </Form>
            </Card>
          
          }
        </Col>
      </Row>
    </>
  );

}
