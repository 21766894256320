import React, { useState, useEffect } from 'react';
import CardHOC from "../../CardHOC";
import {AuthService} from "../../Shared/Auth.service";
import { IssuerSuperAdminService } from '../IssuerSuperAdmin.service';
import {Button, Form, Input, message, Modal, notification, Spin, Table} from "antd";
import { useHistory } from 'react-router-dom';
import moment from 'moment';

const issuerSuperAdminService = new IssuerSuperAdminService();
const useUserContext = () => new AuthService().useUserContext();

export default function() {
  const {userInfo} = useUserContext();
  const history = useHistory();

  const [loading, setLoading] = useState<boolean>(false);
  const [invites, setInvites] = useState([]);
  const [isInviteModalVisible, setIsInviteModalVisible] = useState(false);
  const [inviteNo, setInviteNo] = useState(0);
  const [modalForm] = Form.useForm();
  const [series, setSeries] = useState();
  const [seriesId, setSeriesId] = useState();

  useEffect(() => {
    (async () => {
      if(!userInfo) return;

      setLoading(true);
      const response = await issuerSuperAdminService.getInvites(userInfo?._id);
      if(response.data){
        setInvites(response.data.invites);
        setInviteNo(+response.data.invites.length + 1);
        setSeries(response.data.seriesName);
        setSeriesId(response.data.seriesId);
      }
      setLoading(false);

    })();
  }, [userInfo, isInviteModalVisible]);

  const openInviteModal = () => {
    modalForm.resetFields();
    setIsInviteModalVisible(true);
  }

  const inviteInvestors = async(formValue) => {
    setLoading(true);

    const response = await issuerSuperAdminService.inviteInvestor({
      firstName: formValue.firstName,
      lastName: formValue.lastName,
      email: formValue.email,
      inviteNo: inviteNo,
      seriesId: seriesId,
      userId: userInfo?._id
    });

    if(response.success) {
      notification.success({
        message: 'Success',
        description: 'Invite sent to Investor Successfully'
      });
      setIsInviteModalVisible(false);
    } else {
      console.error(response.error);
      message.error(response.error.message);
    }

    setLoading(false);
  }

  const seriesName = (value) => `Crypto Asset Rating ${value}`;

  const columns = [
    {
      title: 'Invites Number',
      dataIndex: 'inviteNo',
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      render: (value)=>value
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      render: (value)=>value
    },
    {
      title: 'Email Address',
      dataIndex: 'email',
      render: (value)=>value
    },
    {
      title: 'CREATED ON',
      dataIndex: 'creationTS',
      render: (value: number) => moment(value).format('lll')
    }
  ];

  return (
    <CardHOC
      heading={'Invites List'}
      component={<>

        {loading &&
          <div style={{textAlign:'center'}}>
            <br/><br/>
            <Spin size='large'/>
          </div>
        }

        {!loading &&
          <>
            <div style={{textAlign:'right', marginBottom: '10px'}}>
              <Button type="primary" size='large' onClick={() => openInviteModal()}>
                Invite Investors
              </Button>
            </div>

            <Table
              columns={columns}
              dataSource={invites}
              rowKey='_id'
              pagination={false}
            />
          </>
        }

        <Modal
          title={`Invite Investor to ${seriesName(series)}`}
          okText='SUBMIT'
          cancelText='CANCEL'
          visible={isInviteModalVisible}
          onOk={()=> modalForm.submit()}
          onCancel={()=>{
            setIsInviteModalVisible(false);
          }}
          okButtonProps={{
            loading: loading,
          }}
        >

        <Form
          form={modalForm}
          autoComplete={'off'}
          onFinish={inviteInvestors}
        >
          <Form.Item
            name={'firstName'}
            label={'First Name'}
            wrapperCol={{ xs: { span: 24 }, sm: { span: 14 } }}
            rules={[
              {
                required: true,
                message: "This field is required",
              },
            ]}
          >
            <Input placeholder={'First Name'}/>
          </Form.Item>

          <Form.Item
            name={'lastName'}
            label={'Last Name'}
            wrapperCol={{ xs: { span: 24 }, sm: { span: 14 } }}
            rules={[
              {
                required: true,
                message: "This field is required",
              },
            ]}
          >
            <Input placeholder={'Last Name'}/>
          </Form.Item>
          <Form.Item
            name={'email'}
            label={'Email'}
            wrapperCol={{ xs: { span: 24 }, sm: { span: 14 } }}
            rules={[
              {
                required: true,
                message: "This field is required",
              },
            ]}
          >
            <Input placeholder={'Email'}/>
          </Form.Item>
        </Form>

        </Modal>
      </>}
    />
  );
}