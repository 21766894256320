import React, { useState, useEffect } from "react";
import {
  List,
  Button,
  Form,
  Radio,
  Input,
  Collapse,
  Progress,
  Space,
  Modal,
  Popconfirm,
} from "antd";
import { IssuerSuperAdminService } from "./../IssuerSuperAdmin.service";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { SharedService } from "../../Shared/Shared.service";
import { AuthService } from "../../Shared/Auth.service";
import CompanyDetails from "../../Investor/Home/CompanyDetails";

const auth = new AuthService();
const useUserContext = () => auth.useUserContext();

const sharedService = new SharedService();
const { Panel } = Collapse;
const issuerSuperAdminService = new IssuerSuperAdminService();

function Data() {
  this.question = "";
  this.type = "";
  this.category = "";
  this.answer = "";
  this.isAnswered = false;
  this.hidden = false;
}
export default () => {
  const { userInfo } = useUserContext();
  const [questions, setQuestions] = useState([]);
  const [generalQuestions, setGeneralQuestions] = useState([]);
  const [offeringDocsQuestions, setOfferingDocsQuestions] = useState([]);
  const [socialMediaQuestions, setSocialMediaQuestions] = useState([]);
  const [businessQuestions, setBusinessQuestions] = useState([]);
  const [displayFlag, setDisplayFlag] = useState(false);
  const [questionToAnswer, setQuestionToAnswer] = useState(null);
  const [options, setOptionsView] = useState(false);
  const [textArea, setTextAreaView] = useState(false);
  const [textBox, setTextBoxView] = useState(false);
  const [attachment, setAttachmentView] = useState(false);
  const [submiting, setSubmiting] = useState(false);
  const [content, setContent] = useState("");
  const [issuerWebpageModalView, setIssuerWebpageModalView] = useState(false);
  const [form] = Form.useForm();
  const [documentsToSubmit, setDocumentsToSubmit] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [filesObj, setFilesObj] = useState({
    attachement: {
      uploading: false,
      uploadFilePercentage: 0,
      URL: null,
      name: null,
      type: null,
    },
  });

  const initialFileObj = {
    attachement: {
      uploading: false,
      uploadFilePercentage: 0,
      URL: null,
      name: null,
      type: null,
    },
  };

  useEffect(() => {
    (async () => {
      const response = await issuerSuperAdminService.getQuestionsToView({
        company: userInfo.companyName,
      });
      const ques = response.data.filter((que) => que.isAnswered !== false);
      setQuestions(ques);
      applyFilter(ques);
    })();
  }, [userInfo.companyName, displayFlag]);

  const cancel = () => {
    form.resetFields();
    setDisplayFlag(false);
  };

  const applyFilter = (ques) => {
    const generalQuestions = ques?.filter(
      (question) => question.category === "General"
    );
    setGeneralQuestions(generalQuestions);
    const offeringDocsQuestions = ques?.filter(
      (question) => question.category === "Offering docs"
    );
    setOfferingDocsQuestions(offeringDocsQuestions);
    const businessQuestions = ques?.filter(
      (question) => question.category === "Business"
    );
    setBusinessQuestions(businessQuestions);
    const socialMediaQuestions = ques?.filter(
      (question) => question.category === "Social Media"
    );
    setSocialMediaQuestions(socialMediaQuestions);
  };
  const answerQuestion = (question) => {
    setDisplayFlag(true);
    setQuestionToAnswer(question);
    if (question.type === "option") {
      setOptionsView(true);
      form.setFieldsValue({ option: question.answer });
    }
    if (question.type === "textArea") {
      setTextAreaView(true);
      setContent(question.answer);
      form.setFieldsValue({ textArea: question.answer });
    }
    if (question.type === "textBox") {
      setTextBoxView(true);
      form.setFieldsValue({ textBox: question.answer });
    }
    if (question.type === "attachement") {
      let arr = question.answer.map((data) => data.name);
      setDocuments(arr);
      setDocumentsToSubmit(question.answer);
      form.setFieldsValue({
        attachment: question.answer[0]?.name,
      });
      setFilesObj({
        attachement: {
          uploading: false,
          uploadFilePercentage: 100,
          URL: null,
          name: null,
          type: null,
        },
      });
      setAttachmentView(true);
    }
  };
  const hideQuestion = (que) => {
    (async () => {
      var data = new Data();
      data.question = que.question;
      data.category = que.category;
      data.type = que.type;
      data.answer = que.answer;
      data.isAnswered = true;
      data.company = userInfo.companyName;
      data.hidden = true;

      setSubmiting(true);
      const response = await issuerSuperAdminService.setAnswer(data);
      if (response.success) {
        setSubmiting(false);
        que.hidden = true;
      } else {
        que.hidden = false;
        setSubmiting(false);
      }
    })();
  };
  const unhideQuestion = (que) => {
    (async () => {
      var data = new Data();
      data.question = que.question;
      data.category = que.category;
      data.type = que.type;
      data.answer = que.answer;
      data.isAnswered = true;
      data.company = userInfo.companyName;
      data.hidden = false;
      setSubmiting(true);
      const response = await issuerSuperAdminService.setAnswer(data);
      if (response.success) {
        setSubmiting(false);
        que.hidden = false;
      } else {
        que.hidden = true;
        setSubmiting(false);
      }
    })();
  };
  const onFinished = (values) => {
    var data = new Data();
    data.question = questionToAnswer.question;
    data.category = questionToAnswer.category;
    data.type = questionToAnswer.type;
    data.isAnswered = true;
    data.partOfWebpage = questionToAnswer.partOfWebpage;
    data.company = userInfo.companyName;
    data.companyId = userInfo.company.id;
    data.hidden = false;
    if (questionToAnswer.type === "textBox") {
      if (values.textBox) {
        if (values.textBox.trim().length <= 0) {
          alert("Answer cannot be empty!!");
          return;
        }
      } else {
        alert("Answer cannot be empty!!");
        return;
      }
      data.answer = values.textBox.trim();
    }
    if (questionToAnswer.type === "textArea") {
      if (values.textArea) {
        data.answer = content;
      } else {
        alert("Answer cannot be empty!!");
        return;
      }
    }
    if (questionToAnswer.type === "option") {
      if (values.option) {
        data.answer = values.option;
      } else {
        alert("Please select a option !");
        return;
      }
    }
    if (questionToAnswer.type === "attachement") {
      if (documentsToSubmit) {
        data.answer = documentsToSubmit;
      } else {
        alert("Please select a file !");
        return;
      }
    }
    (async () => {
      const response = await issuerSuperAdminService.setAnswer(data);
      if (response.success) {
        setOptionsView(false);
        setTextAreaView(false);
        setTextBoxView(false);
        setOptionsView(false);
        setAttachmentView(false);
        setDocumentsToSubmit([]);
        setDocuments([]);
        setFilesObj(initialFileObj);
        form.resetFields();
        setDisplayFlag(false);
      }
    })();
  };
  const handleChange = (content, editor) => {
    setContent(content);
  };

  //upload file start

  const uploadFile = (e, key, setFilesObj, form) => {
    if (!e.target.files) return;

    const file = e.target.files[0];

    if (!file) return;

    const fileType = sharedService.getFileType(file.name);
    setFilesObj((prev) => {
      const current = sharedService.clone(prev);
      current[key].URL = null;
      return current;
    });

    issuerSuperAdminService.uploadFile(
      file,
      (uploadFilePercent) => {
        setFilesObj((prev) => {
          const current = sharedService.clone(prev);
          current[key].uploading = true;
          current[key].uploadFilePercentage = uploadFilePercent;
          return current;
        });
      },
      (err) => {
        console.error(err);
        setFilesObj((prev) => {
          const current = sharedService.clone(prev);
          current[key].uploading = false;
          return current;
        });
      },
      async (response) => {
        if (response.success) {
          const URL = response.data.url;
          setFilesObj((prev) => {
            const current = sharedService.clone(prev);
            current[key].URL = URL;
            current[key].uploading = false;
            current[key].name = file.name;
            current[key].type = fileType.toLowerCase();
            return current;
          });
          let fileDetails = { [key]: { URL, name: file.name } };
          form.setFieldsValue(fileDetails);
          setDocuments((prev) => prev.concat(file.name));
          setDocumentsToSubmit((prev) => prev.concat({ URL, name: file.name }));
        } else {
          console.error(response.error);
          setFilesObj((prev) => {
            const current = sharedService.clone(prev);
            current[key].uploading = false;
            return current;
          });
        }
      }
    );
  };

  //upload file ends

  const back = () => {
    setOptionsView(false);
    setTextAreaView(false);
    setTextBoxView(false);
    setOptionsView(false);
    setAttachmentView(false);
    form.resetFields();
    setDisplayFlag(false);
  };

  const deleteItem = (index) => {
    setDocuments(documents.filter((document) => document !== documents[index]));
    setDocumentsToSubmit(
      documentsToSubmit.filter(
        (document) => document !== documentsToSubmit[index]
      )
    );
  };

  return (
    <>
      <br />
      <div hidden={displayFlag}>
        <Collapse>
          <Panel header="General" key="General" style={{ textAlign: "left" }}>
            <List style={{ textAlign: "left" }} loading={!questions}>
              {generalQuestions?.map((question, index) => (
                <List.Item
                  style={{ cursor: "pointer" }}
                  key={index}
                  actions={[
                    <Button
                      type="primary"
                      onClick={() => answerQuestion(generalQuestions[index])}
                      disabled={submiting}
                    >
                      EDIT
                    </Button>,
                    <Button
                      hidden={question.hidden}
                      onClick={() => hideQuestion(generalQuestions[index])}
                      loading={submiting}
                    >
                      HIDE
                    </Button>,
                    <Button
                      hidden={!question.hidden}
                      onClick={() => unhideQuestion(generalQuestions[index])}
                      loading={submiting}
                    >
                      UNHIDE
                    </Button>,
                  ]}
                >
                  <List.Item.Meta
                    title={question.question}
                    description={
                      question.type !== "attachement" ? (
                        <>
                          Answer:{" "}
                          <div
                            dangerouslySetInnerHTML={{
                              __html: question.answer,
                            }}
                          ></div>
                        </>
                      ) : (
                        <>
                          Answer: <br />
                          {question?.answer?.map((a) => (
                            <>
                              <a
                                href={a.URL}
                                rel="noopener noreferrer"
                                target="_blank"
                              >
                                {" "}
                                {a.name}
                              </a>{" "}
                              <br />
                            </>
                          ))}
                        </>
                      )
                    }
                  ></List.Item.Meta>
                </List.Item>
              ))}
            </List>
          </Panel>
        </Collapse>

        <Collapse>
          <Panel header="Business" key="Business" style={{ textAlign: "left" }}>
            <List style={{ textAlign: "left" }} loading={!questions}>
              {businessQuestions?.map((question, index) => (
                <List.Item
                  style={{ cursor: "pointer" }}
                  key={index}
                  actions={[
                    <Button
                      type="primary"
                      onClick={() => answerQuestion(businessQuestions[index])}
                      disabled={submiting}
                    >
                      EDIT
                    </Button>,
                    <Button
                      hidden={question.hidden}
                      onClick={() => hideQuestion(businessQuestions[index])}
                      loading={submiting}
                    >
                      HIDE
                    </Button>,
                    <Button
                      hidden={!question.hidden}
                      onClick={() => unhideQuestion(businessQuestions[index])}
                      loading={submiting}
                    >
                      UNHIDE
                    </Button>,
                  ]}
                >
                  <List.Item.Meta
                    title={question.question}
                    description={
                      question.type !== "attachement" ? (
                        <>
                          Answer:{" "}
                          <div
                            dangerouslySetInnerHTML={{
                              __html: question.answer,
                            }}
                          ></div>
                        </>
                      ) : (
                        <>
                          Answer: <br />
                          {question?.answer?.map((a) => (
                            <>
                              <a
                                href={a.URL}
                                rel="noopener noreferrer"
                                target="_blank"
                              >
                                {" "}
                                {a.name}
                              </a>{" "}
                              <br />
                            </>
                          ))}
                        </>
                      )
                    }
                  ></List.Item.Meta>
                </List.Item>
              ))}
            </List>
          </Panel>
        </Collapse>

        <Collapse>
          <Panel
            header="Social Media"
            key="Social Media"
            style={{ textAlign: "left" }}
          >
            <List style={{ textAlign: "left" }} loading={!questions}>
              {socialMediaQuestions?.map((question, index) => (
                <List.Item
                  style={{ cursor: "pointer" }}
                  key={index}
                  actions={[
                    <Button
                      type="primary"
                      onClick={() => answerQuestion(question)}
                      disabled={submiting}
                    >
                      EDIT
                    </Button>,
                    <Button
                      hidden={question.hidden}
                      onClick={() => hideQuestion(question)}
                      loading={submiting}
                    >
                      HIDE
                    </Button>,
                    <Button
                      hidden={!question.hidden}
                      onClick={() => unhideQuestion(question)}
                      loading={submiting}
                    >
                      UNHIDE
                    </Button>,
                  ]}
                >
                  <List.Item.Meta
                    title={question.question}
                    description={
                      question.type !== "attachement" ? (
                        <>
                          Answer:{" "}
                          <div
                            dangerouslySetInnerHTML={{
                              __html: question.answer,
                            }}
                          ></div>
                        </>
                      ) : (
                        <>
                          Answer: <br />
                          {question?.answer?.map((a) => (
                            <>
                              <a
                                href={a.URL}
                                rel="noopener noreferrer"
                                target="_blank"
                              >
                                {" "}
                                {a.name}
                              </a>{" "}
                              <br />
                            </>
                          ))}
                        </>
                      )
                    }
                  ></List.Item.Meta>
                </List.Item>
              ))}
            </List>
          </Panel>
        </Collapse>

        <Collapse>
          <Panel
            header="Offering Docs"
            key="Offering Docs"
            style={{
              textAlign: "left",
            }}
          >
            <List
              style={{
                textAlign: "left",
              }}
              loading={!questions}
            >
              {offeringDocsQuestions?.map((question, index) => (
                <List.Item
                  style={{ cursor: "pointer" }}
                  key={index}
                  actions={[
                    <Button
                      type="primary"
                      onClick={() => answerQuestion(question)}
                      disabled={submiting}
                    >
                      EDIT
                    </Button>,
                    <Button
                      hidden={question.hidden}
                      onClick={() => hideQuestion(question)}
                      loading={submiting}
                    >
                      HIDE
                    </Button>,
                    <Button
                      hidden={!question.hidden}
                      onClick={() => unhideQuestion(question)}
                      loading={submiting}
                    >
                      UNHIDE
                    </Button>,
                  ]}
                >
                  <List.Item.Meta
                    title={question.question}
                    description={
                      question.type !== "attachement" ? (
                        <>
                          Answer: <br />
                          <div
                            dangerouslySetInnerHTML={{
                              __html: question.answer,
                            }}
                          />
                        </>
                      ) : (
                        <>
                          Answer: <br />
                          {question?.answer?.map((a) => (
                            <>
                              <a
                                href={a.URL}
                                rel="noopener noreferrer"
                                target="_blank"
                              >
                                {" "}
                                {a.name}
                              </a>{" "}
                              <br />
                            </>
                          ))}
                        </>
                      )
                    }
                  ></List.Item.Meta>
                </List.Item>
              ))}
            </List>
          </Panel>
        </Collapse>
      </div>

      <div hidden={!displayFlag}>
        <Form name="answerForm" form={form} onFinish={onFinished}>
          <Form.Item label="Question">
            <label>
              <h1>{questionToAnswer?.question}</h1>
            </label>
          </Form.Item>
          <div hidden={!options}>
            <Form.Item hidden={!options} name="option">
              <Radio.Group>
                <Radio value="yes">Yes</Radio>
                <Radio value="No">No</Radio>
              </Radio.Group>
            </Form.Item>
          </div>
          <div hidden={!textArea}>
            <Form.Item name="textArea">
              <SunEditor setContents={content} onChange={handleChange} />
            </Form.Item>
          </div>
          <div hidden={!textBox}>
            <Form.Item name="textBox">
              <Input></Input>
            </Form.Item>
          </div>
          <div hidden={!attachment}>
            <Form.Item name="attachement">
              <Input
                type="file"
                onChange={(e) => {
                  uploadFile(e, "attachement", setFilesObj, form);
                }}
                disabled={submiting}
              />
              {filesObj.attachement.uploadFilePercentage > 0 && (
                <>
                  <Progress
                    percent={filesObj.attachement.uploadFilePercentage}
                  />
                  <br />
                  <div align="left">
                    Files Uploaded:
                    <List itemLayout="horizontal" style={{ textAlign: "left" }}>
                      {documents?.map((obj, index) => (
                        <List.Item
                          textalign="left"
                          style={{ cursor: "pointer" }}
                          key={index}
                          actions={[
                            <Popconfirm
                              title="This item will be deleted!"
                              onConfirm={() => deleteItem(index)}
                            >
                              <Button type="primary" danger>
                                X
                              </Button>
                            </Popconfirm>,
                          ]}
                        >
                          <List.Item.Meta title={obj} />
                        </List.Item>
                      ))}
                    </List>
                  </div>
                </>
              )}{" "}
            </Form.Item>
          </div>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              UPDATE
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button htmlType="button" onClick={() => back()}>
              BACK
            </Button>
          </Form.Item>
        </Form>
      </div>
      <Modal
        title="Token Issuer Details"
        closable={false}
        visible={issuerWebpageModalView}
        footer={
          <Button
            htmlType="button"
            type="primary"
            onClick={() => setIssuerWebpageModalView(false)}
          >
            OK
          </Button>
        }
        width={1024}
      >
        {issuerWebpageModalView && (
          <CompanyDetails
            company={userInfo.company.name}
            investButtonCallback={() => {
              alert("Only available for investors.");
            }}
          ></CompanyDetails>
        )}
      </Modal>
      <br />
      <div style={{ textAlign: "right" }}>
        <Space>
          <Button href="/issuer-super-admin/information-questions">Back</Button>
          {/* <Button type="primary" onClick={()=>{setIssuerWebpageModalView(true)}} >Preview Offering page</Button> */}
        </Space>
      </div>
    </>
  );
};
