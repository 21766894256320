import React, { useEffect, useState } from 'react';
import { Empty, Divider, Card, Row, Table, Progress ,Statistic , Button, Col} from 'antd';
import { AuthService } from '../../Shared/Auth.service'
import { InvestorService } from '../Investor.service'
import { Link } from 'react-router-dom';
import InvestModel from '../../Shared/InvestModel/InvestModel';

const authService = new AuthService();
const useUserContext = () => authService.useUserContext()
const colorConfiguration = {
    textAlign: 'left',
    color: '#1890ff',
    fontSize: '15px',
    fontWeight: 'bold',
}

export default () => {
    const { userInfo, setUserInfo } = useUserContext();
    const [totalInvestor, setTotalInvestor] = useState();
    const [sharePercentage, setSharePercentage] = useState();
    const [paymentDetails, setPaymentDetails] = useState([]);
    const [lastUpdatedDate, setLastUpdatedDate] = useState('');
    const [tokenDetails, setTokenDetails ] = useState(null);
    const [displayInvestModel, setDisplayInvestModel] = useState(false);
    const [investedTokens, setInvestedTokens] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const transactionDataSource = [];
    const netEstimatedDataSource = [];
    const [investmentCompanyId, setInvestmentCompanyId] = useState('');

    const columns = [
        {
        title: 'Type of Security',
        dataIndex: 'typeOfSecurity',
        key: 'typeOfSecurity',
        },
        {
        title: 'Issuer',
        dataIndex: 'companyDetails.name',
        key: 'company',
        },
        {
        title: 'Token Count',
        dataIndex: 'tokenCount',
        key: 'tokenCount',
        },
        {
        title: 'Total Investors',
        dataIndex: 'totalInvestors',
        key: 'totalInvestors',
        },
        {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        },
        {
        title: 'Lock Period',
        dataIndex: 'lockPeriod',
        key: 'lockPeriod',
        },
        {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        },
        {
        title: 'Price Per Token',
        dataIndex: 'pricePerToken',
        key: 'pricePerToken',
        },
  ];
  useEffect(() => {
    const fetchInvestedTokens = async () => {
      try {
        const investorId = userInfo._id;
        const result = await new InvestorService().getAllInvestedTokenDetailsByInvestorId(investorId);
        if (result.success && result.data) {
            const tokensData = result.data.map(tokenDetails => {
              return {
                key: tokenDetails._id,
                tokenCount: tokenDetails.tokenCount,
                totalInvestors: tokenDetails.totalInvestors,
                description: tokenDetails.description,
                lockPeriod: tokenDetails.lockPeriod,
                status: tokenDetails.status,
                pricePerToken: tokenDetails.pricePerToken,
                typeOfSecurity: tokenDetails.typeOfSecurity,
              };
            });
          
            setInvestedTokens(tokensData);
          }
      } catch (error) {
        console.error('Error fetching invested tokens:', error);
      }
    };

    // Fetch invested tokens when userInfo changes
    if (userInfo) {
      fetchInvestedTokens();
    }
  }, [userInfo]);

    const netEstimatedColumns = [
        {
            title: 'Exchange',
            dataIndex: 'exchange',
            key: 'exchange',
        },
        {
            title: 'Estimated Value',
            dataIndex: 'estimatedValue',
            key: 'estimatedValue',
        }
    ];
    const transactionColumns = [
        {
            title: 'Tokens',
            dataIndex: 'tokens',
            key: 'tokens',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Sent | Recived',
            dataIndex: 'sent|recived',
            key: 'sent|recived',
        },
        {
            title: 'From | To',
            dataIndex: 'from|to',
            key: 'from|to',
        }
    ];

    const tokenDataSource = tokenDetails?.map(token => {
        return {
          key: token._id,
          companyname: token.companyDetails.name  ? token.companyDetails.name : 'NA',
          companyId: token.companyId,
          tokenid: token._id,
          propertyId: token?.propertyDetails?._id,
          tokendate:new Date(token.creationTS).toLocaleString(),
          status: token.status ? token.status.toUpperCase() :'',
          isTokenSaleClosed: token.hasOwnProperty('isTokenSaleClosed') && token.isTokenSaleClosed ? true : false,
        }
      })
    const tokenColumns = [
        {
          title: 'Company Name',
          dataIndex: 'companyname',
          key: 'companyname',
        },
        {
          title: 'Token',
          dataIndex: 'tokenid',
          key: 'tokenid',
        },
        {
          title: 'Token Date',
          dataIndex: 'tokendate',
          key: 'tokendate',
        },
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
        },
        {
          title: 'Property link',
          key: 'propertylink',
          render: (record) => {
              return (
                <>
                    <Link to={`/property-details?propertyId=${record.propertyId}`}><Button type="link">Link</Button></Link>
                </>
              );
            },
        },
        {
          title: 'Invest ',
          key: 'invest',
          render: (record) => {
              return (
                <>
                    {record.isTokenSaleClosed ? 'CLOSED': ((<Button
                      type="link"
                      onClick={() => {
                        setInvestmentCompanyId(record.companyId);
                        setDisplayInvestModel(true);
                      }}
                    >
                      Invest
                    </Button>))}
                </>
              );
            },
        },
      ];

    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const result = await new InvestorService().getPayments({ investorId: userInfo._id });
            if (result.success && result.data) {
                setPaymentDetails(result.data.filter(t => t.accept === true));
                setIsLoading(false)
            }else{
                setIsLoading(false)
            }
        })();

        (async () => {
            const result = await new InvestorService().getAllAvailableTokensForInvestor();
            console.log(result)
            if (result.success && result.data) {
                setTokenDetails(result.data);
            }
        })();

    }, [userInfo._id])

    const cancelInvestModel = ()=>{
        setDisplayInvestModel(false);
      }

    return (
        <>
            <InvestModel displayInvestModel={displayInvestModel} handleCancel={cancelInvestModel} investmentCompanyId={investmentCompanyId}></InvestModel>

            <Row>
                <Col span={23}>
                    <Table dataSource={tokenDataSource} loading={isLoading} columns={tokenColumns} />
                </Col>
            </Row>
            <Row>
                <br></br>
            </Row>
            <Row>
        <Card title={
          <Divider orientation="left" style={colorConfiguration}> Invested Tokens </Divider>
        } bordered={true} style={{ width: "100%" }}>
          <Table dataSource={investedTokens} columns={columns} />
        </Card>
      </Row>
            {/* <Row>
                <Card bordered={true} style={{ width: "70%" }} title={
                    < Divider orientation="left" style={colorConfiguration}> Net Estimated Value </Divider>
                }>
                    Last Updated On : {lastUpdatedDate}
                    <br></br>
                    <br></br>
                    <Table dataSource={netEstimatedDataSource} columns={netEstimatedColumns} />
                </Card>

                <Card bordered={true} style={{ width: "30%" }} title={
                    < Divider orientation="left" style={colorConfiguration}> Investors & Shares </Divider>
                }>
                    <Statistic title="Total Investors" value={totalInvestor} />
                    <br></br>
                    Your Shares : 
                    <br></br>
                    <br></br>
                    <Progress  type="circle" percent={sharePercentage}  />      
                </Card>
            </Row>   */}
            <Row>
                <br></br>
            </Row>
            {/* <Row>
                <Card title={
                    <Divider orientation="left" style={colorConfiguration}> Transactions </Divider>
                } bordered={true} style={{ width: "100%" }}>
                    <Table dataSource={transactionDataSource} columns={transactionColumns} />
               </Card>
            </Row> */}
        </>
    )
}