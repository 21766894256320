import { MetamaskService } from '../Metamask.service';

export class LLP {
  public contract = {
    ABI: require('./ABI.json')
  };

  private get web3() {
    return MetamaskService.web3;
  }

  async grantRole(tokenAddress: string, owner: string, address: string, role: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return contractInstance.methods.grantRole(role,address).send({ from: owner });
  }
  async approve(tokenAddress: string, owner: string, spender: string,amount: number ) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return contractInstance.methods.approve(spender, amount).send({ from: owner });
  }
  async bulkMint(tokenAddress: string, owner: string, to: string[],amount: number[] ) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return contractInstance.methods.bulkMint(to, amount).send({ from: owner });
  }
  async controllerRedeem(tokenAddress: string, owner: string, tokenHolder: string,value: number ) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return contractInstance.methods.controllerRedeem(tokenHolder, value).send({ from: owner });
  }
  async controllerTransfer(tokenAddress: string, owner: string, from: string,to: string, value: number ) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return contractInstance.methods.controllerTransfer(from ,to , value).send({ from: owner });
  }
  async modifyWhitelist(tokenAddress: string, owner: string, addWhitelistedWallet: string[],removeWhitelistedWallet: string[]) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return contractInstance.methods.modifyWhitelist(addWhitelistedWallet,removeWhitelistedWallet).send({ from: owner });
  }
  async removeDocument(tokenAddress: string, owner: string,name: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return contractInstance.methods.removeDocument(name).send({ from: owner });
  }
  async setDocument(tokenAddress: string, owner: string,name: string, uri: string, documentHash: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return contractInstance.methods.setDocument(name, uri, documentHash).send({ from: owner });
  }
  async transferFrom(tokenAddress: string, owner: string,from: string, to: string, amount: number) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return contractInstance.methods.transferFrom(from, to, amount).send({ from: owner });
  }
  async balanceOf(tokenAddress: string, address: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return (await contractInstance.methods.balanceOf(address).call()) as string;
  }
  async canTransfer(tokenAddress: string, from: string, to: string, amount: number) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return (await contractInstance.methods.canTransfer(from, to, amount).call()) as boolean;
  }
  async COMPLIANCE_ROLE(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return (await contractInstance.methods.COMPLIANCE_ROLE().call()) as string;
  }
  async DEFAULT_ADMIN_ROLE(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return (await contractInstance.methods.DEFAULT_ADMIN_ROLE().call()) as string;
  }
  async getDocument(tokenAddress: string, name: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return (await contractInstance.methods.getDocument(name).call()) as [string, string, number]
  }
  async name(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return (await contractInstance.methods.name().call()) as string;
  }
  async symbol(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return await contractInstance.methods.sybol().call()
  }
  async totalHolders(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return (await contractInstance.methods.totalHolders().call()) as number;
  }
  async totalSupply(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return (await contractInstance.methods.totalSupply().call()) as number;
  }
  async whitelistedUser(tokenAddress: string, user: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return (await contractInstance.methods.whitelistedUser().call()) as boolean;
  }
}