import {MetamaskService} from '../Metamask.service';
import {environment} from '../../../environments/environment';

export class LLPFactoryService {

  private APIURL = environment.APIURL;

  public contract = {
    ABI: require('./ABI.json')
  }

  private get web3() {
    return MetamaskService.web3;
  }

  async generateNewSecurityToken(
    name: string, 
    tokenSymbol: string, 
    _monthUnit: number,
    _factoryContract: string,
    maxHolders,
    kycAddress,
    countryArray,
    superAdmin,
    complianceAdmin,
    tokenAdmin
    ) {

    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, _factoryContract);

    console.log("calldata : ", name, 
    tokenSymbol, " : ",
    _monthUnit," : ", // lock period
    maxHolders," : ", // max holders
    kycAddress," : ", // kyc address
    countryArray, " : ",// country code
    superAdmin, " : ",// admin wallet address
    complianceAdmin," : ", // compliance admin
    tokenAdmin);

    const method = contractInstance.methods.deployLLPContract(
      name, 
      tokenSymbol, 
      _monthUnit, // lock period
      maxHolders, // max holders
      kycAddress, // kyc address
      countryArray, // country code
      superAdmin, // admin wallet address
      complianceAdmin, // compliance admin
      tokenAdmin
    );
    console.log('method',method)
    return method.send({ from: tokenAdmin[0] });

    // emit NewLLP(
      // _symbol,
      // _name,
      // address(tokenContract),
      // msg.sender,
      // block.timestamp
  }
  
  async tokenSymbolAvailable(tokenSymbol: string,_factoryContract: string) {
    console.log(_factoryContract);
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI,_factoryContract);

    return contractInstance.methods.isSymbolReserved(tokenSymbol).call();
  }
}
