import { MetamaskService } from '../Metamask.service';

export class Erc1643 {
  public contract = {
    ABI: require('./ABI.json')
  };

  private get web3() {
    return MetamaskService.web3;
  }

  async removeDocument(tokenAddress: string, owner: string,name: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return contractInstance.methods.removeDocument(name).send({ from: owner });
  }
  async setDocument(tokenAddress: string, owner: string,name: string, uri: string, documentHash: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return contractInstance.methods.setDocument(name, uri, documentHash).send({ from: owner });
  }
  async getDocument(tokenAddress: string, name: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return (await contractInstance.methods.getDocument(name).call()) as [string, string, number]
  }
}