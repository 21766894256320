import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Typography, Form, Button, Spin, Input, Select, List, Progress, Popconfirm, notification, Space} from 'antd';
import { CheckOutlined, CloseCircleOutlined, LineOutlined } from '@ant-design/icons';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './addProperty.css';
import { AuthService } from '../../Shared/Auth.service';
import { IssuerSuperAdminService } from '../IssuerSuperAdmin.service';
import { SharedService } from '../../Shared/Shared.service';
import { environment } from '../../../environments/environment';
import TextArea from 'antd/lib/input/TextArea';
const {Title} = Typography;
const { Option } = Select;

const issuerSuperAdminService = new IssuerSuperAdminService();
const sharedService = new SharedService();

const useUserContext = () => new AuthService().useUserContext();

export default function AddBasicInfo(props) {
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState();
  const [cCountry, setCCountry] = useState(false);
  const [usaStates, setUSAStates] = useState();
  const [form, form2] = Form.useForm();
  const [form3, form4] = Form.useForm();
  const [form5, form6] = Form.useForm();
  const [documentsToSubmit, setDocumentsToSubmit] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [fileSizeError, setFileSizeError] = useState(false);
  const [fileCount, setFileCount] = useState(0);
  const [disbaleUpload, setDisableUpload] = useState(false);
  const [editUploadView, setEditUploadView] = useState(false);
  const [propertyDescription, setPropertyDescription] = useState('');
  const [managementTeamDescription, setManagementTeamDescription] = useState('');
  const [teamMembers, setTeamMembers] = useState([])
  const [memberName, setMemberName] = useState('')
  const [memberRole, setMemberRole] = useState('');
  const [memberContact, setMemberContact] = useState('');
  const [editBasicInfo, setEditBasicInfo] = useState(false);
  const [memberPhoto, setMemberPhoto] = useState({
    uploading: false,
    uploadFilePercentage: 0,
    URL: null,
    name: null,
    type: null,
  });
  const [filesObj, setFilesObj] = useState({
    attachement: {
      uploading: false,
      uploadFilePercentage: 0,
      URL: null,
      name: null,
      type: null,
    },
  });

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 12 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 12 }
    }
  };

  useEffect(() => {
    (async () => {
      const responsee = await sharedService.getUSAStates();
      setUSAStates(responsee.data);
      const countryRes = await sharedService.getCountries();
      setCountries(countryRes.data);

      if (props?.propertyInfo?.propertyImages?.length > 0) {
        setDocuments(props.propertyInfo.propertyImages);
        setDocumentsToSubmit(props.propertyInfo.propertyImages);
        if (props.propertyInfo.propertyImages?.length > 24) {
          setDisableUpload(true);
        }
      }
      if (props?.propertyInfo?.descriptionOfProperty) {
        setPropertyDescription(props?.propertyInfo?.descriptionOfProperty)
      }
      if (props?.propertyInfo?.descriptionOfManagementTeam) {
        setManagementTeamDescription(props?.propertyInfo?.descriptionOfManagementTeam)
      }
      if (props?.propertyInfo?.teamMembers?.length > 0) {
        setTeamMembers(props?.propertyInfo?.teamMembers)
      }
    })();
  }, [props?.propertyInfo]);

  const currentCountry = (value) => {
    if (value == 'United States of America') setCCountry(true);
    else setCCountry(false);
  }

  //upload file start

  const uploadFile = async (e, key, setFilesObj, form) => {
    if (!e.target.files) return;

    const file = e.target.files[0];

    if (!file) return;

    if (file.size <= 10 * 1024 * 1024) { //10MB
      setFileSizeError(false);
    } else {
      setFileSizeError(true);
      return;
    }

    setFileCount(fileCount + 1);
    if (fileCount + 1 > 24) {
      setDisableUpload(true);
    }

    const fileType = sharedService.getFileType(file.name);
    setFilesObj((prev) => {
      const current = sharedService.clone(prev);
      current[key].URL = null;
      return current;
    });

    try {
      issuerSuperAdminService.uploadFile(file, (uploadFilePercent) => {
        setFilesObj((prev) => {
          const current = sharedService.clone(prev);
          current[key].uploading = true;
          current[key].uploadFilePercentage = uploadFilePercent;
          return current;
        });

      }, (err) => {
        console.error(err);
        setFilesObj((prev) => {
          const current = sharedService.clone(prev);
          current[key].uploading = false;
          return current;
        });

      }, async (response) => {

        if (response.success) {
          const firebaseUrl = response.data.url;
          console.log(firebaseUrl);
          /* upload to firebase ends here */
          if (firebaseUrl?.length > 0) {
            setFilesObj((prev) => {
              const current = sharedService.clone(prev);
              current[key].uploadFilePercentage = 100;
              current[key].URL = firebaseUrl;
              current[key].uploading = false;
              current[key].name = file.name;
              current[key].type = fileType.toLowerCase();
              return current;
            });
            let fileDetails = { [key]: { URL: firebaseUrl, name: file.name } };
            form.setFieldsValue(fileDetails);
            setDocuments((prev) => prev.concat(file.name));
            setDocumentsToSubmit((prev) => prev.concat({ URL: firebaseUrl, name: file.name }));
          } else {
            setFilesObj((prev) => {
              const current = sharedService.clone(prev);
              current[key].uploading = false;
              return current;
            });
          }
        }
      })
    } catch (error) {
      console.error(error);
      setFilesObj((prev) => {
        const current = sharedService.clone(prev);
        current[key].uploading = false;
        return current;
      });
    }
  };

  const deleteItem = (index) => {
    setDocuments(documents.filter((document) => document !== documents[index]));
    setDocumentsToSubmit(
      documentsToSubmit.filter(
        (document) => document !== documentsToSubmit[index]
      )
    );
    setDisableUpload(false);
  };

  const saveBasicInfo = async (_form) => {
    const form = { ..._form };
    form.basicInfo = 'done';
    form.stepDone = 1;
    form.userId = props.userInfo._id;
    form.propertyImages = documentsToSubmit;
    let propertyCount = props.propertyInfo ? props.propertyInfo.newCount ? props.propertyInfo.newCount : 1 : 1;
    form.propertyCount = propertyCount;
    setLoading(true);
    const response = await issuerSuperAdminService.saveBasicInfo(form);
    if (response.success) {
      notification.open({
        message: 'Success',
        duration: 0,
      });
      //window.location.reload();

    } else {
      //message.error(response.error.message);
      notification.open({
        message: 'Error',
        description: response.error.message,
        duration: 0,
      });
    }
    props.setStepNo(1);
    props.setUpdated(true);
    setLoading(false);
  }

  const updateBasicInfo = async (_form) => {
    const form = { ..._form };
    form.basicInfo = 'done';
    form.stepDone = 1;
    form.userId = props.userInfo._id;
    form.updateBasicInfo = true;
    setLoading(true);
    const response = await issuerSuperAdminService.saveBasicInfo(form);
    if (response.success) {
      notification.open({
        message: 'Success',
        duration: 0,
      });
      //window.location.reload();

    } else {
      //message.error(response.error.message);
      notification.open({
        message: 'Error',
        description: response.error.message,
        duration: 0,
      });
    }
    props.setStepNo(1);
    props.setUpdated(true);
    setLoading(false);
    setTimeout(() => window.location.reload(), 500)
  }

  const savePropertyAbout = async (_form) => {
    const form = {};
    form.stepDone = 1;
    form.userId = props.userInfo._id;
    form.updateBasicInfo = true;
    form.descriptionOfProperty = propertyDescription;
    setLoading(true);
    const response = await issuerSuperAdminService.saveBasicInfo(form);
    if (response.success) {
      notification.open({
        message: 'Success',
        duration: 0,
      });
      //window.location.reload();

    } else {
      //message.error(response.error.message);
      notification.open({
        message: 'Error',
        description: response.error.message,
        duration: 0,
      });
    }
    props.setStepNo(1);
    props.setUpdated(true);
    setLoading(false);
  }

  const saveManagementTeamDescription = async (_form) => {
    const form = {};
    form.stepDone = 1;
    form.userId = props.userInfo._id;
    form.updateBasicInfo = true;
    form.descriptionOfManagementTeam = managementTeamDescription;
    setLoading(true);
    const response = await issuerSuperAdminService.saveBasicInfo(form);
    if (response.success) {
      notification.open({
        message: 'Success',
        duration: 0,
      });
      //window.location.reload();

    } else {
      //message.error(response.error.message);
      notification.open({
        message: 'Error',
        description: response.error.message,
        duration: 0,
      });
    }
    props.setStepNo(1);
    props.setUpdated(true);
    setLoading(false);
  }

  const addTeamMember = async (_form) => {
    const form = {};
    form.userId = props.userInfo._id;
    form.updateBasicInfo = true;
    form.teamMembers = [...teamMembers, { memberName, memberPhoto, memberRole, memberContact }];
    setLoading(true);
    const response = await issuerSuperAdminService.saveBasicInfo(form);
    if (response.success) {
      notification.open({
        message: 'Success',
        duration: 0,
      });
      //window.location.reload();

    } else {
      //message.error(response.error.message);
      notification.open({
        message: 'Error',
        description: response.error.message,
        duration: 0,
      });
    }
    props.setStepNo(1);
    props.setUpdated(true);
    setLoading(false);
  }

  const deleteMember = async (index) => {
    const form = {};
    form.userId = props.userInfo._id;
    form.updateBasicInfo = true;
    let newteamMembers = teamMembers.filter((mem, i) => i !== index)
    form.teamMembers = [...newteamMembers];
    setTeamMembers([...newteamMembers])
    setLoading(true);
    const response = await issuerSuperAdminService.saveBasicInfo(form);
    if (response.success) {
      notification.open({
        message: 'Success',
        duration: 0,
      });
      //window.location.reload();

    } else {
      //message.error(response.error.message);
      notification.open({
        message: 'Error',
        description: response.error.message,
        duration: 0,
      });
    }
    props.setStepNo(1);
    props.setUpdated(true);
    setLoading(false);
  }

  const editPhotos = async (_form2) => {
    const form = {};
    form.stepDone = 1;
    form.userId = props.userInfo._id;
    form.propertyImages = documentsToSubmit;
    setLoading(true);
    const response = await issuerSuperAdminService.updatePropertyPhotos(form);
    if (response.success) {
      notification.open({
        message: 'Success',
        duration: 0,
      });
    } else {
      //message.error(response.error.message);
      notification.open({
        message: 'Error',
        description: response.error.message,
        duration: 0,
      });
    }
    props.setStepNo(2);
    props.setUpdated(true);
    setLoading(false);
  }

  const uploadPhoto = async (e, key) => {
    if (!e.target.files) return;
    const file = e.target.files[0];
    if (!file) return;
    const fileType = sharedService.getFileType(file.name);

    setMemberPhoto((prev) => ({ ...prev, URL: null }));

    try {
      issuerSuperAdminService.uploadFile(file, (uploadFilePercent) => {
        setMemberPhoto((prev) => ({ ...prev, uploading: true, uploadFilePercentage: uploadFilePercent }));

      }, (err) => {
        console.error(err);
        setMemberPhoto((prev) => ({ ...prev, uploading: false }));

      }, async (response) => {

        if (response.success) {
          const firebaseUrl = response.data.url;
          console.log(firebaseUrl);
          /* upload to firebase ends here */
          if (firebaseUrl?.length > 0) {
            setMemberPhoto((prev) => ({ ...prev, uploading: false, uploadFilePercentage: 100, name: file.name, type: fileType.toLowerCase(), URL: firebaseUrl }));
            form5.setFieldsValue({ [key]: file.name });
          } else {
            setMemberPhoto((prev) => ({ ...prev, uploading: false }));
          }
        }
      })
    } catch (error) {
      console.error(error);
      setMemberPhoto((prev) => ({ ...prev, uploading: false }));
    }

  }

  const onUploadBack = () => {
    editUploadView(false);
    props.setStepNo(1);
    props.setUpdated(true);
  }

  return (
    <>
      {loading &&
        <div style={{ textAlign: 'center' }}>
          <br />
          <Spin size='large' />
        </div>
      }
      {!loading && !props.basicInfoDone && (
        <>
          <Title level={1} style={{ color: '#186AB4', textAlign: 'center', marginTop: '20px' }}>Property Details</Title>
          <br /><br />
          <Row justify="center">
            <Col span={23}>
              <Form {...formItemLayout} labelAlign="left" form={form} onFinish={saveBasicInfo}>
                <Card>
                  <Form.Item
                    label={`Property Id`}
                    name="propertyId">
                    <Input placeholder={`${props.propertyInfo ? props.propertyInfo.newCount ? props.propertyInfo.newCount : '1' : '1'}`}
                      disabled={true} />
                  </Form.Item>
                  <Form.Item
                    label='Property Title'
                    name="propertyTitle"
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                        whitespace: true
                      }
                    ]}>
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label='Property Category Type'
                    name="propertyCategoryType"
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                        whitespace: true
                      }
                    ]}>
                    <Input placeholder='Multi-Family Property' />
                  </Form.Item>

                  <Form.Item
                    label='Property Country'
                    name="propertyCountry"
                    validateStatus={countries ? '' : 'validating'}
                    hasFeedback
                    help={countries ? '' : "Loading Countries..."}
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                      }
                    ]}>
                    <Select autoClearSearchValue showSearch onChange={currentCountry} placeholder="Select Country">
                      {countries?.sort(function (a, b) { return b.name['en'].localeCompare(a.name['en']); }).map(country =>
                        <Option key={country._id} value={country.name['en']}>{country.name['en']}</Option>
                      )}
                    </Select>
                  </Form.Item>

                  {cCountry &&
                    <Form.Item
                      label='Property State'
                      name="propertyState"
                      rules={[
                        {
                          required: true,
                          message: 'This field is required',
                          whitespace: true
                        }
                      ]}>

                      <Select showSearch placeholder="Select state"  >
                        {usaStates?.map(state =>
                          <Option key={state.state_id} value={state.state_name}>{state.state_name}</Option>
                        )}
                      </Select>

                    </Form.Item>
                  }

                  {!cCountry &&
                    <Form.Item
                      label='CompanyState'
                      name="state"
                      rules={[
                        {
                          required: true,
                          message: 'This field is required',
                          whitespace: true
                        }
                      ]}>
                      <Input />
                    </Form.Item>
                  }

                  <Form.Item
                    label='Property City'
                    name="propertyCity"
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                        whitespace: true
                      }
                    ]}>
                    <Input placeholder='Property City' />
                  </Form.Item>

                  <Form.Item
                    label='Property PINCode'
                    name="propertyZipCode"
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                        whitespace: true
                      }
                    ]}>
                    <Input placeholder='Enter PINCode' />
                  </Form.Item>

                  <Form.Item
                    label='Property Address'
                    name="propertyAddress"
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                        whitespace: true
                      }
                    ]}>
                    <Input placeholder='Enter Property Address' />
                  </Form.Item>

                  <Form.Item
                    label='Carpet Area'
                    name="carpetArea"
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                        whitespace: true
                      }
                    ]}>
                    <Input type="number" placeholder='Enter Carpet Area' />
                  </Form.Item>

                  <Form.Item
                    label='Number of Units'
                    name="numberOfUnits"
                    rules={[
                      {
                        required: false,
                        whitespace: true
                      }
                    ]}>
                    <Input type="number" placeholder='Enter Number of Units' />
                  </Form.Item>

                  <Form.Item
                    label='Year Built'
                    name="yearBuilt"
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                        whitespace: true
                      }
                    ]}>
                    <Input type="text" placeholder='Enter Year Built' />
                  </Form.Item>

                  <Form.Item
                    label='Asset Type'
                    name="assetType"
                    rules={[
                      {
                        required: false,
                        message: 'This field is required',
                        whitespace: true
                      }
                    ]}>
                    <Input type="text" placeholder='Enter Asset Type' />
                  </Form.Item>

                  <Form.Item
                    label='Primary Property Type'
                    name="primaryPropertyType"
                    rules={[
                      {
                        required: false,
                        message: 'This field is required',
                        whitespace: true
                      }
                    ]}>
                    <Input type="text" placeholder='Enter Primary Property Type' />
                  </Form.Item>

                  <Form.Item
                    label='Primary Sub-Type'
                    name="primarySubType"
                    rules={[
                      {
                        required: false,
                        message: 'This field is required',
                        whitespace: true
                      }
                    ]}>
                    <Input type="text" placeholder='Enter Primary Sub-Type' />
                  </Form.Item>

                  <Form.Item
                    label='Building Size'
                    name="buildingSize"
                    rules={[
                      {
                        required: false,
                        message: 'This field is required',
                        whitespace: true
                      }
                    ]}>
                    <Input type="text" placeholder='Enter Building Size (Sq. Ft.)' />
                  </Form.Item>

                  <Form.Item
                    label='Lot Size (Acers)'
                    name="lotSize"
                    rules={[
                      {
                        required: false,
                        message: 'This field is required',
                        whitespace: true
                      }
                    ]}>
                    <Input type="text" placeholder='Enter Lot Size (Acers)' />
                  </Form.Item>

                  <Form.Item
                    label='Parking Count'
                    name="parkingCount"
                    rules={[
                      {
                        required: false,
                        message: 'This field is required',
                        whitespace: true
                      }
                    ]}>
                    <Input type="text" placeholder='Enter Parking Count' />
                  </Form.Item>

                  <Form.Item
                    label='Year Renovated'
                    name="yearRenovated"
                    rules={[
                      {
                        required: false,
                        message: 'This field is required',
                        whitespace: true
                      }
                    ]}>
                    <Input type="text" placeholder='Enter Year Renovated' />
                  </Form.Item>

                  <Form.Item
                    label='Current Occupancy in Percentage'
                    name="currentOccupancy"
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                        whitespace: true
                      }
                    ]}>
                    <Input placeholder='Enter Current Occupancy' type="number" addonAfter="%" />
                  </Form.Item>

                  <Form.Item
                    label='Market Occupancy in Percentage'
                    name="marketOccupancy"
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                        whitespace: true
                      }
                    ]}>
                    <Input placeholder='Enter Market Occupancy' type="number" addonAfter="%" />
                  </Form.Item>

                  <Form.Item
                    label='Current Average Rents'
                    name="currentAverageRent"
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                        whitespace: true
                      }
                    ]}>
                    <Input placeholder='Enter Current Average Rents' type="number" addonAfter={environment.currencySign} />
                  </Form.Item>

                  <Form.Item
                    label='Average Market Rents'
                    name="averageMarketRent"
                    rules={[
                      {
                        required: false,
                        message: 'This field is required',
                        whitespace: true
                      }
                    ]}>
                    <Input placeholder='Enter Average Market Rents' type="number" addonAfter={environment.currencySign} />
                  </Form.Item>

                  <Form.Item
                    label='Purchase Price'
                    name="purchasedPrice"
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                        whitespace: true
                      }
                    ]}>
                    <Input placeholder='Enter Purchase Price' type="number" addonAfter={environment.currencySign} />
                  </Form.Item>

                  <Form.Item
                    label='Stabilized Loan to Value'
                    name="stabilizedLoanToValue"
                    rules={[
                      {
                        required: false,
                        message: 'This field is required',
                        whitespace: true
                      }
                    ]}>
                    <Input placeholder='Enter Stabilized Loan to Value' type="number" addonAfter="%" />
                  </Form.Item>

                  <Form.Item
                    label="Built up Area"
                    name="builtUpArea"
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input placeholder="Enter Built up Area" type="number" />
                  </Form.Item>

                  <Form.Item
                    label="Landmark"
                    name="landmark"
                    rules={[
                      {
                        required: false,
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input placeholder="Enter Landmark" type="text" />
                  </Form.Item>

                  <Form.Item
                    label="Property Age"
                    name="propertyAge"
                    rules={[
                      {
                        required: false,
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input placeholder="Enter Property Age" type="text" />
                  </Form.Item>

                  <Form.Item
                    label="Price per Sq-FT"
                    name="pricePerSqFt"
                    rules={[
                      {
                        required: false,
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input placeholder="Enter Price per Sq-FT" type="number" />
                  </Form.Item>

                  {/* Image upload starts here */}
                  <div className='upload-container' style={{ border: '1px solid black', padding: '50px 5px' }}>
                    <Title level={3} style={{ color: '#186AB4', textAlign: 'center' }}>Upload Property Photos</Title>

                    <Form.Item name="attachement" style={{ justifyContent: 'center' }}>
                      <Input
                        type="file"
                        onChange={(e) => {
                          uploadFile(e, "attachement", setFilesObj, form);
                        }}
                        accept='.png,.PNG,.jpg,.JPG,.jpeg,.JPEG,.webp,.WEBP,.tiff,.TIFF'
                        disabled={loading || disbaleUpload}
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}
                      />
                      {fileSizeError && <h5 style={{ color: 'red', margin: '10px 0' }}>File Size can not be more than 10MB</h5>}
                      {filesObj.attachement.uploadFilePercentage > 0 && (
                        <>
                          <Progress
                            percent={filesObj.attachement.uploadFilePercentage}
                          />
                          <br />
                          <div align="left">
                            Files Uploaded:
                            <List itemLayout="horizontal" style={{ textAlign: "left" }}>
                              {documents?.map((obj, index) => (
                                <List.Item
                                  textalign="left"
                                  style={{ cursor: "pointer" }}
                                  key={index}
                                  actions={[
                                    <Popconfirm
                                      title="This item will be deleted!"
                                      onConfirm={() => deleteItem(index)}
                                    >
                                      <Button type="primary" danger>
                                        X
                                      </Button>
                                    </Popconfirm>,
                                  ]}
                                >
                                  <List.Item.Meta title={obj} />
                                </List.Item>
                              ))}
                            </List>
                          </div>
                        </>
                      )}{" "}
                    </Form.Item>
                  </div>

                  {/* <Title level={3} style={{ color: '#186AB4', textAlign: 'center', marginTop: '100px' }}>Property Title Owner Details</Title>

                  <Form.Item
                    label='First Name'
                    name='ownerFirstName'
                    rules={[{ required: true, message: 'First Name is required' }]}>
                    <Input placeholder='First Name' />
                  </Form.Item>

                  <Form.Item
                    label='Last Name'
                    name='ownerLastName'
                    rules={[{ required: true, message: 'Last Name is required' }]}>
                    <Input placeholder='Last Name' />
                  </Form.Item>

                  <Form.Item
                    label='Company Name(if applicable)'
                    name='ownerCompanyName'
                    rules={[{ required: false }]}>
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label='Owner Country'
                    name="ownerCountry"
                    validateStatus={countries ? '' : 'validating'}
                    hasFeedback
                    help={countries ? '' : "Loading Countries..."}
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                      }
                    ]}>
                    <Select autoClearSearchValue showSearch onChange={currentCountry} placeholder="Select Country">
                      {countries?.sort(function (a, b) { return b.name['en'].localeCompare(a.name['en']); }).map(country =>
                        <Option key={country._id} value={country.name['en']}>{country.name['en']}</Option>
                      )}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label='Phone number'
                    name="ownerPhone"
                    rules={[
                      {
                        required: true,
                        message: 'Enter Phone number here',
                        whitespace: true
                      },
                      {
                        validator: (rule, value) => {
                          const strongRegex = /^[+]{0,1}[0-9\s-]{0,4}[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]{3,10}$/;
                          if (strongRegex.test(value)) return Promise.resolve();
                          return Promise.reject('Invalid phone number');
                        }
                      }
                    ]}>
                    <PhoneInput country={"us"} />
                  </Form.Item> */}
                  <br /><br />
                  <Form.Item style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button size='large' type='primary' htmlType="submit" loading={loading}>Submit</Button>
                  </Form.Item>
                  <br /><br />
                </Card>
              </Form>
            </Col>
          </Row>
        </>
      )}

      {!loading && props.basicInfoDone && (
        <>
          <Title level={1} style={{ color: '#186AB4', textAlign: 'center', marginTop: '20px' }}>Property Details</Title>
          <br /><br />
          <Row justify="center">
            <Col span={23}>
              {!editBasicInfo && (<Form {...formItemLayout} labelAlign="left" form={form} onFinish={saveBasicInfo}>
                <Card>
                  <div className='edit-basic-info'>
                    <Form.Item
                      label={`Property Id`}
                      name="propertyId">
                      <Input placeholder={`${props.propertyInfo.propertyCount}`}
                        disabled={true} />
                    </Form.Item>
                    <Form.Item
                      label='Property Title'
                      name="propertyTitle"
                      rules={[
                        {
                          required: true,
                          message: 'This field is required',
                          whitespace: true
                        }
                      ]}>
                      <Input placeholder={props.propertyInfo.propertyTitle} disabled={true} />
                    </Form.Item>

                    <Form.Item
                      label='Property Category Type'
                      name="propertyCategoryType"
                      rules={[
                        {
                          required: true,
                          message: 'This field is required',
                          whitespace: true
                        }
                      ]}>
                      <Input placeholder={props.propertyInfo.propertyCategoryType} disabled={true} />
                    </Form.Item>

                    <Form.Item
                      label='Property Country'
                      name="propertyCountry"
                      validateStatus={countries ? '' : 'validating'}
                      hasFeedback
                      help={countries ? '' : "Loading Countries..."}
                      rules={[
                        {
                          required: true,
                          message: 'This field is required',
                        }
                      ]}>
                      <Input placeholder={props.propertyInfo.propertyCountry} disabled={true} />
                    </Form.Item>

                    {cCountry &&
                      <Form.Item
                        label='Property State'
                        name="propertyState"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input placeholder={props.propertyInfo.propertyState} disabled={true} />
                      </Form.Item>
                    }

                    {!cCountry &&
                      <Form.Item
                        label='Property State'
                        name="propertyState"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input placeholder={props.propertyInfo.propertyState} disabled={true} />
                      </Form.Item>
                    }

                    <Form.Item
                      label='Property City'
                      name="propertyCity"
                      rules={[
                        {
                          required: true,
                          message: 'This field is required',
                          whitespace: true
                        }
                      ]}>
                      <Input placeholder={props.propertyInfo.propertyCity} disabled={true} />
                    </Form.Item>

                    <Form.Item
                      label='Property PINCode'
                      name="propertyZipCode"
                      rules={[
                        {
                          required: true,
                          message: 'This field is required',
                          whitespace: true
                        }
                      ]}>
                      <Input placeholder={props.propertyInfo.propertyZipCode} disabled={true} />
                    </Form.Item>

                    <Form.Item
                      label='Property Address'
                      name="propertyAddress"
                      rules={[
                        {
                          required: true,
                          message: 'This field is required',
                          whitespace: true
                        }
                      ]}>
                      <Input placeholder={props.propertyInfo.propertyAddress} disabled={true} />
                    </Form.Item>

                    <Form.Item
                      label='Carpet Area'
                      name="carpetArea"
                      rules={[
                        {
                          required: true,
                          message: 'This field is required',
                          whitespace: true
                        }
                      ]}>
                      <Input type="number" placeholder={props.propertyInfo.suarefootage} disabled={true} />
                    </Form.Item>

                    <Form.Item
                      label='Number of Units'
                      name="numberOfUnits"
                      rules={[
                        {
                          required: false,
                          whitespace: true
                        }
                      ]}>
                      <Input type="number" placeholder={props.propertyInfo.numberOfUnits} disabled={true} />
                    </Form.Item>

                    <Form.Item
                      label='Year Built'
                      name="yearBuilt"
                      rules={[
                        {
                          required: true,
                          message: 'This field is required',
                          whitespace: true
                        }
                      ]}>
                      <Input type="text" placeholder={props.propertyInfo.yearBuilt} disabled={true} />
                    </Form.Item>

                    <Form.Item
                    label='Asset Type'
                    name="Asset Type"
                    rules={[
                      {
                        required: false,
                        message: 'This field is required',
                        whitespace: true
                      }
                    ]}>
                    <Input type="text" placeholder={props.propertyInfo.assetType} disabled={true} />
                  </Form.Item>

                  <Form.Item
                    label='Primary Property Type'
                    name="primaryPropertyType"
                    rules={[
                      {
                        required: false,
                        message: 'This field is required',
                        whitespace: true
                      }
                    ]}>
                    <Input type="text" placeholder={props.propertyInfo.primaryPropertyType} disabled={true} />
                  </Form.Item>

                  <Form.Item
                    label='Primary Sub-Type'
                    name="primarySubType"
                    rules={[
                      {
                        required: false,
                        message: 'This field is required',
                        whitespace: true
                      }
                    ]}>
                    <Input type="text" placeholder={props.propertyInfo.primarySubType} disabled={true} />
                  </Form.Item>

                  <Form.Item
                    label='Building Size'
                    name="buildingSize"
                    rules={[
                      {
                        required: false,
                        message: 'This field is required',
                        whitespace: true
                      }
                    ]}>
                    <Input type="text" placeholder={props.propertyInfo.buildingSize} disabled={true} />
                  </Form.Item>

                  <Form.Item
                    label='Lot Size'
                    name="lotSize"
                    rules={[
                      {
                        required: false,
                        message: 'This field is required',
                        whitespace: true
                      }
                    ]}>
                    <Input type="text" placeholder={props.propertyInfo.lotSize} disabled={true} />
                  </Form.Item>

                  <Form.Item
                    label='Parking Count'
                    name="parkingCount"
                    rules={[
                      {
                        required: false,
                        message: 'This field is required',
                        whitespace: true
                      }
                    ]}>
                    <Input type="text" placeholder={props.propertyInfo.parkingCount} disabled={true} />
                  </Form.Item>

                    <Form.Item
                      label='Year Renovated'
                      name="yearRenovated"
                      rules={[
                        {
                          required: false,
                          message: 'This field is required',
                          whitespace: true
                        }
                      ]}>
                      <Input type="text" placeholder={props.propertyInfo.yearRenovated} disabled={true} />
                    </Form.Item>

                    <Form.Item
                      label='Current Occupancy in Percentage'
                      name="currentOccupancy"
                      rules={[
                        {
                          required: true,
                          message: 'This field is required',
                          whitespace: true
                        }
                      ]}>
                      <Input placeholder={props.propertyInfo.currentOccupancy} disabled={true} type="number" addonAfter="%" />
                    </Form.Item>

                    <Form.Item
                      label='Market Occupancy in Percentage'
                      name="marketOccupancy"
                      rules={[
                        {
                          required: true,
                          message: 'This field is required',
                          whitespace: true
                        }
                      ]}>
                      <Input placeholder={props.propertyInfo.marketOccupancy} disabled={true} type="number" addonAfter="%" />
                    </Form.Item>

                    <Form.Item
                      label='Current Average Rents'
                      name="currentAverageRent"
                      rules={[
                        {
                          required: true,
                          message: 'This field is required',
                          whitespace: true
                        }
                      ]}>
                      <Input placeholder={props.propertyInfo.currentAverageRent} disabled={true} type="number" addonAfter={environment.currencySign} />
                    </Form.Item>

                    <Form.Item
                      label='Average Market Rents'
                      name="averageMarketRent"
                      rules={[
                        {
                          required: false,
                          message: 'This field is required',
                          whitespace: true
                        }
                      ]}>
                      <Input placeholder={props.propertyInfo.averageMarketRent} disabled={true} type="number" addonAfter={environment.currencySign} />
                    </Form.Item>

                    <Form.Item
                      label='Purchase Price'
                      name="purchasedPrice"
                      rules={[
                        {
                          required: true,
                          message: 'This field is required',
                          whitespace: true
                        }
                      ]}>
                      <Input placeholder={props.propertyInfo.purchasedPrice} disabled={true} type="number" addonAfter={environment.currencySign} />
                    </Form.Item>

                    <Form.Item
                      label='Stabilized Loan to Value'
                      name="stabilizedLoanToValue"
                      rules={[
                        {
                          required: false,
                          message: 'This field is required',
                          whitespace: true
                        }
                      ]}>
                      <Input placeholder={props.propertyInfo.stabilizedLoanToValue} disabled={true} type="number" addonAfter="%" />
                    </Form.Item>

                    <Form.Item
                      label="Built up Area"
                      name="builtUpArea"
                      rules={[
                        {
                          required: true,
                          message: "This field is required",
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input placeholder={props.propertyInfo.suarefootage} disabled={true} type="number" />
                    </Form.Item>

                    <Form.Item
                      label="Landmark"
                      name="landmark"
                      rules={[
                        {
                          required: false,
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input
                        placeholder={props.propertyInfo.location}
                        disabled={true}
                        type="number"
                      />
                    </Form.Item>

                    <Form.Item
                      label="Property Age"
                      name="propertyAge"
                      rules={[
                        {
                          required: false,
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input
                        placeholder={
                          new Date().getFullYear() -
                          parseInt(props.propertyInfo.yearBuilt) +
                          " year(s)"
                        }
                        disabled={true}
                        type="text"
                      />
                    </Form.Item>

                    <Form.Item
                      label="Price per Sq-FT"
                      name="pricePerSqFT"
                      rules={[
                        {
                          required: false,
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input placeholder={"$" + (parseInt(props.propertyInfo.purchasedPrice) / parseInt(props.propertyInfo.suarefootage))?.toFixed(2) + "/Sq. Ft."} disabled={true} type="number" />
                    </Form.Item>

                    <br /><br />
                    <Form.Item style={{ display: "block", float: "right" }}>
                      {!props.userInfo.roles.includes('master company super admin') && <Button
                        type="primary"
                        size="large"
                        onClick={() => { setEditUploadView(false); setEditBasicInfo(true) }}
                      >
                        Edit
                      </Button>}
                    </Form.Item>
                    <br /><br />
                  </div>
                  <br /><br />

                  <div className='upload-container' style={{ border: '1px solid black', padding: '50px 5px' }}>
                    <Title level={3} style={{ color: '#186AB4', textAlign: 'center' }}>{!editUploadView ? 'Uploaded Property Photos' : 'Edit Property Photos'}</Title>
                    {!editUploadView && props.propertyInfo.propertyImages ? (
                      <>
                        <ul>
                          {props.propertyInfo.propertyImages?.map((item, index) => (
                            <li key={index} className="image-list-item"
                              style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}>
                              <a href={item.URL} download={item.name} target="_blank" rel="noopener noreferrer">{item.name}</a></li>
                          ))}
                        </ul>
                        {!props.userInfo.roles.includes('master company super admin') && <Button size='large' style={{ float: 'right', marginTop: '5px' }} type='secondary' onClick={() => { setEditBasicInfo(false); setEditUploadView(true) }}>Edit Photos</Button>}
                      </>
                    ) : ''}
                    {editUploadView && props.propertyInfo.propertyImages && (
                      <Form {...formItemLayout} labelAlign="left" form={form2} onFinish={editPhotos}>
                        <Form.Item name="attachement" style={{ justifyContent: 'center' }}>
                          <Input
                            type="file"
                            onChange={(e) => {
                              uploadFile(e, "attachement", setFilesObj, form);
                            }}
                            accept='.png,.PNG,.jpg,.JPG,.jpeg,.JPEG,.webp,.WEBP,.tiff,.TIFF'
                            disabled={loading || disbaleUpload}
                          />
                          {fileSizeError && <h5 style={{ color: 'red', margin: '10px 0' }}>File Size can not be more than 10MB</h5>}
                          {filesObj?.attachement && (<Progress
                            percent={filesObj.attachement.uploadFilePercentage}
                          />)}
                          <br />
                          <div align="left">
                            Files Uploaded:
                            <List itemLayout="horizontal" style={{ textAlign: "left" }}>
                              {documents?.map((obj, index) => (
                                <List.Item
                                  textalign="left"
                                  style={{ cursor: "pointer" }}
                                  key={index}
                                  actions={[
                                    <Popconfirm
                                      title="This item will be deleted!"
                                      onConfirm={() => deleteItem(index)}
                                    >
                                      <Button type="primary" danger>
                                        X
                                      </Button>
                                    </Popconfirm>,
                                  ]}
                                >
                                  <List.Item.Meta title={obj ? obj.name ? obj.name : obj : null} />
                                </List.Item>
                              ))}
                              <Button size='large' style={{ marginRight: '50px', marginTop: '10px' }} type='secondary' onClick={onUploadBack}>Back</Button>
                              <Button size='large' style={{ marginTop: '10px' }} type='primary' htmlType="submit" loading={loading}>Update</Button>
                            </List>
                          </div>
                        </Form.Item>
                      </Form>
                    )}
                  </div>
                  <br /> <br />
                </Card>
              </Form>)}
              {editBasicInfo && (<Form {...formItemLayout} labelAlign="left" form={form2} onFinish={updateBasicInfo}>
                <Card>
                  <div className='edit-basic-info'>
                    <Form.Item
                      label={`Property Id`}
                      name="propertyId">
                      <Input placeholder={`${props.propertyInfo.propertyCount}`} defaultValue={props.propertyInfo.propertyCount} disabled
                      />
                    </Form.Item>
                    <Form.Item
                      label='Property Title'
                      name="propertyTitle"
                      rules={[
                        {
                          required: false,
                          message: 'This field is required',
                          whitespace: true
                        }
                      ]}>
                      <Input placeholder={props.propertyInfo.propertyTitle} defaultValue={props.propertyInfo.propertyTitle} />
                    </Form.Item>

                    <Form.Item
                      label='Property Category Type'
                      name="propertyCategoryType"
                      rules={[
                        {
                          required: false,
                          message: 'This field is required',
                          whitespace: true
                        }
                      ]}>
                      <Input placeholder={props.propertyInfo.propertyCategoryType} defaultValue={props.propertyInfo.propertyCategoryType} />
                    </Form.Item>

                    <Form.Item
                      label='Property Country'
                      name="propertyCountry"
                      validateStatus={countries ? '' : 'validating'}
                      hasFeedback
                      help={countries ? '' : "Loading Countries..."}
                      rules={[
                        {
                          required: false,
                          message: 'This field is required',
                        }
                      ]}>
                      <Input placeholder={props.propertyInfo.propertyCountry} defaultValue={props.propertyInfo.propertyCountry} />
                    </Form.Item>

                    {cCountry &&
                      <Form.Item
                        label='Property State'
                        name="propertyState"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input placeholder={props.propertyInfo.propertyState} defaultValue={props.propertyInfo.propertyState} />
                      </Form.Item>
                    }

                    {!cCountry &&
                      <Form.Item
                        label='Property State'
                        name="propertyState"
                        rules={[
                          {
                            required: false,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input placeholder={props.propertyInfo.propertyState} defaultValue={props.propertyInfo.propertyState} />
                      </Form.Item>
                    }

                    <Form.Item
                      label='Property City'
                      name="propertyCity"
                      rules={[
                        {
                          required: false,
                          message: 'This field is required',
                          whitespace: true
                        }
                      ]}>
                      <Input placeholder={props.propertyInfo.propertyCity} defaultValue={props.propertyInfo.propertyCity} />
                    </Form.Item>

                    <Form.Item
                      label='Property PINCode'
                      name="propertyZipCode"
                      rules={[
                        {
                          required: false,
                          message: 'This field is required',
                          whitespace: true
                        }
                      ]}>
                      <Input placeholder={props.propertyInfo.propertyZipCode} defaultValue={props.propertyInfo.propertyZipCode} />
                    </Form.Item>

                    <Form.Item
                      label='Property Address'
                      name="propertyAddress"
                      rules={[
                        {
                          required: false,
                          message: 'This field is required',
                          whitespace: true
                        }
                      ]}>
                      <Input placeholder={props.propertyInfo.propertyAddress} defaultValue={props.propertyInfo.propertyAddress} />
                    </Form.Item>

                    <Form.Item
                      label='Carpet Area'
                      name="carpetArea"
                      rules={[
                        {
                          required: false,
                          message: 'This field is required',
                          whitespace: true
                        }
                      ]}>
                      <Input type="number" placeholder={props.propertyInfo.suarefootage} defaultValue={props.propertyInfo.suarefootage} />
                    </Form.Item>

                    <Form.Item
                      label='Number of Units'
                      name="numberOfUnits"
                      rules={[
                        {
                          required: false,
                          whitespace: true
                        }
                      ]}>
                      <Input type="number" placeholder={props.propertyInfo.numberOfUnits} defaultValue={props.propertyInfo.numberOfUnits} />
                    </Form.Item>

                    <Form.Item
                      label='Year Built'
                      name="yearBuilt"
                      rules={[
                        {
                          required: false,
                          message: 'This field is required',
                          whitespace: true
                        }
                      ]}>
                      <Input type="text" placeholder={props.propertyInfo.yearBuilt} defaultValue={props.propertyInfo.yearBuilt} />
                    </Form.Item>

                    <Form.Item
                    label='Asset Type'
                    name="assetType"
                    rules={[
                      {
                        required: false,
                        message: 'This field is required',
                        whitespace: true
                      }
                    ]}>
                    <Input type="text" placeholder={props.propertyInfo.assetType} defaultValue={props.propertyInfo.assetType} />
                  </Form.Item>

                  <Form.Item
                    label='Primary Property Type'
                    name="primaryPropertyType"
                    rules={[
                      {
                        required: false,
                        message: 'This field is required',
                        whitespace: true
                      }
                    ]}>
                    <Input type="text" placeholder={props.propertyInfo.primaryPropertyType} defaultValue={props.propertyInfo.primaryPropertyType} />
                  </Form.Item>

                  <Form.Item
                    label='Primary Sub-Type'
                    name="primarySubType"
                    rules={[
                      {
                        required: false,
                        message: 'This field is required',
                        whitespace: true
                      }
                    ]}>
                    <Input type="text" placeholder={props.propertyInfo.primarySubType} defaultValue={props.propertyInfo.primarySubType} />
                  </Form.Item>

                  <Form.Item
                    label='Building Size'
                    name="buildingSize"
                    rules={[
                      {
                        required: false,
                        message: 'This field is required',
                        whitespace: true
                      }
                    ]}>
                    <Input type="text" placeholder={props.propertyInfo.buildingSize} defaultValue={props.propertyInfo.buildingSize} />
                  </Form.Item>

                  <Form.Item
                    label='Lot Size'
                    name="lotSize"
                    rules={[
                      {
                        required: false,
                        message: 'This field is required',
                        whitespace: true
                      }
                    ]}>
                    <Input type="text" placeholder={props.propertyInfo.lotSize} defaultValue={props.propertyInfo.lotSize} />
                  </Form.Item>

                  <Form.Item
                    label='Parking Count'
                    name="parkingCount"
                    rules={[
                      {
                        required: false,
                        message: 'This field is required',
                        whitespace: true
                      }
                    ]}>
                    <Input type="text" placeholder={props.propertyInfo.parkingCount} defaultValue={props.propertyInfo.parkingCount} />
                  </Form.Item>

                    <Form.Item
                      label='Year Renovated'
                      name="yearRenovated"
                      rules={[
                        {
                          required: false,
                          message: 'This field is required',
                          whitespace: true
                        }
                      ]}>
                      <Input type="text" placeholder={props.propertyInfo.yearRenovated} defaultValue={props.propertyInfo.yearRenovated} />
                    </Form.Item>

                    <Form.Item
                      label='Current Occupancy in Percentage'
                      name="currentOccupancy"
                      rules={[
                        {
                          required: false,
                          message: 'This field is required',
                          whitespace: true
                        }
                      ]}>
                      <Input placeholder={props.propertyInfo.currentOccupancy} defaultValue={props.propertyInfo.currentOccupancy} type="number" addonAfter="%" />
                    </Form.Item>

                    <Form.Item
                      label='Market Occupancy in Percentage'
                      name="marketOccupancy"
                      rules={[
                        {
                          required: false,
                          message: 'This field is required',
                          whitespace: true
                        }
                      ]}>
                      <Input placeholder={props.propertyInfo.marketOccupancy} defaultValue={props.propertyInfo.marketOccupancy} type="number" addonAfter="%" />
                    </Form.Item>

                    <Form.Item
                      label='Current Average Rents'
                      name="currentAverageRent"
                      rules={[
                        {
                          required: false,
                          message: 'This field is required',
                          whitespace: true
                        }
                      ]}>
                      <Input placeholder={props.propertyInfo.currentAverageRent} defaultValue={props.propertyInfo.currentAverageRent} type="number" addonAfter={environment.currencySign} />
                    </Form.Item>

                    <Form.Item
                      label='Average Market Rents'
                      name="averageMarketRent"
                      rules={[
                        {
                          required: false,
                          message: 'This field is required',
                          whitespace: true
                        }
                      ]}>
                      <Input placeholder={props.propertyInfo.averageMarketRent} defaultValue={props.propertyInfo.averageMarketRent} type="number" addonAfter={environment.currencySign} />
                    </Form.Item>

                    <Form.Item
                      label='Purchase Price'
                      name="purchasedPrice"
                      rules={[
                        {
                          required: false,
                          message: 'This field is required',
                          whitespace: true
                        }
                      ]}>
                      <Input placeholder={props.propertyInfo.purchasedPrice} defaultValue={props.propertyInfo.purchasedPrice} type="number" addonAfter={environment.currencySign} />
                    </Form.Item>

                    <Form.Item
                      label='Stabilized Loan to Value'
                      name="stabilizedLoanToValue"
                      rules={[
                        {
                          required: false,
                          message: 'This field is required',
                          whitespace: true
                        }
                      ]}>
                      <Input placeholder={props.propertyInfo.stabilizedLoanToValue} defaultValue={props.propertyInfo.stabilizedLoanToValue} type="number" addonAfter="%" />
                    </Form.Item>

                    <Form.Item
                      label="Built up Area"
                      name="builtUpArea"
                      rules={[
                        {
                          required: false,
                          message: "This field is required",
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input
                        placeholder={props.propertyInfo.suarefootage} defaultValue={props.propertyInfo.suarefootage}
                        type="number"
                      />
                    </Form.Item>

                    <Form.Item
                      label="Landmark"
                      name="landmark"
                      rules={[
                        {
                          required: false,
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input
                        placeholder={props.propertyInfo.location}
                        defaultValue={props.propertyInfo.location}
                        type="text"
                      />
                    </Form.Item>

                    <Form.Item
                      label="Property Age"
                      name="propertyAge"
                      rules={[
                        {
                          required: false,
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input
                        placeholder={
                          new Date().getFullYear() -
                          parseInt(props.propertyInfo.yearBuilt) +
                          " year(s)"
                        }
                        defaultValue={
                          new Date().getFullYear() -
                          parseInt(props.propertyInfo.yearBuilt) +
                          " year(s)"
                        }
                        type="text"
                      />
                    </Form.Item>

                    <Form.Item
                      label="Price per Sq-FT"
                      name="pricePerSqFT"
                      rules={[
                        {
                          required: false,
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input
                        placeholder={"$" + (parseInt(props.propertyInfo.purchasedPrice) / parseInt(props.propertyInfo.suarefootage))?.toFixed(2) + "/Sq. Ft."}
                        defaultValue={"$" + (parseInt(props.propertyInfo.purchasedPrice) / parseInt(props.propertyInfo.suarefootage))?.toFixed(2) + "/Sq. Ft."}
                        type="number"
                      />
                    </Form.Item>

                    <br /><br />
                    <div className='btn-ctn' style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                      <Button size='large' style={{ display: 'flex', justifyContent: 'flex-end' }} type='primary' htmlType="submit" loading={loading}>Update</Button>
                    </div>
                    <br /><br />
                  </div>

                  <div className='upload-container' style={{ border: '1px solid black', padding: '50px 5px' }}>
                    <Title level={3} style={{ color: '#186AB4', textAlign: 'center' }}>{!editUploadView ? 'Uploaded Property Photos' : 'Edit Property Photos'}</Title>
                    {!editUploadView && props.propertyInfo.propertyImages ? (
                      <>
                        <ul>
                          {props.propertyInfo.propertyImages?.map((item, index) => (
                            <li key={index} className="image-list-item"
                              style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}>
                              <a href={item.URL} download={item.name} target="_blank" rel="noopener noreferrer">{item.name}</a></li>
                          ))}
                        </ul>
                        <Button size='large' style={{ float: 'right', marginTop: '5px' }} type='secondary' onClick={() => { setEditBasicInfo(false); setEditUploadView(true) }}>Edit Photos</Button>
                      </>
                    ) : ''}
                  </div>
                  <br /> <br />
                </Card>
              </Form>)}
            </Col>
          </Row>
        </>
      )}

      {/* property about section here */}
      {!loading && (<>
        <Row justify="center">
          <Col span={23}>
            <Form {...formItemLayout} labelAlign="left" form={form3} onFinish={savePropertyAbout}>
              <Card>
                <Form.Item
                  label='Description of Property'
                  name='descriptionOfProperty'
                  rules={[{ required: true, message: 'Description of Property is required' }]}>
                  <textarea defaultValue={propertyDescription} value={propertyDescription} onChange={(e) => setPropertyDescription(e.target.value)}  style={{ height: '100px', width: '300px', padding: '10px' ,resize:"none"}} />
                </Form.Item>
                <Form.Item style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {!props.userInfo.roles.includes('master company super admin') && <Button size='large' type='primary' htmlType="submit" loading={loading}>Submit</Button>}
                </Form.Item>
              </Card>
            </Form>
          </Col>
        </Row>
      </>
      )}

      {/* description of management team here */}
      {!loading && (<>
        <Row justify="center">
          <Col span={23}>
            <Form {...formItemLayout} labelAlign="left" form={form4} onFinish={saveManagementTeamDescription}>
              <Card>
                <Form.Item
                  label='Description of Management Team'
                  name='descriptionOfManagementTeam'
                  rules={[{ required: true, message: 'Description of Management Team is required' }]}>
                  <textarea defaultValue={managementTeamDescription} value={managementTeamDescription} onChange={(e) => setManagementTeamDescription(e.target.value)} style={{ height: '100px', width: '300px', padding: '10px' ,resize:"none"}} />
                </Form.Item>
                <Form.Item style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {!props.userInfo.roles.includes('master company super admin') && <Button size='large' type='primary' htmlType="submit" loading={loading}>Submit</Button>}
                </Form.Item>
              </Card>
            </Form>
          </Col>
        </Row>
      </>
    )}

    {/* team members details here */}
    {!loading && (
      <Row justify="center">
        <Col span={23}>
          <br/>
          {teamMembers?.length > 0 && (teamMembers.map((mem, index) => (
            <div style={{marginBottom: '20px', border: '1px solid black', padding: '10px'}} key={index}>
              <Form.Item
                label='Name of Designated Patner'
                name='name'
                rules={[{ required: true, message: 'Name of Designated Patner is required' }]}>
                <Input value={mem.memberName} defaultValue={mem.memberName} disabled={true} style={{maxWidth: '300px'}}/>
              </Form.Item>

              <Form.Item
                label="Designated Patner Photo"
                name='photo'
                rules={[
                  {
                    required: false,
                    message: 'This field is required'
                  }
                ]}>
                <>
                  <div style={{}}>
                    {mem.memberPhoto && (<a key={Math.random()} href={`${mem.memberPhoto.URL}`} target="_blank">{mem.memberPhoto?.name}</a>)}
                  </div>
                </>
              </Form.Item>
              <Form.Item
                label='Address of Designated Partner'
                name='memberRole'
                rules={[{ required: true, message: 'Address of Designated Partner is required' }]}>
                <textarea defaultValue={mem.memberRole} value={mem.memberRole} disabled={true} style={{height: '80px', width: '300px', padding: '10px'}}/>
              </Form.Item>
              <Form.Item
                label='DPIN of Designated Partner'
                name='memberCOntactDetails'
                rules={[{ required: true, message: 'DPIN of Designated Partner is required' }]}>
                <textarea defaultValue={mem.memberContact} value={mem.memberContact} disabled={true} style={{height: '80px', width: '300px', padding: '10px'}}/>
              </Form.Item>
              <br/>
              <div style={{display: 'flex', justifyContent: 'center'}}>
                <Popconfirm
                  title="This member will be deleted!"
                  onConfirm={() => deleteMember(index)}>
                  {!props.userInfo.roles.includes('master company super admin') &&
                  <Button type="primary" danger>
                    Delete Member X
                  </Button>}
                </Popconfirm>
              </div>
            </div>
          )))}
          <br/>
          <Form {...formItemLayout} labelAlign="left" form={form5} onFinish={addTeamMember}>
            <Card>
              <Form.Item
                label='Name of Designated Patner'
                name='name'
                rules={[{ required: true, message: 'Name of Designated Patner is required' }]}>
                <Input value={memberName} onChange={(e)=> setMemberName(e.target.value)}/>
              </Form.Item>

              {!props.userInfo.roles.includes('master company super admin') &&
                <Form.Item
                label="Designated Patner Photo"
                name='photo'
                rules={[
                  {
                    required: false,
                    message: 'This field is required'
                  }
                ]}>
                <>
                  <Input type='file' onChange={e => { uploadPhoto(e, 'photo') }} accept=".png,.PNG,.JPG,.jpg,.JPEF,.jpeg"/>
                  {memberPhoto?.uploadFilePercentage > 0 &&
                    <Progress percent={memberPhoto?.uploadFilePercentage} />
                  }
                  <div style={{}}>
                    <p>File Uploaded:</p>
                    {memberPhoto && (<h4 key={Math.random()}>{memberPhoto?.name}</h4>)}
                  </div>
                </>
              </Form.Item>}
              
              <Form.Item
                label='Address of Designated Partner'
                name='memberRole'
                rules={[{ required: true, message: 'Address of Designated Partner is required' }]}>
                <textarea defaultValue={memberRole} value={memberRole} onChange={(e)=> setMemberRole(e.target.value)} style={{height: '80px', width: '300px', padding: '10px'}}/>
              </Form.Item>
              <Form.Item
                label='DPIN of Designated Partner'
                name='memberCOntactDetails'
                rules={[{ required: true, message: 'DPIN of Designated Partner is required' }]}>
                <textarea defaultValue={memberContact} value={memberContact} onChange={(e)=> setMemberContact(e.target.value)} style={{height: '80px', width: '300px', padding: '10px'}}/>
              </Form.Item>

              <Form.Item style={{display: 'flex', justifyContent: 'flex-end'}}>
                {!props.userInfo.roles.includes('master company super admin') && <Button size='large' type='primary' htmlType="submit" loading={loading}>Submit</Button>}
              </Form.Item>
            </Card>
          </Form>
        </Col>
      </Row>
      )}

      {/* team members details here
      {!loading && (<>
        <Row justify="center">
          <Col span={23}>
            <br />
            {teamMembers?.length > 0 && (teamMembers.map((mem, index) => (
              <div style={{ marginBottom: '20px', border: '1px solid black', padding: '10px' }} key={index}>
                <Form.Item
                  label='Name of Member'
                  name='name'
                  rules={[{ required: true, message: 'Name of Member is required' }]}>
                  <Input value={mem.memberName} defaultValue={mem.memberName} disabled={true} style={{ maxWidth: '300px' }} />
                </Form.Item>

                <Form.Item
                  label="Member Photo"
                  name='photo'
                  rules={[
                    {
                      required: false,
                      message: 'This field is required'
                    }
                  ]}>
                  <>
                    <div style={{}}>
                      {mem.memberPhoto && (<a key={Math.random()} href={`${mem.memberPhoto.URL}`} target="_blank">{mem.memberPhoto?.name}</a>)}
                    </div>
                  </>
                </Form.Item>
                <Form.Item
                  label='Description of Member Role'
                  name='memberRole'
                  rules={[{ required: true, message: 'Description of Member Role is required' }]}>
                  <textarea defaultValue={mem.memberRole} value={mem.memberRole} disabled={true} style={{ height: '100px', width: '300px', padding: '10px' ,resize:"none"}} />
                </Form.Item>
                <Form.Item
                  label='Description of Member Contact Details'
                  name='memberCOntactDetails'
                  rules={[{ required: true, message: 'Description of Member Contact Details' }]}>
                  <textarea defaultValue={mem.memberContact} value={mem.memberContact} disabled={true} style={{ height: '100px', width: '300px', padding: '10px' ,resize:"none"}} />
                </Form.Item>
                <br />
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Popconfirm
                    title="This member will be deleted!"
                    onConfirm={() => deleteMember(index)}>
                    <Button type="primary" danger>
                      Delete Member X
                    </Button>
                  </Popconfirm>
                </div>
              </div>
            )))}
            <br />
            <Form {...formItemLayout} labelAlign="left" form={form5} onFinish={addTeamMember}>
              <Card>
                <Form.Item
                  label='Name of Member'
                  name='name'
                  rules={[{ required: true, message: 'Name of Member is required' }]}>
                  <Input value={memberName} onChange={(e) => setMemberName(e.target.value)} />
                </Form.Item>

                <Form.Item
                  label="Member Photo"
                  name='photo'
                  rules={[
                    {
                      required: false,
                      message: 'This field is required'
                    }
                  ]}>
                  <>
                    <Input type='file' onChange={e => { uploadPhoto(e, 'photo') }} accept=".png,.PNG,.JPG,.jpg,.JPEF,.jpeg" />
                    {memberPhoto?.uploadFilePercentage > 0 &&
                      <Progress percent={memberPhoto?.uploadFilePercentage} />
                    }
                    <div style={{}}>
                      <p>File Uploaded:</p>
                      {memberPhoto && (<h4 key={Math.random()}>{memberPhoto?.name}</h4>)}
                    </div>
                  </>
                </Form.Item>
                <Form.Item
                  label='Description of Member Role'
                  name='memberRole'
                  rules={[{ required: true, message: 'Description of Member Role is required' }]}>
                  <textarea defaultValue={memberRole} value={memberRole} onChange={(e) => setMemberRole(e.target.value)} style={{ height: '100px', width: '300px', padding: '10px' ,resize:"none"}} />
                </Form.Item>
                <Form.Item
                  label='Description of Member Contact Details'
                  name='memberCOntactDetails'
                  rules={[{ required: true, message: 'Description of Member Contact Details' }]}>
                  <textarea defaultValue={memberContact} value={memberContact} onChange={(e) => setMemberContact(e.target.value)} style={{ height: '100px', width: '300px', padding: '10px' ,resize:"none"}}/>
                </Form.Item>

                <Form.Item style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button size='large' type='primary' htmlType="submit" loading={loading}>Submit</Button>
                </Form.Item>
              </Card>
            </Form>
          </Col>
        </Row>
      </>
      )} */}

    </>
  
  )
}
