import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { environment } from '../../environments/environment';
import "./index.css";

class Footer extends Component {
  constructor(props) {
    super(props)
    const date = new Date()
    this.year = date.getFullYear()
  }
  render() {
    return (
      // <div className="wd_scroll_wrap">
      <footer className="foo-bot2">
        {/* <div className="footer-bottom" id="insize"> */}
        {/* <Container> */}
        <Row>
          <div className="foo-bot">
            <div className="col-md-4" >
              <div className="distance" >
                <ul>
                  <Link to="/disclaimer"><li>About Us</li></Link>
                  <Link to="/terms-of-use"> <li>Home</li></Link>
                  <Link to="/privacy-policy"><li>Cookies Policy</li></Link>
                  <Link to="/privacy-policy"><li>Contact Us</li></Link>
                  <a href="/interest-property-company-manager"><li style={{width: '20rem'}}>Interested to Register as Property Company Manager?</li></a>
                </ul>
              </div>
            </div>

            <div className="col-md-4">
              <div className="footback">
                <p id="retapicon">{environment.headerTitle}</p>
                <div id="line1"></div>
                <div id="round"></div>
                <div id="line2"></div>
                <i className="fa fa-envelope" aria-hidden="true" id="maillogo"></i>

                <p id="mailcontent">Stay connected with an email</p>
                <p id="mail">info@cryptoassetrating.com</p>
              </div>
            </div>

            <div className="col-md-4">
              <div className="footbbbb">

                <i className="fa fa-phone-square" aria-hidden="true" id="phonelogo"></i>

                <p id="phonecontent">Call us at any time</p>
                <p id="contact">+ 1 888-666-0441 (US) + 1 888-666-3176 (US)</p>


              </div>
            </div>

          </div>
        </Row>


        {/* </Container> */}
        <Row>
          <div className="foo-bot1">
            <div className="col-md-4 ">
              <ul className="listul">
                <li className="oval"><i className="fa fa-facebook" aria-hidden="true"></i></li>
                <li className="oval"><i className="fa fa-twitter" aria-hidden="true"></i></li>
                <li className="oval"><i className="fa fa-linkedin" aria-hidden="true"></i></li>
                <li className="oval"><i className="fa fa-youtube-play" aria-hidden="true"></i></li>
                <li className="oval"><i className="fa fa-pinterest" aria-hidden="true"></i></li>
                <li className="oval"><i className="fa fa-telegram" aria-hidden="true"></i></li>
              </ul>
            </div>
            <div className="col-md-4" >
              <p className="copyright">{environment.headerTitle} - copyright © 2018</p>
            </div>
            <div className="col-md-4">
              <p className="copyright1">Privacy Policy | Terms & Conditions | Disclaimer</p>
            </div>
          </div>
        </Row>
        {/* </div> */}
      </footer>
      // </div>
    );
  }
}

export default Footer;