import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Typography, Form, Input, Progress, Button, message, Spin, Result } from 'antd';
import { SharedService } from '../../Shared/Shared.service';
import { IssuerCounselService } from '../IssuerCounsel.service';
import { APIResponse, SymbolDetailsAndSTData, TokenConfigurationProcess } from '../../Shared/interfaces';
import { LinkOutlined } from '@ant-design/icons';
import { SecurityTokenRegistryService } from '../../Shared/SecurityTokenRegistery/SecurityTokenRegistry.service';
import { MetamaskService } from '../../Shared/Metamask.service';
import TransactionModal from '../../Shared/TransactionModal';
import { TokenConfigurationService } from '../../TokenConfigurations/TokenConfiguration.service';
import { useParams } from 'react-router-dom';

import { SecurityTokenService } from '../../Shared/SecurityToken/SecurityToken.service';
import MainFacet from '../../Shared/SecurityToken/Facets/MainFacet/index';

const {Title} = Typography;
const sharedService = new SharedService();
const issuerCounselService = new IssuerCounselService();

const securityTokenService = new SecurityTokenService();
const mainFacet = new MainFacet();

const securityTokenRegisteryService = new SecurityTokenRegistryService();
const tokenConfigurationService = new TokenConfigurationService();
const useSelectedWalletContext = () => new MetamaskService().useSelectedWalletContext();

export default () => {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tokenConfigurationProcess, setTokenConfigurationProcess] = useState<TokenConfigurationProcess>();
  const [symbolDetailsAndSTData, setSymbolDetailsAndSTData] = useState<SymbolDetailsAndSTData>();
  const [legendDoc, setLegendDoc] = useState<string[]>();
  const {selectedWallet, networkId} = useSelectedWalletContext();
  const [issuerCounselWallet, setIssuerCounselWallet] = useState('');
  const [fileUploaded, setFileUploaded] = useState();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [transactions, setTransactions] = useState<{submitting?: boolean, receipt?: any, details: string}[]>([]);
  
  const {tokenConfigurationProcessId} = useParams<{tokenConfigurationProcessId: string}>();

  const [filesObj, setFilesObj] = useState(
    {
      legendCreationFile: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null
      },
    }
  );


  useEffect(() => {
    (async () => {
      const response = await tokenConfigurationService.getTokenConfigurationProcessById({tokenConfigurationProcessId});
      // const response = await tokenConfigurationService.getLastTokenConfigurationProcess();
      const _tokenConfigurationProcess: TokenConfigurationProcess = response.data;
      setTokenConfigurationProcess(_tokenConfigurationProcess);
      
      if(!_tokenConfigurationProcess?.tokenSymbol) return setLoading(false);
      
      const _symbolDetailsAndSTData = await securityTokenRegisteryService.getSymbolDetailsAndSTData(_tokenConfigurationProcess.tokenSymbol);

      setSymbolDetailsAndSTData(_symbolDetailsAndSTData);

      if(!_symbolDetailsAndSTData?.symbolDetails.isDeployed) setLoading(false);

      // const _issuerCounselWallet = await securityTokenService.issuerCounsel(_symbolDetailsAndSTData.securityTokenData.contractAddress);
      const _issuerCounselWallet = await mainFacet.issuerCounsel(_symbolDetailsAndSTData.securityTokenData.contractAddress);
      setIssuerCounselWallet(_issuerCounselWallet);


      if(_tokenConfigurationProcess.legendCreationFile?.URL) {
        const URL = _tokenConfigurationProcess.legendCreationFile?.URL;

        const docName = URL.substring(URL.lastIndexOf('/') + 1);
  
        // const _legendDoc = await securityTokenService.getDocument(_symbolDetailsAndSTData.securityTokenData.contractAddress, sharedService.stringToBytes32(docName));
        // setLegendDoc(_legendDoc);
      }

      setLoading(false);

    })();
  }, [tokenConfigurationProcessId]);


  const uploadFile = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
      if(!e.target.files) return;

      const file =  e.target.files[0];
      console.log(file);

      if(!file) return;

      const fileType = sharedService.getFileType(file.name);

      setFilesObj(prev => {
        const current = sharedService.clone(prev);
        current[key].URL = null;
        return current;
      });

    issuerCounselService.uploadFile(file, (uploadFilePercent: number) => {
      setFilesObj(prev => {
        const current = sharedService.clone(prev);
        current[key].uploading = true;
        current[key].uploadFilePercentage = uploadFilePercent;
        return current;
      });

    }, (err) => {
      console.error(err);
      setFilesObj(prev => {
        const current = sharedService.clone(prev);
        current[key].uploading = false;
        return current;
      });
      
    }, async(response: APIResponse) => {

      if (response.success) {
        const URL = response.data.url;
        form.setFieldsValue({[key]: file.name});
        form.setFieldsValue({'legendCreationFile': file.name});
        form.setFieldsValue({'legendCreationFileInput': file.name});

        setFilesObj(prev => {
          const current = sharedService.clone(prev);
          current[key].URL = URL;
          current[key].uploading = false;
          current[key].name = file.name;
          current[key].type = fileType.toLowerCase()
          return current;
        });
        setFileUploaded(URL);
        
      } else {
        console.error(response.error);
        setFilesObj(prev => {
          const current = sharedService.clone(prev);
          current[key].uploading = false;
          return current;
        });
      }

    });
  }



  const createLegend = async(formValue) => {
    const req = sharedService.clone(formValue);
    req.legendCreationFile = {
      name: filesObj.legendCreationFile?.name,
      URL: filesObj.legendCreationFile?.URL,
      type: filesObj.legendCreationFile.type,
    };
    req.tokenConfigurationProcessId = tokenConfigurationProcessId;

    setSubmitting(true);
    const response = await issuerCounselService.createLegend(req);

    if (response.success) {
      message.success('Legend Created successfully');

      setTokenConfigurationProcess(prev => {
        const current = sharedService.clone(prev) as TokenConfigurationProcess;
        current.legendCreationFile = req.legendCreationFile;
        return current;
      });
      
    } else {
      console.error(response.error);
      message.error(response.error);
    }

    setSubmitting(false);
  }


  const setDocument = async() => {
    setIsModalVisible(true);

    setTransactions([
      {details: 'Saving File on Blockchain', submitting: true}
    ]);

    try {

      const URL = tokenConfigurationProcess?.legendCreationFile?.URL as string;

      const docName = URL.substring(URL.lastIndexOf('/') + 1);
      const _name = sharedService.stringToBytes32(docName);
      const _documentHash = sharedService.stringToBytes32( docName.substring(0, docName.lastIndexOf('.')) );

    
      const receipt = await securityTokenService.setDocument(
        symbolDetailsAndSTData?.securityTokenData.contractAddress as string, 
        selectedWallet as string,
        _name,
        URL,
        _documentHash
      );

      if(receipt.status) setLegendDoc([URL, _documentHash, Math.floor(Date.now()/1000).toString()]);

      setTransactions(prev => {
        const current = sharedService.clone(prev);
        current[0].receipt = receipt;
        return current;
      });
      
    } catch (err) {
      console.error(err);
    }

    setTransactions(prev => {
      const current = sharedService.clone(prev);
      current[0].submitting = false;
      return current;
    });
  }


  return (
    <>
      <br/><br/>
      <Row justify="center">
        <Col span={20}>
          {loading && 
            <div style={{textAlign:'center'}}>
              <br/>
              <Spin size='large'/>
            </div>
          }

          {!loading && 
            <Card>
              <Title level={1} style={{textAlign:'center'}}>Create a Legend</Title>
              {/* <Row justify="center">
                <Col span={20}> */}

              {!tokenConfigurationProcess && 
                <Result title={`Token configuration is not created`}/>
              }

              {tokenConfigurationProcess && 
                <Card bordered={false} className='card-shadow'>
                  <br/>
                  <>
                    {!tokenConfigurationProcess.legendCreationFile && 
                      <Form labelAlign="left" form={form} onFinish={createLegend}>
                        <Form.Item
                          label="* Upload the document for creating the legend"
                          name='legendCreationFile'
                          labelCol={{span: 14}}
                          wrapperCol={{span: 10}}
                          rules={[
                            {
                              required: false,
                              message: 'This field is required'
                            }
                          ]}>
                          <>
                            <Input type='file' name="legendCreationFileInput" onChange={e => { uploadFile(e, 'legendCreationFile') }} />
                            {filesObj.legendCreationFile.uploadFilePercentage>0 && 
                              <Progress percent={filesObj.legendCreationFile.uploadFilePercentage} />
                            }
                          </>
                        </Form.Item>

                        <div style={{textAlign:'right'}}>
                          <Button htmlType='submit' size='large' type='primary' loading={submitting}>
                            CREATE A LEGEND
                          </Button>
                        </div>

                      </Form>
                    }

                    {tokenConfigurationProcess.legendCreationFile && 
                    <>
                      <div> 
                        <a 
                          href={tokenConfigurationProcess.legendCreationFile?.URL} 
                          download={tokenConfigurationProcess.legendCreationFile?.name} 
                          target="_blank" 
                          rel="noopener noreferrer">
                          <LinkOutlined /> Legend created. Click here to view it
                        </a>
                        <br/><br/>
                      </div>

                      {symbolDetailsAndSTData?.symbolDetails.isDeployed && selectedWallet?.toLowerCase() !== issuerCounselWallet.toLowerCase() && 
                        <>
                          <Title level={2} style={{textAlign:'center'}}>Wrong selected wallet on metamask</Title>
                          <Result
                            status="error"
                            title = {
                              <p>
                                Select the wallet {' '}
                                <a target="_blank" rel="noopener noreferrer" href={`${sharedService.etherscanURL[networkId as string]}/address/${symbolDetailsAndSTData.symbolDetails.owner}`}>
                                  {sharedService.minifyAddress(symbolDetailsAndSTData.symbolDetails.owner)}
                                </a> 
                                {' '} in order to Create Legend
                              </p>
                            }
                          />
                        </>
                      }

                      {symbolDetailsAndSTData?.symbolDetails.isDeployed && selectedWallet?.toLowerCase() === issuerCounselWallet.toLowerCase() && 
                        <>

                          {(legendDoc && !!legendDoc[0]) && 
                            <Result status="success" title="Legend Creation File saved on blockchain successfully"/>
                          }
                          {(!legendDoc || !legendDoc[0]) && 
                            <Button htmlType='submit' size='large' type='primary' onClick={setDocument} >
                              SAVE LEGEND FILE ON BLOCKCHAIN
                            </Button>
                          }
                          
                        </>
                      
                      }
                    </>
                    }
                  </>

                </Card>
              }
            </Card>

          }
        </Col>
      </Row>

        <TransactionModal
        title = {'File save'}
        transactions = {transactions}
        isModalVisible = {isModalVisible}
        closeModal = {() => setIsModalVisible(false)}
      />
    </>
);
}