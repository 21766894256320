import React, { useState, useEffect } from "react";
import {
  Card,
  Typography,
  message,
} from "antd";
import CardHOC from "../../CardHOC";
import { TeamMemberService } from "../TeamMember.service";
import { AuthService } from "../../Shared/Auth.service";
import { useHistory } from 'react-router-dom';

const { Text } = Typography;
const teamMemberService = new TeamMemberService();
const authService = new AuthService();
const useUserContext = () => authService.useUserContext();

const KYCUpload = () => {

  const { userInfo } = useUserContext();
  const [uniqueURL, setUniqueURL] = useState('');
  const [loading, setLoading] = useState(false);
  const [kycSubmitted, setKYCSubmitted] = useState(false);
  const [kycRejected, setKYCRejected] = useState(false);
  const history = useHistory();

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        if(userInfo?.status?.kyc === 'rejected') {
          setKYCRejected(true);
        } else if(userInfo?.status?.kyc === 'submitted') {
          setKYCSubmitted(true);
        } else {
          let res = await teamMemberService.getKYCDetails({ teamMemberId: userInfo?._id });
          if (res.data.length > 0) {
            history.push('/team-member/vesting-schedule');
          }
          res = await teamMemberService.generateLinkForKYC({
            companyId: userInfo.company.id,
            companyName: userInfo.company.name,
            role: userInfo.role,
            email: userInfo.email,
            userId: userInfo._id,
          });

          if (!res || !res.success || !res.data) {
            message.error("Something went wrong, try again.");
            setLoading(false);
            return;
          }
          setUniqueURL(res.data);
          setKYCSubmitted(false);
        }
      } catch (e) {
        message.error("Something went wrong, try again.");
      }
      setLoading(false);
    })();
  }, [history, userInfo]);

  return (
    <>
      {!kycRejected && !kycSubmitted && (
        <Card loading={loading}>
        <Text>
          we have sent you a link on your registered email. Please open the link
          in your smart phone to complete KYC. If you haven't recieved the link
          you can type in the below given URL directly in your browser to
          complete KYC.
        </Text>
        <br />
        <br />
        < a href={uniqueURL} rel="noopener noreferrer" target="_blank">
          {uniqueURL}
        </a>
        <br />
        <br />
        <Text>
          <b>
            Once you complete KYC please refresh the page to continue your
            investement process.
          </b>
        </Text>
        </Card>)}

      {kycRejected && !kycSubmitted && (<Card loading={loading}>
        <h6>Sorry! your KYC is rejected by marketplace compliance admin! You can not proceed further!</h6>
      </Card>)}

      {!kycRejected && kycSubmitted && (<Card loading={loading}>
        <h6>Your KYC Submission request is pending with marketplace compliance admin. Once he approves it you will get a email notification and then you can proceed further!</h6>
      </Card>)}
    </>
  );
};

export default () => (<CardHOC component={<KYCUpload />} heading="KYC Upload" />);
