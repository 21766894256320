import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Typography,Progress, Form, Input, Button, notification, Spin, Descriptions } from 'antd';
import { IssuerSuperAdminService } from '../IssuerSuperAdmin.service';
import { SharedService } from '../../Shared/Shared.service'
import { AuthService } from '../../Shared/Auth.service';
import { DeleteOutlined } from '@ant-design/icons';


const {Title} = Typography;


const issuerSuperAdminService = new IssuerSuperAdminService();
const sharedService = new SharedService();
const authService = new AuthService();
const useUserContext = () => authService.useUserContext();

export default function BankInfo() {
  const { userInfo } = useUserContext();
  const [submitting, setSubmitting] = useState(false);
  const [bankInfo, setBankInfo] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [isEditionMode, setIsEditionMode] = useState(false);
  const [escrowFiles, setEscrowFiles] = useState<any>();
  const [uploadPercent, setUploadPercent]= useState<any>(0)
  const [disableEdit, setDisableEdit]= useState(false)

  useEffect(() => {
    (async () => {
      setLoading(true);

      const _bankInfo = await issuerSuperAdminService.getBankInfoByCompanyId(userInfo?.company?.id)
      console.log('bank info ',_bankInfo)
      if(_bankInfo.success && _bankInfo.data?._id){
        setBankInfo(_bankInfo.data);
        setIsEditionMode(true);
        if(_bankInfo.data?.status == 'approved' || _bankInfo.data?.status == 'pending'){
          setDisableEdit(true);
        }
      }

      setLoading(false);
    })();
  }, []);



  const saveBankInfo = async(formValue) => {

    console.log(formValue);
    console.log(escrowFiles);
    let data
    if(isEditionMode){
      data = {...bankInfo, escrowDocs: [...bankInfo.escrowDocs, ...(escrowFiles?.escrowDocs || [])]}
    }else{
     data = {...formValue, escrowDocs:escrowFiles.escrowDocs}
    }
    
     
    console.error('final data is ', data)
    // return;
    
    setSubmitting(true);
    const response = await issuerSuperAdminService.updateBankInfoByCompanyId(data,userInfo?.company?.id);

    if (response.success) {
      notification.success({message: 'Success', description: 'Bank Info saved successfully'});
      setBankInfo(data);
      setIsEditionMode(true);
      
    } else {
      notification.error({message: 'Error', description: response.error.message});
      
    }

    setSubmitting(false);
  }

  const uploadFile = async (e) => {
    if (!e.target.files) return;
    const files = e.target.files;
    
    if (!files) return;
    console.error(files)
   
    try {
      const uploadPromises = Object.keys(files).map( key => {
        
        return new Promise((resolve, reject) => {

          issuerSuperAdminService.uploadFile(files[key], (uploadFilePercent) => {
          console.log(uploadFilePercent)
  
        }, (err) => {
          console.error(err);
          reject(err);
  
        }, async (response) => {
  
          if (response.success) {
            console.log('recevied response')
            const firebaseUrl = response.data.url;
            console.log(firebaseUrl);
            console.error(response)
            response.data['name'] =files[key].name;
            response.data['type'] =files[key].type;
            resolve(response.data) 
            /* upload to firebase ends here */
            // if (firebaseUrl?.length > 0) {
            //   setFilesObj((prev) => {
            //     const current = sharedService.clone(prev);
            //     current[key].uploadFilePercentage = 100;
            //     current[key].URL = firebaseUrl;
            //     current[key].uploading = false;
            //     current[key].name = file.name;
            //     current[key].type = fileType.toLowerCase();
            //     return current;
            //   });
            //   let fileDetails = { [key]: { URL: firebaseUrl, name: file.name } };
            //   form.setFieldsValue(fileDetails);
            //   setDocuments((prev) => prev.concat(file.name));
            //   setDocumentsToSubmit((prev) => prev.concat({ URL: firebaseUrl, name: file.name }));
            } else {
              reject(response)
            }
        }
          
        )
        });
      });
      setUploadPercent(10)
      const res = await Promise.all(uploadPromises)
      setUploadPercent(100)
      setEscrowFiles({escrowDocs:res})
      console.error('res: ',res)
    } catch (error) {
      console.error(error);
    }

  };

  const deleteFile = (doc,i)=>{
    console.log('deleting File ',doc)
    // bankInfo.escrowDocs.splice(i,1)
    console.log(bankInfo)
    setBankInfo(prev => ({...prev, escrowDocs:prev.escrowDocs.filter(file => file.url != doc.url) }))
    
  }

  const sendForApproval = async() => {
    try {
      const res = await issuerSuperAdminService.sendBankInfoForApprovalByCompanyId(userInfo?.company?.id)
      notification.success({message: 'Success', description: 'Bank Info send for approval'});
      console.log(res);

    } catch (error) {
      console.log(error)
      notification.error({message: 'Error', description: error});
    }
  }
  return (
    <>
      <br/><br/>
      <Row justify="center">
        <Col span={20}>
          {loading &&  
            <div style={{textAlign:'center'}}>
              <br/>
              <Spin size='large'/>
            </div>
          }
          {!loading && 
            <Card>
              <Title level={1} style={{textAlign:'center'}}>Bank Information</Title>

              {(true) && 
                <Form labelAlign="left" initialValues={isEditionMode? bankInfo : undefined} onFinish={saveBankInfo}>
                  
                  <Form.Item 
                    label="Bank Name"
                    labelCol={{xs: { span: 24 }, sm: {span: 10, push: 2 },md:{span:10,push:0}}}
                    wrapperCol={{xs: { span: 24 }, sm: {span: 8 }}}
                    rules={[
                      {
                        required: true, 
                        message: 'This field is required', 
                        whitespace: true
                      }
                    ]}
                    name='bankName'>
                    <Input disabled={disableEdit} />
                  </Form.Item>


                  <Form.Item 
                    label="Branch Name"
                    labelCol={{xs: { span: 24 }, sm: {span: 10, push: 2 },md:{span:10,push:0}}}
                    wrapperCol={{xs: { span: 24 }, sm: {span: 8 }}}
                    rules={[
                      {
                        required: false, 
                        message: 'This field is required', 
                        whitespace: true
                      }
                    ]}
                    name='branchName'>
                    <Input disabled={disableEdit} />
                  </Form.Item>

                  <Form.Item 
                    label="Account No"
                    labelCol={{xs: { span: 24 }, sm: {span: 10, push: 2 },md:{span:10,push:0}}}
                    wrapperCol={{xs: { span: 24 }, sm: {span: 8 }}}
                    rules={[
                      {
                        required: true, 
                        message: 'This field is required', 
                        whitespace: true
                      }
                    ]}
                    name='accountNo'>
                    <Input disabled={disableEdit} type="number"/>
                  </Form.Item>

                  <Form.Item 
                    label="IFSC Code"
                    labelCol={{xs: { span: 24 }, sm: {span: 10, push: 2 },md:{span:10,push:0}}}
                    wrapperCol={{xs: { span: 24 }, sm: {span: 8 }}}
                    rules={[
                      {
                        required: true, 
                        message: 'This field is required', 
                        whitespace: true
                      }
                    ]}
                    name='IFSCCode'>
                    <Input disabled={disableEdit}/>
                  </Form.Item>

                  <Form.Item 
                    label="Account Holder"
                    labelCol={{xs: { span: 24 }, sm: {span: 10, push: 2 },md:{span:10,push:0}}}
                    wrapperCol={{xs: { span: 24 }, sm: {span: 8 }}}
                    rules={[
                      {
                        required: true, 
                        message: 'This field is required', 
                        whitespace: true
                      }
                    ]}
                    name='accountHolder'
                  >
                    <Input disabled={disableEdit} />
                  </Form.Item>

                  <Form.Item 
                    label="Upload Escrow Docs"
                    labelCol={{xs: { span: 24 }, sm: {span: 10, push: 2 },md:{span:10,push:0}}}
                    wrapperCol={{xs: { span: 24 }, sm: {span: 8 }}}
                    style={{alignItems:'baseline'}}
                    rules={[
                      {
                        required: false, 
                        message: 'This field is required', 
                      }
                    ]}
                    name='escrowDocs'>
                    <>
                      <Input
                        type="file"
                        onChange={(e) => {
                          uploadFile(e);
                        }}
                        multiple={true}
                        style={{ marginTop: "20px" }}
                      />
                      {uploadPercent > 0  && (
                        <Progress
                          percent={uploadPercent}
                        />
                      )}
                    </>
                  </Form.Item>

                  {isEditionMode &&
                  <div className="escrowFilesList">
                    {bankInfo.escrowDocs?.map((doc,i) => {
                      return<>
                       <div style={{textAlign:'left',cursor:'pointer'}}><span onClick={()=>deleteFile(doc,i)}><DeleteOutlined /></span> <a href={doc.url} target='_blank'>{doc.name}</a><br /></div>
                      </>
                      
                    })}
                  </div>}

                  <div style={{textAlign:'right'}}>
                   {isEditionMode && <Button  size='large' type='primary' disabled={disableEdit} onClick={sendForApproval} loading={submitting}>
                      Send For Approval
                    </Button>}&nbsp;&nbsp;
                    <Button htmlType='submit' size='large' type='primary' disabled={(uploadPercent < 100 && !isEditionMode) || disableEdit} loading={submitting}>
                      {isEditionMode ? 'UPDATE' : 'SAVE'}
                    </Button>
                  </div>
                </Form>
              }

            </Card>
          }
        </Col>
      </Row>
    </>
  );
}