import React, { useState } from 'react';
import { Modal, Spin, Divider, Input, Button, Descriptions,Row,Col } from 'antd';
import { SharedService } from '../../Shared/Shared.service';
import moment from 'moment';

const {TextArea} = Input

const sharedService = new SharedService();

const formatPhoneNumber = phoneNumberString => {
  var match = phoneNumberString.match(/^(\d{1,5})(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return  `+(${match[1]}) ${match[2]}-${match[3]}-${match[4]}`
  }
  return null
}

export default ({record, reviewRegistrationReq, ...props}) => {
  const [comments, setComments] = useState<string>();
  return (
    <Modal 
      width={700} 
      {...props}
      footer={record?.status === 'pending'? 
      [
        <Button key="reject" danger disabled={record?.verifiying} loading={record?.rejecting} onClick={() => reviewRegistrationReq('rejected', comments || null)}>
          Reject
        </Button>,
        <Button key="approve" style={{backgroundColor:"#142A44",color:"#fff"}} disabled={record?.rejecting} loading={record?.verifiying} onClick={() => reviewRegistrationReq('verified', comments || null)}>
          Approve
        </Button>
      ] : 
      [
        <Button key="close" onClick={props.onCancel}>
          Close
        </Button>
      ]}>
            {record && 
        <Spin spinning={false} size='large'>
          <div className='modal-details'>
          <div style={{backgroundColor:'#142A44',padding:'15px'}}>
                  <sub style={{color:'white',fontWeight:'bold',fontSize:'35px',fontFamily:'Arial, Helvetica, sans-serif'}}>Issuer Details</sub>
              </div>
              <br/>
              <Row>
                <Col span={8}>
                <div>
                  <sub style={{color:'#666770',fontFamily:'Arial, Helvetica, sans-serif',textAlign:'left',fontSize:'15px'}}>First Name</sub>
                  <br/>
                  <sub style={{color:'#050505',fontSize:'20px',textAlign:'left'}}>{record.firstName}</sub>
                </div>
              </Col>
              <Col span={8}>
                <div>
                  <sub style={{color:'#666770',fontFamily:'Arial, Helvetica, sans-serif',textAlign:'left',fontSize:'15px'}}>Last Name</sub>
                  <br/>
                  <sub style={{color:'#050505',fontSize:'20px',textAlign:'left'}}>{record.lastName}</sub>
                </div>
              </Col>
              <Col span={8}>
                <div>
                  <sub style={{color:'#666770',fontFamily:'Arial, Helvetica, sans-serif',textAlign:'left',fontSize:'15px'}}>Company</sub>
                  <br/>
                  <sub style={{color:'#050505',fontSize:'20px',textAlign:'left'}}>{record.companyName}</sub>
                </div>
              </Col>
              </Row>
              <br/>
              <Row>
                <Col span={16}>
                <div>
                  <sub style={{color:'#666770',fontFamily:'Arial, Helvetica, sans-serif',textAlign:'left',fontSize:'15px'}}>Email</sub>
                  <br/>
                  <sub style={{color:'#050505',fontSize:'20px',textAlign:'left'}}>{record.email}</sub>
                </div>
              </Col>
              <Col span={8}>
                <div>
                  <sub style={{color:'#666770',fontFamily:'Arial, Helvetica, sans-serif',textAlign:'left',fontSize:'15px'}}>Phone</sub>
                  <br/>
                  <sub style={{color:'#050505',fontSize:'20px',textAlign:'left'}}>{formatPhoneNumber(record.phone)}</sub>
                </div>
              </Col>
              </Row>
              <br/>
              <Row>
                <Col span={8}>
                <div>
                  <sub style={{color:'#666770',fontFamily:'Arial, Helvetica, sans-serif',textAlign:'left',fontSize:'15px'}}>Creation Date</sub>
                  <br/>
                  <sub style={{color:'#050505',fontSize:'20px',textAlign:'left'}}>{moment(record.creationTS).format('LLL')}</sub>
                </div>
              </Col>
              </Row>
              {/* <Col span={8}>
                <div>
                  <sub style={{color:'#666770',fontFamily:'Arial, Helvetica, sans-serif',textAlign:'left',fontSize:'15px'}}>Amount To Raise</sub>
                  <br/>
                  <sub style={{color:'#050505',fontSize:'20px',textAlign:'left'}}>{Number(record.amountToRaise).toLocaleString()}</sub>
                </div>
              </Col>
              <Col span={8}>
                <div>
                  <sub style={{color:'#666770',fontFamily:'Arial, Helvetica, sans-serif',textAlign:'left',fontSize:'15px'}}>Underlying Asset</sub>
                  <br/>
                  <sub style={{color:'#050505',fontSize:'20px',textAlign:'left'}}>{sharedService.underlyingAssetName(record.underlyingAsset)}</sub>
                </div>
              </Col>
              
              <br/>
              <Row>
                <Col span={8}>
                <div>
                  <sub style={{color:'#666770',fontFamily:'Arial, Helvetica, sans-serif',textAlign:'left',fontSize:'15px'}}>Tentative Date</sub>
                  <br/>
                  <sub style={{color:'#050505',fontSize:'20px',textAlign:'left'}}>{moment(record.tentativeDate).format('LLL')}</sub>
                </div>
              </Col>
              <Col span={8}>
                <div>
                  <sub style={{color:'#666770',fontFamily:'Arial, Helvetica, sans-serif',textAlign:'left',fontSize:'15px'}}>Creation Date</sub>
                  <br/>
                  <sub style={{color:'#050505',fontSize:'20px',textAlign:'left'}}>{moment(record.creationTS).format('LLL')}</sub>
                </div>
              </Col>
              </Row> */}
              <br/>
              {record?.status === 'pending' && 
              <>
              <Divider orientation="left">Comments</Divider>
              <TextArea value={comments} onChange={e => setComments(e.target.value)}></TextArea>
              </>
              }
              {record?.status !== 'pending' && 
              <>
              <Divider orientation="left">Comments</Divider>
              <p>{ record.comments ?(<>{record.comments}</>):(<>{'---'}</>)}</p>
              </>
              }
          </div>
        </Spin> 
      }

    </Modal>
  )
};
