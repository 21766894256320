import React, { useState, useEffect } from "react";
import { Row, Col, Card, Typography, Tabs, Button, Spin, Table, Modal, Form, Input, notification } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { AuthService } from "../Shared/Auth.service";
import { useHistory, useLocation } from "react-router-dom";
import { SharedService } from "../Shared/Shared.service";
import { User } from "../Shared/interfaces";
import { AdminService } from "../IssuerSuperAdmin/Admin/Admin.service";
import { IssuerTokenAdminService } from "../IssuerTokenAdmin/IssuerTokenAdmin.service";

const { Title } = Typography;

const adminService = new AdminService();
const sharedService = new SharedService();
const issuerTokenAdminService = new IssuerTokenAdminService();

const ManagementList = () => {
    const history = useHistory();
    const [admins, setAdmins] = useState<User[]>();
    const [adminRoles, setAdminRoles] = useState<any[]>();
    const [modalForm] = Form.useForm();
    const [isInviteModalVisible, setIsInviteModalVisible] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [companyList, setCompanyList] = useState<any>();
    const [repaint, setRepaint] = useState<boolean>(false);

    const dataSource = companyList?.map(company => {
      return {
        key: company._id,
        companyname: company.companyName  ? company.companyName : 'NA',
        email:company.email,
        status: company.status ? company.status :'PENDING'
      }
    })
    
    const columns = [
      {
        title: 'Company Name',
        dataIndex: 'companyname',
        key: 'companyname',
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
      },
      {
        title: 'Action',
        key: 'action',
        render: (record) => {
            return (
              <>
                  {record.status =='PENDING' ? <Button
                    type="link"
                    onClick={() => approveCompany(record)}
                  >
                    Approve
                  </Button>
                  : 'Approved'
                  ? record.status =='INVITED' : ''
                  }
              </>
            );
          },
      },
    ];

    useEffect(() => {
      (async () => {
        const response = await adminService.getAdmins();
  
        const _admins: User[] = response.data;
  
        const _adminRoles = sharedService.adminRolesOptions.map((roleOpt) => {
          const result = sharedService.clone(roleOpt);
          result["total"] = _admins.filter((admin) =>
            admin.roles.includes(roleOpt.value as any)
          ).length;
  
          return result;
        });
  
        console.log(_adminRoles);
  
        setAdmins(_admins);
        setAdminRoles(_adminRoles);
      })();

      (async()=> {
        const companyList = await issuerTokenAdminService.getCompanyList();
        console.log('listing comp..',companyList)
        setCompanyList(companyList.data)
      })();
    }, [repaint]);


    const openInviteModal = () => {
        modalForm.resetFields();
        setIsInviteModalVisible(true);
      }

    const inviteCompany = async(formValue) => {
        setLoading(true);
        //api call here
        const result = await issuerTokenAdminService.inviteCompany(formValue);
        console.log(result)
        if(result){
          notification.success({ message: 'Success! invitation sent' });
          setIsInviteModalVisible(false)
        }
        setLoading(false);
      }

      const approveCompany = async(record) =>{
        setLoading(true);
        // console.log('approving company with data ' + record.key);return;
        const result = await issuerTokenAdminService.approveCompany(record.key);
        console.log(result)
        if(result){
          notification.success({ message: 'Approved successfully!' });
          setRepaint(prev => !prev)
        }
        setLoading(false);
      }

    return (
      <>
        <br />
        <br />
        <Row justify="center">
          <Col span={23}>
            {!admins && (
              <div style={{ textAlign: "center" }}>
                <Spin size="large" />
              </div>
            )}
            {admins && (
              <Card>
                <Title level={1} style={{ textAlign: "center" }}>
                  Company Management
                </Title>
                <Row>
                  <Col
                    span={3}
                    offset={21}
                    style={{ display: "flex", justifyContent: "end" }}
                  >
                    <Button
                      type="primary"
                      size="large"
                      onClick={() => openInviteModal()}
                    >
                      Invite New Company
                    </Button>
                  </Col>
                </Row>

                <Table dataSource={dataSource} columns={columns} />

                <Modal
                  title={`Invite New Company`}
                  okText="SUBMIT"
                  cancelText="CANCEL"
                  visible={isInviteModalVisible}
                  onOk={() => modalForm.submit()}
                  onCancel={() => {
                    setIsInviteModalVisible(false);
                  }}
                  okButtonProps={{
                    loading: loading,
                  }}
                >
                  <Form
                    form={modalForm}
                    autoComplete={"off"}
                    onFinish={inviteCompany}
                  >
                    <Form.Item
                      name={"email"}
                      label={"Email"}
                      wrapperCol={{ xs: { span: 24 }, sm: { span: 14 } }}
                      rules={[
                        {
                          required: true,
                          message: "This field is required",
                        },
                      ]}
                    >
                      <Input style={{width:'100%'}} placeholder={"Enter Company Email"} />
                    </Form.Item>
                  </Form>
                </Modal>

              </Card>
            )}
          </Col>
        </Row>
      </>
    );

}

export default ManagementList