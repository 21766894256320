import React, { useEffect, useState } from "react";
import axios from "axios";
import { APIResponse } from "../Shared/interfaces";
import { environment } from "../../environments/environment";
import { SharedService } from "../Shared/Shared.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

declare global {
  interface Window {
    ethereum: any;
  }
}

const sharedService = new SharedService();

function ConnectYourWallet() {
  const APIURL = environment.APIURL;

  useEffect(() => {
    getUsers();
    getWalletAdress();
  }, []);

  const [previousWalletAdress, setPreviousWalletAdress] = useState("");
  const [walletAdress, setWalletAdress] = useState("");
  const [userId, setUserId] = useState("");

  const getWalletAdress = () => {
    window.ethereum
      .request({ method: "eth_requestAccounts" })
      .then((res: any) => {
        // Return the address of the wallet
        console.log("Wallet address: ", res);
        setWalletAdress(res[0]!.toString());
      });
    window.ethereum.on("accountsChanged", function (accounts) {
      // Time to reload your interface with accounts[0]!
      console.log("accounts: ", accounts[0]);
      setWalletAdress(accounts[0]);
    });
  };

  const getUsers = async () => {
    axios
      .post<any, APIResponse>(
        `${APIURL}/users/getUser`,
        {},
        { headers: await sharedService.getAuthHeader() }
      )
      .then((response: any) => {
        console.log("getUsers Response: ", response);
        setUserId(response.data._id);
        setPreviousWalletAdress(response.data.walletAddress);
      });
  };

  const saveWalletAddress = async () => {
    axios
      .post<any, APIResponse>(
        `${APIURL}/account/addOrUpdateUserWalletAddress`,
        {
          userId: userId,
          walletAddress: walletAdress,
        },
        { headers: await sharedService.getAuthHeader() }
      )
      .then((response: any) => {
        console.log("saveWalletAddress Response: ", response);
        if (response.success == true) {
          toast("Successfully saved your wallet!");
        }
        if (response.success == false) {
          toast(response.error.message);
        }
      });
  };

  return (
    <>
      <div
        style={{
          display: "table",
          marginLeft: "auto",
          marginRight: "auto",
          paddingTop: "1rem",
          width: "100%",
          padding: "20px",
        }}
      >
        <h1>Connect your Wallet</h1>
        <span style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <span>Previous Wallet Address: </span>
          <input
            style={{ width: "65%", padding: "1px 5px" }}
            value={previousWalletAdress}
            disabled
          ></input>
        </span>
        <br></br>
        <span style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <span>New Wallet Address: </span>
          <input
            style={{ width: "65%", padding: "1px 5px" }}
            value={walletAdress}
            disabled
          ></input>
        </span>
        <span
          style={{
            background: "blue",
            color: "white",
            padding: "8px",
            borderRadius: "8px",
            cursor: "pointer",
            marginTop: "15px",
            display: "table",
          }}
          onClick={saveWalletAddress}
        >
          Save your Wallet
        </span>
      </div>
      <ToastContainer />
    </>
  );
}

export default ConnectYourWallet;
