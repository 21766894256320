import React, { useEffect, useState } from "react";
import { Form, Button, Input, Select, message, Typography, Checkbox } from "antd";
import { InvestorService } from "../Investor.service";
import eversign from "./Eversign";
import { useHistory } from "react-router";
import { SharedService } from "../../Shared/Shared.service";
import ppm from "../../../assets/Documents/CAR - PPM.pdf"

const sharedService = new SharedService();
const { Text } = Typography;
const { Item } = Form;
const { Option } = Select;
const investorService = new InvestorService();
const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 8,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 6,
  },
};
const validation = [
  {
    required: true,
    message: "Please enter a valid amount",
  },
  {
    validator: (text, value) => {
      let isNum = /^\d+$/.test(value);
      if (!isNum)
        return new Promise(async (resolve, reject) =>
          reject("Please enter a valid amount")
        );
      else return new Promise(async (resolve, reject) => resolve());
    },
  },
];

export default ({ token, userInfo, selectedTokenSymbol }) => {
  const [localCurrency, setLocalCurrency] = useState('Rs');
  // const [completePaymentURL, setCompletePaymentURL] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [ppmAccepted, setPPMAccepted] = useState(false);
  const [signURL, setSignURL] = useState(null)
  const [paymentData, setPaymentData] = useState(null)
  const history = useHistory();

  // useEffect(() => {
  //   (async () => {
  //     const response = await investorService.getInvestSignURL(userInfo);
  //     await setSignURL(response.data);
  //   })();
  // }, []);

  const fetchSignUrl = async (formData) => {
    setPaymentData(formData)
    try {
      const noOfTokens = Number((formData.amountToInvest / token.pricePerToken) * 100)
      const data = {...userInfo, noOfTokens};
      data.tokenCompanyId = token.companyId;
      const response = await investorService.getInvestSignURL(data)
      setSignURL(response.data)
    } catch (e) {
      console.log(e)
      message.error("Something Went Wrong")
    }
  }

  const makePayment = async () => {
    const data = paymentData
    setSubmitting(true);
    const dataToSend = {
      name: token.tokenName,
      description: token.stock,
      local_price: {
        amount: data.amountToInvest,
        currency: localCurrency,
      },
      pricing_type: "fixed_price",
      metadata: {
        customer_id: userInfo._id,
        customer_name: userInfo.firstName + " " + userInfo.lastName,
      },
      redirect_url: null,
      cancel_url: null,
      email: userInfo?.email,
      tokenSymbol: selectedTokenSymbol
    };
    const response = await investorService.payUsingCoinbase(dataToSend);
    if (response.success && response.data) {
      console.log(response.data);
      window.location.href = response.data.data.hosted_url
      // setCompletePaymentURL(response.data.data.hosted_url);
    } else {
      message.error("Something went wrong please try again");
    }
    setSubmitting(false);
  };

  const CurrencySelector = () => {
    const [currencyList, setCurrencyList] = useState(null);
    useEffect(() => {
      (async () => {
        const response = await sharedService.getCurrencyList();
        if (response.success && response.data) {
          setCurrencyList(response.data);
        } else {
          setCurrencyList([]);
          message.error("Failed to load currency list");
        }
      })();
    }, []);
    return (
      <>
        <Select
          defaultValue={localCurrency}
          onChange={(e) => {
            console.log(e);
            setLocalCurrency(e);
          }}
          className="select-after"
          loading={!currencyList}
          showSearch
        >
          {currencyList?.map((data, index) => (
            <Option value={data?.currency?.trim()} key={index}>
              {data?.currency?.trim()}
            </Option>
          ))}
        </Select>
      </>
    );
  };

  // const onSign = () => {
  //   window.location.href = completePaymentURL
  // }

  return (
    <>
      <div style={{ textAlign: "left" }}>
        <Text>
          <b>You have to pay the appropiate amount cryptocurrency</b>
        </Text>
        <br />
        <br />
        {signURL === null && 
          <Form {...layout} onFinish={fetchSignUrl}>
          <Item
            name="amountToInvest"
            label="Amount to Invest"
            rules={validation}
          >
            <Input
              addonAfter={<CurrencySelector />}
              disabled={submitting}
            ></Input>
          </Item>
          <Item>
          	<Checkbox
          		checked={ppmAccepted}
          		onChange={e => setPPMAccepted(e.target.checked)}
          	>
          	<a target="#" href={ppm}>I understand and agree to the PPM of the offering</a>
          	</Checkbox>
          </Item>
          <Item {...tailLayout}>
            <Button disabled={!ppmAccepted} htmlType="submit" type="primary" loading={submitting}>
              Submit
            </Button>
            {"  "}
            {/* {completePaymentURL && (
              <a onClick={fetchSignUrl}>click here to proceed</a>
            )} */}
          </Item>
        </Form>
        }
        <div id="embedInvestSignDivision">
            <>
          {signURL !== null &&
            eversign.open({
              url: signURL,
              containerID: "embedInvestSignDivision",
              width: 750,
              height: 800,
              events: {
                loaded: () => console.log("loading successful"),
                signed: makePayment,
                error: () => console.log("EVERSIGN ERROR"),
                declined: () => console.log("declined"),
              },
            })
          }
        </>
        </div>
      </div>
    </>
  );
};
