import React from 'react'
// import { lazy } from '@loadable/component';
import { Switch, Route, useRouteMatch } from "react-router-dom";


import Login from './Login';
import Register from './Register';
import VerifyEmail from './VerifyEmail';
import ResendEmailVerifcationCode from './ResendEmailVerifcationCode';
import DeviceRegistration from './DeviceRegistration'
import TOTP from './TOTP';
import TwoFA from './2FA';
import ForgotPassword from './Password/ForgotPassword';
import ResetPassword from './Password/ResetPassword';
import ChangePassword from './Password/ChangePassword';
import EmailVerification from './EmailVerification/EmailVerification.js';
// const Register = lazy(() => import('./Register'));




export default () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/register/user/:email`}>
        <Register />
      </Route>
      <Route path={`${path}/register/:role`}>
        <Register />
      </Route>
      <Route path={`${path}/login`}>
        <Login />
      </Route>
      <Route path={`${path}/forgot-password`}>
        <ForgotPassword />
      </Route>
      <Route path={`${path}/reset-password/:resetToken`}>
        <ResetPassword />
      </Route>
      <Route path={`${path}/change-password`}>
        <ChangePassword />
      </Route>
      <Route path={`${path}/verify-email/:code`}>
        <VerifyEmail />
      </Route>
      <Route path={`${path}/TOTP`}>
        <TOTP />
      </Route>
      <Route path={`${path}/2FA`}>
        <TwoFA />
      </Route>
      <Route path={`${path}/resend-email-verification-code`}>
        <ResendEmailVerifcationCode />
      </Route>
      <Route path={`${path}/device-registration`}>
        <DeviceRegistration />
      </Route>
      <Route path={`${path}/verify-email-otp/:userId`}>
        <EmailVerification />
      </Route>
    </Switch>
  )
}