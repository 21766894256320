import React, { useEffect, useState } from "react";
import moment from "moment";
import { Card } from "antd";
import axios from "axios";
import { APIResponse } from "../../Shared/interfaces";
import { AuthService } from "../../Shared/Auth.service";
import { InvestorService } from "../Investor.service";
import { SharedService } from "../../Shared/Shared.service";
import { environment } from "../../../environments/environment";

declare global {
  interface Window {
    ethereum: any;
  }
}
const authService = new AuthService();
const useUserContext = () => authService.useUserContext();
const investorService = new InvestorService();
const sharedService = new SharedService();

function TokenTransferRequest() {
  const APIURL = environment.APIURL;
  const [companyId, setCompanyId] = useState("");
  const [investorId, setinvesterId] = useState("");

  const [newWalletAdress, setNewWalletAdress] = useState("");
  const getWalletAdress = () => {
    window.ethereum.request({ method: "eth_requestAccounts" }).then((res) => {
      // Return the address of the wallet
      console.log("Wallet address: ", res);
      setNewWalletAdress(res[0]!.toString());
    });
    window.ethereum.on("accountsChanged", function (accounts) {
      // Time to reload your interface with accounts[0]!
      console.log("accounts: ", accounts[0]);
      setNewWalletAdress(accounts[0]);
    });
  };

  const { userInfo, setUserInfo } = useUserContext();
  const [tokenName, setTokenName] = useState("");
  const [tokenCount, setTokenCount] = useState(0);
  const [oldWalletAddress, setOldWalletAddress] = useState("");
  const [userPreviousReputation, setUserPreviousReputation] =
    useState("Full Result upr");
  const [reasonForReputation, setReasonForReputation] =
    useState("Full Result erd");
  const [investorDetails, setInvestorDetails] = useState(null);
  useEffect(() => {
    (async () => {
      const response = await investorService.getKYCDetails({
        investorId: userInfo?._id,
      });
      setInvestorDetails(response.data["0"]);
      getWalletAdress();
      getUsers();
    })();
  }, []);

  const getUsers = async () => {
    axios
      .post<any, APIResponse>(
        `${APIURL}/users/getUser`,
        {},
        { headers: await sharedService.getAuthHeader() }
      )
      .then((response: any) => {
        setCompanyId(response.data.company.id);
        setinvesterId(response.data._id);
        console.log("companyId: ", response);
      });
  };

  const allApprovedOrRejectedKycRequest = async () => {
    axios
      .get<any, APIResponse>(
        `${APIURL}/issuerSuperAdmin/getAllApprovedOrRejectedKYCRequestForTokenTransfer?companyId=${companyId}`,
        { headers: await sharedService.getAuthHeader() }
      )
      .then((response: any) => {
        console.log("allApprovedOrRejectedKycRequest Response: ", response);
      });
  };

  const allPendingKycRequest = async () => {
    axios
      .get<any, APIResponse>(
        `${APIURL}/issuerSuperAdmin/getPendingApprovalKYCRequestForTokenTransfer?companyId=${companyId}`,
        { headers: await sharedService.getAuthHeader() }
      )
      .then((response: any) => {
        console.log("allPendingKycRequest Response: ", response);
      });
  };

  const submitNewKYC = async () => {
    axios
      .post<any, APIResponse>(
        `${APIURL}/issuerSuperAdmin/submitKYCDetailsForRecoveryOfToken?companyId=${companyId}`,
        {
          investorId: investorId,
          oldWalletAddress: oldWalletAddress,
          newWalletAdress: newWalletAdress,
          tokenName: tokenName,
          tokenCount: tokenCount,
        },
        { headers: await sharedService.getAuthHeader() }
      )
      .then((response: any) => {
        if (response.success == true) {
          alert(response.data);
        }
        if (response.success == false) {
          alert(response.error.message);
        }
        allApprovedOrRejectedKycRequest();
        allPendingKycRequest();
      });
  };

  return (
    <>
      <div style={{ display: "grid" }}>
        <p
          style={{
            fontSize: "22px",
            fontWeight: "bold",
            textAlign: "left",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "20px",
          }}
        >
          Token Transfer Request
        </p>
        <Card
          style={{
            margin: "auto",
            width: 900,
            boxShadow:
              "0 4px 12px 0 rgba(0, 0, 0, .1), 0 6px 20px 0 rgba(0, 0, 0, 0.1)",
          }}
        >
          <table style={{ margin: "auto", width: "100%" }}>
            <tr>
              <td style={{ textAlign: "left", fontWeight: "bold" }}>
                Old Wallet Address
              </td>
              <td style={{ textAlign: "left", height: "2rem" }}>
                <input
                  style={{ width: "100%", padding: "2px" }}
                  value={oldWalletAddress}
                  onChange={(e) => setOldWalletAddress(e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "left", fontWeight: "bold" }}>
                New Wallet Adress
              </td>
              <td style={{ textAlign: "left", height: "2rem" }}>
                <input
                  style={{ width: "100%", padding: "2px" }}
                  value={newWalletAdress}
                  disabled
                ></input>
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "left", fontWeight: "bold" }}>
                Token Name
              </td>
              <td style={{ textAlign: "left", height: "2rem" }}>
                <input
                  value={tokenName}
                  onChange={(e) => setTokenName(e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "left", fontWeight: "bold" }}>
                Token Count
              </td>
              <td style={{ textAlign: "left", height: "2rem" }}>
                <input
                  value={tokenCount}
                  onChange={(e) => setTokenCount(parseInt(e.target.value))}
                />
              </td>
            </tr>
          </table>
        </Card>
        <button
          style={{
            background: "blue",
            color: "white",
            width: "fitontent",
            padding: "4px 16px",
            fontSize: "16px",
            fontWeight: "bold",
            borderRadius: "6px",
            margin: "10px auto 10px auto",
          }}
          onClick={submitNewKYC}
        >
          Submit New KYC
        </button>
      </div>
    </>
  );
}

export default TokenTransferRequest;
