import React, { useState, useEffect } from 'react';
import { Typography, Form, Card, Row, Input, Select, DatePicker, Radio, Button, Modal, Col, Progress, Result, message, Descriptions, Spin ,notification} from 'antd';
import { IssuerSuperAdminService } from '../IssuerSuperAdmin.service';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { SharedService } from '../../Shared/Shared.service';
import { Country, IssuerBasicInfoVerificationRequest, APIResponse, IssuerRegistrationRequest } from '../../Shared/interfaces';
import { AuthService } from '../../Shared/Auth.service';
import { resolve } from 'path';
import { rejects } from 'assert';
import './memberInfo.css';

const { Text, Title } = Typography;
const { Option } = Select;

const sharedService = new SharedService();
const issuerSuperAdminService = new IssuerSuperAdminService();

const AddMember = (props) => {
   const [countries, setCountries] = useState();
   const [form] = Form.useForm();
   const [cCountry, setCCountry] = useState(false);
   const [usaStates, setUSAStates] = useState();
   const companyId = props.companyId;

   const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 12 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 }
      }
    };

    useEffect(() => {
      (async () => {
         const countryRes = await sharedService.getCountries();
         setCountries(countryRes.data);
         const responsee = await sharedService.getUSAStates();
         setUSAStates(responsee.data);
      })();
   }, []);

    const currentCountry = (value) => {
      if (value == 'United States of America') setCCountry(true);
      else setCCountry(false);
    }
  
   const submitMember = async (_form) => {
   const form = { ..._form };
   form.companyId = companyId;
   form.locale = "en";
   form.creationTS = new Date().getTime();
   form.role = "companyMember";
   props.setLoading(true);

   const response = await issuerSuperAdminService.saveCompanyMember(form);

   if (response.success) {
      notification.open({
         message: 'Success',
         description: 'Success',
         duration: 0,
       });
       props.setMemberModal(false);
       props.setRecord();

   } else {
      //message.error(response.error.message);
      notification.open({
        message: 'Error',
        description: response.error.message,
        duration: 0,
      });
   }

   props.setLoading(false);

   }

   return (
      <>
         <Title level={1} style={{ color: '#186AB4', textAlign: 'center', marginTop:'20px' }}>Add Member Company Representative</Title>
         <br/><br/>
         <Row justify="center">
            <Col span={24}>
              <Form {...formItemLayout} labelAlign="left" form={form} onFinish={submitMember}>
                  <Card>
                     <Form.Item
                        label={`First Name`}
                        name="firstName"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input placeholder='Enter First Name' />
                      </Form.Item>

                      <Form.Item
                        label={`Last Name`}
                        name="lastName"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input placeholder='Enter Last Name' />
                      </Form.Item>

                     <Form.Item
                        label={`Email Address`}
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input placeholder='Enter Email Address' />
                      </Form.Item>

                      <Form.Item
                        label='Country'
                        name="country"
                        validateStatus={countries ? '' : 'validating'}
                        hasFeedback
                        help={countries ? '' : "Loading Countries..."}
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                          }
                        ]}>
                          <Select autoClearSearchValue showSearch onChange={currentCountry} placeholder="Select Country">
                            {countries?.sort(function (a, b) { return b.name['en'].localeCompare(a.name['en']); }).map(country =>
                              <Option key={country._id} value={country.name['en']}>{country.name['en']}</Option>
                            )}
                          </Select>
                      </Form.Item>

                      {cCountry &&
                        <Form.Item
                          label='State'
                          name="state"
                          rules={[
                            {
                              required: true,
                              message: 'This field is required',
                              whitespace: true
                            }
                          ]}>

                          <Select showSearch placeholder="Select state"  >
                            {usaStates?.map(state =>
                              <Option key={state?.state_id} value={state.state_name}>{state.state_name}</Option>
                            )}
                          </Select>

                        </Form.Item>
                      }

                      {!cCountry &&
                        <Form.Item
                          label='State'
                          name="state"
                          rules={[
                            {
                              required: true,
                              message: 'This field is required',
                              whitespace: true
                            }
                          ]}>
                          <Input />
                        </Form.Item>
                      }
                     
                     <Form.Item 
                        label='Phone number'
                        name="phone"
                        rules={[
                          {
                            required: true,
                            message: 'Enter Phone number here',
                            whitespace: true
                          },
                          {
                            validator: (rule, value) => {
                              const strongRegex = /^[+]{0,1}[0-9\s-]{0,4}[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]{3,10}$/;
                              if(strongRegex.test(value)) return Promise.resolve();
                              return Promise.reject('Invalid phone number');
                            }
                          }
                        ]}>
                          <PhoneInput country={"us"}/>
                      </Form.Item>
                      <br /><br />
                     <Form.Item>
                        <Button size='large' style={{ marginLeft: '300px' }} type='primary' htmlType="submit" loading={props.loading}>Submit</Button>
                     </Form.Item>
                     <br /><br />
                  </Card>
               </Form>
            </Col>
         </Row>
      </>
   )
}

export default AddMember
