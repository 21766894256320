import React, { useState, useEffect } from 'react';
import { useHistory,useParams } from 'react-router-dom';
import { Typography, Form, Card, Row, Input, Select, DatePicker, Radio, Button, Modal, Col, Progress, Result, message, Descriptions, Spin ,notification} from 'antd';
import { IssuerTokenAdminService } from '../IssuerTokenAdmin/IssuerTokenAdmin.service';
import { SharedService } from '../Shared/Shared.service';
import { environment } from '../../environments/environment';

const { Text, Title } = Typography;

const AddManagementCompany = () => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    let   [showOtherField,setShowOtherFieldVisibility] = useState(false);
    const history = useHistory()
    const {companyId } = useParams()
    const [propertyFiles, setPropertyFiles] = useState(
      {
        certificateOfIncorporation: {
          uploading: false,
          uploadFilePercentage: 0,
          URL: null,
          name: null,
          type: null
        },
        TaxIdDoc: {
          uploading: false,
          uploadFilePercentage: 0,
          URL: null,
          name: null,
          type: null
        },
        quotation: {
          uploading: false,
          uploadFilePercentage: 0,
          URL: null,
          name: null,
          type: null
        }
      }
    );

    const issuerTokenAdminService = new IssuerTokenAdminService();
    const sharedService = new SharedService();

    const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 12 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12 }
        }
      };

      const submitCompanyForm = async (_form) => {
        let form = { ..._form };
        console.log(_form)
        
        form.certificateOfIncorporation = propertyFiles.certificateOfIncorporation?.URL ? [{
          name: propertyFiles.certificateOfIncorporation?.name,
          URL: propertyFiles.certificateOfIncorporation?.URL,
          type: propertyFiles.certificateOfIncorporation.type,
        }] : [];
        form.TaxIdDoc = propertyFiles.TaxIdDoc?.URL ? [{
          name: propertyFiles.TaxIdDoc?.name,
          URL: propertyFiles.TaxIdDoc?.URL,
          type: propertyFiles.TaxIdDoc.type,
        }] : [];
        form.quotation = propertyFiles.quotation?.URL ? [{
          name: propertyFiles.quotation?.name,
          URL: propertyFiles.quotation?.URL,
          type: propertyFiles.quotation.type,
        }] : [];

        if(!companyId) return;
        form = {...form, id:companyId}
        const response = await issuerTokenAdminService.registerCompany(form);
        console.log(response)
        if(response && response.data.update){
          notification.success({ message: 'Success' });
          setTimeout(() => {
            history.push('/management-company')
          }, 6000);
          
        }else{
          notification.success({ message: response.data.msg });
          setTimeout(() => {
            history.push('/management-company')
          }, 6000);

        }
      }

      const onChangeService = (service) => {
        if(service == 'other'){
            setShowOtherFieldVisibility(true);
            return;
        }
        setShowOtherFieldVisibility(false);
      };

      const uploadFile = async (e, key) => {
        if (!e.target.files) return;
        const file = e.target.files[0];
        if (!file) return;
        const fileType = sharedService.getFileType(file.name);
    
        setPropertyFiles((prev) => ({ [key]: { URL: null, ...prev[key] }, ...prev }));
    
        try {
          setPropertyFiles((prev) => {
            return ({ ...prev, [key]: { ...prev[key], uploading: true, uploadFilePercentage: 10 } });
          });
          const { hash } = await sharedService.uploadDocumentToIPFS({ file });
          const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
          setPropertyFiles((prev) => ({ ...prev, [key]: { ...prev[key], URL, uploading: false, uploadFilePercentage: 100, name: file.name, type: fileType.toLowerCase() } }));
          form.setFieldsValue({ [key]: file.name });
        } catch (err) {
          console.error(err);
          setPropertyFiles((prev) => ({ ...prev, [key]: { ...prev[key], uploading: false } }));
        }
    
      }

  return (
    <>
    {!companyId && history.push('/management-company')}
    
      {loading &&  
         <div style={{textAlign:'center'}}>
           <br/>
           <Spin size='large'/>
         </div>
       }
       {!loading && (
          <>
          <Title level={1} style={{ color: '#186AB4', textAlign: 'center', marginTop:'20px' }}>Regsiter Company</Title>
          <br/><br/>
          <Row justify="center">
            <Col span={16}>
              <Form {...formItemLayout} labelAlign="left" form={form} onFinish={submitCompanyForm}>
                  <Card>
                     <Form.Item
                        label={`Name of the  Company`}
                        name="companyName"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input placeholder='Enter Company Name' />
                      </Form.Item>

                     <Form.Item
                        label='Address 1'
                        name="address1"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input placeholder='Enter address 1' />
                     </Form.Item>

                     <Form.Item
                        label='Address 2'
                        name="address2"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                         <Input placeholder='Enter address 2' />
                      </Form.Item>

                      <Form.Item
                        label='City'
                        name="city"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                          <Input placeholder='Enter city' />
                      </Form.Item>
                      <Form.Item
                        label='State'
                        name="state"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                          <Input placeholder='Enter state' />
                      </Form.Item>
                      <Form.Item
                        label='Pincode'
                        name="pincode"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                          <Input placeholder='Enter pincode' />
                      </Form.Item>

                      <Form.Item
                        label='Contact Person First Name'
                        name='contactPersonFirstName'
                        rules={[{ required: true, message: 'This field is required' }]}>
                        <Input placeholder='' />
                      </Form.Item>

                      <Form.Item
                        label='Contact Person Last Name'
                        name='contactPersonLastName'
                        rules={[{ message: 'Contact Person Last Name' }]}>
                        <Input placeholder='' />
                      </Form.Item>
                      
                      <Form.Item 
                        label='Title'
                        name="title"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                           <Input placeholder='' />
                      </Form.Item>

                      <Form.Item
                        label='Mobile'
                        name="mobile"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                          }
                        ]}>
                        <Input placeholder='' />
                      </Form.Item>

                      <Form.Item
                        label='Landline'
                        name="landline"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: false,
                          }
                        ]}
                      >
                        <Input type="text" placeholder='' />
                      </Form.Item>

                      <Form.Item
                        label='Email'
                        name='email'
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}
                      >
                        <Input type="email" placeholder='' />
                      </Form.Item>

                      <Form.Item
                        label='Upload Certificate of Incorporation'
                        name='certificateOfIncorporation'
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}
                      >
                        <Input type="file" onChange={e => { uploadFile(e, 'certificateOfIncorporation') }} />
                        {propertyFiles.certificateOfIncorporation.uploadFilePercentage > 0 &&
                      <Progress percent={propertyFiles.certificateOfIncorporation.uploadFilePercentage} />
                    }
                      </Form.Item>

                      <Form.Item
                        label='Upload Gox Tax Id Doc'
                        name='TaxIdDoc'
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}
                      >
                        <Input type="file" onChange={e => { uploadFile(e, 'TaxIdDoc') }} />
                        {propertyFiles.TaxIdDoc.uploadFilePercentage > 0 &&
                      <Progress percent={propertyFiles.TaxIdDoc.uploadFilePercentage} />
                    }
                      </Form.Item>

                      <Form.Item
                        label='Upload Quotation'
                        name='quotation'
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}
                      >
                        <Input type="file" onChange={e => { uploadFile(e, 'quotation') }} />
                        {propertyFiles.quotation.uploadFilePercentage > 0 &&
                      <Progress percent={propertyFiles.quotation.uploadFilePercentage} />
                    }
                      </Form.Item>
                      
                      <Form.Item
                        label='Services'
                        name='services'
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}
                      >
                        <Select
                          placeholder="Select Type Of Service"
                          onChange={onChangeService}
                        >
                          <Select.Option value="" selected disabled>Select</Select.Option>
                          <Select.Option value="propertyManagement">Property Management</Select.Option>
                          <Select.Option value="rentalManagement">Rental Management</Select.Option>
                          <Select.Option value="customPropertyManagement">Custom Property Management</Select.Option>
                          <Select.Option value="propertyMaintenance">Property Maintenance</Select.Option>
                          <Select.Option value="other">Other</Select.Option>
                        </Select>
                      </Form.Item>

                      { showOtherField && <Form.Item
                        label='Enter the Other Service Type'
                        name='service'
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}
                      >
                        <Input type="text" placeholder='' />
                      </Form.Item>}

                      <br /><br />
                     <Form.Item>
                        <Button size='large' style={{ marginLeft: '300px' }} type='primary' htmlType="submit" loading={loading}>Submit</Button>
                     </Form.Item>
                     <br /><br />
                  </Card>
               </Form>
            </Col>
         </Row>
         </>
       )}
      </>
  )
}

export default AddManagementCompany