import React, { useEffect, useState, useRef } from "react";
import {
    Table,
    Card,
    Col,
    Row,
    Typography,
    Descriptions,
    Button,
    Tabs,
    Radio,
    message,
    Input,
    Carousel,
    Form,
} from "antd";
import { RightCircleOutlined, LeftCircleOutlined } from "@ant-design/icons";
import { PlaySquareOutlined, ProjectOutlined } from "@ant-design/icons";
import { SharedService } from "../Shared/Shared.service";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { useForm } from "antd/lib/form/Form";
import { AccountService } from "../Account/Account.service";


const sharedService = new SharedService();
const accountService = new AccountService();

const InterestPropertyCompanyManager = () => {

    const [firstName, setFirstName] = useState("");
    const [ lastName, setLastName ] = useState("");
    const [email, setEmail] = useState("");
    const [comments, setComments] = useState("");
    const [form]=Form.useForm()
    const [submitted, setSubmitted] = useState(false);
    const { TextArea } = Input;

    const submitInterest = async () => {
        try {
            let form = {
                firstName,
                lastName,
                email,
                comments
            };

			let response = await accountService.submitInterestToRegisterAsPCA(form);
			if (response) {
				message.success("Successfully send interest to marketplace super admin.");
				setSubmitted(true);
			}
		} catch (error) {
			console.error(error);
			message.error("Something went wrong while sending interest!");
		}
    }

    return (
        <>
            <br />
            <Row justify="center">
                <Col span={16} style={{ textAlign: "center" }}>
                    <Card>
                        {!submitted && (<Form
                            form={form}
                            onFinish={submitInterest}
                            >
                            <Form.Item
                                label="First Name"
                                name="firstName"
                                rules={[
                                {
                                    required: true,
                                    message: 'Please input your first name!',
                                },
                                ]}
                            >
                                <Input
                                type="text"
                                value={firstName}
                                onChange={(e)=>setFirstName(e.target.value)}
                                />
                            </Form.Item>

                            <Form.Item
                                label="Last Name"
                                name="lastName"
                                rules={[
                                {
                                    required: true,
                                    message: 'Please input your last name!',
                                },
                                ]}
                            >
                                <Input
                                type="text"
                                value={lastName}
                                onChange={(e)=>setLastName(e.target.value)}
                                />
                            </Form.Item>

                            <Form.Item
                                label="Email"
                                name="email"
                                rules={[
                                {
                                    required: true,
                                    message: 'Please input your email!',
                                },
                                ]}
                            >
                                <Input
                                type="email"
                                value={email}
                                onChange={(e)=>setEmail(e.target.value)}
                                />
                            </Form.Item>

                            <Form.Item
                                label="Comments"
                                name="comments"
                            >
                                <TextArea
                                rows={4}
                                value={comments}
                                onChange={(e)=> setComments(e.target.value)}
                                />
                            </Form.Item>

                            <Form.Item>
                                <Button
                                    style={{ marginTop: '20px' }}
                                    type='primary'
                                    htmlType='submit'
                                    className='login-form-button'
                                >
                                    Submit
                                </Button>
                            </Form.Item>
                        </Form>)}

                        {submitted && (
                        <h6>Your interest to register as propery company manager has been successfully submitted to MarketPlace Super Admin.
                        If he approves your interest then you will be getting an email invite to register!
                        <br/>
                        Now you can go to marketplace page to see all properties!</h6>)}
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default InterestPropertyCompanyManager;
