import React, { useState, useEffect } from "react";
import {
  Typography,
  Form,
  Card,
  Row,
  Input,
  DatePicker,
  Button,
  Col,
  Progress,
  Spin,
  notification,
} from "antd";

import LlpDetailsStyle from "./llpDetails.module.css";
import { IssuerSuperAdminService } from "../IssuerSuperAdmin/IssuerSuperAdmin.service";
import { AuthService } from "../Shared/Auth.service";
import { SharedService } from "../Shared/Shared.service";
import { environment } from "../../environments/environment";

const issuerSuperAdminService = new IssuerSuperAdminService();
const { Text, Title } = Typography;

const useUserContext = () => new AuthService().useUserContext();
const LlpDetails = (props) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  // const normFile = (e) => {
  //   if (Array.isArray(e)) {
  //     return e;
  //   }
  //   return e?.fileList;
  // };
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 12 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 12 },
    },
  };

  const { userInfo } = useUserContext();
  useEffect(() => {
    (async () => {
      let res = await issuerSuperAdminService.getPropertyDetails(userInfo?._id);
      console.log("res:", res.data[0]);
    })();
  }, []);

  const saveAdditionalDetails = async (_form) => {
    const form = { ..._form };
    try {
      setLoading(true);
      console.log(form);
      const response = await issuerSuperAdminService.saveAdditionalDetails(
        form
      );
      console.log("rishhhh");
      if (response.success) {
        notification.open({
          message: "Success",
          duration: 0,
        });
      } else {
        notification.open({
          message: "Error",
          description: response.error.message,
          duration: 0,
        });
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  // const submitLlpDetailForm = async (_form) => {
  //   const form = { ..._form };
  //   console.log(_form);
  // };
  const [propertyFiles, setPropertyFiles] = useState({
    incorporationDocument: {
      uploading: false,
      uploadFilePercentage: 0,
      URL: null,
      name: null,
      type: null,
    },
    statmentOfAccountAndSolvency: {
      uploading: false,
      uploadFilePercentage: 0,
      URL: null,
      name: null,
      type: null,
    },
    annualReturn: {
      uploading: false,
      uploadFilePercentage: 0,
      URL: null,
      name: null,
      type: null,
    },
    additionalDocuments: {
      uploading: false,
      uploadFilePercentage: 0,
      URL: null,
      name: null,
      type: null,
    },
  });
  const sharedService = new SharedService();
  const uploadFile = async (e, key) => {
    if (!e.target.files) return;
    const file = e.target.files[0];
    if (!file) return;
    const fileType = sharedService.getFileType(file.name);

    setPropertyFiles((prev) => ({
      [key]: { URL: null, ...prev[key] },
      ...prev,
    }));

    try {
      setPropertyFiles((prev) => {
        return {
          ...prev,
          [key]: { ...prev[key], uploading: true, uploadFilePercentage: 10 },
        };
      });
      const { hash } = await sharedService.uploadDocumentToIPFS({ file });
      const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
      setPropertyFiles((prev) => ({
        ...prev,
        [key]: {
          ...prev[key],
          URL,
          uploading: false,
          uploadFilePercentage: 100,
          name: file.name,
          type: fileType.toLowerCase(),
        },
      }));
      form.setFieldsValue({ [key]: file.name });
    } catch (err) {
      console.error(err);
      setPropertyFiles((prev) => ({
        ...prev,
        [key]: { ...prev[key], uploading: false },
      }));
    }
  };

  return (
    <>
      {loading && (
        <div style={{ textAlign: "center" }}>
          <br />
          <Spin size="large" />
        </div>
      )}
      {!loading && (
        <>
          <Title
            level={1}
            style={{ color: "#186AB4", textAlign: "center", marginTop: "20px" }}
          >
            Add LLP Information
          </Title>
          <br />
          <br />
          <Row justify="center">
            <Col span={16}>
              <Form
                {...formItemLayout}
                labelAlign="left"
                form={form}
                onFinish={saveAdditionalDetails}
              >
                <Card>
                  <Form.Item
                    label="Enter LLPIN Number"
                    name="llpin number"
                    rules={[
                      {
                        required: true,
                        message: "LLPIN is required",
                      },
                    ]}
                  >
                    <Input placeholder="" />
                  </Form.Item>

                  <Form.Item
                    label="Upload Incorporation Document"
                    name="incorporationDocument" // Ensure this matches the valuePropName
                    rules={[
                      {
                        required: true,
                        message: "Document required",
                      },
                    ]}
                    valuePropName="fileList"
                  >
                    <>
                      <Input
                        type="file"
                        onChange={(e) => {
                          uploadFile(e, "incorporationDocument");
                        }}
                      />
                      {propertyFiles.incorporationDocument
                        .uploadFilePercentage > 0 && (
                        <Progress
                          percent={
                            propertyFiles.incorporationDocument
                              .uploadFilePercentage
                          }
                        />
                      )}
                    </>
                  </Form.Item>

                  <Form.Item
                    label="Upload Statement of Account & Solvency"
                    name="statmentOfAccountAndSolvency" // Ensure this matches the valuePropName
                    rules={[
                      {
                        required: true,
                        message: "Document required",
                      },
                    ]}
                    valuePropName="fileList"
                  >
                    <>
                      <Input
                        type="file"
                        onChange={(e) => {
                          uploadFile(e, "statmentOfAccountAndSolvency");
                        }}
                      />
                      {propertyFiles.statmentOfAccountAndSolvency
                        .uploadFilePercentage > 0 && (
                        <Progress
                          percent={
                            propertyFiles.statmentOfAccountAndSolvency
                              .uploadFilePercentage
                          }
                        />
                      )}
                    </>
                  </Form.Item>

                  <Form.Item
                    label="Upload Option for Annual Return"
                    name="annualReturn" // Ensure this matches the valuePropName
                    rules={[
                      {
                        required: true,
                        message: "Document required",
                      },
                    ]}
                    valuePropName="fileList"
                  >
                    <>
                      <Input
                        type="file"
                        onChange={(e) => {
                          uploadFile(e, "annualReturn");
                        }}
                      />
                      {propertyFiles.annualReturn.uploadFilePercentage > 0 && (
                        <Progress
                          percent={
                            propertyFiles.annualReturn.uploadFilePercentage
                          }
                        />
                      )}
                    </>
                  </Form.Item>

                  <Form.Item
                    label="Date of Incorporation"
                    name="leaseStartDate"
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <DatePicker
                      className={LlpDetailsStyle.customDatePicker}
                      format="MMMM DD, YYYY"
                    />
                  </Form.Item>

                  <Form.Item
                    label="Registration number of the firm"
                    name="registration number"
                    rules={[
                      {
                        required: true,
                        message: "Registration is required",
                      },
                    ]}
                  >
                    <Input placeholder="" />
                  </Form.Item>

                  <Form.Item
                    label="Upload Additional Documents"
                    name="additionalDocuments" // Ensure this matches the valuePropName
                    rules={[
                      {
                        required: true,
                        message: "Document required",
                      },
                    ]}
                    valuePropName="fileList"
                  >
                    <>
                      <Input
                        type="file"
                        onChange={(e) => {
                          uploadFile(e, "additionalDocuments");
                        }}
                      />
                      {propertyFiles.additionalDocuments.uploadFilePercentage >
                        0 && (
                        <Progress
                          percent={
                            propertyFiles.additionalDocuments
                              .uploadFilePercentage
                          }
                        />
                      )}
                    </>
                  </Form.Item>

                  <br />
                  <br />
                  <Form.Item>
                    <Button
                      size="large"
                      style={{ marginLeft: "300px" }}
                      type="primary"
                      htmlType="submit"
                      loading={loading}
                    >
                      Submit
                    </Button>
                  </Form.Item>
                  <br />
                  <br />
                </Card>
              </Form>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default LlpDetails;
