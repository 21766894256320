import React, { useState, useEffect } from "react";
import { SharedService } from "../../Shared/Shared.service";
import {
  Row,
  Col,
  Card,
  Typography,
  Table,
  Button,
  Form,
  Divider,
  Input,
  Progress,
  message,
  Result,
  Popconfirm,
  List,
  Modal,
  DatePicker,
  notification,
  Radio,
  Spin,
  Tooltip,
  Select,
} from "antd";
import { InfoCircleFilled, InfoCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { InvestorService } from "../Investor.service";
import { AuthService } from "../../Shared/Auth.service";
import { SecurityTokenRegistryService } from "../../Shared/SecurityTokenRegistery/SecurityTokenRegistry.service";
// import PaymentOptions from './PaymentOptions'
import CompanyDetails from "./CompanyDetails";
import KYCUniqueURLCard from "../KYCUniqueURLCard";
import Payment from "./Payment";
import Process from "../Process";
import eversign from "./Eversign";
import ProcessCheckbox from "../ProcessCheckbox/";
import { environment } from "../../../environments/environment";
const { Title } = Typography;
const securityTokenRegistryService = new SecurityTokenRegistryService();
const investorService = new InvestorService();
const authService = new AuthService();
const useUserContext = () => authService.useUserContext();
const sharedService = new SharedService();

export default function Home() {
  const [tokens, setTokens] = useState(null);
  const [presentToken, setPresentToken] = useState(null);
  const [KYCView, setKYCView] = useState(false);
  const [aiVerificationForm] = Form.useForm();
  const { userInfo, setUserInfo } = useUserContext();
  const [KYCSubmitted, setKYCSubmitted] = useState(false);
  const [heading, setHeading] = useState("Home");
  const [AIInfoView, setAIInfoView] = useState(true);
  const [AIVerificationSubmited, setAIVerificationSubmited] = useState(false);
  const [tAndCView, setTAndCView] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [tableView, setTableView] = useState(true);
  const [AIView, setAIView] = useState(false);
  const [currentToken, setCurrentToken] = useState(null);
  const [AISuccessView, setAISuccessView] = useState(false);
  const [paymentView, setPaymentView] = useState(false);
  const [paymentApproved, setPaymentApproved] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [signURL, setSignURL] = useState(null);
  const [tokensName, setTokenNames] = useState(null);
  const [modalData, setModalData] = useState(null);
  const [documentsToSubmit, setDocumentsToSubmit] = useState([]);
  const [tokenDetails, setTokenDetails] = useState(null);
  const [bankDetails, setBankDetails] = useState(null);
  const [modalView, showModal] = useState(false);
  const [aiData, setAIData] = useState(null);
  const [KYCLink, setKYCLink] = useState(null);
  const [currentlyInvestingIn, setCurrentlyInvestingIn] = useState(null);
  const [oldAISubmissionView, setOldAISubmissionView] = useState(false);
  const [paymentNotAcceptedView, setPaymentNotAcceptedView] = useState(false);
  const [AIDocumentsSubmittedView, setAIDocumentsSubmittedView] = useState(false);
  const [propertyId, setPropertyId] = useState();
  const [issuerCompanyId, setIssuerCompanyId] = useState();
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [selectedToken, setSelectedToken] = useState();
  const [selectedTokenSymbol, setSelectedTokenSymbol] = useState('');
  const [KYCNotSubmitted, setKYCNotSubmitted] = useState(false);
  const [KYCPendingForApproval, setKYCPendingForApproval] = useState(false);
  const [KYCApproved, setKYCApproved] = useState(false);
  const [KYCRejected, setKYCRejected] = useState(false);

  const [aiDocument, setAiDocument] = useState({
    aiDocument: {
      uploading: false,
      uploadFilePercentage: 0,
      URL: null,
      name: null,
      type: null,
    },
  });
  const [openEversign, setOpenEversign] = useState(false);
  const [verificationScreen, showVerificationScreen] = useState(false);
  const history = useHistory();
  const { Option } = Select;

  const columns = [
    {
      title: "ISSUER",
      dataIndex: "issuerInfo",
      key: "issuer",
      render: (text) => <p>{text["0"]?.companyName}</p>,
    },
    {
      title: "TOKEN Symbol",
      dataIndex: "_id",
      key: "tokenSymbol",
      render: (text) => tokenDetails.get(text)[0]?.tokenSymbol,
    },
    {
      title: `PRICE PER TOKEN (Rs)`,
      dataIndex: "pricePerToken",
      key: "pricePerToken",
    },
    {
      title: "TOKEN NAME",
      key: "tokenName",
      dataIndex: "",
      render: (index, token) => {
        index.tokenName = tokensName.get(index.tokenConfigurationProcessId);
        return index.tokenName;
      },
    },
    // {
    //   title: "ACTION",
    //   key: "action",
    //   render: (text, token) => {
    //     return (
    //       <>
    //         <Button type="primary" onClick={() => buyToken(token)}>
    //           Invest
    //         </Button>
    //       </>
    //     );
    //   },
    //   //render:(text,token)=><Button onClick={()=>buyToken(token)} >Invest</Button>
    // },
    {
      title: "LINK",
      key: "link",
      render: (text, token) => {
        return (
          <>
          {propertyId?.length > 0 ? 
          (<Button type="primary" onClick={() => history.push(`/property-details?propertyId=${propertyId}`)}>
            View Property
          </Button>): ''}
        </>
        );
      },
    }
  ];
  
  useEffect(() => {
  	setOpenEversign(signURL && tAndCView && heading === "Terms and Condtions")
  }, [signURL, tAndCView, heading])

  useEffect(() => {
    (async () => {
      setLoading(true);
      setLoading2(true);
      if(userInfo.status.ndaSigned == true && userInfo.status.kyc == 'approved'){
        setKYCApproved(true);
      }else if(userInfo.status.ndaSigned == true && userInfo.status.kyc == 'rejected'){
        setKYCRejected(true);
      }else if(userInfo.status.ndaSigned == true && userInfo.status.kyc == 'pending'){
        setKYCPendingForApproval(true);
      } else if(userInfo.status.ndaSigned == true) {
        console.log('generating link for kyc');
        await generateLinkForKYC();
        setKYCNotSubmitted(true);
      }
      setLoading(false);
      setLoading2(false);
    } )();
  },[]);

  //USE EFFECT OF TOKEN,TOKEN DETAILS AND BANK DETAILS OF ISSUER RETRIEVAL
  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await investorService.getTokens();
      let tempTokens = null;
      if (response.success && response.data?.length > 0) {
        if (userInfo.country.toLowerCase() === "united states of america") {
          tempTokens = await response.data.filter(
            (token) => token.issuerInfo[0]?.regulation === "regulation-d"
          );
        } else {
          tempTokens = await response.data.filter(
            (token) => token.issuerInfo[0]?.regulation === "regulation-s"
          );
        }

        console.log(tempTokens);
        //by default taking only first token
        // setSelectedToken([tempTokens[0]]);
        // setSelectedTokenSymbol(tempTokens[0]?.tokenSymbol)
        // setTokens(tempTokens);
      } else {
        console.log("no token found");
        console.log(response);
        setTokens(null);
        setLoading(false);
        return false;
      }

      if(tempTokens?.length > 0){
        const tokenDetails = await investorService.getTokenDetails(tempTokens[0].targetDbString);
        if (tokenDetails.success && tokenDetails.data) {
          const tokensMap = new Map();
          tokenDetails.data.forEach((token) => {
            token.propertyId?.length > 0 ? setPropertyId(token.propertyId) : setPropertyId();
            if(token.companyId) setIssuerCompanyId(token.companyId);
            tokensMap.set(token._id, token.tokenDetails);
          });
          setTokenDetails(tokensMap);
        }
      }
      const bankDetails = await investorService.getBankDetails({companyId: tempTokens[0]?.companyId});
      if (bankDetails.success && bankDetails.data) {
        const bankDetailsMap = new Map();
        // bankDetails.data.forEach((token) =>
        //   bankDetailsMap.set(token._id, token.bankDetails)
        // );
        bankDetailsMap.set(bankDetails.data._id, bankDetails.data)
        setBankDetails(bankDetailsMap);
      }
      const tokensName =
        await securityTokenRegistryService.getCompaniesAndTokenInfo();
      if (tokensName.success && tokensName.data?.length > 0) {
        const tokenNameMap = new Map();
        tokensName.data.forEach((token) =>
          tokenNameMap.set(token._id, token.tokenInfo.name)
        );
        setTokenNames(tokenNameMap);
      }
      setLoading(false);
    })();
  }, []);
  // USE EFFECT OF TOKEN,TOKEN DETAILS AND BANK DETAILS OF ISSUER RETRIEVAL ENDS HERE

  useEffect(() => {
    (async () => {
      const response = await investorService.getPayments({
        investorId: userInfo._id,
      });
      if (response.success && response.data) {
        if (response.data.length <= 0) {
          setPaymentApproved(false);
        } else {
          let paymentAccepted = response.data[0].accept;
          setPaymentApproved(paymentAccepted);
        }
      }
      
      const response1 = await investorService.getCompanyAndNonAccreditedInvestor();
      let nonAcceditedInvestor = response1.data.count;
      //console.log(userInfo);
      if(selectedTokenSymbol && userInfo?.tokens[selectedTokenSymbol]?.tAndCSigned==false && nonAcceditedInvestor < 35 && !userInfo.accreditedInvestor){
        showVerificationScreen(true);
      }else{
        showVerificationScreen(false);
      }
    })();
  }, [selectedToken]);

  //USE EFFECT OF SIGN URL RETRIEVAL
  useEffect(() => {
    (async () => {
      if(selectedTokenSymbol){
        if (userInfo?.tokens[selectedTokenSymbol]?.tAndCSigned === true) return;
          if(selectedToken.length > 0){
            const data = Object.assign({tokenCompanyId: selectedToken[0].companyId},userInfo);
            const response = await investorService.getSignURL(data);
            await setSignURL(response.data);
          }
      }
    })();
  }, [selectedTokenSymbol]);
  //USE EFFECT OF SIGN URL RETRIEVAL ENDS HERE

  // //USE EFFECT OF AI DETAILS RETRIEVAL
  useEffect(() => {
    (async () => {
      const response = await investorService.getAiDetails({
        investorId: userInfo._id,
      });
      if (response.success && response.data) {
        if (response.data.length <= 0) {
          setAIVerificationSubmited(false);
        } else {
          setAIVerificationSubmited(true);
          setAIData(response.data[0]);
        }
      }
    })();
  }, []);
  //USE EFFECT OF AI DETAILS RETRIEVAL ENDS HERE.

  //display details starts
  const displayDetails = (token) => {
    showModal(true);
    setModalData(
      Object.assign(token, { tokenDetails: tokenDetails.get(token._id)[0] })
    );
  };
  // //display details end

  const generateLinkForKYC = async (company) => {
    let dataToSend = {
      _id: userInfo._id,
      email: userInfo.email,
      role: userInfo.role,
      company,
    };
    const response = await investorService.generateLinkForKYC(dataToSend);
    if (response.success && response.data) {
      setKYCLink(response.data);
    }
  };
  const getInvestorsCount = async (company) => {
    try {
      const response = await investorService.getInvestorsCount({ company });
      if (response.success && response.data) {
        return response.data.length;
      }
    } catch (e) {
      console.log("getInvestorsCount");
      console.log(e);
      message.error("can't fetch details of token");
      return -1;
    }
    return -1;
  };
  //buy token starts
  const buyToken = async (token) => {
    showModal(false);
    //console.log("BUY TOKEN", token);
    await setPresentToken(token);
    setCurrentToken(token);
    //await Object.assign(token, {tokenDetails:tokenDetails.get(token._id)[0]});
    await setCurrentlyInvestingIn(token?.issuerInfo[0]?.companyName);
    //console.log(token.issuerInfo[0].companyName.toLowerCase().trim());
    if (!token) return;
    let investorsCount = await getInvestorsCount(
      token?.issuerInfo[0]?.companyName.toLowerCase()
    );
    //console.log(investorsCount);
    // if (investorsCount === -1) {
    //   return;
    // }
    if (investorsCount >= token.totalInvestors) {
      message.info(
        "You can not invest in the token as total number of investors set by issuers is fullfield"
      );
      return;
    }
    if (userInfo?.tokens[selectedTokenSymbol]?.tAndCSigned != true) {
      switchToTandCView();
      return;
    }

    let kycCheck = new Set(userInfo?.tokens[selectedTokenSymbol]?.KYCSubmittedCompany);
    let kycResult = kycCheck.has(
      token.issuerInfo[0].companyName.toLowerCase().trim()
    );

    // Ai verification view
    if (userInfo.country.toLowerCase() === "united states of america" || !userInfo.accreditedInvestor || userInfo.accreditedInvestor== "true"){
      if(!kycResult==true && !userInfo?.tokens[selectedTokenSymbol]?.paymentDone){
        let aiCheck = new Set(userInfo.aiSubmittedCompany);
        let aiResult = aiCheck.has(
          token.issuerInfo[0].companyName.toLowerCase()
        );
        aiCheck = new Set(userInfo.aiSubmittedCompany);
        aiResult = aiCheck.has(token.issuerInfo[0].companyName.toLowerCase());
        let aiResubmitCompanySet = new Set(userInfo.aiResubmitCompany);
        let aiResubmitCheck = aiResubmitCompanySet.has(
          token.issuerInfo[0].companyName.toLowerCase()
        );
        if (aiResubmitCheck) {
          setAIVerificationSubmited(false);
          switchToAIView();
          notification.info({
            message: "Resubmit Accredited Investor Verification",
            description: `Issuer(${token.issuerInfo[0].companyName.toLowerCase()}) ask you to resubmit your Accredited Investor Verification details`,
          });
          return;
        }
        if (!aiResult) {
          if (!AIVerificationSubmited) {
            switchToAIView();
            return;
          } else {
            // disabling ai just after submitted view
            //switchToOldAISubmissionView();
            //return;
          }
        }
      }
    }

    let kycResubmitCompanySet = new Set(userInfo?.tokens[selectedTokenSymbol]?.KYCResubmitCompany);
    let kycResubmitCheck = kycResubmitCompanySet.has(
      token.issuerInfo[0].companyName.toLowerCase()
    );
    if (userInfo.country.toLowerCase() === "united states of america" || !userInfo.accreditedInvestor || userInfo.accreditedInvestor== "true"){
      if (kycResult === true) {
        if (!userInfo?.tokens[selectedTokenSymbol]?.paymentDone || userInfo.isAccredited) {
          setCurrentToken(token);
          switchToPaymentsView();
          return;
        } else {
          let aiCheck = new Set(userInfo.aiSubmittedCompany);
          let aiResult = aiCheck.has(
            token.issuerInfo[0].companyName.toLowerCase()
          );
          aiCheck = new Set(userInfo.aiSubmittedCompany);
          aiResult = aiCheck.has(token.issuerInfo[0].companyName.toLowerCase());
          let aiResubmitCompanySet = new Set(userInfo.aiResubmitCompany);
          let aiResubmitCheck = aiResubmitCompanySet.has(
            token.issuerInfo[0].companyName.toLowerCase()
          );
          if (aiResult) {
            switchToAIDocumentSubmittedView();
            return;
          } else {
            if (AIVerificationSubmited) {
              switchToOldAISubmissionView();
              return;
            } else {
              switchToAIView();
              return;
            }
          }
        }
      }
      if (kycResubmitCheck) {
        await generateLinkForKYC(token.issuerInfo[0].companyName.toLowerCase());
        switchToCompleteKYCView();
        return;
      }
      kycCheck = new Set(userInfo?.tokens[selectedTokenSymbol]?.KYCSubmittedCompany);
      kycResult = kycCheck.has(token.issuerInfo[0].companyName.toLowerCase());
      // if (kycResult) {
      //   switchToKYCSubmittedView();
      //   return;
      // }

      if (kycResult === false) {
        await generateLinkForKYC(token.issuerInfo[0].companyName.toLowerCase());
        switchToCompleteKYCView();
        return;
      }
    } else {
      if (kycResult) {
        if ((!userInfo.paymentDone || paymentApproved) && !paymentView) {
          setCurrentToken(token);
          switchToPaymentsView();
          return;
        }
        if (userInfo.paymentDone && !paymentApproved) {
          switchToPaymentNotAcceptedView();
          return;
        }
      } else {
        await generateLinkForKYC(token.issuerInfo[0].companyName.toLowerCase());
        switchToCompleteKYCView();
        return;
      }
      switchToTableView();
      //   if(){

      //  }
    }
  };
  // buy tokens end

  // AI View
  const switchToAIView = () => {
    setHeading("Accredited Investor Verification");
    setTableView(false);
    setOldAISubmissionView(false);
    setKYCView(false);
    setAISuccessView(false);
    setPaymentView(false);
    setKYCSubmitted(false);
    setAISuccessView(false);
    setAIView(true);
    setTAndCView(false);
    setPaymentNotAcceptedView(false);
  };
  //AI view ends

  const switchToPaymentNotAcceptedView = () => {
    setHeading("Payment Info");
    setTableView(false);
    setOldAISubmissionView(false);
    setKYCView(false);
    setAISuccessView(false);
    setPaymentView(false);
    setKYCSubmitted(false);
    setAISuccessView(false);
    setAIView(false);
    setTAndCView(false);
    setPaymentNotAcceptedView(true);
  };

  // T and C view starts
  const switchToTandCView = () => {
    setHeading("Terms and Condtions");
    setTableView(false);
    setOldAISubmissionView(false);
    setKYCView(false);
    setAISuccessView(false);
    setPaymentView(false);
    setKYCSubmitted(false);
    setAISuccessView(false);
    setAIView(false);
    setTAndCView(true);
  };
  // T and C view ends

  // AI Submitted View
  const switchToAIDocumentSubmittedView = () => {
    setHeading("Accredited Investor Verification");
    setTableView(false);
    setOldAISubmissionView(false);
    setKYCView(false);
    setPaymentView(false);
    setAIView(false);
    setKYCSubmitted(false);
    setAISuccessView(false);
    setAIDocumentsSubmittedView(true);
    setTAndCView(false);
    setPaymentNotAcceptedView(false);
  };
  // AI Submitted ends

  //TABLE VIEW
  const switchToTableView = () => {
    setHeading("HOME");
    setOldAISubmissionView(false);
    setKYCView(false);
    setPaymentView(false);
    setAIView(false);
    setKYCSubmitted(false);
    setAISuccessView(false);
    setAIDocumentsSubmittedView(false);
    setTableView(true);
    setTAndCView(false);
    setPaymentNotAcceptedView(false);
  };
  // //KYC SubmittedView
  const switchToKYCSubmittedView = () => {
    setHeading("KYC DETAILS");
    setOldAISubmissionView(false);
    setKYCView(false);
    setPaymentView(false);
    setAIView(false);
    setAISuccessView(false);
    setAIDocumentsSubmittedView(false);
    setTableView(false);
    setKYCSubmitted(true);
    setTAndCView(false);
  setPaymentNotAcceptedView(false);
  };

  const switchToCompleteKYCView = () => {
    setHeading("SUBMIT KYC");
    setOldAISubmissionView(false);
    setKYCView(false);
    setPaymentView(false);
    setAIView(false);
    setAISuccessView(false);
    setAIDocumentsSubmittedView(false);
    setTableView(false);
    setKYCSubmitted(true);
    setTAndCView(false);
    setPaymentNotAcceptedView(false);
  };
  //KYC Submitted view

  //OLD AI Submission View
  const switchToOldAISubmissionView = () => {
    setHeading("Accredited Investor Verification");
    setKYCView(false);
    setPaymentView(false);
    setAIView(false);
    setAISuccessView(false);
    setAIDocumentsSubmittedView(false);
    setTableView(false);
    setKYCSubmitted(false);
    setOldAISubmissionView(true);
    setTAndCView(false);
    setPaymentNotAcceptedView(false);
  };

  // OLD AI SUBMISSION VIEW ENDS

  //Payment View
  const switchToPaymentsView = () => {
    setHeading("");
    setKYCView(false);
    setAIView(false);
    setAISuccessView(false);
    setAIDocumentsSubmittedView(false);
    setTableView(false);
    setKYCSubmitted(false);
    setOldAISubmissionView(false);
    setPaymentView(true);
    setTAndCView(false);
    setPaymentNotAcceptedView(false);
  };

  // Payment View ENDS

  //save Ai details starts
  const saveAIVerificationDetails = async (data) => {
    setSubmitting(true);
    let todaysDate = new Date();
    console.log(todaysDate.getFullYear());
    if (
      data.aiVerificationDate.toDate().getFullYear() !==
      todaysDate.getFullYear()
    ) {
      message.error(
        "Accredited Investor Verification should be of current financial year."
      );
      setSubmitting(false);
      return;
    }
    data.aiVerificationDate = data.aiVerificationDate.toDate().toDateString();
    data.creationTS = Date.now();
    data.aiDocument = documentsToSubmit;
    data.verified = false;
    data.rejected = false;
    data.resubmit = false;
    data.investorId = userInfo._id;
    if (userInfo.companyName && userInfo?.companyName?.trim()?.length !== 0)
      data.company = userInfo.companyName.toLowerCase();
    else data.company = currentlyInvestingIn.toLowerCase();
    data.firstName = userInfo.firstName;
    data.lastName = userInfo.lastName;
    data.email = userInfo.email;
    data.contactNumber = userInfo.contactNumber;
    data.creationTS = Date.now();
    data.selectedTokenSymbol = selectedTokenSymbol;
    let temp = Object.assign(
      {
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        country: userInfo.country,
        contactNumber: userInfo.contactNumber,
      },
      data
    );
    console.log(temp);
    const response = await investorService.submitAIDetails(temp);
    if (response.data && response.success) {
      await userInfo.aiSubmittedCompany.push(
        currentlyInvestingIn.toLowerCase()
      );
      userInfo.aiResubmitCompany = await userInfo.aiResubmitCompany.filter(
        (company) =>
          company.toLowerCase() !== currentlyInvestingIn.toLowerCase()
      );
      setAIVerificationSubmited(true);
      switchToAIDocumentSubmittedView();
    }
    setSubmitting(false);
  };
  //save ai details ends

  //save nonaccredited investor
  const saveAccreditionInfo = async(data)=>{
    data.id = userInfo._id;
    if(data.accreditedInvestor == false || data.accreditedInvestor == 'false'){
      setSubmitting(true);
      data.accreditedInvestor = 'false';
      const response = await investorService.updateAccreditedInvestore(data);
      if (response.success) {
        showVerificationScreen(false);
      }
      window.location.reload();
      setSubmitting(false);
    }else{
      setSubmitting(true);
      data.accreditedInvestor = 'true';
      const response = await investorService.updateAccreditedInvestore(data);
      if (response.success) {
        showVerificationScreen(false);
      }
      setSubmitting(false);
    }
  }

  //SAVE OLD AI DATA STARTS
  const saveOldAIVerificationDetails = async (data) => {
    setSubmitting(true);
    data.verified = false;
    data.rejected = false;
    data.resubmit = false;
    data.investorId = userInfo._id;
    data.creationTS = await Date.now();
    if (userInfo.companyName && userInfo?.companyName?.trim()?.length !== 0)
      data.company = currentlyInvestingIn.toLowerCase();
    else data.company = currentlyInvestingIn.toLowerCase();
    let temp = Object.assign(
      {
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        country: userInfo.country,
        contactNumber: userInfo.contactNumber,
      },
      data
    );
    console.log(temp);
    const response = await investorService.submitAIDetails(temp);
    if (response.data && response.success) {
      userInfo.aiSubmittedCompany.push(currentlyInvestingIn.toLowerCase());
      setAIVerificationSubmited(true);
      switchToAIDocumentSubmittedView();
    }
    setSubmitting(false);
  };
  //SAVE OLD AI DATA ENDS

  // DELETE UPLOADED FILE STARTS
  const deleteItem = (index) => {
    setDocuments(documents.filter((document) => document !== documents[index]));
    setDocumentsToSubmit(
      documentsToSubmit.filter(
        (document) => document !== documentsToSubmit[index]
      )
    );
  };
  // DELETE UPLOADED FILE ENDS HERE

  //UPLOAD FILE STARTS HERE
  const uploadFile = (e, key, setFilesObj, form) => {
    if (!e.target.files) return;

    const file = e.target.files[0];

    if (!file) return;

    const fileType = sharedService.getFileType(file.name);
    setFilesObj((prev) => {
      const current = sharedService.clone(prev);
      current[key].URL = null;
      return current;
    });

    investorService.uploadFile(
      file,
      (uploadFilePercent) => {
        setFilesObj((prev) => {
          const current = sharedService.clone(prev);
          current[key].uploading = true;
          current[key].uploadFilePercentage = uploadFilePercent;
          return current;
        });
      },
      (err) => {
        console.error(err);
        setFilesObj((prev) => {
          const current = sharedService.clone(prev);
          current[key].uploading = false;
          return current;
        });
      },
      async (response) => {
        if (response.success) {
          const URL = response.data.url;
          setFilesObj((prev) => {
            const current = sharedService.clone(prev);
            current[key].URL = URL;
            current[key].uploading = false;
            current[key].name = file.name;
            current[key].type = fileType.toLowerCase();
            return current;
          });
          let fileDetails = { [key]: { URL, name: file.name } };
          form.setFieldsValue(fileDetails);
          setDocuments((prev) => prev.concat(file.name));
          setDocumentsToSubmit((prev) => prev.concat(fileDetails));
        } else {
          console.error(response.error);
          setFilesObj((prev) => {
            const current = sharedService.clone(prev);
            current[key].uploading = false;
            return current;
          });
        }
      }
    );
  };
  //UPLOAD FILE ENDS HERE

  //ACCEPT T AND C STARTS
  const acceptTandC = async () => {
    const response = await investorService.acceptTandC({
      email: userInfo.email,
      tokenSymbol: selectedTokenSymbol
    });
    if (response.success && response.data) {
      window.location.reload();
      // userInfo[selectedTokenSymbol].tAndCSigned = true;
      // setUserInfo(userInfo);
      // buyToken(presentToken);
    }
  };
  //ACCEPT T AND C ENDS

  //HELO SIGN API CALL STARTS
  const onSign = () => {
    setTAndCView(false);
    acceptTandC();
  };
  //HELO SIGN API CALL ENDS

  //VIEW AFTER AI AND KYC ACCEPTED
  const paymentBackCallback = () => {
    userInfo.paymentDone = true;
    buyToken(presentToken);
  };
  //INVEST IN TOKENS ENDS HERE

  // select token from dropdown
  const selectToken =async (tokenSymbol) =>{
    let newToken = tokens.filter((token)=>token.tokenSymbol === tokenSymbol)
    if(newToken?.length > 0){
      setLoading2(true);
      /* setting all variable to initials starts here */
      setPresentToken(null);
      setKYCView(false);
      setKYCSubmitted(false);
      setHeading("Home");
      setAIInfoView(true);
      setAIVerificationSubmited(false);
      setTAndCView(false);
      setTableView(true);
      setAIView(false);
      setCurrentToken(null);
      setAISuccessView(false);
      setPaymentView(false);
      setPaymentApproved(false);
      setDocuments([]);
      setModalData(null);
      setDocumentsToSubmit([]);
      setBankDetails(null);
      showModal(false);
      setAIData(null);
      setKYCLink(null);
      setCurrentlyInvestingIn(null);
      setOldAISubmissionView(false);
      setPaymentNotAcceptedView(false);
      setAIDocumentsSubmittedView(false);
      setPropertyId();
      setIssuerCompanyId();
      /* setting all variable to initials ends here */

      const tokenDetails = await investorService.getTokenDetails(newToken[0].targetDbString);
      if (tokenDetails.success && tokenDetails.data) {
        const tokensMap = new Map();
        tokenDetails.data.forEach((token) => {
          token.propertyId?.length > 0 ? setPropertyId(token.propertyId) : setPropertyId();
          if(token.companyId) setIssuerCompanyId(token.companyId);
          tokensMap.set(token._id, token.tokenDetails);
        });
        setTokenDetails(tokensMap);
      }
      setSelectedToken(newToken);
      setSelectedTokenSymbol(newToken[0].tokenSymbol);
      setLoading2(false);
    }
  }

  return (
    <>
      <br />
      {loading && (<Spin size="large" style={{marginLeft: '45%', marginTop: '5%'}}/>)}
      {!loading && (
      <Row justify="center">
        <Col span={22} style={{ textAlign: "center" }}>
          <Card style={{marginBottom: '30px'}}>
          <Form.Item 
            label='Available Tokens'
            name="Available Tokens"
            style={{textAlign: 'left'}}
            rules={[
              {
                required: false,
                message: 'This field is required',
              }
            ]}>
              {tokens && tokens[0] && (
                <>
                  <Select onSelect={(val)=>{selectToken(val)}} defaultValue={tokens.length>0 ? tokens[0].issuerInfo[0]?.companyName: ''}
                    style={{width: '300px', marginLeft: '0'}}>
                    {tokens?.length>0 && tokens.map((token)=>(
                        <Option key={token._id} value={token.tokenSymbol} selected>{token.issuerInfo[0]?.companyName}</Option>
                    ))}
                  </Select>
                </>
              )}
          </Form.Item>
          </Card>
        </Col>
      </Row>)}
      {loading2 && (<Spin size="large" style={{marginLeft: '45%', marginTop: '5%'}}/>)}
      {!loading && !loading2 && (<Row justify="center">
        <Col span={18} style={{ textAlign: "center" }}>
          <Card>
            <Process key={Math.random()} selectedTokenSymbol={selectedTokenSymbol}/>
            <Divider />
            <Title
              level={2}
              style={{
                textAlign: "left",
                color: "#1890ff",
                // fontSize: '30px',
                fontWeight: "bold",
              }}
            >
              {heading}
            </Title>

            {KYCApproved && (
              <div >
                <h4>Your KYC has been approved! Now you can do investment or buy token.</h4>
              </div>
            )}

            {KYCRejected && (
              <div >
                <h4>Your KYC has been rejected by property compliance admin for some security reason! You can not do any investment.</h4>
              </div>
            )}

            {KYCPendingForApproval && (
              <div >
                <h4>Your KYC is submitted and pending for approval with property admin! once he approves you will get a email notification. Please wait till then!</h4>
              </div>
            )}

            {!KYCPendingForApproval && KYCNotSubmitted && (
              <div >
                {KYCLink && <KYCUniqueURLCard uniqueURL={KYCLink} />}
              </div>
            )}

            <Spin size="large" spinning={loading && (!tokenDetails || !tokensName)}></Spin>
              <div hidden={!tableView}>
              {tokenDetails && tokensName && !verificationScreen && (
                  <>
                  <Table
                    dataSource={selectedToken}
                    loading={!(tokens && tokenDetails)}
                    columns={columns}
                    pagination={false}
                    key={Math.random()}
                  />
                  <br />
                  </>)}
                  {tokenDetails && tokensName && verificationScreen && (
                  <>
                  <Form
                    onFinish={saveAccreditionInfo}
                  >
                    <Form.Item
                      label='Are you an Accredited Investor?'
                      name="accreditedInvestor"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Radio.Group>
                          <Radio key='yes' value={true}>
                            Yes
                          </Radio>
                          <Radio key='no' value={false}>
                            No
                          </Radio>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item
                      label={<span> Are you an Sophisticated Investor? &nbsp; 
                        <Tooltip title="An individual or an institution with significant market experience, knowledge and financial resources capable of evaluating the merits and risks of the prospective investment."
                          placement="rightTop" color='#1890ff'>
                          <InfoCircleFilled />
                        </Tooltip></span>}
                      name="sophisticatedInvestor"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Radio.Group>
                          <Radio key='yes' value={true}>
                            Yes
                          </Radio>
                          <Radio key='no' value={false}>
                            No
                          </Radio>
                      </Radio.Group>
                    </Form.Item>

                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={submitting}
                      >
                        Submit
                      </Button>
                    </Form.Item>
                  </Form>
                  <br />
                  </>)}
              </div>

            {/* USA Investors AI Upload View */}
            <div hidden={!AIView}>
              <div hidden={!AIInfoView}>
                <h5>
                  If you have not completed your Accredited investor
                  verification complete it by clicking the below given link.
                  <br /> You can also get your accredited investor verification
                  done from your chartered accoutant and upload the document
                  below.
                </h5>
                <br />
                <a
                  href="https://www.earlyiq.com/partner/cryptoassetrating/investor/one/getstarted"
                  rel="noopener norefferer"
                  target="_blank"
                >
                  Click the link to complete Accredited investor verification
                </a>

                <Divider>OR</Divider>
                <h5>
                  If you have already completed your Accredited investor
                  verification, Than upload the document by clicking the button
                  given below
                </h5>
                <br />
                <Button type="primary" onClick={() => setAIInfoView(false)}>
                  Upload document here
                </Button>
              </div>

              <div hidden={AIInfoView}>
                <h5>
                  To begin investing in Reg D Token, first get approved for your
                  Accredited Status. Submit the verified results for your
                  Accredited Status here.
                </h5>
                <Divider />
                <Form
                  form={aiVerificationForm}
                  onFinish={saveAIVerificationDetails}
                >
                  <Form.Item
                    label="Accredited Investor Verification done on"
                    name="aiVerificationDate"
                    rules={[
                      {
                        required: true,
                        message: "Please select date !",
                      },
                    ]}
                  >
                    <DatePicker disabled={AIVerificationSubmited}></DatePicker>
                  </Form.Item>

                  <Form.Item
                    label="Accredited Investor Verification Documents"
                    name="aiDocument"
                    rules={[
                      {
                        required: true,
                        message: "Please select atleast one document !",
                      },
                    ]}
                  >
                    <Input
                      type="file"
                      onChange={(e) => {
                        uploadFile(
                          e,
                          "aiDocument",
                          setAiDocument,
                          aiVerificationForm
                        );
                      }}
                      disabled={AIVerificationSubmited}
                    />
                    {aiDocument.aiDocument.uploadFilePercentage > 0 && (
                      <>
                        <Progress
                          percent={aiDocument.aiDocument.uploadFilePercentage}
                        />
                        <br />
                        <div align="left">
                          Files Uploaded:
                          <List
                            itemLayout="horizontal"
                            style={{ textAlign: "left" }}
                          >
                            {documents?.map((obj, index) => (
                              <List.Item
                                textalign="left"
                                style={{ cursor: "pointer" }}
                                key={index}
                                actions={[
                                  <Popconfirm
                                    title="This item will be deleted!"
                                    onConfirm={() => deleteItem(index)}
                                  >
                                    <Button type="primary" danger>
                                      X
                                    </Button>
                                  </Popconfirm>,
                                ]}
                              >
                                <List.Item.Meta title={obj} />
                              </List.Item>
                            ))}
                          </List>
                        </div>
                      </>
                    )}
                  </Form.Item>

                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={submitting}
                      disabled={AIVerificationSubmited}
                    >
                      Submit
                    </Button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Button
                      loading={submitting}
                      htmlType="button"
                      onClick={() => {
                        aiVerificationForm.resetFields();
                        setDocuments([]);
                        setDocumentsToSubmit([]);
                      }}
                      disabled={AIVerificationSubmited}
                    >
                      Cancel
                    </Button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Button onClick={() => setAIInfoView(true)}>Back</Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
            {/* USA Investors AI Upload ends here. */}

            {/* AI Submitted for the company */}
            <div hidden={!AIDocumentsSubmittedView}>
              <Result
                title="Successfully Submitted!"
                subTitle="your Accredited Investor Verification documents are successfully submitted."
                extra={[
                  <Button type="primary" key={Math.random()} onClick={() => {window.location.reload();
                    //buyToken(presentToken)}
                  }}>
                    NEXT
                  </Button>,
                ]}
              ></Result>
            </div>
            {/*AI submitted for the company ends  */}

            {/* Terms and Conditions View Starts Here */}
            <div hidden={!tAndCView} id="embedSignDivision">
              <>
                {openEversign &&
                  eversign.open({
                    url: signURL,
                    containerID: "embedSignDivision",
                    width: 750,
                    height: 800,
                    events: {
                      loaded: () => console.log("loading successful"),
                      signed: onSign,
                      error: () => console.log("EVERSIGN ERROR"),
                      declined: () => console.log("declined"),
                    },
                  })}
              </>
            </div>
            {/* Terms and Conditions View ends here */}

            {/* USA Investors AI Success View */}
            <div hidden={!AISuccessView}>
              <Result
                status="success"
                title="Accredited Investor Verification Successfull"
                subTitle={
                  <a href="/investor/KYCverification">
                    Please proceed to KYC Verification by clicking here!
                  </a>
                }
              ></Result>
            </div>
            {/* USA Investor AI sucess View ends here. */}
            <div hidden={!paymentNotAcceptedView}>
              <Result
                status="info"
                title="Your payment is not accepted yet.Please wait for approval to do next investment."
              ></Result>
            </div>
            {/* Non USA Investor KYC view */}
            <div hidden={!KYCView}>
              <h2>Once KYC is approved you will not see this page.</h2>
              <br />
              <a href="/investor/KYCverification">
                Please proceed to KYC Verification by clicking here!
              </a>
            </div>
            {/* Non USA Investor KYC View end here */}

            {/* KYC SUBMITTED FOR COMPANY */}
            <div hidden={!KYCSubmitted}>
              {KYCLink && <KYCUniqueURLCard uniqueURL={KYCLink} />}
            </div>
            {/* KYC SUBMITTED FOR COMPANY ENDS*/}

            {/* Payment View */}
            <div hidden={!paymentView}>
              {paymentView && (
                <Payment
                  user={userInfo}
                  onBack={paymentBackCallback}
                  currentToken={currentToken}
                  selectedTokenSymbol={selectedTokenSymbol}
                  issuerCompanyId={issuerCompanyId}
                />
              )}
            </div>
            {/* payment View ends here */}

            {/* Issuer Company Details */}
            <Modal
              title="Token Issuer Details"
              closable={false}
              visible={modalView}
              footer={
                <Button
                  htmlType="button"
                  type="primary"
                  onClick={() => showModal(false)}
                >
                  OK
                </Button>
              }
              width={1024}
            >
              {modalView && (
                <CompanyDetails
                  company={modalData?.issuerInfo[0]?.companyName}
                  onBack={() => showModal(false)}
                  investButtonCallback={() => {
                    buyToken(modalData);
                  }}
                ></CompanyDetails>
              )}
            </Modal>
            {/* Issuer Company details end here */}

            {/* When AI verification is already submitted */}
            <div hidden={!oldAISubmissionView}>
              <Result
                status="success"
                title="You have already submitted your Accredited Investor Verification documents.Below is the list of your submitted document(s)."
                subTitle={aiData?.aiDocument.map((document, index) => {
                  return (
                    <div>
                      {index + 1}){" "}
                      <a href={document.aiDocument.URL} target="_blank">
                        {document.aiDocument.name}
                      </a>
                      <br />
                    </div>
                  );
                })}
                extra={[
                  <Button
                    type="primary"
                    htmlType="button"
                    onClick={() => {
                      saveOldAIVerificationDetails(aiData);
                      setOldAISubmissionView(false);
                    }}
                    key={Math.random()}
                    loading={submitting}
                  >
                    Submit Existing
                  </Button>,
                  <Button key={Math.random()} onClick={() => switchToAIView()} loading={submitting}>
                    Upload New
                  </Button>,
                ]}
              ></Result>
            </div>

            {/* AI Verification already submitted section ends here */}
          </Card>
        </Col>
        <Col span={4}>
          <Card>
            <ProcessCheckbox
              style={{ marginTop: "5%" }}
              key1={Math.floor(Math.random() * 100)}
              selectedTokenSymbol={selectedTokenSymbol}
            />
          </Card>
        </Col>
      </Row>)}
    </>
  );
}
