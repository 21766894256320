import React, { useState } from "react";
import {
  Typography,
  Form,
  Card,
  Input,
  Button,
  Space,
  Upload,
  Row,
  Col,
} from "antd";
import { CloseCircleOutlined, UploadOutlined } from "@ant-design/icons";

const { Text, Title } = Typography;
const { TextArea } = Input;

interface DesignatedPartner {
  id: number;
  name: string;
  address: string;
  dpin: string;
}

const DesignatedPartnerDetail = () => {
  const [loading, setLoading] = useState(false);
  const [partners, setPartners] = useState<DesignatedPartner[]>([
    { id: 1, name: "", address: "", dpin: "" },
    { id: 2, name: "", address: "", dpin: "" },
  ]);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 12 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 12 },
    },
  };

  const addPartner = () => {
    const newPartnerId = partners.length + 1;
    setPartners([
      ...partners,
      { id: newPartnerId, name: "", address: "", dpin: "" },
    ]);
  };

  const [form] = Form.useForm();
  const submitTenantForm = async () => {
    try {
      const formValues = await form.validateFields();

      const formData = partners.map((partner) => ({
        name: formValues[`name${partner.id}`],
        address: formValues[`address${partner.id}`],
        dpin: formValues[`dpin${partner.id}`],
      }));

      console.log("Form Data:", formData);

      // Set loading to true before making an asynchronous request
      setLoading(true);

      // Perform some asynchronous operation (e.g., submitting form data to a server)

      // Once the operation is complete, set loading back to false
      setLoading(false);
    } catch (error) {
      // Handle validation errors or other errors here
      console.error("Form validation error:", error);
    }
  };

  const removePartner = (partnerId: number) => {
    setPartners((prevPartners) => prevPartners.filter((p) => p.id !== partnerId));
  };

  return (
    <>
      {loading && (
        <div style={{ textAlign: "center" }}>
          <br />
          <Typography.Title level={2}>Loading...</Typography.Title>
        </div>
      )}
      {!loading && (
        <>
          <Title
            level={1}
            style={{ color: "#186AB4", textAlign: "center", marginTop: "20px" }}
          >
            Add Designated Partner Details
          </Title>
          <br />
          <Row justify="center">
            <Col span={16}>
              <Form
                {...formItemLayout}
                labelAlign="left"
                onFinish={submitTenantForm}
                form={form}
              >
                {partners.map((partner) => (
                  <Card
                    key={partner.id}
                    title={`Designated Partner ${partner.id}`}
                    extra={
                      partners.length > 2 && partner.id>2 ? (
                        <Button
                          type="link"
                          icon={<CloseCircleOutlined />}
                          onClick={() => removePartner(partner.id)}
                        />
                      ) : null
                    }
                  >
                    <Form.Item
                      label={`Name (Designated Partner ${partner.id})`}
                      name={`name${partner.id}`}
                      initialValue={partner.name}
                      rules={[
                        {
                          required: true,
                          message: "Name is required",
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input
                        placeholder={`Enter Name for Designated Partner ${partner.id}`}
                        disabled={!!partner.name}
                      />
                    </Form.Item>

                    <Form.Item
                      label={`Address (Designated Partner ${partner.id})`}
                      name={`address${partner.id}`}
                      initialValue={partner.address}
                      rules={[
                        {
                          required: true,
                          message: "Address is required",
                          whitespace: true,
                        },
                      ]}
                    >
                      <TextArea
                        rows={4}
                        placeholder={`Enter Address for Designated Partner ${partner.id}`}
                        disabled={!!partner.address}
                      />
                    </Form.Item>

                    <Form.Item
                      label={`DPIN (Designated Partner ${partner.id})`}
                      name={`dpin${partner.id}`}
                      initialValue={partner.dpin}
                      rules={[
                        {
                          required: true,
                          message: "DPIN is required",
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input
                        placeholder={`Enter DPIN for Designated Partner ${partner.id}`}
                        disabled={!!partner.dpin}
                      />
                    </Form.Item>
                  </Card>
                ))}

                <Card title="Upload LLP Agreement">
                  <Form.Item
                    label="LLP Agreement"
                    name="llpAgreement"
                    valuePropName="fileList"
                    getValueFromEvent={(e) => e && e.fileList}
                  >
                    <Upload name="file" action="/upload.do" listType="text">
                      <Button icon={<UploadOutlined />}>Click to Upload</Button>
                    </Upload>
                  </Form.Item>
                </Card>

                <Form.Item>
                  <Space>
                    <Button type="primary" htmlType="submit" loading={loading}>
                      Submit
                    </Button>
                    <Button onClick={addPartner}>
                      Add Another Designated Partner
                    </Button>
                  </Space>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default DesignatedPartnerDetail;
