import React, { useEffect, useState } from 'react'
import { Table, Card, Col, Row, Typography, Descriptions, Button, Tabs, message } from 'antd'
import { PlatformIssuerAdminService } from '../../PlatformIssuerAdmin/PlatformIssuerAdmin.service';
import { PlaySquareOutlined, ProjectOutlined } from '@ant-design/icons';
import moment from 'moment'
import { AuthService } from '../../Shared/Auth.service';
import AddProperty from '../../IssuerSuperAdmin/AddProperty';
const { Title } = Typography
const { TabPane } = Tabs;

const platformIssuerAdminService = new PlatformIssuerAdminService();
const useUserContext = () => new AuthService().useUserContext();

export default () => {
    const { userInfo } = useUserContext();
    const [pendingPropertyData, setPendingPropertyData] = useState()
    const [detailsDivision, setDetailsDivision] = useState(true)
    const [companyDetails, setCompanyDetails] = useState<any>(null)
    const [submitting, setSubmitting] = useState(false)
    const [approvedRejectedData, setApprovedRejectedData] = useState([])
    const [seriesManagerId, setSeriesManagerId] = useState(null)
    const [showPropertyDetails, setShowPropertyDetails] = useState(false)

    const pendingPropertyCols = [
        {
            title: 'Property Name',
            dataIndex: ['seriesDetails','name'],
            key: 'name'
        },
        {
            title: 'Category',
            dataIndex: 'propertyCategoryType',
            key: 'propertyCategoryType'
        },
        {
            title: 'Building Size',
            dataIndex: 'buildingSize',
            key: 'buildingSize'
        },
        {
            title: 'Country',
            dataIndex: 'propertyCountry',
            key: 'propertyCountry'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status'
        },
        {
            title: 'ACTION',
            dataIndex: 'action',
            key: 'action',
            render: (text, prop) => <Button type='primary' onClick={() => showDetails(prop)}>DETAILS</Button>
        }

    ]
    const approvedPropertyCols = [
        {
            title: 'Property Name',
            dataIndex: ['seriesDetails','name'],
            key: 'name'
        },
        {
            title: 'Category',
            dataIndex: 'propertyCategoryType',
            key: 'propertyCategoryType'
        },
        {
            title: 'Building Size',
            dataIndex: 'buildingSize',
            key: 'buildingSize'
        },
        {
            title: 'Country',
            dataIndex: 'propertyCountry',
            key: 'propertyCountry'
        },
        {
            title: 'Status',
            dataIndex: 'status.registrationRequest',
            key: 'action',
            render: (text, company) => {
                return (
                    <div>
                        {company?.status?.registrationRequest == 'approved' && <span>Approved</span>}
                        {company?.status?.registrationRequest == 'rejected' && <span>Rejected</span>}
                    </div>
                )
            }
        },
        {
            title: 'ACTION',
            dataIndex: 'action',
            key: 'action',
            render: (text, prop) => <Button type='primary' onClick={() => showDetails(prop)}>DETAILS</Button>
        }

    ]
    
    const showDetails = async (property) => {
        console.log('function invoked of details', property)
        // setSeriesManagerId(property.seriesDetails.seriesManagerId);
        sessionStorage.setItem("seriesManagerId", property.seriesDetails.seriesManagerId);
        sessionStorage.setItem("companyId",property.seriesDetails.companyId)
        setShowPropertyDetails(true);
    }


    useEffect(
        () => {
            (async () => {
                setSubmitting(true)
                const response = await platformIssuerAdminService.getAllApprovedOrRejectedOrPendingPropertyDetails()
                if (!response.success) {
                    message.error(response.error.message);
                    setSubmitting(false)
                    return
                }
                console.log(response)
                const pendingPropertyData = response.data.filter(prop => prop.status == 'pending')
                setPendingPropertyData(pendingPropertyData)

                const approvedRejectedPropertyData = response.data.filter(prop => prop.status == 'approved' || prop.status == 'rejected')
                setApprovedRejectedData(approvedRejectedPropertyData)

                setSubmitting(false)
            }

            )();
        }, [userInfo]
    )


    return (
        <>
            <br />
           {showPropertyDetails ? 
            (<AddProperty></AddProperty>)
            :
           (<Row justify="center">
                <Col span={22} style={{ textAlign: 'center' }}>
                    <Card>
                        <Title
                            level={2}
                            style={{
                                textAlign: 'left',
                                color: '#1890ff',
                                // fontSize: '30px',
                                fontWeight: 'bold'
                            }}
                        >
                            Issuer Property Verification Request
                        </Title>
                        <Tabs defaultActiveKey="request" centered>
                            <TabPane tab={<span><PlaySquareOutlined />Pending Property Verification Requests</span>} key="request">
                                <div hidden={!detailsDivision}>
                                    <Table loading={submitting} dataSource={pendingPropertyData} columns={pendingPropertyCols}
                                        scroll={{ x: true }} />
                                </div>

                            </TabPane>
                            <TabPane tab={<span><ProjectOutlined /> Approved/Rejected Property Verification Request</span>}>
                                <Table columns={approvedPropertyCols} dataSource={approvedRejectedData}
                                    loading={submitting} scroll={{ x: true }}></Table>
                            </TabPane>
                        </Tabs>


                    </Card>
                </Col>
            </Row>)}

            
        </>
    )
}