import React, { useState, useEffect } from "react";
import { Switch, Route, useRouteMatch,useHistory } from "react-router-dom";
import TenantList from "./TenantList";
import AddTenant from "./AddTenant";

const Tenants = () => {
  const { path } = useRouteMatch();
  const  [tenant, setTenant]=useState({})
  const history = useHistory();
  
  const getSelectedTenant= (tenant:any) =>{
    console.log('inside the parent',tenant)
    setTenant(tenant);
    history.push(`${path}/add-tenant/${tenant._id}`);
  }

  return (
    <Switch>
      <Route path={`${path}`} exact>
        <TenantList selectedTenant={getSelectedTenant}/>
      </Route>
      <Route path={`${path}/add-tenant`} exact>
        <AddTenant tenantdata={{}}/>
      </Route>
      <Route path={`${path}/add-tenant/:tenantId`} exact>
        <AddTenant tenantdata={tenant}/>
      </Route>
    </Switch>
  )
}

export default Tenants