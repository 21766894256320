import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import Home from "./Home";
import KYCUpload from "./KYCUpload";
import KYCStatus from "./KYCStatus";
import AIStatus from "./AIStatus";
import Payments from "./Payments";
import Dashboard from "./Dashboard";
import NotVerified from "./NotVerified";
import EmailNotVerified from "./EmailNotVerified";
import Nda from "./NDA";
import PaymentDeclinedComponent from "./PaymentDeclined";
import PaymentSuccess from "./PaymentSuccess/PaymentSuccess";
import AddWalletForOtherRegulations from "./AddWalletForOtherRegulations";
import TokenIssuerDocuments from "./TokenIssuerDocuments/TokenIssuerDocuments";
import SkrillPaymentStatus from "./SkrillPaymentStatus/SkrillPaymentStatus";
export default () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/KYCVerification`}>
        <KYCUpload />
      </Route>
      <Route path={`${path}/kyc-status`}>
        <KYCStatus />
      </Route>
      <Route path={`${path}/ai-status`}>
        <AIStatus />
      </Route>
      <Route path={`${path}/payments`}>
        <Payments />
      </Route>
      <Route path={`${path}/token-issuer-documents`}>
        <TokenIssuerDocuments />
      </Route>
      <Route path={`${path}/dashboard`}>
        <Dashboard />
      </Route>
      <Route path={`${path}/not-verified`}>
        <NotVerified />
      </Route>
      <Route path={`${path}/email-not-verified`}>
        <EmailNotVerified />
      </Route>
      <Route path={`${path}/nda`}>
        <Nda />
      </Route>
      <Route path={`${path}/payment-declined/:paymentId`}>
        <PaymentDeclinedComponent />
      </Route>
      <Route path={`${path}/payment-complete/:paymentId`}>
        <PaymentSuccess />
      </Route>
      <Route path={`${path}/skrill-payment-status`}>
        <SkrillPaymentStatus />
      </Route>
      <Route path={`${path}/home`}>
        <Home />
      </Route>
      {/*<Route path={`${path}/add-wallet-for-other-regulations`}>*/}
      {/*  <AddWalletForOtherRegulations />*/}
      {/*</Route>*/}
      {/*<Route path={`${path}/whitelisted-wallets`}>*/}
      {/*  <InvestorWhitelistedWallets/>*/}
      {/*</Route>*/}
    </Switch>
  );
};
