import { SharedService } from '../Shared/Shared.service';
import { APIResponse, User } from '../Shared/interfaces';
import { environment } from '../../environments/environment';
import axios from 'axios';
import {MetamaskService} from '../Shared/Metamask.service';

// import { VestingService } from '../Shared/Vesting/Vesting.service';
import VestingFacet from '../Shared/SecurityToken/Facets/VestingFacet/index';

const sharedService = new SharedService();
// const vestingService = new VestingService();
const vestingFacet = new VestingFacet();

export class IssuerSuperAdminService {
  private APIURL = environment.APIURL;

  public getFilePath(fileName: string) {
    return `issuerSuperAdmins/${fileName}`;
  }

  async getLLPAddress(id) {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/tokenConfiguration/getDeployedSmartContract`,
      {params: { companyId: id }, headers: await sharedService.getAuthHeader() }
    );
  }

  public contract = {
    ABI: require('../Shared/LLPFactory/ABI.json')
  }
  private get web3() {
    return MetamaskService.web3;
  }

  async uploadDeedOnchain(
    uri: string, 
    hash: string,
    llpContract: string,
    admin: string
    ) {

    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, llpContract);

    const method = contractInstance.methods.setDocument(
      "0x6f776e6572736869705f64656564000000000000000000000000000000000000", 
      uri, 
      hash
    );
    console.log('method',method)
    return method.send({ from: admin });
  }

  public uploadFileAsync(file: Blob) {
    const req = new FormData();
    req.append('doc', file);
    return sharedService.uploadDocumentAsync(`${this.APIURL}/issuerSuperAdmin/uploadDocument`, req);
  }

  public uploadFile(file: Blob, progressCb?, errorCb?, completeCb?) {
    const req = new FormData();
    req.append('doc', file);

    sharedService.uploadDocument(
      `${this.APIURL}/issuerSuperAdmin/uploadDocument`,
      req,
      progressCb,
      errorCb,
      completeCb
    );
  }
  async getQuestions(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerTokenAdmin/getQuestions`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async getQuestionsToView(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerTokenAdmin/getQuestionsToView`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async setAnswer(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerTokenAdmin/setAnswer`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async submitBasicInfoVerif(form: any) {
    console.log(form);
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/submitBasicInfoVerif`,
      form,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async getBasicInfoVerif() {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/getBasicInfoVerif`,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async addTeamRole(req: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/addTeamRole`,
      req,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async editTeamRole(req: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/editTeamRole`,
      req,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async getTeamRoles(departmentId: string) {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/getTeamRoles`,
      { params: { departmentId }, headers: await sharedService.getAuthHeader() }
    );
  }

  async getTeamRole(teamRoleId: string) {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/getTeamRole`,
      { params: { teamRoleId }, headers: await sharedService.getAuthHeader() }
    );
  }

  async getTeamDepartments(team?: string) {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/getTeamDepartments`,
      { params: { team }, headers: await sharedService.getAuthHeader() }
    );
  }

  async getTeamDepartment(teamDepartmentId: string) {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/getTeamDepartment`,
      {
        params: { teamDepartmentId },
        headers: await sharedService.getAuthHeader(),
      }
    );
  }

  async sendInviteToTeamMember(req: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/sendInviteToTeamMember`,
      req,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async getTeamMembers(
    limit: number,
    searchPage: number,
    team?: 'employee' | 'contractor_consultant_advisor',
    departmentId?: string,
    teamRoleId?: string
  ) {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/getTeamMembers`,
      {
        params: { limit, searchPage, team, departmentId, teamRoleId },
        headers: await sharedService.getAuthHeader(),
      }
    );
  }

  async getTeamMember(teamMemberId: string) {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/getTeamMember`,
      { params: { teamMemberId }, headers: await sharedService.getAuthHeader() }
    );
  }

  async subscriptionAgreementCreation(companyName: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/subscriptionAgreementCreation`,
      companyName,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async getTeamMembersWithoutVesting(
    tokenAddress: string | null,
    team?: 'employee' | 'contractor_consultant_advisor',
    departmentId?: string,
    teamRoleId?: string
  ) {
    const addresses = tokenAddress
      ? await vestingFacet.getAllVestingScheduleOwnerAddresses(tokenAddress)
      : [];

    const response = await axios.get<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/getTeamMembersWithoutVesting`,
      {
        params: {
          addresses: addresses.toString(),
          team,
          departmentId,
          teamRoleId,
        },
        headers: await sharedService.getAuthHeader(),
      }
    );

    const teamMembers: User[] = response.data;

    return { teamMembers, totalVestingScheduleOwners: addresses.length };
  }

  async getTeamMemberTokenConfigurationProcess() {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/getTeamMemberTokenConfigurationProcess`,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async requestPriceUpdate() {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/requestPriceUpdate`,
      {},
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async sendInviteToIssuerRole(req: { role: string; email: string }) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/sendInviteToIssuerRole`,
      req,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async sendRequestToCreateLegend() {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/sendRequestToCreateLegend`,
      {},
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async sendRequestToRemoveLegend(req: { message: string }) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/sendRequestToRemoveLegend`,
      req,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async saveBankInfo(req: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/saveBankInfo`,
      req,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async getBankInfo() {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/getBankInfo`,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async getRegistrationRequest() {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/getRegistrationRequest`,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async getMembersDetailsList(userId) {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/getMemberDetails?userId=${userId}`,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async getInitialMembers(companyId: any) {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/getIntialMember`,
      { headers: await sharedService.getAuthHeader(), params: {companyId} }
    );
  }

  async updateMemberCompany(req: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/updateMemberCompany`,
      req,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async saveMemberCompany(req: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/addMemberCompany`,
      req,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async saveIntialMembers(req: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/saveInitialMember`,
      req,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async getInitialCompany() {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/getIntialCompany`,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async getCompanyMembers(companyId) {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/getCompanyMembers`,
      { params: { companyId }, headers: await sharedService.getAuthHeader() }
    );
  }

  async saveCompanyMember(req: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/saveCompanyMember`,
      req,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async updateMemberForCompany(req: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/updateMemberForCompany`,
      req,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async addComapnyInitial(req: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/addCompanyIntial`,
      req,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  
  async saveIntialCompany(req: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/saveInitialCompany`,
      req,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async getCompanyMember(memberId) {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/getCompanyMember`,
      { params: { memberId }, headers: await sharedService.getAuthHeader() }
    );
  }

  async editCompanyMember(req: {
    team: string;
    corporateRoles: string[];
    department: string;
    teamRoleId: string;
  }) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/editCompanyMember`,
      req,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async getTokenTypeDetail(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/getTokenTypeDetail`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async getIssuerWebpageConfig(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/getIssuerWebpageConfig`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async setIssuerWebpageConfig(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/setIssuerWebpageConfig`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async getDueDiligenceList(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/getDueDiligenceList`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async setDueDiligenceAnswer(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/setDueDiligenceAnswer`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async handleCreateOfferingAccount(req: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/handleCreateOfferingAccount`,
      req,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async currentOfferingStatus(req: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/currentOfferingStatus`,
      req,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async handlePrimeTrustEscrowAccount(req: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/handlePrimeTrustEscrowAccount`,
      req,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async handlePrimeTrustSign(req: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/handlePrimeTrustSign`,
      req,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async fileEscrowServiceApplication(req: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/fileEscrowServiceApplication`,
      req,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async getPrimeTrustEntity(params) {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/getPrimeTrustEntity`,
      { params, headers: await sharedService.getAuthHeader() }
    );
  }

  async getIssuerDueDiligence(params) {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/getIssuerDueDiligence`,
      { params, headers: await sharedService.getAuthHeader() }
    );
  }
  async getAnsweredDueDligenceList(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/getAnsweredDueDligenceList`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async savePaymentMethod(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/savePaymentMethod`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async getSavedPaymentMethod(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/getSavedPaymentMethod`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  //get property details for Property Company Manager
  async getPropertyDetails(userId: any) {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/getPropertyDetails/${userId}`,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  // add basic info details for Property Company Manager
  async saveBasicInfo(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/saveBasicInfo`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }

    // update chart photos for Property Company Manager
    async updateChartPhotos(data: any) {
      return axios.post<any, APIResponse>(
        `${this.APIURL}/issuerSuperAdmin/updateChartPhotos`,
        data,
        { headers: await sharedService.getAuthHeader() }
      );
    }

    // add offering info details for Property Company Manager
    async saveOfferingInfo(data: any) {
      return axios.post<any, APIResponse>(
        `${this.APIURL}/issuerSuperAdmin/saveOfferingInfo`,
        data,
        { headers: await sharedService.getAuthHeader() }
      );
    }

  // save additional details
  async saveAdditionalDetails(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/saveAdditionalDetails`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  // save documents
  async savePropertyDocuments(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/savePropertyDocuments`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  //merge docs
  async mergePropertyDocuments(user:any){
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/mergePropertyDocuments`,
      user,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  // get invites
  async getInvites(userId:any) {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/getInvites?userId=${userId}`,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  // save Invite
  async inviteInvestor(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/inviteInvestor`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  // update uploaded documents
  async updateUploadedDocuments(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/savePropertyDocuments`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  // update property photos for Property Company Manager
  async updatePropertyPhotos(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/savePropertyDocuments`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  // update property photos for Property Company Manager
  async updateOfferingVideos(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/savePropertyDocuments`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  
  // update Deed docs llp
  async updateDeedDocs(data: any, companyId) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/uploadDeedDocuments?companyId=${companyId}`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  
   // get get List Of Uploaded Deed
   async getListOfUploadedDeedByCompanyId(companyId) {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/getListOfUploadedDeedByCompanyId?companyId=${companyId}`,
      { headers: await sharedService.getAuthHeader() }
    );
  }

   // get bankinfo
   async getBankInfoByCompanyId(companyId) {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/getBankInfo?companyId=${companyId}`,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async updateBankInfoByCompanyId(data: any, companyId) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/saveBankInfo?companyId=${companyId}`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async sendBankInfoForApprovalByCompanyId(companyId) {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/sendBankInfoForApprovalByCompanyId?companyId=${companyId}`,
      { headers: await sharedService.getAuthHeader() }
    );
  }


  async sendPropertyDetailsForApprovalToMSA(companyId) {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/sendPropertyDetailsForApprovalToMSAByCompanyId?companyId=${companyId}`,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async approveOrRejectPropertyDetails(companyId,payload){
    return axios.post<any, APIResponse>(`${this.APIURL}/issuerSuperAdmin/approveOrRejectPropertyDetailsByCompanyId?companyId=${companyId}`,payload, {headers: await sharedService.getAuthHeader()});    
  }


}
