import React from 'react';
import { Switch, Route, useRouteMatch } from "react-router-dom";
import BasicInfoVerification from './BasicInfoVerification';
import RegistrationVerification from './RegistrationVerification';
import Team from './Team';
import CreateIssuerRole from './CreateIssuerRole';
import Admin from './Admin';
import InformationQuestions from './InformationQuestions'
import ViewAndEditAnswers from './ViewAndEditAnswers'
import Home from './Home';
import MemberDetails from './MemberInfo/MemberDetails';
import ManageIndividuals from './MemberInfo/ManageIndividuals';
import ManageCompanies from './MemberInfo/ManageCompanies';
import AddCompany from './MemberInfo/AddCompany';
import BankInfo from './BankInfo';
import ApproversAndAccounts from './ApproversAndAccounts';
import WithdrawalRequests from './WithdrawalRequests';
import Dashboard  from './Dashboard';
import AnswerDueDiligence from './AnswerDueDiligence';
import CreateOffering from './CreateOffering';
import ManagePaymentMethod from './ManagePaymentMethod';
import WebsiteManagement from './ManageAPIKey';
import ServiceProviderManagement from './ServiceProviderManagement/ServiceProviderManagement';
import SeriesList from './../Series/SeriesList';
import KYCVerificationRequest from './TeamMemberKYCRequests/KYCVerificationRequest';

export default () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/information-questions`}>
       <InformationQuestions/>
      </Route>
      <Route path={`${path}/view-and-edit-answers`}>
      <ViewAndEditAnswers/>
      </Route>
      <Route path={`${path}/home`}>
      <Home/>
      </Route>
      <Route path={`${path}/basic-info-verif`}>
        <BasicInfoVerification/>
      </Route>
      <Route path={`${path}/registration-verif`}>
        <RegistrationVerification/>
      </Route>
      <Route path={`${path}/team`}>
        <Team/>
      </Route>
      <Route path={`${path}/create-issuer-role`}>
        <CreateIssuerRole/>
      </Route>
      <Route path={`${path}/member-details`}>
        <MemberDetails />
      </Route>
      <Route path={`${path}/manage-individuals`}>
        <ManageIndividuals />
      </Route>
      <Route path={`${path}/manage-companies`}>
        <ManageCompanies />
      </Route>
      <Route path={`${path}/add-company`}>
        <AddCompany />
      </Route>
      <Route path={`${path}/admin`}>
        <Admin/>
      </Route>
      <Route path={`${path}/bank-info`}>
        <BankInfo/>
      </Route>
      <Route path={`${path}/approvers-and-accounts`}>
        <ApproversAndAccounts/>
      </Route>
      <Route path={`${path}/withdrawal-requests`}>
        <WithdrawalRequests/>
      </Route>
      <Route path={`${path}/dashboard`}>
        <Dashboard/>
     </Route>
      <Route path={`${path}/answer-due-diligence`}>
        <AnswerDueDiligence/>      
      </Route>      
      <Route path={`${path}/create-offering`}>
        <CreateOffering/>
      </Route>      
      <Route path={`${path}/manage-payment-methods`}>
        <ManagePaymentMethod></ManagePaymentMethod>
      </Route>      
      <Route path={`${path}/manage-website`}>
        <WebsiteManagement/>
      </Route>   
      <Route path={`${path}/manage-service-providers`}>
        <ServiceProviderManagement />
      </Route>
      <Route path={`${path}/series-details`}>
        <SeriesList newSeries={false}/>
      </Route>
      <Route path={`${path}/team-member-kyc-requests`}>
        <KYCVerificationRequest />
      </Route>       
    </Switch>
  );
}
