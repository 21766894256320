import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Typography, Form, Input, Button, notification, Spin, Steps, Descriptions} from 'antd';
import { CheckOutlined, LineOutlined } from '@ant-design/icons';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { AuthService } from '../../Shared/Auth.service';
import { IssuerSuperAdminService } from '../../IssuerSuperAdmin/IssuerSuperAdmin.service';
import { SharedService } from '../../Shared/Shared.service';
import AddBasicInfo from './AddBasicInfo.js';
import AdditionalDetails from './AdditionalDetails.js';
import AddDocuments from './AddDocuments';
import OfferingInformation from './OfferingInformation.js';
const {Title} = Typography;
const { Step } = Steps;

const issuerSuperAdminService = new IssuerSuperAdminService();
const sharedService = new SharedService();

const configTypeToCurrentStep = {
  'basic-information': 1,
  'Offering-information': 2,
  'additional-details': 3,
  'documents': 4
};
const currentStepToConfigType = Object.fromEntries(
  Object.entries(configTypeToCurrentStep).map((entry) => entry.reverse())
);

const useUserContext = () => new AuthService().useUserContext();

export default function AddProperty(props:any) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const { userInfo } = useUserContext();
  const [stepNo, setStepNo] = useState(1);
  const [updated, setUpdated] = useState(false);
  const [propertyInfo, setPropertyInfo] = useState();
  const [basicInfoDone, setBasicInfoDone] = useState(false);
  const [offeringDisabled, setOfferingDisabled] = useState(true);
  const [offeringDone, setOfferingDone] = useState(false);
  const [additionalDisabled, setAdditionalDisabled] = useState(true);
  const [additionalDetailsDone, setAdditionalDetailsDone] = useState(false);
  const [documentsDisabled, setDocumentsDisabled] = useState(true);
  const [documentsDone, setDocumentsDone] = useState(false);
  const [numberOfTeamMembers, setNumberOfTeamMembers] = useState(0);
  const [isMSA, setIsMSA] = useState(userInfo?.roles.includes('master company super admin'));

  useEffect(() => {
    (async () => {
      setLoading(true);
      console.log('call from ',' id ',)
      let id = userInfo?._id
      if(isMSA){
        id = sessionStorage.getItem('seriesManagerId') ?? ''
      }
      let res = await issuerSuperAdminService.getPropertyDetails(id);
      if(res.data){
        setPropertyInfo(res.data[0]);
        if(res.data[0]){
          if(isNaN(res.data[0].stepDone)){
            setStepNo(1);
          }else{
            setStepNo(res.data[0].stepDone + 1);
          }
          if(res.data[0].basicInfo=='done'){
            setBasicInfoDone(true);
            setOfferingDisabled(false);
          }
          if(res.data[0].offering=='done'){
            setOfferingDone(true);
            setAdditionalDisabled(false);
          }
          if(res.data[0].additionalDetails=='done'){
            setAdditionalDetailsDone(true);
            setDocumentsDisabled(false);
          }
          if(res.data[0].propertyDocuments=='done'){
            setDocumentsDone(true);
          }
          // if(Array.isArray(res.data[0].teamMembers)){
          //   setNumberOfTeamMembers(res.data[0].teamMembers.length);
          //   if(res.data[0].teamMembers.length < 2){
          //     setOfferingDisabled(true);
          //     setAdditionalDisabled(true);
          //     setDocumentsDisabled(true);
          //   }
          // }
        }
      }
      setLoaded(true);
      setLoading(false);
      setUpdated(false);
    })();
  }, [updated]);

  useEffect(() => {
    let redirectConfigType: string | null = null;

    if (stepNo == 1) {
       redirectConfigType = 'basic-information';
    } else if (stepNo === 2) {
      redirectConfigType = 'offering-information';
    } else if (stepNo === 3) {
      redirectConfigType = 'additional-details';
    }else {
      redirectConfigType = 'documents';
    }
    if (!redirectConfigType) return;
    history.push(
      `/issuer-super-admin/add-property/${redirectConfigType}`
    );
  }, [stepNo]);

  return (
    <>
    {loading && 
      <div style={{textAlign:'center'}}>
        <br/>
        <Spin size='large'/>
      </div>}
      <br/>
      {!loading && loaded && (
        <Card>
          <Row justify="center" style={{ marginBottom: '50px' }}>
            <Col span={24}>
              <Title level={1} style={{ color: '#186AB4', textAlign: 'center' }}>
                Add Property Page
              </Title>
              <Steps
                type="navigation"
                current={stepNo-1}
              >
                <Step
                  status={
                    stepNo>1 && numberOfTeamMembers>=2 ? 'finish' : undefined
                  }
                  title="Basic Information"
                  onClick={()=>{
                    setStepNo(1);
                    history.push(
                      '/issuer-super-admin/add-property/basic-information'
                    )}}
                    style={{cursor:'pointer'}}
                ></Step>
                <Step
                  disabled={offeringDisabled}
                  status={
                    stepNo>2
                      ? 'finish'
                      : undefined
                  }
                  title="Offering Information"
                  onClick={()=>{
                    setStepNo(2);
                    history.push(
                      '/issuer-super-admin/add-property/offering-information'
                    )}}
                    style={{cursor:`${!offeringDisabled?'pointer':'not-allowed'}`}}
                ></Step>
                <Step
                  disabled={additionalDisabled}
                  status={
                    stepNo>3
                      ? 'finish'
                      : undefined
                  }
                  title="Additional Details"
                  onClick={()=>{
                    setStepNo(3);
                    history.push(
                      '/issuer-super-admin/add-property/additional-details'
                    )}}
                    style={{cursor:`${!additionalDisabled?'pointer':'not-allowed'}`}}
                ></Step>
                <Step
                  disabled={documentsDisabled}
                  status={
                    stepNo>4
                      ? 'finish'
                      : undefined
                  }
                  onClick={()=>{
                    setStepNo(4);
                    history.push(
                      '/issuer-super-admin/add-property/documents'
                    )}}
                    style={{cursor:`${!documentsDisabled?'pointer':'not-allowed'}`}}
                  title="Documents"
                ></Step>
              </Steps>
              {stepNo == 1 && loaded && (
                <AddBasicInfo
                  userInfo={userInfo}
                  propertyInfo={propertyInfo}
                  setStepNo={setStepNo}
                  setUpdated={setUpdated}
                  basicInfoDone={basicInfoDone}
                />
              )}
              {stepNo == 2 && loaded && (
                <OfferingInformation
                  userInfo={userInfo}
                  propertyInfo={propertyInfo}
                  setStepNo={setStepNo}
                  setUpdated={setUpdated}
                  offeringDone={offeringDone}
                />
              )}
              {stepNo == 3 && loaded && (
                <AdditionalDetails
                  userInfo={userInfo}
                  propertyInfo={propertyInfo}
                  setStepNo={setStepNo}
                  setUpdated={setUpdated}
                  additionalDetailsDone={additionalDetailsDone}
                />
              )}
              {stepNo >= 4 && loaded && (
                <AddDocuments 
                  userInfo={userInfo}
                  propertyInfo={propertyInfo}
                  setStepNo={setStepNo}
                  setUpdated={setUpdated}
                  documentsDone={documentsDone}
                />
              )}
               {stepNo == 5 && loaded && (
                <AdditionalDetails
                  userInfo={userInfo}
                  propertyInfo={propertyInfo}
                  setStepNo={setStepNo}
                  setUpdated={setUpdated}
                  additionalDetailsDone={additionalDetailsDone}
                />
              )}
               {stepNo == 6 && loaded && (
                <AdditionalDetails
                  userInfo={userInfo}
                  propertyInfo={propertyInfo}
                  setStepNo={setStepNo}
                  setUpdated={setUpdated}
                  additionalDetailsDone={additionalDetailsDone}
                />
              )}
            </Col>
          </Row>
        </Card>
      )}
    </>
  );
}