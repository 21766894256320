import React, { useEffect, useState } from "react";
import { AccountService } from "../../Account.service";
import {
	Form,
	Input,
	Button,
	Card,
	Select,
	InputNumber,
	Col,
	Row,
	message,
	Alert,
	Result,
	DatePicker,
	Checkbox,
} from "antd";
import { Link, useHistory, useLocation } from "react-router-dom";
import { SharedService } from "../../../Shared/Shared.service";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import axios from "axios";
import ConnectToMetamask from "../../../ConnectToMetamask";
import { MetamaskService } from "../../../Shared/Metamask.service";
import { AuthService } from "../../../Shared/Auth.service";
import queryString from "query-string";

const { Option } = Select;

const sharedService = new SharedService();
const accountService = new AccountService();
const metamaskService = new MetamaskService();

const UserContext = AuthService.UserContext;
const useUserInfo = () => new AuthService().useUserInfo();
const SelectedWalletContext = MetamaskService.SelectedWalletContext;
const useSelectedWallet = (userId) => new MetamaskService().useSelectedWallet(userId);

const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 8 },
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 16 },
	},
};
const tailFormItemLayout = {
	wrapperCol: {
		xs: {
			span: 24,
			offset: 0,
		},
		sm: {
			span: 16,
			offset: 8,
		},
	},
};

export default () => {
	const history = useHistory();
	const [userInfo, setUserInfo] = useUserInfo();
	const [selectedWallet, networkId] = useSelectedWallet("new investor");
	const [registered, setRegistered] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const [countries, setCountries] = useState([]);
	const [usaStates, setUSAStates] = useState();
	const [form] = Form.useForm();
	const [userCountry, setUserCountry] = useState();
	const [cCountry, setCCountry] = useState(false);
	const [countryError, setCountryError] = useState(false);
	const [termsCheck, setTermsCheck] = useState(false);

	const isNotConnectedToMetamask =
		MetamaskService.isMetamaskAuthenticated === false && (networkId !== 1 || networkId !== 5);

	useEffect(() => {
		(async () => {
			const response = await sharedService.getCountries();
			setCountries(response.data);
			const responsee = await sharedService.getUSAStates();
			setUSAStates(responsee.data);
		})();

		(async () => {
			await axios
				.get("https://geolocation-db.com/json/697de680-a737-11ea-9820-af05f4014d91")
				.then((response) => {
					//console.log(response.country_name);
					setUserCountry(response.country_name);
				})
				.catch((response) => console.log(response));
		})();
	}, []);

	const location = useLocation();
	let { code, email, seriesId } = queryString.parse(location.search);
	if (email?.length > 0 && email.indexOf(" ") > -1) {
		email = email.replaceAll(" ", "+");
	}

	const forwardToLogin = () => {
		history.push("/account/login");
	};

	const currentCountry = (value) => {
		if (value == "United States of America") setCCountry(true);
		else setCCountry(false);
	};

	const calculateAge = (dateOfBirth) => {
		var dob = new Date(dateOfBirth);

		var month_diff = Date.now() - dob.getTime();

		var age_dt = new Date(month_diff);

		var year = age_dt.getUTCFullYear();

		var age = Math.abs(year - 1970);

		return age;
	};

	const register = async (req) => {
		if (!req.country || req.country.toUpperCase() !== userCountry.toUpperCase()) { // Check if country is not selected or doesn't match user's country
			setCountryError(true); // Set the error state to true
			return;
		}
		setCountryError(false);
		setSubmitting(true);
		const age = calculateAge(req.dateOfBirth);
		if (age < 18) {
			message.error("Investor age should be greater than or eqauls to 18!");
			setSubmitting(false);
			return;
		}
		try {
			let res = await accountService.getSeriesCompanyDetails(seriesId);
			let seriesDetails = res.data;
			if (seriesId && seriesDetails?.companyId) {
				req.seriesId = seriesId;
				req.code = code;
				req.companyObj = { id: seriesDetails.companyId, name: seriesDetails.name };
				req.companyId = seriesDetails.companyId;
			} else {
				return message.error("Issuer company not found, please try after some time!");
			}

			const response = await accountService.registerInvestor(req);
			if (response.success) {
				message.success("Successfully registered.");
				setRegistered(true);
				form.resetFields();
			} else {
				message.error(response.error.message);
			}
		} catch (error) {
			console.error(error);
			message.error("Something went wrong while registering!");
		}

		setSubmitting(false);
	};

	// useEffect(() => {
	//   form.setFieldsValue({ walletAddress: selectedWallet });
	// }, [selectedWallet, form]);
	// form.setFieldsValue({ walletAddress: selectedWallet });
	if (email) {
		form.setFieldsValue({ email: email });
	}
	return (
		<>
			{/* <SelectedWalletContext.Provider value={{ selectedWallet, networkId }}>
        <div hidden={!isNotConnectedToMetamask}>
          <ConnectToMetamask></ConnectToMetamask>
        </div> */}
			<div hidden={isNotConnectedToMetamask}>
				<Row justify="center">
					<Col span={20}>
						<Card>
							<div hidden={registered}>
								{registered && (
									<Alert
										message="We have received your registration request. Please self verify your email address to proceed."
										type="success"
									></Alert>
								)}
								<Link to="/account/login">
									<h4 style={{ textAlign: "center" }}>Already An Investor? Login Now</h4>
								</Link>
								<h3 style={{ textAlign: "center" }}>Investor Registration</h3>
								<Form
									{...formItemLayout}
									form={form}
									onFinish={register}
									style={{ width: "100%", display: "grid", gap: "1rem" }}
								>
									<Form.Item
										label="First name"
										name="firstName"
										rules={[
											{
												required: true,
												message: "Please input your First Name!",
												whitespace: true,
											},
										]}
									>
										<Input />
									</Form.Item>

									<Form.Item
										label="Last name"
										name="lastName"
										rules={[
											{
												required: true,
												message: "Please input your Last Name!",
												whitespace: true,
											},
										]}
									>
										<Input />
									</Form.Item>

									<Form.Item
										label="E-mail"
										name="email"
										rules={[
											{
												type: "email",
												message: "The input is not valid E-mail!",
											},
											{
												required: true,
												message: "Please input your E-mail!",
											},
										]}
									>
										{email ? <Input disabled /> : <Input />}
									</Form.Item>

									<Form.Item
										label="Password"
										name="password"
										hasFeedback
										rules={[
											{
												required: true,
												message: "Please input your password!",
											},
											{
												validator: (rule, value) => {
													const strongRegex =
														/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$\-_[\](){}<>|°¬+=~".,;:'#!%*?&])[A-Za-z\d@$\-_[\](){}<>|°¬+=~".,;:'#!%*?&]{8,}$/;
													if (strongRegex.test(value)) return Promise.resolve();
													return Promise.reject(
														"The password must contains at least 1 number, 1 lowercase, 1 uppercase and 1 special character and 8 characters long"
													);
												},
											},
										]}
									>
										<Input.Password />
									</Form.Item>

									<Form.Item
										label="Confirm Password"
										name="confirm"
										dependencies={["password"]}
										hasFeedback
										rules={[
											{
												required: true,
												message: "Please confirm your password!",
											},
											({ getFieldValue }) => ({
												validator(rule, value) {
													if (!value || getFieldValue("password") === value) return Promise.resolve();
													return Promise.reject("The two passwords that you entered do not match!");
												},
											}),
										]}
									>
										<Input.Password />
									</Form.Item>
									<Form.Item
										label="Date of birth"
										name="dateOfBirth"
										rules={[
											{
												required: true,
												message: "Please enter your date of birth",
											},
										]}
									>
										<DatePicker style={{ width: "100%" }} />
									</Form.Item>

									{/* <Form.Item
                      label="Wallet Address"
                      name="walletAddress"
                      rules={[
                        {
                          required: true,
                          message: "Please connect to metamask",
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input style={{ color: "green" }} disabled />
                    </Form.Item> */}

									<Form.Item
										label="Country"
										name="country"
										validateStatus={countries ? "" : "validating"}
										//hasFeedback
										help={countries ? "" : "Loading Countries..."}
                    
									>
										<Select
											showSearch
											placeholder="Select Country"
											onChange={currentCountry}
											// autoComplete="cCountry"
										>
											{countries?.map((country) => (
												<Option key={country._id} value={country.name["en"]}>
													{country.name["en"]}
												</Option>
											))}
										</Select>
                                    
									</Form.Item>

									{countryError && (
										<Alert
											message="The country you select and the country you are operating are different you can only register from the country you select"
											type="error"
										></Alert>
									)}
									<Form.Item
										label="Contact Number"
										name="contactNumber"
										width="100"
										rules={[
											{
												required: true,
												message: "Enter Contact Number ",
												whitespace: true,
											},
										]}
										// width
									>
										<PhoneInput country={"us"} />
									</Form.Item>
									{cCountry && (
										<Form.Item
											label="State"
											name="state"
											rules={[
												{
													required: true,
													message: "Please input state!",
													whitespace: true,
												},
											]}
										>
											<Select showSearch placeholder="Select state" autoComplete="new-password">
												{usaStates?.map((state) => (
													<Option key={state?.state_id} value={state.state_name}>
														{state.state_name}
													</Option>
												))}
											</Select>
										</Form.Item>
									)}
									{!cCountry && (
										<Form.Item
											label="State"
											name="state"
											rules={[
												{
													required: true,
													message: "Please input state!",
													whitespace: true,
												},
											]}
										>
											<Input />
										</Form.Item>
									)}
									<Form.Item label="City" name="city" rules={[{ required: true, message: "City is required" }]}>
										<Input placeholder="" />
									</Form.Item>
									<Form.Item
										label="Street Address 1 :"
										name="streetAddress1"
										rules={[
											{
												required: true,
												message: "Street address 1 is required",
											},
										]}
									>
										<Input placeholder="" />
									</Form.Item>
									<Form.Item
										label="Street Address 2:"
										name="streetAddress2"
										rules={[
											{
												required: true,
												message: "Street address 2 is required",
											},
										]}
									>
										<Input placeholder="" />
									</Form.Item>

									<Form.Item
										label="PINCode"
										name="zipCode"
										rules={[
											{
												required: true,
												message: "Please input PINCode!",
											},
										]}
									>
										<Input min={0} style={{ width: "100%" }} />
									</Form.Item>

									<Checkbox
										checked={termsCheck}
										onChange={() => setTermsCheck(!termsCheck)}
										style={{ width: "100%", marginBottom: "20px" }}
									>
										I hereby accept all <Link to="/terms-of-use">terms and conditions</Link> of the platform
									</Checkbox>

									<Form.Item {...tailFormItemLayout}>
										<Button type="primary" htmlType="submit" loading={submitting} disabled={!termsCheck}>
											Register
										</Button>
										<br />
										<>Or </>
										<Link to="/account/login">Login Now!</Link>
									</Form.Item>
								</Form>
							</div>
							<div hidden={!registered}>
								<Result
									status="success"
									title="We received your registration request."
									subTitle="Wait for the approval by the system to get successfully registered to Retap India."
									extra={[
										<Button type="primary" htmlType="button" onClick={forwardToLogin}>
											Login
										</Button>,
									]}
								></Result>
							</div>
						</Card>
					</Col>
				</Row>
			</div>
			{/* </SelectedWalletContext.Provider> */}
		</>
	);
};
